import Buttons from "views/Components/Buttons.js";
import Calendar from "views/Calendar/Calendar.js";
import Charts from "views/Charts/Charts.js";
// import Dashboard from "views/Dashboard/Dashboard.js";
import ErrorPage from "views/Pages/ErrorPage.js";
import ExtendedForms from "views/Forms/ExtendedForms.js";
import ExtendedTables from "views/Tables/ExtendedTables.js";
import FullScreenMap from "views/Maps/FullScreenMap.js";
import GoogleMaps from "views/Maps/GoogleMaps.js";
import GridSystem from "views/Components/GridSystem.js";
import Icons from "views/Components/Icons.js";
import LockScreenPage from "views/Pages/LockScreenPage.js";
// import LoginPage from "views/Pages/LoginPage.js";
import Notifications from "views/Components/Notifications.js";
import Panels from "views/Components/Panels.js";
import PricingPage from "views/Pages/PricingPage.js";
import ReactTables from "views/Tables/ReactTables.js";
import RegisterPage from "views/Pages/RegisterPage.js";
import RegularForms from "views/Forms/RegularForms.js";
import RegularTables from "views/Tables/RegularTables.js";
import SweetAlert from "views/Components/SweetAlert.js";
import TimelinePage from "views/Pages/Timeline.js";
import Typography from "views/Components/Typography.js";
import UserProfile from "views/Pages/UserProfile.js";
import ValidationForms from "views/Forms/ValidationForms.js";
import VectorMap from "views/Maps/VectorMap.js";
import Widgets from "views/Widgets/Widgets.js";
import Wizard from "views/Forms/Wizard.js";

 import Dashboard from "Screens/Home/Dashboard.js"
 import LoginPage from "Screens/Login/Main.js"
 import AdminUsers from "Screens/Manager/Users.js"
 import Services from "Screens/Manager/Services.js"
 import Categories from "Screens/Manager/Categories.js"
// import Receipts from "Screens/Manager/Receipts.js"
// import Comissions from "Screens/Manager/Comissions.js"
 import Extract from "Screens/Manager/Extract.js"
 import Reports from "Screens/Manager/Reports.js"

import Customers from "Screens/Customers/Main.js"
import Customer from "Screens/Customers/View.js"
import CustomerEdit from "Screens/Customers/Edit.js"
// import CustomerConversations from "Screens/Customers/Conversations.js"
// import CustomerHelpdesk from "Screens/Customers/Helpdesk.js"

import Approval from "Screens/Providers/Approval.js"
import ApprovalView from "Screens/Providers/ApprovalView.js"
import Providers from "Screens/Providers/Main.js"
import Provider from "Screens/Providers/View.js"
import ProviderEdit from "Screens/Providers/Edit.js"
// import ProviderConversations from "Screens/Providers/Conversations.js"
// import ProviderHelpdesk from "Screens/Providers/Helpdesk.js"
// import ProviderHelpdesk from "Screens/Providers/Helpdesk.js"

// import ServiceOffers from "Screens/ServiceOrder/Offers.js"
 import ServiceOrders from "Screens/ServiceManagement/Main.js"
 import ServiceOrder from "Screens/ServiceManagement/View.js"
 // import ServiceSimulation from "Screens/ServiceOrder/Simulation.js"

 import Complaints from "Screens/Support/Complaints.js"
 import Helpdesk from "Screens/Support/Helpdesk.js"
// import ManageChats from "Screens/Support/Chats.js"
// import KB from "Screens/Support/KB.js"

import Configurations from "Screens/Configurations/Main.js"
import Regions from "Screens/Configurations/Regions.js"
import Comissions from "Screens/Configurations/Comissions.js"

// Screens
import Blank from "views/Pages/Blank.js";


// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DateRange from "@material-ui/icons/DateRange";
import GridOn from "@material-ui/icons/GridOn";
import Image from "@material-ui/icons/Image";
import Place from "@material-ui/icons/Place";
import Timeline from "@material-ui/icons/Timeline";
import WidgetsIcon from "@material-ui/icons/Widgets";

import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import PeopleIcon from '@material-ui/icons/People';
import ContactsIcon from '@material-ui/icons/Contacts';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import PieChartIcon from '@material-ui/icons/PieChart';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import ForumIcon from '@material-ui/icons/Forum';
import PaymentIcon from '@material-ui/icons/Payment';
import CodeIcon from '@material-ui/icons/Code';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import CategoryIcon from '@material-ui/icons/Category';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import GamesIcon from '@material-ui/icons/Games';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Início",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin",
  },
  {
    collapse: true,
    name: "Área Gerencial",
    icon: BusinessCenterIcon,
    state: "item1",
    views: [
      {
        path: "/adminusers",
        name: "Gestão de Usuários",
        mini: "GU",
        component: AdminUsers,
        layout: "/admin",
      },
      {
        path: "/manageservices",
        name: "Cadastro de Serviços",
        mini: "Ser",
		component: Services,
        layout: "/admin",
      },
      {
        path: "/managecategories",
        name: "Cadastro de Categorias",
        mini: "Cat",
		component: Categories,
        layout: "/admin",
      },	  
      {
        path: "/incoming",
        name: "Gestão de Recebimentos",
        mini: "GR",
		component: Extract,
        layout: "/admin",
      },
     /* {
        path: "/comission",
        name: "Extrato Comissões",
        mini: "EC",
		component: Blank,
        layout: "/admin",
      },	  
      {
        path: "/extract",
        name: "Extrato Financeiro",
        mini: "EF",
		component: Blank,
        layout: "/admin",
      },	*/	  
      {
        path: "/reports",
        name: "Relatórios",
        mini: "REP",
        component: Reports,
        layout: "/admin",
      },	  
    ],
  },  
  {
    collapse: true,
    name: "Gestão de Clientes",
    icon: PeopleIcon,
    state: "item2",
    views: [
      {
        path: "/customers",
        name: "Cadastro de Clientes",
        mini: "CLI",
		child:"customer",
        component: Customers,
        layout: "/admin",
      },
	  {
		path: "/customer/edit/:id",
		component: CustomerEdit,
		layout: "/admin",
		name: "Edição de cliente",
		disabled:1
	  }, 	  
	  {
		path: "/customer/:id",
		component: Customer,
		layout: "/admin",
		name: "Visualização de cliente",
		disabled:1
	  }, 	  	  	  
      /*{
        path: "/customersChat",
        name: "Conversas",
        mini: "CON",
        component: Blank,
        layout: "/admin",
      },
      {
        path: "/customersTickets",
        name: "Chamados Helpdesk",
        mini: "CH",
        component: Blank,
        layout: "/admin",
      },	*/  
    ],
  },    
  
  {
    collapse: true,
    name: "Gestão de Prestadores",
    icon: ContactsIcon,
    state: "item3",
    views: [
      {
        path: "/approveproviders",
        name: "Aprovação de cadastros",
        mini: "AP",
		child:"approveprovider",
        component: Approval,
        layout: "/admin",
      },
	  {
		path: "/approveprovider/:id",
		component: ApprovalView,
		layout: "/admin",
		name: "Aprovação de cadastro",
		disabled:1
	  },  
      {
        path: "/providers",
        name: "Cadastro de prestadores",
        mini: "CP",
		child:"provider",
        component: Providers,
        layout: "/admin",
      },
	  {
		path: "/provider/edit/:id",
		component: ProviderEdit,
		layout: "/admin",
		name: "Edição de cadastro de prestador",
		disabled:1
	  }, 	  
	  {
		path: "/provider/:id",
		component: Provider,
		layout: "/admin",
		name: "Cadastro de prestador",
		disabled:1
	  }, 
     /* {
        path: "/providersChat",
        name: "Conversas",
        mini: "CON",
        component: Blank,
        layout: "/admin",
      },
      {
        path: "/providersTickets",
        name: "Chamados Helpdesk",
        mini: "CH",
        component: Blank,
        layout: "/admin",
      }	*/ 
    ],
  },    
  
  {
    collapse: true,
    name: "Gestão de Serviços",
    icon: WorkOutlineIcon,
    state: "item4",
    views: [
      /*{
        path: "/serviceoffers",
        name: "Ofertas de Serviço",
        mini: "OFR",
        component: Blank,
        layout: "/admin",
      },*/
      {
        path: "/serviceorders",
        name: "Ordens de Serviço",
        mini: "OS",
		child:"service",
        component: ServiceOrders,
        layout: "/admin",
      },
	  {
		path: "/service/:id",
		component: ServiceOrder,
		layout: "/admin",
		name: "Ordem de serviço",
		disabled:1
	  }, 	  
     /* {
        path: "/simulateServiceOffer",
        name: "Simulação de solicitação",
        mini: "SIM",
        component: Blank,
        layout: "/admin",
      },*/	  
    ],
  },    
  
  {
    collapse: true,
    name: "Central de Suporte",
    icon: HelpOutlineIcon,
    state: "item5",
    views: [
      {
        path: "/complaints",
        name: "Denúncias e Reclamações",
        mini: "DEN",
        component: Complaints,
        layout: "/admin",
      },	
      {
        path: "/helpdesk",
        name: "Gestão de Chamados",
        mini: "GC",
        component: Blank,
        layout: "/admin",
      },
      /*{
        path: "/onlinechat",
        name: "Chat Online",
        mini: "CH",
        component: Blank,
        layout: "/admin",
      },*/
     /* {
        path: "/kb",
        name: "Base de Conhecimento",
        mini: "KB",
        component: RegisterPage,
        layout: "/admin",
      },*/	  
    ],
  },    
    
    
  {
    collapse: true,
    name: "Configurações",
    icon: PermDataSettingIcon,
    state: "item6",
    views: [
      {
        path: "/configurations",
        name: "Gerais",
        mini: "GEN",
        component: Configurations,
        layout: "/admin",
      },
      {
        path: "/regions",
        name: "Cadastro de Regiões",
        mini: "REG",
        component: Regions,
        layout: "/admin",
      },
      {
        path: "/comissions",
        name: "Tabela de Comissão",
        mini: "COM",
        component: Comissions,
        layout: "/admin",
      }
    ],
  },    
  {
	path: "/login",
	component: LoginPage,
	layout: "/auth",
	name: "Login",
	disabled:1
  },  


/*
  
  {
    collapse: true,
    name: "Pages",
    icon: Image,
    state: "pageCollapse",
    views: [
      {
        path: "/pricing-page",
        name: "Pricing Page",
        mini: "PP",
        component: PricingPage,
        layout: "/auth",
      },
      {
        path: "/timeline-page",
        name: "Timeline Page",
        rtlName: "تيالجدول الزمني",
        mini: "T",
        rtlMini: "تي",
        component: TimelinePage,
        layout: "/admin",
      },
      {
        path: "/login",
        name: "Login Page",
        rtlName: "هعذاتسجيل الدخول",
        mini: "L",
        rtlMini: "هعذا",
        component: LoginPage,
        layout: "/auth",
      },
      {
        path: "/register-page",
        name: "Register Page",
        rtlName: "تسجيل",
        mini: "R",
        rtlMini: "صع",
        component: RegisterPage,
        layout: "/auth",
      },
      {
        path: "/lock-screen-page",
        name: "Lock Screen Page",
        rtlName: "اقفل الشاشة",
        mini: "LS",
        rtlMini: "هذاع",
        component: LockScreenPage,
        layout: "/auth",
      },
      {
        path: "/user-page",
        name: "User Profile",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UP",
        rtlMini: "شع",
        component: UserProfile,
        layout: "/admin",
      },
      {
        path: "/error-page",
        name: "Error Page",
        rtlName: "صفحة الخطأ",
        mini: "E",
        rtlMini: "البريد",
        component: ErrorPage,
        layout: "/auth",
      },
    ],
  },
  {
    collapse: true,
    name: "Components",
    icon: Apps,
    state: "componentsCollapse",
    views: [
      {
        collapse: true,
        name: "Multi Level Collapse",
        mini: "MC",
        rtlMini: "ر",
        state: "multiCollapse",
        views: [
          {
            path: "#sample-path",
            name: "Example",
            rtlName: "مثال",
            mini: "E",
            rtlMini: "ه",
            component: () => {},
            layout: "#sample-layout",
          },
        ],
      },
      {
        path: "/buttons",
        name: "Buttons",
        rtlName: "وصفت",
        mini: "B",
        rtlMini: "ب",
        component: Buttons,
        layout: "/admin",
      },
      {
        path: "/grid-system",
        name: "Grid System",
        rtlName: "نظام الشبكة",
        mini: "GS",
        rtlMini: "زو",
        component: GridSystem,
        layout: "/admin",
      },
      {
        path: "/panels",
        name: "Panels",
        rtlName: "لوحات",
        mini: "P",
        rtlMini: "ع",
        component: Panels,
        layout: "/admin",
      },
      {
        path: "/sweet-alert",
        name: "Sweet Alert",
        rtlName: "الحلو تنبيه",
        mini: "SA",
        rtlMini: "ومن",
        component: SweetAlert,
        layout: "/admin",
      },
      {
        path: "/notifications",
        name: "Notifications",
        rtlName: "إخطارات",
        mini: "N",
        rtlMini: "ن",
        component: Notifications,
        layout: "/admin",
      },
      {
        path: "/icons",
        name: "Icons",
        rtlName: "الرموز",
        mini: "I",
        rtlMini: "و",
        component: Icons,
        layout: "/admin",
      },
      {
        path: "/typography",
        name: "Typography",
        rtlName: "طباعة",
        mini: "T",
        rtlMini: "ر",
        component: Typography,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Forms",
    rtlName: "إستمارات",
    icon: "content_paste",
    state: "formsCollapse",
    views: [
      {
        path: "/regular-forms",
        name: "Regular Forms",
        rtlName: "أشكال عادية",
        mini: "RF",
        rtlMini: "صو",
        component: RegularForms,
        layout: "/admin",
      },
      {
        path: "/extended-forms",
        name: "Extended Forms",
        rtlName: "نماذج موسعة",
        mini: "EF",
        rtlMini: "هوو",
        component: ExtendedForms,
        layout: "/admin",
      },
      {
        path: "/validation-forms",
        name: "Validation Forms",
        rtlName: "نماذج التحقق من الصحة",
        mini: "VF",
        rtlMini: "تو",
        component: ValidationForms,
        layout: "/admin",
      },
      {
        path: "/wizard",
        name: "Wizard",
        rtlName: "ساحر",
        mini: "W",
        rtlMini: "ث",
        component: Wizard,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Tables",
    rtlName: "الجداول",
    icon: GridOn,
    state: "tablesCollapse",
    views: [
      {
        path: "/regular-tables",
        name: "Regular Tables",
        rtlName: "طاولات عادية",
        mini: "RT",
        rtlMini: "صر",
        component: RegularTables,
        layout: "/admin",
      },
      {
        path: "/extended-tables",
        name: "Extended Tables",
        rtlName: "جداول ممتدة",
        mini: "ET",
        rtlMini: "هور",
        component: ExtendedTables,
        layout: "/admin",
      },
      {
        path: "/react-tables",
        name: "React Tables",
        rtlName: "رد فعل الطاولة",
        mini: "RT",
        rtlMini: "در",
        component: ReactTables,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Maps",
    rtlName: "خرائط",
    icon: Place,
    state: "mapsCollapse",
    views: [
      {
        path: "/google-maps",
        name: "Google Maps",
        rtlName: "خرائط جوجل",
        mini: "GM",
        rtlMini: "زم",
        component: GoogleMaps,
        layout: "/admin",
      },
      {
        path: "/full-screen-maps",
        name: "Full Screen Map",
        rtlName: "خريطة كاملة الشاشة",
        mini: "FSM",
        rtlMini: "ووم",
        component: FullScreenMap,
        layout: "/admin",
      },
      {
        path: "/vector-maps",
        name: "Vector Map",
        rtlName: "خريطة المتجه",
        mini: "VM",
        rtlMini: "تم",
        component: VectorMap,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/widgets",
    name: "Widgets",
    rtlName: "الحاجيات",
    icon: WidgetsIcon,
    component: Widgets,
    layout: "/admin",
  },
  {
    path: "/charts",
    name: "Charts",
    rtlName: "الرسوم البيانية",
    icon: Timeline,
    component: Charts,
    layout: "/admin",
  },
  {
    path: "/calendar",
    name: "Calendar",
    rtlName: "التقويم",
    icon: DateRange,
    component: Calendar,
    layout: "/admin",
  },
  */
  
  
];
export default dashRoutes;
