import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// @material-ui/core components
import { makeStyles,lighten } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import * as config from '../../Config/api.js';

import { DataGrid } from '@material-ui/data-grid';
import moment from "moment";

import {Link,useParams, useHistory} from "react-router-dom";

import ReactStars from 'react-stars'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import CheckIcon from '@material-ui/icons/Check';
import NotIcon from '@material-ui/icons/Block';
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';

import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import MenuItem from '@material-ui/core/MenuItem';

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import Person from "@material-ui/icons/Person";
import View from '@material-ui/icons/Visibility';
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import RoomIcon from '@material-ui/icons/Room';

import { Button } from 'primereact/button';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Table from "components/Table/Table.js";
// import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

// Load Actions
import * as AppActions from 'Store/Actions/App'
import * as ProviderActions from 'Store/Actions/Provider'


const useStyles = makeStyles(styles);

const ProviderView = props => {

// set vars from props
const { 
	appLoading,
	setDialog,
	dialog,
	providerApproval,
	retrieveProvider,
	setAppLoading	
} = props;		

const { id } = useParams();

const [provider, setProviderCol] = React.useState({});
const [mapImageUrl, setMapImageUrl] = React.useState("");  
const [avatar, setAvatar] = React.useState(null);  
const [contentVisible, setContentVisible] = React.useState(false);  

const [dialogAlert, setAlertDialog] = React.useState(false); // state for tab
const [dialogMessage, setDialogMessage] = React.useState(""); // state for tab
const [dialogTitle, setDialogTitle] = React.useState("Verifique as informações"); // state for tab
const [dialogAction, setDialogAction] = React.useState(false); // state for tab
  
const [agendaGrid, setAgenda] = React.useState([]); // set agenda obj

const [value, setValue] = React.useState(0);

const classes = useStyles();


console.log("[provider]--->"+JSON.stringify(provider,null,2));

 
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const handleChange = (event, newValue) => {
setValue(newValue);
};
 
const calculateAge = (birthday) => {

	var a = moment();
	var b = moment(birthday, 'YYYY-MM-DD');  
	var diff = a.diff(b, 'years'); // calculates patient's age in years
	return diff; // this prints out the age

} 

const maskDocument = (document) => {
	
	// Clean and format
	var cleaned = ('' + document).replace(/\D/g, '')
	  var match = cleaned.match(/^(\d{3})(\d{3})(\d{3})(\d{2})$/)
	  if (match) {
		return '' + match[1] + '.' + match[2] + '.' + match[3]+ '-' + match[4];
	  } // end if
	  else {
		return "CPF Inválido";
	  } // end else	  
	
}

const maskCompanyDocument = (document) => {
	
	// Clean and format
	var cleaned = ('' + document).replace(/\D/g, '')
	  var match = cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/)
	  if (match) {
		return '' + match[1] + '.' + match[2] + '.' + match[3] + '/' + match[4] + '-' + match[5];
	  } // end if
	  else {
		return "CNPJ Inválido";
	  } // end else	  
	
}

const maskPhone = (phone) => {
	
	// Clean and format
	var cleaned = ('' + phone).replace(/\D/g, '')
	  var match = cleaned.match(/^(\d{2})\D*(\d{5}|\d{4})\D*(\d{4})$/)
	  if (match) {
		return '(' + match[1] + ') ' + match[2] + '-' + match[3];
	  } // end if
	  else {
		return "Telefone Inválido";
	  } // end else	  
	
}

const maskCep = (cep) => {
	
	// Clean and format
	var cleaned = ('' + cep).replace(/\D/g, '')
	  var match = cleaned.match(/^(\d{5})(\d{3})$/)
	  if (match) {
		return match[1] + '-' + match[2];
	  } // end if
	  else {
		return "CEP Inválido";
	  } // end else	  
	
}

const maskGender = (gender) => {
	
	if (gender === 1) {
		return "Masculino";
	} // end if
	else if (gender === 2) {
		return "Feminino";
	} // end else if
	else {
		return "Indefinido";
	} // end else
	
}

// set Use of history for PUSH 
let history = useHistory();
  
// When mount component
React.useEffect(() => {

	setAppLoading(true);

	var propsParam = {
		setProviderCol:setProviderCol,
		setMapImageUrl:setMapImageUrl,
		setAvatar:setAvatar,
		setAgenda:setAgenda,
		setContentVisible:setContentVisible
	}
	retrieveProvider(id,propsParam);

			
}, []);  

if(!contentVisible) {	  
	return (
		<div style={{width:"100%",height:"80vh",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
		
			<div style={{display:"flex",flex:1,alignItems:"center",justifyContent:"center"}}>
			
				<CircularProgress color="primary" />
				<div style={{fontSize:"18px",marginLeft:"15px"}}> Carregando dados...</div>
			
			</div>
		
		</div>
	)
return;
  }


if(contentVisible) {	 
  return ( 
  <>	
   <Box ml={3} mb={5}>
   
	 <Breadcrumbs aria-label="breadcrumb">
	  <Typography color="textSecondary">Gestão de Prestadores</Typography>
	  <Typography color="textSecondary"><Link to="/admin/providers">Cadastro de prestadores</Link></Typography>
	  <Typography color="textPrimary">Visualizando Cadastro</Typography>
	</Breadcrumbs>
	
	<Divider />

	{provider.status != "9" &&
	<span style={{float:"right",marginRight:"30px",marginTop:"30px"}}>
		<Button variant="contained" 
			onClick={() => alert("Em desenvolvimento...") }
		 >
		  Editar Cadastro
		</Button>
	</span>	
	}
	
	<h3 style={{marginTop:"30px"}}>Visualizando <strong>{provider.ProviderProfile.ProviderProfileCompany.realName}</strong></h3>	
	<h5>Navegue nas informações do prestador abaixo.</h5>
		
	</Box>
  
    <GridContainer>
	
      <GridItem xs={12}>

		  <Tabs
			value={value}
			onChange={handleChange}
			indicatorColor="primary"
			textColor="primary"
			variant="scrollable"
			scrollButtons="auto"
			centered
		  >
			<Tab label="Dados do cadastro" />
			<Tab disabled={provider.status == 3 || provider.status == 2 || provider.status == 9 ? false : true} label="Dados Bancários" />
			<Tab disabled={provider.status == 3 || provider.status == 2 || provider.status == 9 ? false : true} label="Grade de Atendimento" />
			<Tab disabled={provider.status == 3 || provider.status == 2 || provider.status == 9 ? false : true} label="Cadastro de Colaboradores" />
			<Tab disabled={provider.status == 3 || provider.status == 9 ? false : true} label="Ordens de serviço" />
			<Tab disabled={provider.status == 3 || provider.status == 9 ? false : true} label="Conversas" />
			<Tab disabled={provider.status == 3 || provider.status == 9 ? false : true} label="Chamados" />
		</Tabs>

     	  <Divider />
			<TabPanel value={value} index={0}>

					<Card>
					  <CardHeader color="info" icon>
						<CardIcon color="info">
						  <Assignment />
						</CardIcon>
						<h4 className={classes.cardIconTitle}>Dados Cadastrais</h4>
					  </CardHeader>
					  <CardBody>	 

						<TableContainer component={Box} mt={2}>
						  <Table style={{minWwidth:"500px"}} aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Status e qualificação</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">
									  Status do Cadastro <br />

									  { provider.status != 9 && 
										 <small style={{color:"silver"}}> Aprovado por [Nome Usuário]</small>
									  }		

									  { provider.status === 9 && 
										<small style={{color:"silver"}}> Encerrado em {moment(provider.deletedAt).format("DD/MM/YYYY")}</small>
									  }		
									  
								  </TableCell>
								  <TableCell align="left">
								  
								  {provider.status == 3 &&
								  <>
									<span class="product-badge status-ativado">Cadastro completo</span> <small><i>em {moment(provider.updatedAt).format("DD/MM/YYYY")}</i></small>
								  </>
								  }
								  
								  {provider.status == 2 &&
								  <>
									<span class="product-badge status-review">Validação pendente</span> <small><i>O prestador fez o complemento do cadastro mas não validou o telefone ou email ainda.</i></small>
									
											<Button label="Validar manualmente" style={{float:"right",marginLeft:"25px",height:"30px"}} className="p-button-raised p-button-sm p-button-primary p-button-text" onClick={() => alert("Em desenvolvimento...") }  />									
									
								  </>
								  }

								  {provider.status == 1 &&
								  <>
									<span class="product-badge status-bloqueado">Complemento pendente</span> <small><i>O prestador não acessou o app após aprovação.</i></small>
								  </>
								  }			
									  
								  { provider.status === 9 && 
									  <>
										<span class="product-badge status-encerrado">Encerrado</span> <small><i> Prestador foi excluído da plataforma </i></small>
									  </>
								  }									  
								  
								  
								  </TableCell>
								</TableRow>
								
																  
							  {(provider.status == 3 || provider.status == 2) &&
							  <>
								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Status da Conta IUGU</TableCell>
								  <TableCell align="left">
								  
								  	{provider.iuguStatus == 0 &&
									  <>
										<span class="product-badge status-review">Conta em análise na IUGU</span> <small><i>O prestador não pode ficar disponível para receber oportunidades.</i></small>
									  </>
									  }		

									  {provider.iuguStatus == 1 &&
									  <>
										<span class="product-badge status-ativado">Conta aprovada na IUGU</span> <small><i>Subconta aprovada na IUGU em {moment(provider.ProviderIuguAccount.verifiedAt).format("DD/MM/YYYY")}</i></small>
										
											{provider.iuguBankStatus == 0 &&
											  <>
												<p style={{marginTop:"10px"}}>
												<span class="product-badge status-review">Conta bancária não verificada</span> <small><i>Prestador recebe mas não transfere para conta bancária.</i></small>
												</p>
											  </>
											  }		

											  {provider.iuguBankStatus == 1 &&
											  <>
												<p style={{marginTop:"10px"}}>
												<span class="product-badge status-ativado">Conta bancária verificada</span> <small><i>Prestador recebe e transfere para conta bancária.</i></small>
												</p>
											  </>
											  }	
										
									  </>
									  }	

									  {provider.iuguStatus == 2 &&
									  <>
										<span class="product-badge status-bloqueado">Conta não aprovada na IUGU</span> <small><i>Subconta reprovada na IUGU em {moment(provider.ProviderIuguAccount.updatedAt).format("DD/MM/YYYY")}</i></small>
									  </>
									  }							  
								  
								  </TableCell>
								</TableRow>
								
								<TableRow >
								  <TableCell component="th" scope="row">Status de disponibilidade</TableCell>
								  <TableCell align="left">
								  
									  {provider.avaibilityStatus == 0 &&
									  <>
										<span class="product-badge status-bloqueado">Indisponível</span> <small><i>O prestador não recebe ofertas de serviço.</i></small>
									  </>
									  }		

									  {provider.avaibilityStatus == 1 &&
									  <>
										<span class="product-badge status-ativado">Disponível</span> <small><i>O prestador pode receber ofertas de serviço.</i></small>
									  </>
									  }											  
								  
								  </TableCell>
								</TableRow>
								
								<TableRow >
								  <TableCell component="th" scope="row">Qualificação na plataforma</TableCell>
								  <TableCell align="left">
								  
										<div>
										
										
										{provider.ProviderRating &&
											<ReactStars
											  count={5}
											  edit={false}
											  value={provider.ProviderRating.generalRating}
											  half={true}
											  size={36}
											  color2={'#ffd700'} />	
										}
										
										{!provider.ProviderRating &&
												<div>Prestador ainda não foi qualificado</div>
										}										
										
										</div>								  
											
								  </TableCell>
								</TableRow>	
							 </>
							 }								
								

							</TableBody>
						  </Table>
						</TableContainer>
						
						{provider.status > 2 &&
						<TableContainer component={Box} mt={2}>
						  <Table style={{minWwidth:"500px"}} aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Dados da Validação do Perfil</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Código de Validação</TableCell>
								  <TableCell align="left">{provider.activationCode}</TableCell>
								</TableRow>
								
								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Data de Validação</TableCell>
								  <TableCell align="left">{moment(provider.activationDate).format("DD/MM/YYYY")}</TableCell>
								</TableRow>								

								<TableRow >
								  <TableCell component="th" scope="row">Método de Validação</TableCell>
								  <TableCell align="left">{provider.activationMethod}</TableCell>
								</TableRow>

							</TableBody>
						  </Table>
						</TableContainer>
						}							
							  
				 		
						<TableContainer component={Box} mt={2}>
						  <Table style={{minWwidth:"500px"}} aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Dados do Contato</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Avatar</TableCell>
								  <TableCell align="left">
									<img src={avatar} style={{height:"250px",width:"auto",backgroundColor:"gray"}} />
								 </TableCell>
								</TableRow>

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Nome Completo</TableCell>
								  <TableCell align="left">{provider.ProviderProfile.ProviderProfilePerson.completeName}</TableCell>
								</TableRow>

								<TableRow >
								  <TableCell component="th" scope="row">Email</TableCell>
								  <TableCell align="left">{provider.email}</TableCell>
								</TableRow>

								<TableRow >
								  <TableCell component="th" scope="row">Telefone Celular</TableCell>
								  <TableCell align="left">{maskPhone(provider.mobilePhone)}</TableCell>
								</TableRow>

								<TableRow >
								  <TableCell component="th" scope="row">Data de Nascimento</TableCell>
								  <TableCell align="left">{moment(provider.ProviderProfile.ProviderProfilePerson.birthday).format("DD/MM/YYYY")} - ({calculateAge(provider.ProviderProfile.ProviderProfilePerson.birthday)} anos)</TableCell>
								</TableRow>

								<TableRow >
								  <TableCell component="th" scope="row">CPF</TableCell>
								  <TableCell align="left">{maskDocument(provider.ProviderProfile.ProviderProfilePerson.personalDocument)}</TableCell>
								</TableRow>

							</TableBody>
						  </Table>
						</TableContainer>
		

						<TableContainer component={Box} mt={2}>
						  <Table style={{minWwidth:"500px"}} aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Dados da Empresa</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Razão Social</TableCell>
								  <TableCell align="left">{provider.ProviderProfile.ProviderProfileCompany.realName}</TableCell>
								</TableRow>

								<TableRow >
								  <TableCell component="th" scope="row">Nome Fantasia</TableCell>
								  <TableCell align="left">{provider.ProviderProfile.ProviderProfileCompany.fantasyName}</TableCell>
								</TableRow>

								<TableRow >
								  <TableCell component="th" scope="row">CNPJ</TableCell>
								  <TableCell align="left">{maskCompanyDocument(provider.ProviderProfile.ProviderProfileCompany.document)}</TableCell>
								</TableRow>

							</TableBody>
						  </Table>
						</TableContainer>
						

						<TableContainer component={Box} mt={2}>
						  <Table style={{minWwidth:"500px"}} aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Endereço da empresa</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>


								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">CEP</TableCell>
								  <TableCell align="left">{maskCep(provider.ProviderProfile.ProviderAddress.postalCode)}</TableCell>
								</TableRow>

								<TableRow >
								  <TableCell component="th" scope="row">Endereço Completo</TableCell>
								  <TableCell align="left">{provider.ProviderProfile.ProviderAddress.fullAddress}</TableCell>
								</TableRow>

								<TableRow >
								  <TableCell component="th" scope="row">Bairro</TableCell>
								  <TableCell align="left">{provider.ProviderProfile.ProviderAddress.suburb}</TableCell>
								</TableRow>

								<TableRow >
								  <TableCell component="th" scope="row">Cidade</TableCell>
								  <TableCell align="left">{provider.ProviderProfile.ProviderAddress.city}</TableCell>
								</TableRow>

								<TableRow >
								  <TableCell component="th" scope="row">UF</TableCell>
								  <TableCell align="left">{provider.ProviderProfile.ProviderAddress.state}</TableCell>
								</TableRow>

							</TableBody>
						  </Table>
						</TableContainer>
		

						<TableContainer component={Box} mt={2}>
						  <Table aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Localização aproximada do endereço no Google Maps</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell component="th" scope="row"><img src={mapImageUrl} style={{minHeight:"400px",backgroundColor:"gray"}} alt="Google Maps Location" /></TableCell>
								</TableRow>


							</TableBody>
						  </Table>
						</TableContainer>	



						<TableContainer component={Box} mt={2}>
						  <Table aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Serviços escolhidos pelo prestador</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell component="th" scope="row">

									<Box style={{display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"flex-start",flexWrap:"wrap"}}>

										  {/* Results from API */}
										  {provider.ProviderHasServiceCategories.map((item) => {
													
												 return (
													<Card style={{margin:"10px",maxWidth:"300px"}} key={item.id}>
													  <CardHeader color="info" icon>
															<h4 className={classes.cardIconTitle}>{item.ServiceCategory.name}</h4>
													  </CardHeader>
													  <CardBody>	
															<Box>{item.ServiceCategory.description}</Box>
													  </CardBody>
													</Card>
													)}
											)}	
									
											
											{ provider.ProviderHasServiceCategories.length === 0 && 
												<div> Ops! Nenhum serviço foi selecionado... </div>						
											}

									</Box>


									</TableCell>
								</TableRow>


							</TableBody>
						  </Table>
						</TableContainer>	
						
						

						<TableContainer component={Box} mt={2}>
						  <Table aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Formações profissionais definidas pelo prestador</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell component="th" scope="row">


									<Box style={{display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"flex-start",flexWrap:"wrap"}}>

									  {/* Results from API */}
									  {provider.ProviderGraduations.map((item) => {
											
											 return (
												<>
												<Card style={{margin:"10px",maxWidth:"300px"}} key={item.id}>
												  <CardHeader color="info" icon>
														<h4 className={classes.cardIconTitle} style={{color:"purple"}}>Curso: {item.className}</h4>
												  </CardHeader>
												  <CardBody>	
														<Box>{item.institutionName} - {item.classType} ({item.classStatus})</Box>
												  
														{item.classBegin &&
														<>
															<span style={{marginRight:"20px"}}>Iniciado em: {item.classBegin}</span>
															<Box style={{color:"gray",fontSize:"80%"}}>Iniciado em: {item.classBegin}</Box>
														</>
														}										  
														{item.estimateEnd &&
														<>
															<Box style={{color:"gray",fontSize:"80%"}}>Até: {item.estimateEnd}</Box>
														</>
														}										  
														
												  </CardBody>
												</Card>
												</>
												)}
										)}	
										
										{ provider.ProviderHasServiceCategories.length === 0 && 
											<div> Ops! Nenhuma formação profissional foi definida... </div>						
										}

									</Box>


									</TableCell>
								</TableRow>


							</TableBody>
						  </Table>
						</TableContainer>	


						<TableContainer component={Box} mt={2}>
						  <Table aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Experiências profissionais definidas pelo prestador</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell component="th" scope="row">

									<Box style={{display:"flex",flexDirection:"row",alignItems:"flex-start",justifyContent:"flex-start",flexWrap:"wrap"}}>

										  {/* Results from API */}
										  {provider.ProviderExperiences.map((item) => {
												
												 return (
													<Card style={{margin:"10px",maxWidth:"300px"}} key={item.id}>
													  <CardHeader color="info" icon>
															<h4 className={classes.cardIconTitle} style={{color:"purple"}}>Empresa: {item.company}</h4>
													  </CardHeader>
													  <CardBody>	
															<Box><strong>Atuou como</strong> <i>{item.role}</i></Box>
															<Box><strong>Principal Atividade:</strong> {item.activities}</Box>

																  {item.serviceDescription &&
																  <>
																   <Box><strong>Descrição das atividades:</strong> {item.serviceDescription}</Box>
																  </>
																  }	

																  {item.started &&
																	<Box style={{color:"gray",fontSize:"80%"}}>Iniciado em: {item.started}</Box>
																  }		

																  {item.ended &&
																	<Box style={{color:"gray",fontSize:"80%"}}>Até: {item.ended}</Box>
																  }	
															
													  </CardBody>
													</Card>
													)}
											)}	
							
											{ provider.ProviderHasServiceCategories.length === 0 && 
												<div> Ops! Nenhuma experiência foi definida... </div>						
											}


									</Box>


									</TableCell>
								</TableRow>


							</TableBody>
						  </Table>
						</TableContainer>	


						<TableContainer component={Box} mt={2}>
						  <Table aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Documentos comprobatórios enviados pelo prestador</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell component="th" scope="row">

									<Box style={{display:"flex",flexDirection:"row",alignItems:"flex-start",justifyContent:"flex-start",flexWrap:"wrap"}}>

									  {/* Results from API */}
									  {provider.ProviderComprovationDocuments.map((item) => {
											
											{/* REPLACE LATER with method with token header */}	
											const url= config.API_URL+`/provider/downloadFile/${item.ProviderUserId}/${item.file}`
											
											 return (
													<Card style={{margin:"10px",maxWidth:"350px"}} key={item.id}>
													  <CardHeader color="info" icon>
															<h4 className={classes.cardIconTitle} style={{color:"purple"}}>{item.name}</h4>
													  </CardHeader>
													  <CardBody>	
															<Box><strong>Tipo de Arquivo:</strong> <i>{item.fileType}</i></Box>
															<Box><strong>Nome do arquivo:</strong> {item.file}</Box>

															<Button variant="contained" style={{backgroundColor:"#4556ac",marginTop:"10px"}}>
															  <a className="button" href={url} style={{color:"#fff"}}>Download</a>
															</Button>

													  </CardBody>

													</Card>
												)}
										)}	
										
										{ provider.ProviderHasServiceCategories.length === 0 && 
											<div> Ops! Nenhum documento foi enviado... </div>						
										}

									</Box>


									</TableCell>
								</TableRow>


							</TableBody>
						  </Table>
						</TableContainer>											
						

					  </CardBody>
					</Card>	 
 
			</TabPanel>
				  
			<TabPanel value={value} index={1}>

					<Card>
					  <CardHeader color="info" icon>
						<CardIcon color="info">
						  <RoomIcon />
						</CardIcon>
						<h4 className={classes.cardIconTitle}>Dados Bancários</h4>
					  </CardHeader>
					  <CardBody>	  

						<TableContainer component={Box} mt={2}>
						  <Table style={{minWwidth:"500px"}} aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Dados bancários para recebimentos</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>
										
							{provider.ProviderBankAccounts.length > 0 &&
							<>
								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Banco</TableCell>
								  <TableCell align="left">{provider.ProviderBankAccounts[0].bankName} ({provider.ProviderBankAccounts[0].bankCode})</TableCell>
								</TableRow>

								<TableRow >
								  <TableCell component="th" scope="row">Agência</TableCell>
								  <TableCell align="left">{provider.ProviderBankAccounts[0].agency}</TableCell>
								</TableRow>

								<TableRow >
								  <TableCell component="th" scope="row">Tipo de Conta</TableCell>
								  <TableCell align="left">{provider.ProviderBankAccounts[0].accountType}</TableCell>
								</TableRow>

								<TableRow >
								  <TableCell component="th" scope="row">Número da Conta (com dígito)</TableCell>
								  <TableCell align="left">{provider.ProviderBankAccounts[0].accountNumber}</TableCell>
								</TableRow>
								
								<TableRow >
								  <TableCell component="th" scope="row">Status Iugu</TableCell>
								  <TableCell align="left">
								  

								  	{provider.iuguStatus == 0 &&
									  <>
										<span class="product-badge status-review">Conta em análise na IUGU</span> <small><i>O prestador não pode ficar disponível para receber oportunidades.</i></small>
									  </>
									  }		

									  {provider.iuguStatus == 1 &&
									  <>
										<span class="product-badge status-ativado">Conta aprovada na IUGU</span> <small><i>Subconta aprovada na IUGU em {moment(provider.ProviderIuguAccount.verifiedAt).format("DD/MM/YYYY")}</i></small>
										
											{provider.iuguBankStatus == 0 &&
											  <>
												<p style={{marginTop:"10px"}}>
												<span class="product-badge status-review">Conta bancária não verificada</span> <small><i>Prestador recebe mas não transfere para conta bancária.</i></small>
												</p>
											  </>
											  }		

											  {provider.iuguBankStatus == 1 &&
											  <>
												<p style={{marginTop:"10px"}}>
												<span class="product-badge status-ativado">Conta bancária verificada</span> <small><i>Prestador recebe e transfere para conta bancária.</i></small>
												</p>
											  </>
											  }	
										
									  </>
									  }	

									  {provider.iuguStatus == 2 &&
									  <>
										<span class="product-badge status-bloqueado">Conta não aprovada na IUGU</span> <small><i>Subconta reprovada na IUGU em {moment(provider.ProviderIuguAccount.updatedAt).format("DD/MM/YYYY")}</i></small>
									  </>
									  }									  
								  
								  </TableCell>
								</TableRow>	
							</>
							}		

							{provider.ProviderBankAccounts.length == 0 &&
							<>
								<TableRow >
								  <TableCell component="th" scope="row" colpsan="2">Conta bancária ainda não cadastrada.</TableCell>
								 </TableRow>	
							</>
							}								

							</TableBody>
						  </Table>
						</TableContainer>
						
						<TableContainer component={Box} mt={2}>
							<Table style={{minWwidth:"500px"}} aria-label="simple table">						
						
							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Arquivo anexo comprobatório <small>(enviado para IUGU)</small></TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>
						
								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Documento Anexo</TableCell>
								  <TableCell align="left">
									
										<Card style={{margin:"10px",maxWidth:"350px"}}>
										  <CardHeader color="info" icon>
												<h4 className={classes.cardIconTitle} style={{color:"purple"}}>Documento bancário comprobatório</h4>
										  </CardHeader>
										  <CardBody>	
												
												<Button variant="contained" style={{backgroundColor:"#4556ac",marginTop:"10px"}}>
												  <a className="button" href="javascript:void()" style={{color:"#fff"}}>Download</a>
												</Button>

										  </CardBody>

										</Card>									
									
								 </TableCell>
								</TableRow>				

							</TableBody>
						  </Table>
						</TableContainer>						

	
					  </CardBody>
					</Card>	

			</TabPanel>	

			<TabPanel value={value} index={2}>

					<Card>
					  <CardHeader color="info" icon>
						<CardIcon color="info">
						  <Assignment />
						</CardIcon>
						<h4 className={classes.cardIconTitle}>Grade de Atendimento</h4>
					  </CardHeader>
					  <CardBody>	  
				 		
											
						<TableContainer component={Box} mt={2}>
						  <Table style={{width:"60%"}} aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={4}>Dia e Período que aceita receber oportunidades</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

							{agendaGrid.length > 0 &&
							<>
								<TableRow >
								  <TableCell align="left" component="th" scope="row" style={{fontWeight:"bold"}}>Dia da semana</TableCell>
								  <TableCell align="left" component="th" scope="row" style={{fontWeight:"bold"}}>Manhã <br /><small style={{fcolor:"silver"}}>09:00 às 13:00</small></TableCell>
								  <TableCell align="left" component="th" scope="row" style={{fontWeight:"bold"}}>Tarde<br /><small style={{fcolor:"silver"}}>13:00 às 18:00</small></TableCell>
								  <TableCell align="left" component="th" scope="row" style={{fontWeight:"bold"}}>Noite<br /><small style={{fcolor:"silver"}}>18:00 às 21:00</small></TableCell>
								</TableRow>
								
								<TableRow >
								  <TableCell align="left" component="th" scope="row">Segunda-feira</TableCell>
								  <TableCell align="left">
									  {agendaGrid.includes("1,1") && <CheckIcon style={{fontSize:"2rem",color:"green"}} />}
									  {!agendaGrid.includes("1,1") && <NotIcon style={{fontSize:"2rem",color:"red"}} />}
								  </TableCell>
								  <TableCell align="left">
									  {agendaGrid.includes("1,2") && <CheckIcon style={{fontSize:"2rem",color:"green"}} />}
									  {!agendaGrid.includes("1,2") && <NotIcon style={{fontSize:"2rem",color:"red"}} />}
								  </TableCell>
								  <TableCell align="left">
									  {agendaGrid.includes("1,3") && <CheckIcon style={{fontSize:"2rem",color:"green"}} />}
									  {!agendaGrid.includes("1,3") && <NotIcon style={{fontSize:"2rem",color:"red"}} />}
								  </TableCell>
								</TableRow>
								
								<TableRow >
								  <TableCell align="left" component="th" scope="row">Terça-feira</TableCell>
								  <TableCell align="left">
									  {agendaGrid.includes("2,1") && <CheckIcon style={{fontSize:"2rem",color:"green"}} />}
									  {!agendaGrid.includes("2,1") && <NotIcon style={{fontSize:"2rem",color:"red"}} />}
								  </TableCell>
								  <TableCell align="left">
									  {agendaGrid.includes("2,2") && <CheckIcon style={{fontSize:"2rem",color:"green"}} />}
									  {!agendaGrid.includes("2,2") && <NotIcon style={{fontSize:"2rem",color:"red"}} />}
								  </TableCell>
								  <TableCell align="left">
									  {agendaGrid.includes("2,3") && <CheckIcon style={{fontSize:"2rem",color:"green"}} />}
									  {!agendaGrid.includes("2,3") && <NotIcon style={{fontSize:"2rem",color:"red"}} />}
								  </TableCell>
								</TableRow>

								<TableRow >
								  <TableCell align="left" component="th" scope="row">Quarta-feira</TableCell>
								  <TableCell align="left">
									  {agendaGrid.includes("3,1") && <CheckIcon style={{fontSize:"2rem",color:"green"}} />}
									  {!agendaGrid.includes("3,1") && <NotIcon style={{fontSize:"2rem",color:"red"}} />}
								  </TableCell>
								  <TableCell align="left">
									  {agendaGrid.includes("3,2") && <CheckIcon style={{fontSize:"2rem",color:"green"}} />}
									  {!agendaGrid.includes("3,2") && <NotIcon style={{fontSize:"2rem",color:"red"}} />}
								  </TableCell>
								  <TableCell align="left">
									  {agendaGrid.includes("3,3") && <CheckIcon style={{fontSize:"2rem",color:"green"}} />}
									  {!agendaGrid.includes("3,3") && <NotIcon style={{fontSize:"2rem",color:"red"}} />}
								  </TableCell>
								</TableRow>

								<TableRow >
								  <TableCell align="left" component="th" scope="row">Quinta-feira</TableCell>
								  <TableCell align="left">
									  {agendaGrid.includes("4,1") && <CheckIcon style={{fontSize:"2rem",color:"green"}} />}
									  {!agendaGrid.includes("4,1") && <NotIcon style={{fontSize:"2rem",color:"red"}} />}
								  </TableCell>
								  <TableCell align="left">
									  {agendaGrid.includes("4,2") && <CheckIcon style={{fontSize:"2rem",color:"green"}} />}
									  {!agendaGrid.includes("4,2") && <NotIcon style={{fontSize:"2rem",color:"red"}} />}
								  </TableCell>
								  <TableCell align="left">
									  {agendaGrid.includes("4,3") && <CheckIcon style={{fontSize:"2rem",color:"green"}} />}
									  {!agendaGrid.includes("4,3") && <NotIcon style={{fontSize:"2rem",color:"red"}} />}
								  </TableCell>
								</TableRow>

								<TableRow >
								  <TableCell align="left" component="th" scope="row">Sexta-feira</TableCell>
								  <TableCell align="left">
									  {agendaGrid.includes("5,1") && <CheckIcon style={{fontSize:"2rem",color:"green"}} />}
									  {!agendaGrid.includes("5,1") && <NotIcon style={{fontSize:"2rem",color:"red"}} />}
								  </TableCell>
								  <TableCell align="left">
									  {agendaGrid.includes("5,2") && <CheckIcon style={{fontSize:"2rem",color:"green"}} />}
									  {!agendaGrid.includes("5,2") && <NotIcon style={{fontSize:"2rem",color:"red"}} />}
								  </TableCell>
								  <TableCell align="left">
									  {agendaGrid.includes("5,3") && <CheckIcon style={{fontSize:"2rem",color:"green"}} />}
									  {!agendaGrid.includes("5,3") && <NotIcon style={{fontSize:"2rem",color:"red"}} />}
								  </TableCell>
								</TableRow>

								<TableRow >
								  <TableCell align="left" component="th" scope="row">Sábado</TableCell>
								  <TableCell align="left">
									  {agendaGrid.includes("6,1") && <CheckIcon style={{fontSize:"2rem",color:"green"}} />}
									  {!agendaGrid.includes("6,1") && <NotIcon style={{fontSize:"2rem",color:"red"}} />}
								  </TableCell>
								  <TableCell align="left">
									  {agendaGrid.includes("6,2") && <CheckIcon style={{fontSize:"2rem",color:"green"}} />}
									  {!agendaGrid.includes("6,2") && <NotIcon style={{fontSize:"2rem",color:"red"}} />}
								  </TableCell>
								  <TableCell align="left">
									  {agendaGrid.includes("6,3") && <CheckIcon style={{fontSize:"2rem",color:"green"}} />}
									  {!agendaGrid.includes("6,3") && <NotIcon style={{fontSize:"2rem",color:"red"}} />}
								  </TableCell>
								</TableRow>

								<TableRow >
								  <TableCell align="left" component="th" scope="row">Domingo</TableCell>
								  <TableCell align="left">
									  {agendaGrid.includes("7,1") && <CheckIcon style={{fontSize:"2rem",color:"green"}} />}
									  {!agendaGrid.includes("7,1") && <NotIcon style={{fontSize:"2rem",color:"red"}} />}
								  </TableCell>
								  <TableCell align="left">
									  {agendaGrid.includes("7,2") && <CheckIcon style={{fontSize:"2rem",color:"green"}} />}
									  {!agendaGrid.includes("7,2") && <NotIcon style={{fontSize:"2rem",color:"red"}} />}
								  </TableCell>
								  <TableCell align="left">
									  {agendaGrid.includes("7,3") && <CheckIcon style={{fontSize:"2rem",color:"green"}} />}
									  {!agendaGrid.includes("7,3") && <NotIcon style={{fontSize:"2rem",color:"red"}} />}
								  </TableCell>
								</TableRow>

								<TableRow >
								  <TableCell align="left" component="th" scope="row">Feriados</TableCell>
								  <TableCell align="left">
									  {agendaGrid.includes("8,1") && <CheckIcon style={{fontSize:"2rem",color:"green"}} />}
									  {!agendaGrid.includes("8,1") && <NotIcon style={{fontSize:"2rem",color:"red"}} />}
								  </TableCell>
								  <TableCell align="left">
									  {agendaGrid.includes("8,2") && <CheckIcon style={{fontSize:"2rem",color:"green"}} />}
									  {!agendaGrid.includes("8,2") && <NotIcon style={{fontSize:"2rem",color:"red"}} />}
								  </TableCell>
								  <TableCell align="left">
									  {agendaGrid.includes("8,3") && <CheckIcon style={{fontSize:"2rem",color:"green"}} />}
									  {!agendaGrid.includes("8,3") && <NotIcon style={{fontSize:"2rem",color:"red"}} />}
								  </TableCell>
								</TableRow>	
							</>
							}								
 
							</TableBody>
						  </Table>
						</TableContainer>
					

					  </CardBody>
					</Card>	

			</TabPanel>	

			<TabPanel value={value} index={3}>

					<Card>
					  <CardHeader color="info" icon>
						<CardIcon color="info">
						  <Assignment />
						</CardIcon>
						<h4 className={classes.cardIconTitle}>Cadastro de Colaboradores</h4>
					  </CardHeader>
					  <CardBody>	  
				 		
						<TableContainer component={Box} mt={2}>
						  <Table aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Experiências profissionais definidas pelo prestador</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell component="th" scope="row">

									<Box style={{display:"flex",flexDirection:"row",alignItems:"flex-start",justifyContent:"flex-start",flexWrap:"wrap"}}>
	

										  {/* Results from API */}
										  {provider.ProviderProfile.ProviderProfileCompany.ProviderProfileCompanyEmployees.map((item) => {
												
												var ProviderUserId = provider.id;
												var avatar = item.avatar ? item.avatar:null;
												var employeeAvatar = config.API_URL+"/provider/avatar/retrieve/"+ProviderUserId+"/"+avatar+"";		

												
												 return (
													<Card style={{margin:"10px",maxWidth:"300px",minHeight:"241px"}} key={item.id}>
													  <CardHeader color="info" icon>
													  
															<span style={{float:"left", margin:"10px",marginBottom:"0"}}>
																<img src={employeeAvatar} style={{height:"75px",width:"auto",backgroundColor:"gray"}} />
															</span>																
													  
															<h4 className={classes.cardIconTitle} style={{color:"purple"}}>{item.completeName}</h4>
													  </CardHeader>
													  <CardBody>
																	
															<Divider style={{marginBottom:"5px"}} />
																	
															<Box><strong>Email:</strong> <i>{item.email}</i></Box>
															<Box><strong>Especialidade:</strong> {item.role}</Box>
															<Box><strong>CPF:</strong> {maskDocument(item.document)}</Box>
															
															{item.documentAlt &&
															<>
																<Box><strong>RG:</strong> {item.documentAlt}</Box>
																<Box><strong>Órgão Emissor:</strong> {item.documentAltInfo} </Box>
															</>
															}
																  {(item.gender == 1 || item.gender == 2) &&
																	<Box><strong>Gênero</strong> {maskGender(item.gender)}</Box>
																  }		

																  {(item.birthday && item.birthday != "0000-00-00") &&
																	<Box><strong>Data de Nascimento</strong> {moment(item.birthday).format("DD/MM/YYYY")}</Box>
																  }	
															
													  </CardBody>
													</Card>
													)}
											)}	
	
											{ provider.ProviderProfile.ProviderProfileCompany.ProviderProfileCompanyEmployees.length === 0 && 
												<div> Ops! Nenhum colaborador foi encontrado... </div>						
											}


									</Box>


									</TableCell>
								</TableRow>


							</TableBody>
						  </Table>
						</TableContainer>	


					  </CardBody>
					</Card>	

			</TabPanel>	

			<TabPanel value={value} index={4}>

					<Card>
					  <CardHeader color="info" icon>
						<CardIcon color="info">
						  <Assignment />
						</CardIcon>
						<h4 className={classes.cardIconTitle}>Ordens de serviço</h4>
					  </CardHeader>
					  <CardBody>	  
										
						[TABELA DINAMICA]
										
					  </CardBody>
					</Card>	

			</TabPanel>	
			
			<TabPanel value={value} index={5}>

					<Card>
					  <CardHeader color="info" icon>
						<CardIcon color="info">
						  <Assignment />
						</CardIcon>
						<h4 className={classes.cardIconTitle}>Conversas</h4>
					  </CardHeader>
					  <CardBody>	  


										
					  </CardBody>
					</Card>	

			</TabPanel>

			<TabPanel value={value} index={6}>

					<Card>
					  <CardHeader color="info" icon>
						<CardIcon color="info">
						  <Assignment />
						</CardIcon>
						<h4 className={classes.cardIconTitle}>Chamados</h4>
					  </CardHeader>
					  <CardBody>	  

						Chamados de suporte
										
					  </CardBody>
					</Card>	

			</TabPanel>

		
			<Box ml={3}> 

				{provider.status != "9" &&
				<Button variant="contained"
					/*onClick={() => history.push("/admin/provider-edit/"+provider.id+"")}*/
					onClick={() => alert("Em desenvolvimento...") }
				 >
				  Editar Cadastro
				</Button>
				}

					<ApproveDialog 
						dialogAlert={dialogAlert}
						setAlertDialog={setAlertDialog}
						dialogTitle={dialogTitle}
						dialogMessage={dialogMessage}
						setDialogTitle={setDialogTitle}
						setDialogMessage={setDialogMessage}
						setDialogAction={setDialogAction}
						provider={provider}
						history={history}
					/>

					<RepproveDialog 
						dialogAlert={dialogAlert}
						setAlertDialog={setAlertDialog}
						dialogTitle={dialogTitle}
						dialogMessage={dialogMessage}
						setDialogTitle={setDialogTitle}
						setDialogMessage={setDialogMessage}
						setDialogAction={setDialogAction}
						provider={provider}
						history={history}
					/>

			</Box>
	   
	  </GridItem>
	  
    </GridContainer>
	</>
  );
} // end if

return (null);

}

const mapStateToProps = store => ({
  buttonLoading:store.appReducer.buttonLoading,  
  appLoading:store.providerReducer.appLoading,
  dialog:store.providerReducer.dialog,
  providersApproval:store.providerReducer.providersApproval,
  providerApproval:store.providerReducer.providerApproval
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions, ...ProviderActions }, dispatch);


// Transition Slide for Dialog SLIDE UP	
const TransitionSlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;  
});	

/* APPROVE Dialog Component START */
export const ApproveDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
	
const useStyles = makeStyles((theme) => ({
  appBar: {
    
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const screenStyleDialog = {
  alignItems: "stretch",
  justifyContent: "top",
   display:"flex",
  flexDirection:"column",
  backgroundColor:"#FFF"
}; 
	
// set classes var
const classes = useStyles();	

// set vars from props
// Remember to add those who come from Redux
const { 
	setDialog,
	dialog,
	approveProvider
} = props;	


/* Dialog END*/	

const [loading, setLoading] = React.useState(false);

const confirm = (props) => {




};	

var formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});


// useEffect only for dialgo changes
React.useEffect(() => {
		
	// execute only if this modal
	if (dialog.target == 'approveProvider' && dialog.status) {
		

	
	}  // end if

}, [dialog]); // end useEffect only for dialgo changes

	return (
	<div>
		 <Dialog maxWidth="sm" open={dialog.target == 'approveProvider' && dialog.status} onClose={()=>setDialog('approveProvider',false)} TransitionComponent={TransitionSlideUp}>

		   <DialogTitle id="customized-dialog-title" onClose={()=>setDialog('approveProvider',false)}>

			<IconButton aria-label="close" className={classes.closeButton} onClick={()=>setDialog('approveProvider',false)}>
			  <CloseIcon />
			</IconButton>

			  Aprovando cadastro de prestador
			</DialogTitle>

			<DialogContent dividers style={{padding:0}}>
			<Box style={{...screenStyleDialog}} p={0}>
			
				<Box mt={0} ml={1} mr={1}>
					
					<Box style={{marginBottom:"5px",alignItems:"stretch",justifyContent:"flex-start",display:"flex",flexDirection:"column"}} p={0}>

								<h5 style={{fontSize:"1.2rem",color:"#A255A0",marginTop:"10px",textAlign:"center"}}>Aprovação de cadastro</h5>
								
								<Box mt={0}  mb={1} ml={2} mr={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  
										<div style={{marginRight:"10px"}}> 
											<WarningIcon style={{fontSize:"2rem",color:"red"}} />
										</div>
										
										<div> 
											<div style={{marginBottom:"10px",textAlign:"left"}}> Atenção, ao aprovar o cadastro o Prestador será notificado e poderá acessar a plataforma. Quer mesmo aprovar?  </div>
										</div>
								</Box>									
							
					</Box>
					
					<Divider style={{marginBottom:"5px"}} />
					
					{/* URGENT Date */}						
							
					<div>						
						<Box mt={2}  mb={2} ml={2} mr={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  
						
							Antes de aprovar o cadastro, verifique todas as informações enviadas, incluindo os documentos anexos. 

						</Box>								
					</div>
			
			
					<Divider style={{marginBottom:"5px"}} />
						
					<Box style={{display:"flex",flexDirection:"row",justifyContent:"center"}} ml={1} mr={1} mb={5} mt={2}>						
						
						<Button disabled={loading} color="secondary" variant="outlined" style={{flex:1,height:"48px",marginRight:"10px"}} onClick={()=>setDialog('approveProvider',false)}>
						 Fechar
						</Button>		
						
						<Button disabled={loading}  variant="contained" style={{flex:2,height:"48px",color:"#FFF",backgroundColor:"green"}} onClick={()=>confirm(props)}>
							{ !loading &&
							   <div>Confirmar Aprovação</div>
							}
							
							{ loading &&
							<>
							   <CircularProgress size={30} color="inherit" />
							   <span style={{marginLeft:"10px"}}>Aprovando...</span>
							</>
							}
						</Button>
							
					</Box>			
			
				</Box>				
			
			</Box>
		  </DialogContent>

		  </Dialog>
	 
		 
		</div>		
	);
	
});


/* REPPROVE Dialog Component START */
export const RepproveDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
	
const useStyles = makeStyles((theme) => ({
  appBar: {
    
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const screenStyleDialog = {
  alignItems: "stretch",
  justifyContent: "top",
   display:"flex",
  flexDirection:"column",
  backgroundColor:"#FFF"
}; 
	
// set classes var
const classes = useStyles();	

// set vars from props
// Remember to add those who come from Redux
const { 
	setDialog,
	dialog,
	repproveProvider
} = props;	


/* Dialog END*/	

const [loading, setLoading] = React.useState(false);
const [repproveReason, setRepproveReason] = React.useState("-");

const confirm = (props) => {




};	

var formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});


console.log("dialog >>>>>>>>>>>>>>>>>>>: "+JSON.stringify(dialog,null,2));


// useEffect only for dialgo changes
React.useEffect(() => {
		
	// execute only if this modal
	if (dialog.target == 'repproveProvider' && dialog.status) {
		

	
	}  // end if

}, [dialog]); // end useEffect only for dialgo changes

	return (
	<div>
		 <Dialog maxWidth="sm" open={dialog.target == 'repproveProvider' && dialog.status} onClose={()=>setDialog('repproveProvider',false)} TransitionComponent={TransitionSlideUp}>

		   <DialogTitle id="customized-dialog-title" onClose={()=>setDialog('repproveProvider',false)}>

			<IconButton aria-label="close" className={classes.closeButton} onClick={()=>setDialog('repproveProvider',false)}>
			  <CloseIcon />
			</IconButton>

			  Reprovando cadastro de prestador
			</DialogTitle>

			<DialogContent dividers style={{padding:0}}>
			<Box style={{...screenStyleDialog}} p={0}>
			
				<Box mt={0} ml={1} mr={1}>
					
					<Box style={{marginBottom:"5px",alignItems:"stretch",justifyContent:"flex-start",display:"flex",flexDirection:"column"}} p={0}>

								<h5 style={{fontSize:"1.2rem",color:"#A255A0",marginTop:"10px",textAlign:"center"}}>Reprovação de cadastro</h5>
								
								<Box mt={0}  mb={1} ml={2} mr={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  
										<div style={{marginRight:"10px"}}> 
											<WarningIcon style={{fontSize:"2rem",color:"red"}} />
										</div>
										
										<div> 
											<div style={{marginBottom:"10px",textAlign:"left"}}> Atenção, ao reprovar o cadastro o Prestador será notificado sobre a reprovação de seu cadastro. Quer mesmo reprovar?  </div>
										</div>
								</Box>									
							
					</Box>
					
					<Divider style={{marginBottom:"5px"}} />
					
					{/* REASON REPPROVE Date */}						

						<Box mt={2}  mb={0} ml={2} mr={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  
								<div> 
									<div style={{marginBottom:"10px",textAlign:"center",fontWeight:"bold"}}> Selecione o motivo da reprovação: </div>
								</div>
						</Box>	
						
						<Box mt={0}  mb={2} ml={2} mr={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  
								<div style={{marginRight:"10px",width:"90%"}}> 

										<Select
										  label="Motivo do encerramento"
										  placeholder="Motivo do encerramento"
										  style={{ width:"100%",paddingTop:0,paddingBottom:0,margin:5,marginLeft:10,marginRight:0,backgroundColor:"#fff",width:"100%"}}
										  size="small"
										  variant="outlined"
										  value={repproveReason}
										  onChange={(event) => {
											  setRepproveReason(event.target.value)
										  }}	
										 >
											<MenuItem value="-">- Selecione um motivo -</MenuItem>
											<MenuItem value="Informações fornecidas são inválidas">Informações fornecidas são inválidas</MenuItem>
											<MenuItem value="Documentos anexos inválidos">Documentos anexos inválidos</MenuItem>
											<MenuItem value="Não tem experiência ou formação necessários">Não tem experiência ou formação necessários</MenuItem>
											<MenuItem value="Outro">Outro motivo não listado</MenuItem>
										</Select>
										
								</div>

						</Box>	
			
			
					<Divider style={{marginBottom:"5px"}} />
						
					<Box style={{display:"flex",flexDirection:"row",justifyContent:"center"}} ml={1} mr={1} mb={5} mt={2}>						
						
						<Button disabled={loading} color="secondary" variant="outlined" style={{flex:1,height:"48px",marginRight:"10px"}} onClick={()=>setDialog('repproveProvider',false)}>
						 Fechar
						</Button>		
						
						<Button disabled={loading}  variant="contained" style={{flex:2,height:"48px",color:"#FFF",backgroundColor:"red"}} onClick={()=>confirm(props)}>
							{ !loading &&
							   <div>Confirmar Reprovação</div>
							}
							
							{ loading &&
							<>
							   <CircularProgress size={30} color="inherit" />
							   <span style={{marginLeft:"10px"}}>Reprovando...</span>
							</>
							}
						</Button>
							
					</Box>			
			
				</Box>				
			
			</Box>
		  </DialogContent>

		  </Dialog>
	 
		 
		</div>		
	);
	
});


export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(ProviderView);
	
