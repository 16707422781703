import React,{useState,useRef} from "react";
import {useHistory,Link} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// @material-ui/core components
import './DataTable.css';
import { makeStyles,lighten } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";

// import { DataGrid,GridToolbar,ptBR  } from '@material-ui/data-grid';
import moment from "moment";

// Import Prime React Components
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import 'primereact/resources/themes/saga-blue/theme.css'; 
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Calendar } from 'primereact/calendar';
import { locale, addLocale, updateLocaleOption, updateLocaleOptions, localeOption, localeOptions } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Backdrop from '@material-ui/core/Backdrop';
import { Fieldset } from 'primereact/fieldset';
import { SelectButton } from 'primereact/selectbutton';
import { InputTextarea } from 'primereact/inputtextarea';
import { Chips } from 'primereact/chips';
import { Panel } from 'primereact/panel';
import { RadioButton } from 'primereact/radiobutton';

// import { BlockUI } from 'primereact/blockui'; // loading panel
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';

// Add Locale
addLocale('pt-BR', {
    firstDayOfWeek: 1,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar'
});

locale('pt-BR');

import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import Person from "@material-ui/icons/Person";
import View from '@material-ui/icons/Visibility';
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import ZoomInIcon from '@material-ui/icons/ZoomIn';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Table from "components/Table/Table.js";
// import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import NavPills from "components/NavPills/NavPills.js";

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

// Load Actions
import * as AppActions from 'Store/Actions/App'
import * as ProviderActions from 'Store/Actions/Provider'

const useStyles = makeStyles(styles);
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

const Config = (props) => {

// set vars from props
const { 
	appLoading,
	setAppLoading,
} = props;		


const [value, setValue] = React.useState(0);
const [contentVisible, setContentVisible] = React.useState(false); 

// console.log("[providers]: "+JSON.stringify(providers,null,2));
	
const classes = useStyles();

// Handle Tab changes
const handleChange = (event, newValue) => {
	
// Check which TAB was picked
//
		
setValue(newValue);

};

 
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

	
		
// set Use of history for PUSH 
let history = useHistory();

// Tasks to execute when mount component
React.useEffect(() => {
	
	setTimeout(function() {
			
		setAppLoading(false);
		setContentVisible(true);		
		
	},1000);

    return () => {
     setAppLoading(true);
    };	
	
}, []);

	
if(!contentVisible) {	  
	return (
		<div style={{width:"100%",height:"80vh",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
		
			<div style={{display:"flex",flex:1,alignItems:"center",justifyContent:"center"}}>
			
				<CircularProgress color="primary" />
				<div style={{fontSize:"18px",marginLeft:"15px"}}> Carregando dados...</div>
			
			</div>
		
		</div>
	)
  }

if(contentVisible) {
  return ( 
  <>	
   <Box ml={3} mb={5}>
   
	 <Breadcrumbs aria-label="breadcrumb">
	  <Typography color="textSecondary">Configurações</Typography>
	  <Typography color="textPrimary">Gerais</Typography>
	</Breadcrumbs>
	
	<Divider />
	
	<h3 style={{marginTop:"30px"}}>Configurações gerais da plataforma</h3>
	<h5>Incluindo dados básicos e serviços de terceiros.</h5>	
		
	</Box>
  
    <GridContainer>
	
      <GridItem xs={12}>

		<Tab1Content
			setAppLoading={setAppLoading}
			setContentVisible={setContentVisible}
		/>
					
	  </GridItem>
	  
    </GridContainer>		
	</>
  );
}

return (null);

}

const mapStateToProps = store => ({
  buttonLoading:store.appReducer.buttonLoading
 });

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions }, dispatch);



// Tab1Content Object
export const Tab1Content = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {

// set vars from props
const { 
	appLoading,
	setAppLoading,
	retrieveConfig,
	saveConfig
} = props;		

// Set classes
const classes = useStyles();	

// useRef
var dt = useRef(null);

// Scope data
const [configRows, setConfigRows] = useState([]);

// Filter fields
const [filterByName, setFilterByName] = useState("");
const [filterByEmail, setFilterByEmail] = useState("");
const [filterByContactName, setFilterByContactName] = useState("");
const [filterByCompanyDocument, setFilterByCompanyDocument] = useState("");
const [filterByDocument, setFilterByDocument] = useState("");
const [filterByCreatedAt, setFilterByCreatedAt] = useState("");
const [filterByStatus, setFilterByStatus] = useState(null); 

const [checked, setChecked] = useState(false); 

// Dialog control
const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico"); 

// Service Dialog control
const [alertUser, setAlertUser] = React.useState(false); 
const [alertUserUpdate, setAlertUserUpdate] = React.useState(false); 
const [userId, setUserId] = React.useState(null); 

// Tab 1 fields and methods
const [providerLocalDirectory, setProviderLocalDirectory] = React.useState(""); 
const [providerAvatarDirectory, setProviderAvatarDirectory] = React.useState(""); 
const [providerGeneralDirectory, setProviderGeneralDirectory] = React.useState(""); 
const [customerGeneralDirectory, setCustomerGeneralDirectory] = React.useState(""); 
const [fileUploadTempPath, setFileUploadTempPath] = React.useState(""); 
const [mediaGeneralDirectory, setMediaGeneralDirectory] = React.useState(""); 
const [mediaGeneralUrl, setMediaGeneralUrl] = React.useState(""); 

// Tab 2 fields and methods
const [provider_canceltax, setProvider_canceltax] = React.useState(null); 
const [customer_canceltax, setCustomer_canceltax] = React.useState(null); 
const [customer_altertax, setCustomer_altertax] = React.useState(null); 
const [platform_default_comission, setPlatform_default_comission] = React.useState(null); 
const [provider_default_comission, setProvider_default_comission] = React.useState(null); 
const [partner_default_comission, setPartner_default_comission] = React.useState(null); 


// Tab 3 fields and methods
const [tokenChatKey, setTokenChatKey] = React.useState(""); 
const [streamChatToken, setStreamChatToken] = React.useState(""); 
const [sendgrid_api_key, setSendgrid_api_key] = React.useState(""); 
const [zenvia_senderid, setZenvia_senderid] = React.useState(""); 
const [zenvia_token, setZenvia_token] = React.useState(""); 
const [google_maps_token, setGoogle_maps_token] = React.useState(""); 
const [freshdesk_api_url, setFreshdesk_api_url] = React.useState(""); 
const [freshdesk_api_token, setFreshdesk_api_token] = React.useState(""); 
 
// Tab 4 fields and methods
const [partner_account_iugu_id, setPartner_account_iugu_id] = React.useState(""); 
const [provider_account_iugu_id, setProvider_account_iugu_id] = React.useState(""); 
const [partneruser_id, setPartneruser_id] = React.useState(null); 
const [token_api_iugu, setToken_api_iugu] = React.useState(""); 
const [token_api_iugu_prod, setToken_api_iugu_prod] = React.useState(""); 
const [platform_mode, setPlatform_mode] = React.useState("devel"); 

// Loading Tab
const [tabLoading, setTabLoading] = React.useState(false); 


// DataGrid Params
const [parentLoading, setParentLoading] = useState(false);

// DataGrid Params
const [loading, setLoading] = useState(false);
const [totalRecords, setTotalRecords] = useState(0);
const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
		filters:null,
		sortField:"createdAt",
		sortOrder:1
    });

React.useEffect(() => {

	loadLazyData();

},[lazyParams]);
 
const loadLazyData = () => {
	
	setLoading(true);
	
	// Retrieve List of Config
	retrieveConfigAction();
}
  	
// Tasks to execute when mount component
React.useEffect(() => {

    return () => {
     setAppLoading(true);
    };			
}, []);

		
const retrieveConfigAction = () => {

	var propsParam = {
		setConfigRows:setConfigRows,
		setLoading:setLoading
	}	
	retrieveConfig(propsParam);

} // end 


// Dialog Footer
const renderFooter = () => {
	return (
		<div>
			<Button label="OK" icon="pi pi-check" onClick={() => setAlert(false)} />
		</div>
	);
}	

// Set values for each field
React.useEffect(() => {

	// If service returned set values
	if (configRows.length > 0) {
			
		var item = configRows.filter(item => item.key == "providerLocalDirectory");		
		setProviderLocalDirectory(item.length > 0 ? item[0].value : "");
		
		var item = configRows.filter(item => item.key == "providerAvatarDirectory");
		setProviderAvatarDirectory(item.length > 0 ? item[0].value : "");
		
		var item = configRows.filter(item => item.key == "providerGeneralDirectory");
		setProviderGeneralDirectory(item.length > 0 ? item[0].value : "");
		
		var item = configRows.filter(item => item.key == "customerGeneralDirectory");
		setCustomerGeneralDirectory(item.length > 0 ? item[0].value : "");
		
		var item = configRows.filter(item => item.key == "fileUploadTempPath");
		setFileUploadTempPath(item.length > 0 ? item[0].value : "");
		
		var item = configRows.filter(item => item.key == "mediaGeneralDirectory");
		setMediaGeneralDirectory(item.length > 0 ? item[0].value : "");
		
		var item = configRows.filter(item => item.key == "mediaGeneralUrl");
		setMediaGeneralUrl(item.length > 0 ? item[0].value : "");

		var item = configRows.filter(item => item.key == "PROVIDER_CANCELTAX");
		setProvider_canceltax(item.length > 0 ? item[0].value : null);
		
		var item = configRows.filter(item => item.key == "CUSTOMER_CANCELTAX");
		setCustomer_canceltax(item.length > 0 ? item[0].value : null);
		
		var item = configRows.filter(item => item.key == "CUSTOMER_ALTERTAX");
		setCustomer_altertax(item.length > 0 ? item[0].value : null);
	
		var item = configRows.filter(item => item.key == "PLATFORM_DEFAULT_COMISSION");
		setPlatform_default_comission(item.length > 0 ? item[0].value : null);
		
		var item = configRows.filter(item => item.key == "PROVIDER_DEFAULT_COMISSION");
		setProvider_default_comission(item.length > 0 ? item[0].value : null);
		
		var item = configRows.filter(item => item.key == "PARTNER_DEFAULT_COMISSION");
		setPartner_default_comission(item.length > 0 ? item[0].value : null);

		var item = configRows.filter(item => item.key == "tokenChatKey");
		setTokenChatKey(item.length > 0 ? item[0].value : "");
		
		var item = configRows.filter(item => item.key == "streamChatToken");
		setStreamChatToken(item.length > 0 ? item[0].value : "");
		
		var item = configRows.filter(item => item.key == "SENDGRID_API_KEY");
		setSendgrid_api_key(item.length > 0 ? item[0].value : "");
		
		var item = configRows.filter(item => item.key == "ZENVIA_SENDERID");
		setZenvia_senderid(item.length > 0 ? item[0].value : "");
		
		var item = configRows.filter(item => item.key == "ZENVIA_TOKEN");
		setZenvia_token(item.length > 0 ? item[0].value : "");
		
		var item = configRows.filter(item => item.key == "GOOGLE_MAPS_TOKEN");
		setGoogle_maps_token(item.length > 0 ? item[0].value : "");
		
		var item = configRows.filter(item => item.key == "FRESHDESK_API_URL");
		setFreshdesk_api_url(item.length > 0 ? item[0].value : "");
		
		var item = configRows.filter(item => item.key == "FRESHDESK_API_TOKEN");
		setFreshdesk_api_token(item.length > 0 ? item[0].value : "");
		 
		var item = configRows.filter(item => item.key == "PARTNER_ACCOUNT_IUGU_ID");
		setPartner_account_iugu_id(item.length > 0 ? item[0].value : "");
		
		var item = configRows.filter(item => item.key == "PROVIDER_ACCOUNT_IUGU_ID");
		setProvider_account_iugu_id(item.length > 0 ? item[0].value : "");
		
		var item = configRows.filter(item => item.key == "PARTNERUSER_ID");
		setPartneruser_id(item.length > 0 ? item[0].value : null);
		
		var item = configRows.filter(item => item.key == "TOKEN_API_IUGU");
		setToken_api_iugu(item.length > 0 ? item[0].value : "");
		
		var item = configRows.filter(item => item.key == "TOKEN_API_IUGU_PROD");
		setToken_api_iugu_prod(item.length > 0 ? item[0].value : "");
		
		var item = configRows.filter(item => item.key == "PLATFORM_MODE");
		setPlatform_mode(item.length > 0 ? item[0].value : "");

	} // end if

},[configRows]);

// set Use of history for PUSH 
let history = useHistory();

// Save config Methods
const save = (group) => {
	
	// set loadig
	setParentLoading(true);
	setTabLoading(true);

	// Process Tab 1 Group
	if (group == 'tab1') {
	
		var data = {
			tab:"tab1",
			providerLocalDirectory:providerLocalDirectory,
			providerAvatarDirectory:providerAvatarDirectory,
			providerGeneralDirectory:providerGeneralDirectory,
			customerGeneralDirectory:customerGeneralDirectory,
			fileUploadTempPath:fileUploadTempPath,
			mediaGeneralDirectory:mediaGeneralDirectory,
			mediaGeneralUrl:mediaGeneralUrl,
			currentConfig:configRows
		} 
		var propsParam = {
			setTabLoading:setTabLoading,
			setParentLoading:setParentLoading,
			setAlert:setAlert,
			setAlertBody:setAlertBody,
			setAlertTitle:setAlertTitle
		}		
		saveConfig(data,propsParam)
		return;
	} // end if
	
	// Process Tab 2 group
	if (group == 'tab2') {
	
		var data = {
			tab:"tab2",
			provider_canceltax:provider_canceltax,
			customer_canceltax:customer_canceltax,
			customer_altertax: customer_altertax,
			platform_default_comission:platform_default_comission,
			provider_default_comission:provider_default_comission,
			partner_default_comission:partner_default_comission,
			currentConfig:configRows	
		} 
		var propsParam = {
			setTabLoading:setTabLoading,
			setParentLoading:setParentLoading,
			setAlert:setAlert,
			setAlertBody:setAlertBody,
			setAlertTitle:setAlertTitle
		}		
		saveConfig(data,propsParam)
		return;
	} // end if
	
		
	// Process Tab  group
	if (group == 'tab3') {
	
		var data = {
			tab:"tab3",
			tokenChatKey:tokenChatKey,
			streamChatToken:streamChatToken,
			sendgrid_api_key:sendgrid_api_key,
			zenvia_senderid:zenvia_senderid,
			zenvia_token:zenvia_token,
			google_maps_token:google_maps_token,
			freshdesk_api_url:freshdesk_api_url,
			freshdesk_api_token:freshdesk_api_token,
			currentConfig:configRows	
		} 
		var propsParam = {
			setTabLoading:setTabLoading,
			setParentLoading:setParentLoading,
			setAlert:setAlert,
			setAlertBody:setAlertBody,
			setAlertTitle:setAlertTitle
		}	
		saveConfig(data,propsParam)
		return;
	} // end if
			
	// Process Tab 2 group
	if (group == 'tab4') {
	
		var data = {
			tab:"tab4",
			partner_account_iugu_id:partner_account_iugu_id,
			provider_account_iugu_id:provider_account_iugu_id,
			partneruser_id:partneruser_id,
			token_api_iugu:token_api_iugu,
			token_api_iugu_prod:token_api_iugu_prod,
			platform_mode:platform_mode,
			currentConfig:configRows	
		} 
		var propsParam = {
			setTabLoading:setTabLoading,
			setParentLoading:setParentLoading,
			setAlert:setAlert,
			setAlertBody:setAlertBody,
			setAlertTitle:setAlertTitle
		}		
		saveConfig(data,propsParam)
		return;
	} // end if

	console.log("[SAVED GROUP....]--->"+JSON.stringify(group,null,2));

} // end 
	
	return (
	<>
		<Backdrop style={{zIndex:"999999",flexDirection:"column"}} open={parentLoading} onClick={()=>{}}>
		  <CircularProgress style={{zIndex:"9999999",color:"#FFF"}} /><br />
		  <div style={{color:"#FFF"}}>Gravando...aguarde!</div>
		</Backdrop>			
						<Card>
							<CardHeader>
							  <h4 className={classes.cardTitle}>
								Altere as configurações abaixo <br /><small> Cuidado ao mexer pois pode afetar o funcionamento da plataforma. As informações que não preencher são utilizadas as padrões.</small>
							  </h4>
							</CardHeader>
							<CardBody>
							  <NavPills
								color="primary"
								tabs={[
								  {
									tabButton: "Diretórios e Arquivos",
									tabContent: (
										  <>		  
										  <TableContainer component={Box} mt={2}>
										  <Table  aria-label="simple table">

											<TableHead>
											  <TableRow style={{backgroundColor:"#EEE"}}>
												<TableCell colSpan={3}>Configurações de diretórios e endpoint</TableCell>
											  </TableRow>
											</TableHead>

											<TableBody>

												<TableRow >
												  <TableCell style={{width:"300px"}} component="th" scope="row">Diretório arquivos documentos Prestadores</TableCell>
												  <TableCell align="left"><InputText style={{width:"50%"}} value={providerLocalDirectory} onChange={(e) => setProviderLocalDirectory(e.target.value)} maxLength="255" /><small className="block" style={{marginTop:"5px"}}>Caminho relativo a raiz. Exemplo: ./fileuploads/provider/documents/ </small></TableCell>
												</TableRow>
												
												<TableRow >
												  <TableCell style={{width:"300px"}} component="th" scope="row">Diretório arquivos imagens avatar Prestadores </TableCell>
												  <TableCell align="left"><InputText style={{width:"50%"}}  maxLength="255" value={providerAvatarDirectory} onChange={(e) => setProviderAvatarDirectory(e.target.value)} /><small className="block" style={{marginTop:"5px"}}>Caminho relativo a raiz</small></TableCell>
												</TableRow>

												<TableRow >
												  <TableCell style={{width:"300px"}} component="th" scope="row">Diretório arquivos gerais Prestadores </TableCell>
												  <TableCell align="left"><InputText style={{width:"50%"}}  maxLength="255" value={providerGeneralDirectory} onChange={(e) => setProviderGeneralDirectory(e.target.value)} /><small className="block" style={{marginTop:"5px"}}>Caminho relativo a raiz</small></TableCell>
												</TableRow>
												
												<TableRow >
												  <TableCell style={{width:"300px"}} component="th" scope="row">Diretório arquivos gerais Clientes </TableCell>
												  <TableCell align="left"><InputText style={{width:"50%"}}  maxLength="255" value={customerGeneralDirectory} onChange={(e) => setCustomerGeneralDirectory(e.target.value)} /><small className="block" style={{marginTop:"5px"}}>Caminho relativo a raiz</small></TableCell>
												</TableRow>

												<TableRow >
												  <TableCell style={{width:"300px"}} component="th" scope="row">Diretório upload arquivos temporários </TableCell>
												  <TableCell align="left"><InputText style={{width:"50%"}}  maxLength="255"  value={fileUploadTempPath} onChange={(e) => setFileUploadTempPath(e.target.value)} /><small className="block" style={{marginTop:"5px"}}>Caminho relativo a raiz</small></TableCell>
												</TableRow>

												<TableRow >
												  <TableCell style={{width:"300px"}} component="th" scope="row">Diretório upload arquivos imagens serviços e categorias </TableCell>
												  <TableCell align="left"><InputText style={{width:"50%"}}  maxLength="255" value={mediaGeneralDirectory} onChange={(e) => setMediaGeneralDirectory(e.target.value)}  /><small className="block" style={{marginTop:"5px"}}>Caminho relativo a raiz</small></TableCell>
												</TableRow>

												<TableRow >
												  <TableCell style={{width:"300px"}} component="th" scope="row"> Endpoint para acesso arquivos imagens serviços e categorias </TableCell>
												  <TableCell align="left"><InputText style={{width:"50%"}}  maxLength="255" value={mediaGeneralUrl} onChange={(e) => setMediaGeneralUrl(e.target.value)}  /><small className="block" style={{marginTop:"5px"}}>Path do endpoint sem o domínio e protocolo. Exemplo: /app/media/service/retrieve </small></TableCell>
												</TableRow>

											</TableBody>
										  </Table>
										</TableContainer>	
										<div style={{textAlign:"right",marginTop:"20px"}}>
											<Button label="GRAVAR" icon="pi pi-check" loading={tabLoading} disabled={tabLoading} className="p-button-lg" onClick={() => { save('tab1'); } } />
										</div>	
										</>
									),
								  },
								  
								  {
									tabButton: "Taxas e Comissões",
									tabContent: (
									 <>									
									  <TableContainer component={Box} mt={2}>
									  <Table  aria-label="simple table">

										<TableHead>
										  <TableRow style={{backgroundColor:"#EEE"}}>
											<TableCell colSpan={3}>Taxas de cancelamento em BRL</TableCell>
										  </TableRow>
										</TableHead>

										<TableBody>

											<TableRow >
											  <TableCell style={{width:"300px"}} component="th" scope="row">Taxa de cancelamento do Prestador</TableCell>
											  <TableCell align="left"><InputNumber mode="decimal" minFractionDigits={2} style={{width:"50%"}}  value={provider_canceltax} onValueChange={(e) => setProvider_canceltax(e.value)} /><small className="block" style={{marginTop:"5px"}}> Taxa aplicada quando o prestador cancela o serviço faltando menos do que 1h e 30min da data agendada. </small></TableCell>
											</TableRow>
											
											<TableRow >
											  <TableCell style={{width:"300px"}} component="th" scope="row">Taxa de cancelamento do Cliente</TableCell>
											  <TableCell align="left"><InputNumber mode="decimal" minFractionDigits={2} style={{width:"50%"}} value={customer_canceltax} onValueChange={(e) => setCustomer_canceltax(e.value)} /><small className="block" style={{marginTop:"5px"}}> Taxa aplicada quando o cliente cancela o serviço faltando menos do que 1h e 30min da data agendada. </small></TableCell>
											</TableRow>	

											<TableRow >
											  <TableCell style={{width:"300px"}} component="th" scope="row">Taxa de alteração do Cliente</TableCell>
											  <TableCell align="left"><InputNumber mode="decimal" minFractionDigits={2} style={{width:"50%"}} value={customer_altertax} onValueChange={(e) => setCustomer_altertax(e.value)} /><small className="block" style={{marginTop:"5px"}}> Taxa aplicada quando o cliente altera o serviço faltando menos do que 1h e 30min da data agendada. </small></TableCell>
											</TableRow>														
											
										</TableBody>
									  </Table>
									</TableContainer>


									<TableContainer component={Box} mt={2}>
									  <Table  aria-label="simple table">

										<TableHead>
										  <TableRow style={{backgroundColor:"#EEE"}}>
											<TableCell colSpan={3}>Comissão padrão para todos os serviços (em %) <br /> <small>Lembre-se que a soma dos percentuais deve ser sempre igual a 100%.</small></TableCell>
										  </TableRow>
										</TableHead>

										<TableBody>

											<TableRow >
											  <TableCell style={{width:"300px"}} component="th" scope="row">Comissão Plataforma</TableCell>
											  <TableCell align="left"><InputNumber mode="decimal" minFractionDigits={2} style={{width:"50%"}} value={platform_default_comission} onValueChange={(e) => setPlatform_default_comission(e.value)} /><small className="block" style={{marginTop:"5px"}}> Defina o % de ganho da plataforma. </small></TableCell>
											</TableRow>		
										
											<TableRow >
											  <TableCell style={{width:"300px"}} component="th" scope="row">Comissão Prestador</TableCell>
											  <TableCell align="left"><InputNumber mode="decimal" minFractionDigits={2} style={{width:"50%"}} value={provider_default_comission} onValueChange={(e) => setProvider_default_comission(e.value)} /><small className="block" style={{marginTop:"5px"}}> Defina o % de ganho do Prestador. </small></TableCell>
											</TableRow>		

											<TableRow >
											  <TableCell style={{width:"300px"}} component="th" scope="row">Comissão Parceiro</TableCell>
											  <TableCell align="left"><InputNumber mode="decimal" minFractionDigits={2} style={{width:"50%"}}  value={partner_default_comission} onValueChange={(e) => setPartner_default_comission(e.value)} /><small className="block" style={{marginTop:"5px"}}> Defina o % de ganho do Parceiro. </small></TableCell>
											</TableRow>													
										
											
										</TableBody>
									  </Table>
									</TableContainer>

												
										<div style={{textAlign:"right",marginTop:"20px"}}>
											<Button label="GRAVAR" loading={tabLoading} disabled={tabLoading} icon="pi pi-check" className="p-button-lg" onClick={() => { save('tab2'); } } />
										</div>	
									 </>
									),
								  },
								 /* {
									tabButton: "Labels e textos",
									tabContent: (
									  <span>
										<p>
										  Completely synergize resource taxing relationships via
										  premier niche markets. Professionally cultivate
										  one-to-one customer service with robust ideas.{" "}
										</p>
										<br />
										<p>
										  Dynamically innovate resource-leveling customer
										  service for state of the art customer service.
										</p>
									  </span>
									),
								  },*/
{
									tabButton: "Serviços Externos",
									tabContent: (
										  <>		  
										  <TableContainer component={Box} mt={2}>
										  <Table  aria-label="simple table">

											<TableHead>
											  <TableRow style={{backgroundColor:"#EEE"}}>
												<TableCell colSpan={3}>STREAM CHAT: Provedor de Chat 1-to-1</TableCell>
											  </TableRow>
											</TableHead>

											<TableBody>

												<TableRow >
												  <TableCell style={{width:"300px"}} component="th" scope="row">Token acesso API</TableCell>
												  <TableCell align="left"><InputText style={{width:"50%"}}  maxLength="255" value={streamChatToken} onChange={(e) => setStreamChatToken(e.target.value)} /><small className="block" style={{marginTop:"5px"}}>Fornecida em sua conta no serviço.</small></TableCell>
												</TableRow>
												
												<TableRow >
												  <TableCell style={{width:"300px"}} component="th" scope="row">Salt Token JWT </TableCell>
												  <TableCell align="left"><InputText style={{width:"50%"}}  maxLength="255" value={tokenChatKey} onChange={(e) => setTokenChatKey(e.target.value)} /><small className="block" style={{marginTop:"5px"}}>Chave única para geração de tokens JWT no serviço</small></TableCell>
												</TableRow>

											</TableBody>
										  </Table>
										</TableContainer>
										
										  <TableContainer component={Box} mt={2}>
										  <Table  aria-label="simple table">

											<TableHead>
											  <TableRow style={{backgroundColor:"#EEE"}}>
												<TableCell colSpan={3}>SENDGRID: Provedor email transacional</TableCell>
											  </TableRow>
											</TableHead>

											<TableBody>

												<TableRow >
												  <TableCell style={{width:"300px"}} component="th" scope="row">Token acesso API</TableCell>
												  <TableCell align="left"><InputText style={{width:"50%"}}  maxLength="255" value={sendgrid_api_key} onChange={(e) => setSendgrid_api_key(e.target.value)} /><small className="block" style={{marginTop:"5px"}}> Token de SMTP relay para utilização do serviço. </small></TableCell>
												</TableRow>
												
											</TableBody>
										  </Table>
										</TableContainer>	
										
										  <TableContainer component={Box} mt={2}>
										  <Table  aria-label="simple table">

											<TableHead>
											  <TableRow style={{backgroundColor:"#EEE"}}>
												<TableCell colSpan={3}>ZENVIA: Provedor SMS transacional</TableCell>
											  </TableRow>
											</TableHead>

											<TableBody>

												<TableRow >
												  <TableCell style={{width:"300px"}} component="th" scope="row">Token para acesso a API</TableCell>
												  <TableCell align="left"><InputText style={{width:"50%"}}  maxLength="255" value={zenvia_token} onChange={(e) => setZenvia_token(e.target.value)} /><small className="block" style={{marginTop:"5px"}}>Fornecida em sua conta no serviço.</small></TableCell>
												</TableRow>
												
												<TableRow >
												  <TableCell style={{width:"300px"}} component="th" scope="row">ID do remetente</TableCell>
												  <TableCell align="left"><InputText style={{width:"50%"}}  maxLength="255" value={zenvia_senderid} onChange={(e) => setZenvia_senderid(e.target.value)} /><small className="block" style={{marginTop:"5px"}}>Código ID do remetente do SMS fornecido pelo serviço.</small></TableCell>
												</TableRow>

											</TableBody>
										  </Table>
										</TableContainer>
										
										  <TableContainer component={Box} mt={2}>
										  <Table  aria-label="simple table">

											<TableHead>
											  <TableRow style={{backgroundColor:"#EEE"}}>
												<TableCell colSpan={3}>FRESHDESK: Provedor solução helpdesk</TableCell>
											  </TableRow>
											</TableHead>

											<TableBody>

												<TableRow >
												  <TableCell style={{width:"300px"}} component="th" scope="row">URL do serviço de API</TableCell>
												  <TableCell align="left"><InputText style={{width:"50%"}}  maxLength="255" value={freshdesk_api_url} onChange={(e) => setFreshdesk_api_url(e.target.value)} /><small className="block" style={{marginTop:"5px"}}>Fornecida em sua conta no serviço. </small></TableCell>
												</TableRow>
												
												<TableRow >
												  <TableCell style={{width:"300px"}} component="th" scope="row">Token para acessar a API</TableCell>
												  <TableCell align="left"><InputText style={{width:"50%"}}  maxLength="255" value={freshdesk_api_token} onChange={(e) => setFreshdesk_api_token(e.target.value)} /><small className="block" style={{marginTop:"5px"}}>Fornecida em sua conta no serviço.</small></TableCell>
												</TableRow>
												
											</TableBody>
										  </Table>
										</TableContainer>
										
										  <TableContainer component={Box} mt={2}>
										  <Table  aria-label="simple table">

											<TableHead>
											  <TableRow style={{backgroundColor:"#EEE"}}>
												<TableCell colSpan={3}>Google Maps API: Utilização de APIs do Google Maps</TableCell>
											  </TableRow>
											</TableHead>

											<TableBody>
												
												<TableRow >
												  <TableCell style={{width:"300px"}} component="th" scope="row">Token para acessar a API</TableCell>
												  <TableCell align="left"><InputText style={{width:"50%"}}  maxLength="255" value={google_maps_token} onChange={(e) => setGoogle_maps_token(e.target.value)} /><small className="block" style={{marginTop:"5px"}}>Fornecida em sua conta no serviço.</small></TableCell>
												</TableRow>

												
											</TableBody>
										  </Table>
										</TableContainer>										
										
										<div style={{textAlign:"right",marginTop:"20px"}}>
											<Button label="GRAVAR" icon="pi pi-check" loading={tabLoading} disabled={tabLoading} className="p-button-lg" onClick={() => { save('tab3'); } } />
										</div>	
										</>
									),
								  },								  
								{
									tabButton: "Gateway IUGU",
									tabContent: (
										<>		  
										  <TableContainer component={Box} mt={2}>
										  <Table  aria-label="simple table">

											<TableHead>
											  <TableRow style={{backgroundColor:"#EEE"}}>
												<TableCell colSpan={3}>Configuração de acesso a API</TableCell>
											  </TableRow>
											</TableHead>

											<TableBody>
												
												<TableRow >
												  <TableCell style={{width:"300px"}} component="th" scope="row">Modo integração </TableCell>
												  <TableCell align="left"><Dropdown value={platform_mode} options={[{"label":"Desenvolvimento","value":"devel"},{"label":"Produção","value":"prod"}]} onChange={(e) => setPlatform_mode(e.target.value)} placeholder="Modo de operação"/><small className="block" style={{marginTop:"5px"}}>Entre em produção caso saiba o que está fazendo.</small></TableCell>
												</TableRow>											

												<TableRow >
												  <TableCell style={{width:"300px"}} component="th" scope="row">Token acesso API - Desenvolvimento</TableCell>
												  <TableCell align="left"><InputText style={{width:"50%"}}  maxLength="255" value={token_api_iugu} onChange={(e) => setToken_api_iugu(e.target.value)} /><small className="block" style={{marginTop:"5px"}}>Fornecida em sua conta no serviço.</small></TableCell>
												</TableRow>
												
												<TableRow >
												  <TableCell style={{width:"300px"}} component="th" scope="row">Token acesso API - Produção</TableCell>
												  <TableCell align="left"><InputText style={{width:"50%"}}  maxLength="255" value={token_api_iugu_prod} onChange={(e) => setToken_api_iugu_prod(e.target.value)} /><small className="block" style={{marginTop:"5px"}}>Fornecida em sua conta no serviço.</small></TableCell>
												</TableRow>
															
											</TableBody>
										  </Table>
										</TableContainer>
										
										  <TableContainer component={Box} mt={2}>
										  <Table  aria-label="simple table">

											<TableHead>
											  <TableRow style={{backgroundColor:"#EEE"}}>
												<TableCell colSpan={3}>Outras configurações</TableCell>
											  </TableRow>
											</TableHead>

											<TableBody>

												<TableRow >
												  <TableCell style={{width:"300px"}} component="th" scope="row">Token da subconta IUGU referente ao PARCEIRO </TableCell>
												  <TableCell align="left"><InputText style={{width:"50%"}}  maxLength="255" value={partner_account_iugu_id} onChange={(e) => setPartner_account_iugu_id(e.target.value)} /><small className="block" style={{marginTop:"5px"}}> Token da conta encontrada na sua conta IUGU.</small></TableCell>
												</TableRow>
												
												<TableRow >
												  <TableCell style={{width:"300px"}} component="th" scope="row">Código ID do Parceiro na Plataforma Motuum</TableCell>
												  <TableCell align="left"><InputNumber mode="decimal" useGrouping={false} style={{width:"50%"}}  maxLength="45" value={partneruser_id} onValueChange={(e) => setPartneruser_id(e.value)} /><small className="block" style={{marginTop:"5px"}}> Código ID do parceiro na plataforma Motuum para vínculos. </small></TableCell>
												</TableRow>	
												
												<TableRow >
												  <TableCell style={{width:"300px"}} component="th" scope="row">Token da subconta IUGU referente ao PRESTADOR </TableCell>
												  <TableCell align="left"><InputText style={{width:"50%"}}  maxLength="255" value={provider_account_iugu_id} onChange={(e) => setProvider_account_iugu_id(e.target.value)} /><small className="block" style={{marginTop:"5px"}}> Token da conta encontrada na sua conta IUGU para modo desenvolvimento <br /> onde o prestador receberá os valores transacionados.</small></TableCell>
												</TableRow>												
												
											</TableBody>
										  </Table>
										</TableContainer>	
										
										<div style={{textAlign:"right",marginTop:"20px"}}>
											<Button label="GRAVAR" icon="pi pi-check" loading={tabLoading} disabled={tabLoading} className="p-button-lg" onClick={() => { save('tab4'); } } />
										</div>	
										</>									 
									),
								  },								  
								]}
							  />
							</CardBody>
						  </Card>

			
			<Dialog header={alertTitle} visible={alert} style={{ width: '50vw' }} footer={renderFooter()} onHide={() => setAlert(false)} draggable={false} dismissableMask={true} closable={false} >
				<p>{alertBody}</p>
			</Dialog> 
			
	</>	
	);
	
});

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(Config);	
