// import '../Assets/Styles/register.css';
import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Button } from 'primereact/button'; 
import { Dialog } from 'primereact/dialog';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

// Load Actions
import * as AppActions from '../Store/Actions/App';

// UpdateService dialog component
const AlertView = (props) => {

// set vars from props
const {
	alert,
	setAlert,
	alertTitle,
	alertBody,
	alertAction,
	scrollBlock,
	setDialog
} = props;		

const close = ()=> {
	setAlert(false);
	
	if (alertAction == "closeRegisterModal") {
		setDialog('registerModal',false);
	} // end if
	
	setTimeout(function() {
		if (scrollBlock) {
			document.body.classList.add('p-overflow-hidden');	
		} // end if
	},300);
}
	

const footerContent = (
	<div className="modalFooterButtonsEnd">
		<div>
			<Button label="OK" icon="pi pi-times" className="p-button-text" onClick={() => close()} />
		</div>
	</div>
);		
	
	return (
	<>
		<Dialog header={alertTitle} visible={alert} style={{ width: '30vw' }} breakpoints={{ '1400px': '40vw','960px': '50vw', '641px': '100vw' }} onHide={() => close()} footer={footerContent} dismissableMask={true} >
		
		<Box
		  sx={{
			width: "100%",
		  }}
		  className="terms"
		>
			<div>{alertBody}</div>
		</Box>
		</Dialog>	
	</>	
	);
	
}

const mapStateToProps = store => ({
  dialog:store.appReducer.dialog
 });
const mapDispatchToProps = dispatch => bindActionCreators({ ...AppActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(AlertView);	
