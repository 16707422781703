import React,{useState,useRef} from "react";
import {useHistory,Link} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// @material-ui/core components
import './DataTable.css';
import { makeStyles,lighten } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";

// import { DataGrid,GridToolbar,ptBR  } from '@material-ui/data-grid';
import moment from "moment";

// Import Prime React Components
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import 'primereact/resources/themes/saga-blue/theme.css'; 
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Calendar } from 'primereact/calendar';
import { locale, addLocale, updateLocaleOption, updateLocaleOptions, localeOption, localeOptions } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Backdrop from '@material-ui/core/Backdrop';
import { Fieldset } from 'primereact/fieldset';
import { SelectButton } from 'primereact/selectbutton';
import { InputTextarea } from 'primereact/inputtextarea';
import { Chips } from 'primereact/chips';
import { Panel } from 'primereact/panel';
import { RadioButton } from 'primereact/radiobutton';

// import { BlockUI } from 'primereact/blockui'; // loading panel
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';

// Add Locale
addLocale('pt-BR', {
    firstDayOfWeek: 1,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar'
});

locale('pt-BR');

import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import Person from "@material-ui/icons/Person";
import View from '@material-ui/icons/Visibility';
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import ZoomInIcon from '@material-ui/icons/ZoomIn';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Table from "components/Table/Table.js";
// import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

// Load Actions
import * as AppActions from 'Store/Actions/App'
import * as ProviderActions from 'Store/Actions/Provider'

const useStyles = makeStyles(styles);

const AdminUsers = (props) => {

// set vars from props
const { 
	appLoading,
	setAppLoading,
} = props;		


const [value, setValue] = React.useState(0);
const [contentVisible, setContentVisible] = React.useState(false); 

// console.log("[providers]: "+JSON.stringify(providers,null,2));
	
const classes = useStyles();

// Handle Tab changes
const handleChange = (event, newValue) => {
	
// Check which TAB was picked
//
		
setValue(newValue);

};

 
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

	
		
// set Use of history for PUSH 
let history = useHistory();

// Tasks to execute when mount component
React.useEffect(() => {
	
	setTimeout(function() {
			
		setAppLoading(false);
		setContentVisible(true);		
		
	},1000);

    return () => {
     setAppLoading(true);
    };	
	
}, []);

	
if(!contentVisible) {	  
	return (
		<div style={{width:"100%",height:"80vh",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
		
			<div style={{display:"flex",flex:1,alignItems:"center",justifyContent:"center"}}>
			
				<CircularProgress color="primary" />
				<div style={{fontSize:"18px",marginLeft:"15px"}}> Carregando dados...</div>
			
			</div>
		
		</div>
	)
  }

if(contentVisible) {
  return ( 
  <>	
   <Box ml={3} mb={5}>
   
	 <Breadcrumbs aria-label="breadcrumb">
	  <Typography color="textSecondary">Configurações</Typography>
	  <Typography color="textPrimary">Tabela de comissão</Typography>
	</Breadcrumbs>
	
	<Divider />
	
	<h3 style={{marginTop:"30px"}}>Tabela com comissão padrão e por serviço</h3>
	<h5>Valor fixo ou percentual de comissão</h5>	
		
	</Box>
  
    <GridContainer>
	
      <GridItem xs={12}>

		<Tab1Content
			setAppLoading={setAppLoading}
			setContentVisible={setContentVisible}
		/>
					
	  </GridItem>
	  
    </GridContainer>		
	</>
  );
}

return (null);

}
 
const mapStateToProps = store => ({
  buttonLoading:store.appReducer.buttonLoading
 });

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions }, dispatch);


// Tab1Content Object
export const Tab1Content = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {

// set vars from props
const { 
	appLoading,
	setAppLoading,
	retrieveComissions
} = props;		

// Set classes
const classes = useStyles();	

// useRef
var dt = useRef(null);

// Scope data
const [comissionsRows, setComissionsRows] = useState([]);
const [checked, setChecked] = useState(false); 

// Dialog control
const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico"); 

// Service Dialog control
const [alertComission, setAlertComission] = React.useState(false); 
const [alertComissionUpdate, setAlertComissionUpdate] = React.useState(false); 
const [comissionId, setComissionId] = React.useState(null); 

// DataGrid Params
const [parentLoading, setParentLoading] = useState(false);

// DataGrid Params
const [loading, setLoading] = useState(false);
const [totalRecords, setTotalRecords] = useState(0);
const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
		filters:null,
		sortField:"createdAt",
		sortOrder:1
    });

React.useEffect(() => {

	loadLazyData();

},[lazyParams]);
 
const loadLazyData = () => {
	
	setLoading(true);
	
	// Retrieve List of AdminUsers
	retrieveAdminComissionsAction();
}

const onPage = (event) => {
	let _lazyParams = { ...lazyParams, ...event };
	setLazyParams(_lazyParams);
}

const onSort = (event) => {	
	let _lazyParams = { ...lazyParams, ...event };
	setLazyParams(_lazyParams);
}

const onFilter = (event) => {
	let _lazyParams = { ...lazyParams, ...event };
	_lazyParams['first'] = 0;
	setLazyParams(_lazyParams);
}

// ToolBar Content  
const leftToolbarTemplate = () => {
	return (
		<React.Fragment>
			
		</React.Fragment>
	)
}

const rightToolbarTemplate = () => {
	return (
		<React.Fragment>
			
		</React.Fragment>
	)
}  
  
// Header Template  
const header = (
	<div className="table-header" style={{height:"40px"}}>
		<h5 style={{float:"left"}}> Tabela de comissões configuradas </h5>
	</div>
);	

const typeComissionBodyTemplate = (rowData) => {
	
		var typeLabel = rowData.type === 2 ? "Percentual" : "Valor Fixo";	
        return (
            <React.Fragment>
                <span className="p-column-title">Tipo</span>
                {typeLabel}
            </React.Fragment>
        );
}
								
const platformComissionBodyTemplate = (rowData) => {
	
		if (rowData.type === 2) {
			var comissionValue = rowData.platformComission+"%";
		} // enf 
		else {
			var comissionValue = "R$ "+rowData.platformComission+",00";
		} // end else
	
        return (
            <React.Fragment>
                <span className="p-column-title">Plataforma</span>
                {comissionValue}
            </React.Fragment>
        );
}
							
const providerComissionBodyTemplate = (rowData) => {
	
		if (rowData.type === 2) {
			var comissionValue = rowData.providerComission+"%";
		} // enf 
		else {
			var comissionValue = "R$ "+rowData.providerComission+",00";
		} // end else
	
        return (
            <React.Fragment>
                <span className="p-column-title">Prestador</span>
                {comissionValue}
            </React.Fragment>
        );
		
}
							
const partnerComissionBodyTemplate = (rowData) => {
        if (rowData.type === 2) {
			var comissionValue = rowData.partnerComission+"%";
		} // enf 
		else {
			var comissionValue = "R$ "+rowData.partnerComission+",00";
		} // end else
	
        return (
            <React.Fragment>
                <span className="p-column-title">Parceiro</span>
                {comissionValue}
            </React.Fragment>
        );
}

const serviceBodyTemplate = (rowData) => {
	
		if (!rowData.Service) {
			var serviceName = "Todos os serviços";
			var serviceMaster = true;
		} // end if
		else {
			var serviceName = rowData.Service.name;
			var serviceMaster = false;			
		} // end else
	
        return (
            <React.Fragment>
                <span className="p-column-title">Serviço</span>
                {serviceName}
				{serviceMaster &&
					<>
						<i style={{color:"gold",marginLeft:"10px"}} title="Padrão" className="pi pi-star"></i>
					</>					
				}
            </React.Fragment>
        );
}

// Column Actions Body Template  
// NEED TO ADD ROW ID AND ONCLICK EVENT
const actionBodyTemplate = (rowData) => {
	return (
		<React.Fragment>
			<Button label="Edição rápida" icon="pi pi-pencil" className="p-button-outlined p-button-secondary p-button-sm" style={{margin:"3px"}} onClick={() => { openUpdateDialog(rowData.id); }} />
		</React.Fragment>
	);
}	


// Dialog Footer
const renderFooter = () => {
	return (
		<div>
			<Button label="OK" icon="pi pi-check" onClick={() => setAlert(false)} />
		</div>
	);
}		
		
// Tasks to execute when mount component
React.useEffect(() => {

	// on component mount
	//console.log("[MOUNTED COMPONENT...]--->");

    return () => {
     setAppLoading(true);
    };			
}, []);

		
const retrieveAdminComissionsAction = () => {

		// Retrive Comissions List
		var propsParam = {
			setComissionsRows:setComissionsRows,
			setLoading:setLoading,
			setTotalRecords:setTotalRecords
		}	
		retrieveComissions(propsParam);
	
;	

} // end 


// Open Category Update Dialog
const openUpdateDialog = (id) => {

	setComissionId(id);
	setAlertComissionUpdate(true);

} // end 

// set Use of history for PUSH 
let history = useHistory();
	
	return (
	<>
		<Card>
			  <CardHeader color="info" icon>
				<CardIcon color="info">
				  <Assignment />
				</CardIcon>
				<h4 className={classes.cardIconTitle}>Lista de comissões</h4>
			  </CardHeader>
	
			  <CardBody>	  

				<div className="datatable-responsive-demo" style={{marginTop:"20px"}}>
				
					<div className="card">
							
						{/*<Toolbar style={{marginBottom:"4px"}} left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>*/}
							
						<DataTable ref={dt} value={comissionsRows} className="p-datatable-responsive-demo" rows={lazyParams.rows} header={header} paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords}" rowsPerPageOptions={[10,20,50]} emptyMessage="Nenhuma comissão cadastrada." lazy paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage} onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder} onFilter={console.log("filtering...")} filters={lazyParams.filters} loading={loading} >
							
							<Column header="Tipo" body={typeComissionBodyTemplate} />
								
							<Column header="Plataforma" body={platformComissionBodyTemplate} />
							
							<Column header="Prestador" body={providerComissionBodyTemplate} />		
							
							<Column header="Parceiro" body={partnerComissionBodyTemplate} />		

							<Column header="Serviço" body={serviceBodyTemplate} style={{width:'40%'}} />
							
							<Column body={actionBodyTemplate} ></Column>
							
						</DataTable>						
						 
					</div>
				</div>
								
			  </CardBody>
			</Card>	
					
			<UpdateComission
				alertComissionUpdate={alertComissionUpdate}
				setAlertComissionUpdate={setAlertComissionUpdate}
				loadLazyData={loadLazyData}
				setAlertTitle={setAlertTitle}
				setAlertBody={setAlertBody}
				setAlert={setAlert}
				setParentLoading={setParentLoading}
				comissionId={comissionId}				
			/>

			<Dialog header={alertTitle} visible={alert} style={{ width: '50vw' }} footer={renderFooter()} onHide={() => setAlert(false)} draggable={false} dismissableMask={true} closable={false} >
				<p>{alertBody}</p>
			</Dialog> 
			
	</>	
	);
	
});

// Update User dialog component
export const UpdateComission = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
		
// Set classes
const classes = useStyles();	

const screenStyleLoading = {
  alignItems: "middle",
  justifyContent: "center",
  width:"100%",
  display: "flex",
  flexDirection: "column"
};	

// set vars from props
const { 
	alertComissionUpdate,
	setAlertComissionUpdate,
	loadLazyData,
	buttonLoading,
	setAlertTitle,
	setAlertBody,
	setAlert,
	updateComission,
	retrieveComission,
	comissionId,
	setParentLoading
} = props;		


const [saving, setSaving] = useState(false); 
const [loadingComission, setLoadingComission] = useState(true); 

// Set default comissionSum
const [comissionRow, setComissionRow] = useState({});

const [serviceName, setServiceName] = useState("");
const [serviceId, setServiceId] = useState("");
const [comissionGlobal, setComissionGlobal] = useState("default");
const [comissionType, setComissionType] = useState("percentage");
const [comissionPlatform, setComissionPlatform] = useState(null);
const [comissionProvider, setComissionProvider] = useState(null);
const [comissionPartner, setComissionPartner] = useState(null);

const [comissionTypeOri, setComissionTypeOri] = useState("percentage");
const [comissionPlatformOri, setComissionPlatformOri] = useState(null);
const [comissionProviderOri, setComissionProviderOri] = useState(null);
const [comissionPartnerOri, setComissionPartnerOri] = useState(null);

// Options for comissions types
const comissionsTypes = [
        {name: 'Percentual', value: "percentage"},
        {name: 'Valor Fixo', value: "fixed"}
];

// Options for status
const statuses = [
    {label: 'Inativo', value: '0'},
    {label: 'Ativo', value: '1'}
];	
	
// Execute when dialog opens
React.useEffect(() => {

	// Only r]uns when  dialog opens
	if (alertComissionUpdate === true) {

		// Retrieve Category - add delay to wait for previous returns
		var data = {
			comissionId:comissionId
		}
		var propsParam = {
			setComissionRow:setComissionRow,
			setLoadingComission:setLoadingComission
		}	
		retrieveComission(data,propsParam);
		
	} // end if
	
	// Reset all fields when closes or open dialog
	// ...
	
	// Reset images when open interface
	setLoadingComission(true);
		
},[alertComissionUpdate]);

// Set values for each field
React.useEffect(() => {

	// If service returned set values
	if (comissionRow.id) {
		
		var serviceName = comissionRow.Service ? comissionRow.Service.name : "Todos os serviços";
		setServiceName(serviceName);
		
		if (!comissionRow.Service) {
			setComissionType("percentage");			
		} // end if
		else {
			setComissionType(comissionRow.type == 2 ? "percentage" : "fixed");
		} // end else
		
		setComissionType(comissionRow.type == 2 ? "percentage" : "fixed");
		setComissionPlatform(comissionRow.platformComission);
		setComissionProvider(comissionRow.providerComission);
		setComissionPartner(comissionRow.partnerComission);
		
		setComissionTypeOri(comissionRow.type == 2 ? "percentage" : "fixed");
		setComissionPlatformOri(comissionRow.platformComission);
		setComissionProviderOri(comissionRow.providerComission);
		setComissionPartnerOri(comissionRow.partnerComission);

		var serviceIdValue = comissionRow.Service ? comissionRow.Service.id : null;
		setServiceId(serviceIdValue);

	} // end if

},[comissionRow]);

// Open alert when save button is disabled
const openAlert = (section) => {

	if (section == 'comission') {
		setAlertTitle("Verifique a comissão do serviço");
		setAlertBody("É necessário que defina no mínimo a comissão da plataforma e do prestador do serviço para prosseguir com a atualização.");
		setAlert(true);
	} // end if		
	else if (section == 'nochange') {
		setAlertTitle("Nada foi alterado");
		setAlertBody("Altere ao menos um dos valores para atualizar.");
		setAlert(true);
	} // end if		
	else if (section == 'comissionSum') {
		setAlertTitle("Verifique a comissão do serviço");
		setAlertBody("A soma percentual das comissões das partes é maior ou menor que 100%. Verifique.");
		setAlert(true);
	} // end if			
	else {
		setAlertTitle("Verifique as informações");
		setAlertBody("Preencha os dados mínimos marcados com * para prosseguir com a atualização.");
		setAlert(true);
	} // end if	
			
} // end set Region			

// Save instance method	
const save = () => {

	// COMISSION check
	if (comissionPlatform == comissionPlatformOri && comissionProvider == comissionProviderOri && comissionPartner == comissionPartnerOri && comissionType == comissionTypeOri) {
		openAlert('nochange');
		return;					
	} // end if

	// COMISSION check
	if (!comissionPlatform || !comissionProvider) {
		openAlert('comission');
		return;					
	} // end if
	else {
		if (comissionType == "percentage") {
			var total = parseInt(comissionPlatform) + parseInt(comissionProvider) + parseInt(comissionPartner ? comissionPartner : 0);				
			if (total != 100) {
				openAlert('comissionSum');
				return;						
			} // end if
		} // end if
	} // end else
	
	// ALL OK, save service
	
	// Turn on loading on button
	setSaving(true);	
	
	// Turn on overlay loading
	setParentLoading(true);

	// Prepare Data
	var data = {
		comissionId:comissionId,
		comissionType:comissionType,
		comissionPlatform:comissionPlatform,
		comissionProvider:comissionProvider,
		comissionPartner:comissionPartner,
		serviceId:serviceId
	}
	// Prepare Props
	var propsParam = {
		setSaving:setSaving,
		setParentLoading:setParentLoading,
		loadLazyData:loadLazyData,
		setAlertTitle:setAlertTitle,
		setAlertBody:setAlertBody,
		setAlert:setAlert,
		setAlertComissionUpdate:setAlertComissionUpdate
	}
	// Save
	updateComission(data,propsParam);

} // end function save
	
	return (
	<>
	<Dialog header="Editar comissão" visible={alertComissionUpdate} style={{ width: '70vw' }} footer={(<div>
			<Button label="FECHAR" disabled={saving} style={{marginTop:"20px"}} icon="pi pi-check" className="p-button-outlined p-button-secondary p-button-sm" onClick={() => {setAlertComissionUpdate(false)} } />
		</div>)} onHide={() => setAlertComissionUpdate(false)} draggable={false} dismissableMask={false} closable={saving ? false : true} closeOnEscape={false} >
				
				{loadingComission && 
					<Box style={{...screenStyleLoading,height:"65vh"}}>
							<Box mt={0} mx={0} style={{textAlign:"center"}}>								
								<CircularProgress /> 
								<div> Carregando dados...Aguarde!</div>								
							</Box>	
					</Box>
				}

				{!loadingComission &&
				<>	
				<GridContainer justify="center">
				  <GridItem xs={12} sm={12}>

					<Card>
					  <CardHeader color="info" icon>
						<CardIcon color="info">
						  <Assignment />
						</CardIcon>
						<h4 className={classes.cardIconTitle}>Edição rápida de comissão</h4>
						<h6 className={classes.cardIconTitle} style={{marginLeft:"78px",color:"#000"}}>Edite de forma rápida a comissão. Para outras alterações entre em configurações ou no serviço desejado.</h6>
					  </CardHeader>
					  <CardBody>	 
					  

						{/* Comission Block */}
						<TableContainer component={Box} mt={2}>
						  <Table aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Definição de comissões do Serviço <small style={{color:"red",marginLeft:"10px"}}>informação obrigatória</small></TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Altere a comissão do serviço selecionado (ou a padrão) de forma rápida.<br /><br /> <small>Plataforma e Prestador são obrigatórios.</small> </TableCell>
								  
								  <TableCell align="left">

									<Fieldset legend={serviceName} style={{marginBottom:"20px"}}>
									
										<div style={{marginTop:"20px",paddingTop:0,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",marginBottom:"20px"}}>
										
										  <div style={{width:"150px"}}>Tipo de comissão<sup style={{color:"red"}}>*</sup></div>
										  
										  {!serviceId &&
											  <div style={{flex:1}}>
												 <SelectButton value={comissionType} optionLabel="name" options={[{name: 'Percentual', value: "percentage"}]} onChange={(e) => setComissionType(e.value)} />
											  </div>										  
										  }
										  
										  {serviceId &&
											  <div style={{flex:1}}>
												 <SelectButton value={comissionType} optionLabel="name" options={comissionsTypes} onChange={(e) => setComissionType(e.value)} />
											  </div>
										  }
										  
										</div>
										
										{/* Platform comission */}
										<div style={{marginTop:0,paddingTop:0,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",marginBottom:"20px"}}>
										
										  <div style={{width:"150px"}}>Comissão Plataforma <sup style={{color:"red"}}>*</sup></div>
										  
										  <div style={{flex:1}}>
											  {comissionType == "percentage" &&
											  <>
												<InputNumber inputId="percent" value={comissionPlatform} onValueChange={(e) => setComissionPlatform(e.value)} max={99} />
											  </>
											  }
											  
											  {comissionType == "fixed" &&
											  <>
												<InputNumber value={comissionPlatform} onValueChange={(e) => setComissionPlatform(e.value)} mode="currency" currency="BRL" locale="pt-BR" />
											  </>
											  }
										  </div>
										  
										</div>
										
										{/* Provider comission */}
										<div style={{marginTop:0,paddingTop:0,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",marginBottom:"20px"}}>
										
										  <div style={{width:"150px"}}>Comissão Prestador <sup style={{color:"red"}}>*</sup></div>
										  
										  <div style={{flex:1}}>
											  {comissionType == "percentage" &&
											  <>
												<InputNumber inputId="percent" value={comissionProvider} onValueChange={(e) => setComissionProvider(e.value)} max={99} />
											  </>
											  }
											  
											  {comissionType == "fixed" &&
											  <>
												<InputNumber value={comissionProvider} onValueChange={(e) => setComissionProvider(e.value)} mode="currency" currency="BRL" locale="pt-BR" />
											  </>
											  }
										  </div>
										  
										</div>	

										{/* Partner comission */}
										<div style={{marginTop:0,paddingTop:0,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",marginBottom:"20px"}}>
										
										  <div style={{width:"150px"}}>Comissão Parceiro</div>
										  
										  <div style={{flex:1}}>
											  {comissionType == "percentage" &&
											  <>
												<InputNumber inputId="percent" value={comissionPartner} onValueChange={(e) => setComissionPartner(e.value)} max={99} />
											  </>
											  }
											  
											  {comissionType == "fixed" &&
											  <>
												<InputNumber value={comissionPartner} onValueChange={(e) => setComissionPartner(e.value)} mode="currency" currency="BRL" locale="pt-BR" />
											  </>
											  }
										  </div>
										  
										</div>										
													

									</Fieldset>
											  
								  
								  </TableCell>
								</TableRow>
								
							</TableBody>
						  </Table>
						</TableContainer>					  
						
					  </CardBody>
					</Card>	 					
					<div style={{textAlign:"right"}}>
						<Button label="FINALIZAR EDIÇÃO" icon="pi pi-check" className="p-button-lg"  loading={saving} onClick={() => { save(); } } />
					</div>					

				  </GridItem>
				</GridContainer>
				</>
				}
				
			</Dialog>	
	</>	
	);
	
});


export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(AdminUsers);	
