import React,{useState,useRef} from "react";
import {useHistory,Link} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// @material-ui/core components
import './DataTable.css';
import { makeStyles,lighten } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";

// import { DataGrid,GridToolbar,ptBR  } from '@material-ui/data-grid';
import moment from "moment";

// Import Prime React Components
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import 'primereact/resources/themes/saga-blue/theme.css'; 
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Calendar } from 'primereact/calendar';
import { locale, addLocale, updateLocaleOption, updateLocaleOptions, localeOption, localeOptions } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';

import { Timeline } from 'primereact/timeline'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// Add Locale
addLocale('pt-BR', {
    firstDayOfWeek: 1,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar'
});

locale('pt-BR');

import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import Person from "@material-ui/icons/Person";
import View from '@material-ui/icons/Visibility';
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import ZoomInIcon from '@material-ui/icons/ZoomIn';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Table from "components/Table/Table.js";
// import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

// Load Actions
import * as AppActions from 'Store/Actions/App'
import * as ServiceActions from 'Store/Actions/Service'

const useStyles = makeStyles(styles);

const AdminUsers = (props) => {

// set vars from props
const { 
	appLoading,
	setAppLoading,
} = props;		


const [value, setValue] = React.useState(0);
const [contentVisible, setContentVisible] = React.useState(false); 

// console.log("[providers]: "+JSON.stringify(providers,null,2));
	
const classes = useStyles();

// Handle Tab changes
const handleChange = (event, newValue) => {
	
// Check which TAB was picked
//
		
setValue(newValue);

};

 
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

	
		
// set Use of history for PUSH 
let history = useHistory();

// Tasks to execute when mount component
React.useEffect(() => {
	
	setTimeout(function() {
			
		setAppLoading(false);
		setContentVisible(true);		
		
	},1000);

    return () => {
     setAppLoading(true);
    };	
	
}, []);

	
if(!contentVisible) {	  
	return (
		<div style={{width:"100%",height:"80vh",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
		
			<div style={{display:"flex",flex:1,alignItems:"center",justifyContent:"center"}}>
			
				<CircularProgress color="primary" />
				<div style={{fontSize:"18px",marginLeft:"15px"}}> Carregando dados...</div>
			
			</div>
		
		</div>
	)
  }

if(contentVisible) {
  return ( 
  <>	
   <Box ml={3} mb={5}>
   
	 <Breadcrumbs aria-label="breadcrumb">
	  <Typography color="textSecondary">Área Gerencial</Typography>
	  <Typography color="textPrimary">Gestão de Recebimentos</Typography>
	</Breadcrumbs>
	
	<Divider />
	
	<h3 style={{marginTop:"30px"}}>Gestão de Recebimentos</h3>
	<h5>Relação de recebimentos provisionados e efetivados. Se desejar filtre ou ordene a busca para encontrar informações desejadas.</h5>	
		
	</Box>
  
    <GridContainer>
	
      <GridItem xs={12}>
	  
		  <Tabs
			value={value}
			onChange={handleChange}
			indicatorColor="primary"
			textColor="primary"
		  >
			<Tab label="Válidos" />
			<Tab label="Cancelados" />
		  </Tabs>
     	  <Divider />
		  
			<TabPanel value={value} index={0}>
			
					<Tab1Content
						setAppLoading={setAppLoading}
						setContentVisible={setContentVisible}
					/>
					
			</TabPanel>
				  
			<TabPanel value={value} index={1}>
										
					<Tab2Content
						setAppLoading={setAppLoading}
						setContentVisible={setContentVisible}
					/>
					
			</TabPanel>		
	   
	  </GridItem>
	  
    </GridContainer>
	</>
  );
}

return (null);

}

const mapStateToProps = store => ({
  buttonLoading:store.appReducer.buttonLoading
 });

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions, ...ServiceActions }, dispatch);


// Tab1Content Object
export const Tab1Content = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {

// set vars from props
const { 
	appLoading,
	setAppLoading,
	retrieveServiceOrdersIncomings
} = props;		

// Set classes
const classes = useStyles();	

// useRef
var dt = useRef(null);

// Scope data
const [serviceOrdersRows, setServiceOrdersRows] = useState([]);
const [serviceComissions, setServiceComissions] = useState({});


// Filter fields
const [filterByName, setFilterByName] = useState("");
const [filterByEmail, setFilterByEmail] = useState("");
const [filterByContactName, setFilterByContactName] = useState("");
const [filterByCompanyDocument, setFilterByCompanyDocument] = useState("");
const [filterByDocument, setFilterByDocument] = useState("");
const [filterByCreatedAt, setFilterByCreatedAt] = useState("");
const [filterByStatus, setFilterByStatus] = useState(null); 

// Dialog control
const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico"); 

// Service Dialog control
const [alertIncomingView, setAlertIncomingView] = React.useState(false); 
const [incomingId, setIncomingId] = React.useState(null); 
const [serviceOrderId, setServiceOrderId] = React.useState(null); 

// DataGrid Params
const [loading, setLoading] = useState(false);
const [parentLoading, setParentLoading] = useState(false);

const [totalRecords, setTotalRecords] = useState(0);
const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 50,
        page: 1,
		filters:null,
		sortField:"createdAt",
		sortOrder:1
    });

// Open update dialog with instance id defined
const openViewDialog = (id) => {

	//setIncomingId(id);
	setServiceOrderId(id);
	setAlertIncomingView(true);

} // end 

React.useEffect(() => {

	loadLazyData();

},[lazyParams]);
 
const loadLazyData = () => {
	
	setLoading(true);
	
	// Retrieve List of AdminUsers
	retrieveIncomingsAction();
}

const onPage = (event) => {
	let _lazyParams = { ...lazyParams, ...event };
	setLazyParams(_lazyParams);
}

const onSort = (event) => {	
	let _lazyParams = { ...lazyParams, ...event };
	setLazyParams(_lazyParams);
}

const onFilter = (event) => {
	let _lazyParams = { ...lazyParams, ...event };
	_lazyParams['first'] = 0;
	setLazyParams(_lazyParams);
}

// ToolBar Content  
const leftToolbarTemplate = () => {
	return (
		<React.Fragment>
			
		</React.Fragment>
	)
}

const rightToolbarTemplate = () => {
	return (
		<React.Fragment>
			
		</React.Fragment>
	)
}  
  
// Header Template  
const header = (
	<div className="table-header" style={{height:"40px"}}>
		<h5 style={{float:"left"}}> Recebimentos provisionados e efetivados </h5>	
	</div>
);	

 
// Column Service Template
const serviceBodyTemplate = (rowData) => {
	
	//console.log("[rowData]--->"+JSON.stringify(rowData,null,2));

        return (
            <React.Fragment>
                <span className="p-column-title">Serviço</span>
               {rowData.ServiceOrderService.name} <br />
			   <small styl={{color:"gray"}}>Valor total: R$ {rowData.ServiceTotalPrice.toFixed(2)}</small>
            </React.Fragment>
        );
    }
						
// Column Comission Template 
const comissionBodyTemplate = (rowData) => {
	
		var platformComissionArray = rowData.platformTotals.filter(item => item.type === "total");
		var platformComissionValue = platformComissionArray.length > 0 ? platformComissionArray[0].value : "-";
		
		var providerComissionArray = rowData.providerTotals.filter(item => item.type === "total");
		var providerComissionValue = providerComissionArray.length > 0 ? providerComissionArray[0].value : "-"
		
		var partnerComissionArray = rowData.partnerTotals.filter(item => item.type === "total");
		var partnerComissionValue = partnerComissionArray.length > 0 ? partnerComissionArray[0].value : "-"
	
        return (
            <React.Fragment>
                <span className="p-column-title">Comissões</span>
				<div style={{display:"flex",justifyContent:"flex-start",alignItems:"center",gap:"25px"}}>
					<div style={{fontSize:"12px",textAlign:"center"}}><span style={{fontSize:"14px"}}> Plataforma</span> <br />R$ {platformComissionValue.toFixed(2)}</div>
					<div style={{fontSize:"12px"}}><span style={{fontSize:"14px"}}> Prestador </span> <br />R$ {providerComissionValue.toFixed(2)}</div>
					<div style={{fontSize:"12px"}}><span style={{fontSize:"14px"}}> Parceiro </span> <br />R$ {partnerComissionValue.toFixed(2)}</div>
				</div>			
            </React.Fragment>
        );
    }

// Column CreatedAt Body Template  
const createdBodyTemplate = (rowData) => {

	return (
		<React.Fragment>
			<span className="p-column-title">Agendado para</span>
			{moment(rowData.scheduledDate).format("DD/MM/YYYY")}
		</React.Fragment>
	);
}	

// Column Activation Status Body Template  
const statusBodyTemplate = (rowData) => {
	
	/*0 - Scheduled   1 - Leaved to service  2 - arrived on service   3 - Started execution    4 - finished execution  5- Under Dispute, 6 - Dispute from customer   99 - Cancelled\\n*/
	
	if (rowData.status === 0 || rowData.status === 1 || rowData.status == 2 || rowData.status == 3) {
		var activationStatus = "REVIEW";
		var activationStatusLabel = "Previsto";
	} // end if
	else if (rowData.status === 4) {
		var activationStatus = "ATIVADO";
		var activationStatusLabel = "Confirmado";
	} // end if
	else if (rowData.status === 99) {
		var activationStatus = "ENCERRADO";
		var activationStatusLabel = "Cancelado";
	} // end if	
	else {
		var activationStatus = "BLOQUEADO";
		var activationStatusLabel = "Em disputa";
	} // end else
	 
	return (
	<>
	<span className={`product-badge status-${activationStatus.toLowerCase()}`}>{activationStatusLabel}</span>
	</>
	)
}	

// Template for Activation Status DropDown List
const statusItemTemplate = (option) => {	
	return <span className={`product-badge status-${option.id.toLowerCase()}`}>{option.label}</span>;
}	

// Column Actions Body Template  
// NEED TO ADD ROW ID AND ONCLICK EVENT
const actionBodyTemplate = (rowData) => {
	
	return (
		<React.Fragment>
			<Button label="Detalhes" icon="pi pi-search" className="p-button-outlined p-button-primary p-button-sm" style={{margin:"3px"}} onClick={() => { openViewDialog(rowData.id) }}  />
		</React.Fragment>
	);
}	

const filterDate = (value, filter) => {
	if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
		return true;
	}

	if (value === undefined || value === null) {
		return false;
	}

	return value === formatDate(filter);
}

const formatDate = (date) => {
	let month = date.getMonth() + 1;
	let day = date.getDate();

	if (month < 10) {
		month = '0' + month;
	}

	if (day < 10) {
		day = '0' + day;
	}

	return date.getFullYear() + '-' + month + '-' + day;
}		

// Apply Filter
const applyFilterAction = () => {
	
	// Check if any of the filters are not equal to NULL
	if (!filterByName && !filterByCreatedAt && !filterByStatus) {
		setAlertTitle("Verifique as informações");
		setAlertBody("Para aplicar o filtro você tem que ao menos selecionar ou preencher algum item para filtrar.");
		setAlert(true);
		return;
	} // end if
	
	var filters = {};
	filters.serviceName = filterByName ? filterByName:null;
	filters.createdAt = filterByCreatedAt ? filterByCreatedAt:null;
	filters.status = filterByStatus ? filterByStatus.code:null;
	
	// Apply filter on Data from service 
	let _lazyParams = { ...lazyParams, filters:filters };
	setLazyParams(_lazyParams);
	
}

// Reset Filter
const resetFilterAction = () => {
	
	// Set calendar range defautl. From first to last day of the current month
    let today = new Date();
	var firstDay = new Date(today.getFullYear(), 0, 1);
	var lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
	
	setFilterByName("");
	setFilterByCreatedAt([firstDay,lastDay]);	
	setFilterByStatus("");
	setFilterByStatus(null);
	
	var filters = null;
	
	// Apply filter on Data from service 
	let _lazyParams = { ...lazyParams, filters:filters };
	setLazyParams(_lazyParams);	
	
	// Load Data from service with defautl option
	// ...
}


// Filter fields and action buttons

	// Service Filter
	const serviceFilter = <InputText type="search" value={filterByName} onChange={(e) => setFilterByName(e.target.value)} className="p-column-filter" placeholder="Filtrar por Serviço" />;	
	
	// CreatedAt Filter
	const dateFilter = <Calendar locale="pt-BR" showButtonBar value={filterByCreatedAt} onChange={(e) => setFilterByCreatedAt(e.target.value)} selectionMode="range" dateFormat="dd/mm/yy" className="p-column-filter" placeholder="Filtrar por período"/>;	

	// Set Status Dropdown Content
	const statuses = [{"id":"BLOQUEADO","label":"Em disputa","code":1},{"id":"REVIEW","label":"Previsto","code":2},{"id":"ATIVADO","label":"Confirmado","code":3}];

	// Activation Status Filter
	const statusFilter = <Dropdown value={filterByStatus} options={statuses} onChange={(e) => setFilterByStatus(e.target.value)} itemTemplate={statusItemTemplate} placeholder="Selecione o Status" className="p-column-filter" showClear />;

	// Action Buttons 
	const actionFilter = <><Button label="Aplicar Filtro" icon="pi pi-check" className="p-button-outlined p-button-primary" onClick={() => applyFilterAction()} style={{marginRight:"5px"}} /> {lazyParams.filters && <Button title="Limpar Filtros" icon="pi  pi-filter-slash" className=" p-button-info p-button-outlined" onClick={() => resetFilterAction()} />}</>
		  
		  
// Dialog Footer
const renderFooter = () => {
	return (
		<div>
			<Button label="OK" icon="pi pi-check" onClick={() => setAlert(false)} />
		</div>
	);
}		
		
// Tasks to execute when mount component
React.useEffect(() => {

	// Set calendar range defautl. From first to last day of the current month
    let today = new Date();
	var firstDay = new Date(today.getFullYear(), 0, 1);
	var lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
	setFilterByCreatedAt([firstDay,lastDay]);

    return () => {
     setAppLoading(true);
    };			
}, []);

		
const retrieveIncomingsAction = () => {
	
	var data = {
		status:[0,1,2,3,4,5,6],
		...lazyParams
	}
	var propsParam = {
		setServiceOrdersRows:setServiceOrdersRows,
		setServiceComissions:setServiceComissions,
		setLoading:setLoading,
		setTotalRecords:setTotalRecords
	}	
	retrieveServiceOrdersIncomings(data,propsParam);	
	
} // end 


/* Function to format as currency */
const formatMoney = (number, decPlaces, decSep, thouSep) => {
    decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
    decSep = typeof decSep === "undefined" ? "." : decSep;
    thouSep = typeof thouSep === "undefined" ? "," : thouSep;
    var sign = number < 0 ? "-" : "";
    var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
    var j = (j = i.length) > 3 ? j % 3 : 0;

    return sign +
        (j ? i.substr(0, j) + thouSep : "") +
        i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
        (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
} // end function


// set Use of history for PUSH 
let history = useHistory();
	
	return (
	<>
		<Card>
			  <CardHeader color="info" icon>
				<CardIcon color="info">
				  <Assignment />
				</CardIcon>
				<h4 className={classes.cardIconTitle}>Recebimentos válidos</h4>
			  </CardHeader>
	
			  <CardBody>	  

				<div className="datatable-responsive-demo" style={{marginTop:"20px"}}>
				
					<div className="card">
							
						<DataTable ref={dt} value={serviceOrdersRows} className="p-datatable-responsive-demo" rows={lazyParams.rows} header={header} paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords}" rowsPerPageOptions={[10,25,50,100]} emptyMessage="Nenhum recebimento encontrado." lazy paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage} onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder} onFilter={console.log("filtering...")} filters={lazyParams.filters} loading={loading} >
							
							<Column field="title" header="Serviço" body={serviceBodyTemplate} filter filterElement={serviceFilter} />
								
							<Column field="title" header="Comissões" body={comissionBodyTemplate} />
													
							<Column field="createdAt" header="Agendado para" body={createdBodyTemplate} filter filterElement={dateFilter} filterFunction={filterDate} />

							<Column header="Status" body={statusBodyTemplate} filter filterElement={statusFilter} />
							
							<Column body={actionBodyTemplate} filter filterElement={actionFilter} ></Column>
							
						</DataTable>						
						
					</div>
				</div>
							
							
				<Card style={{ width: '20rem', marginBottom: '2em',marginRight: '2em', float:'left' }}>
					  <CardHeader color="info" icon>
						<h4 className={classes.cardIconTitle}>Recebimentos confirmados</h4>
						<small className={classes.cardIconTitle}>Conforme filtros acima</small>
					  </CardHeader>
					 <CardBody>
					 
					 <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"start"}}>
						<div style={{padding:"5px",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
							<span style={{minWidth:"90px"}}>Plataforma:</span>
							<span className="product-badge status-ativado">{serviceComissions.serviceConfirmedPlatformComission ? "R$ "+formatMoney(serviceComissions.serviceConfirmedPlatformComission,2,",",".") : "R$ 0,00"} </span>
						</div>
						<div style={{padding:"5px",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
							<span style={{minWidth:"90px"}}>Prestadores:</span>
							<span className="product-badge status-ativado">{serviceComissions.serviceConfirmedProviderComission ? "R$ "+formatMoney(serviceComissions.serviceConfirmedProviderComission,2,",",".") : "R$ 0,00"}</span>
						</div>
						<div style={{padding:"5px",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
							<span style={{minWidth:"90px"}}>Parceiros:</span>
							<span className="product-badge status-ativado">{serviceComissions.serviceConfirmedPartnerComission ? "R$ "+formatMoney(serviceComissions.serviceConfirmedPartnerComission,2,",",".") : "R$ 0,00"}</span>
						</div>
					 </div>
						
					</CardBody>
				</Card>		
									
				<Card style={{ width: '20rem', marginBottom: '2em',marginRight: '2em', float:'left' }}>
					  <CardHeader color="info" icon>
						<h4 className={classes.cardIconTitle}>Recebimentos previstos</h4>
						<small className={classes.cardIconTitle}>Conforme filtros acima</small>
					  </CardHeader>
					 <CardBody>
					 <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"start"}}>
						<div style={{padding:"5px",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
							<span style={{minWidth:"90px"}}>Plataforma:</span>
							<span className="product-badge status-review">{serviceComissions.serviceReviewPlatformComission ? "R$ "+formatMoney(serviceComissions.serviceReviewPlatformComission,2,",",".") : "R$ 0,00"} </span>
						</div>
						<div style={{padding:"5px",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
							<span style={{minWidth:"90px"}}>Prestadores:</span>
							<span className="product-badge status-review">{serviceComissions.serviceReviewProviderComission ? "R$ "+formatMoney(serviceComissions.serviceReviewProviderComission,2,",",".") : "R$ 0,00"}</span>
						</div>
						<div style={{padding:"5px",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
							<span style={{minWidth:"90px"}}>Parceiros:</span>
							<span className="product-badge status-review">{serviceComissions.serviceReviewPartnerComission ? "R$ "+formatMoney(serviceComissions.serviceReviewPartnerComission,2,",",".") : "R$ 0,00"}</span>
						</div>
					 </div>						
					</CardBody>
				</Card>	
	
				<Card style={{ width: '20rem', marginBottom: '2em',marginRight: '2em', float:'left' }}>
					  <CardHeader color="info" icon>
						<h4 className={classes.cardIconTitle}>Recebimentos em análise</h4>
						<small className={classes.cardIconTitle}>Conforme filtros acima</small>
					  </CardHeader>
					 <CardBody>
					 <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"start"}}>
						<div style={{padding:"5px",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
							<span style={{minWidth:"90px"}}>Plataforma:</span>
							<span className="product-badge status-bloqueado">{serviceComissions.serviceDisputePlatformComission ? "R$ "+formatMoney(serviceComissions.serviceDisputePlatformComission,2,",",".") : "R$ 0,00"} </span>
						</div>
						<div style={{padding:"5px",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
							<span style={{minWidth:"90px"}}>Prestadores:</span>
							<span className="product-badge status-bloqueado">{serviceComissions.serviceDisputeProviderComission ? "R$ "+formatMoney(serviceComissions.serviceDisputeProviderComission,2,",",".") : "R$ 0,00"}</span>
						</div>
						<div style={{padding:"5px",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
							<span style={{minWidth:"90px"}}>Parceiros:</span>
							<span className="product-badge status-bloqueado">{serviceComissions.serviceDisputePartnerComission ? "R$ "+formatMoney(serviceComissions.serviceDisputePartnerComission,2,",",".") : "R$ 0,00"}</span>
						</div>
					 </div>						
					</CardBody>
				</Card>

								
			  </CardBody>
			</Card>	
			
			<ViewIncoming
				alertIncomingView={alertIncomingView}
				setAlertIncomingView={setAlertIncomingView}
				loadLazyData={loadLazyData}
				setAlertTitle={setAlertTitle}
				setAlertBody={setAlertBody}
				setAlert={setAlert}
				setParentLoading={setParentLoading}
				incomingId={incomingId}
				serviceOrderId={serviceOrderId}
			/>
			
			
			<Dialog header={alertTitle} visible={alert} style={{ width: '30vw' }} footer={renderFooter()} onHide={() => setAlert(false)} draggable={false} dismissableMask={true} closable={false} >
				<p>{alertBody}</p>
			</Dialog>			
	</>	
	);
	
});


// Tab1Content Object
export const Tab2Content = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {

// set vars from props
const { 
	appLoading,
	setAppLoading,
	retrieveServiceOrdersIncomings
} = props;		

// Set classes
const classes = useStyles();	

// useRef
var dt = useRef(null);

// Scope data
const [serviceOrdersRows, setServiceOrdersRows] = useState([]);
const [serviceComissions, setServiceComissions] = useState({});


// Filter fields
const [filterByName, setFilterByName] = useState("");
const [filterByEmail, setFilterByEmail] = useState("");
const [filterByContactName, setFilterByContactName] = useState("");
const [filterByCompanyDocument, setFilterByCompanyDocument] = useState("");
const [filterByDocument, setFilterByDocument] = useState("");
const [filterByCreatedAt, setFilterByCreatedAt] = useState("");
const [filterByStatus, setFilterByStatus] = useState(null); 

// Dialog control
const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico"); 

// Service Dialog control
const [alertIncomingView, setAlertIncomingView] = React.useState(false); 
const [incomingId, setIncomingId] = React.useState(null); 
const [serviceOrderId, setServiceOrderId] = React.useState(null); 

// DataGrid Params
const [loading, setLoading] = useState(false);
const [parentLoading, setParentLoading] = useState(false);

const [totalRecords, setTotalRecords] = useState(0);
const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 50,
        page: 1,
		filters:null,
		sortField:"scheduledDate",
		sortOrder:1
    });

// Open update dialog with instance id defined
const openViewDialog = (id) => {

	//setIncomingId(id);
	setServiceOrderId(id);
	setAlertIncomingView(true);

} // end 

React.useEffect(() => {

	loadLazyData();

},[lazyParams]);
 
const loadLazyData = () => {
	
	setLoading(true);
	
	// Retrieve List of AdminUsers
	retrieveIncomingsAction();
}

const onPage = (event) => {
	let _lazyParams = { ...lazyParams, ...event };
	setLazyParams(_lazyParams);
}

const onSort = (event) => {	
	let _lazyParams = { ...lazyParams, ...event };
	setLazyParams(_lazyParams);
}

const onFilter = (event) => {
	let _lazyParams = { ...lazyParams, ...event };
	_lazyParams['first'] = 0;
	setLazyParams(_lazyParams);
}

// ToolBar Content  
const leftToolbarTemplate = () => {
	return (
		<React.Fragment>
			
		</React.Fragment>
	)
}

const rightToolbarTemplate = () => {
	return (
		<React.Fragment>
			
		</React.Fragment>
	)
}  
  
// Header Template  
const header = (
	<div className="table-header" style={{height:"40px"}}>
		<h5 style={{float:"left"}}> Recebimentos cancelados </h5>	
	</div>
);	

 
// Column Service Template
const serviceBodyTemplate = (rowData) => {
	
	//console.log("[rowData]--->"+JSON.stringify(rowData,null,2));

        return (
            <React.Fragment>
                <span className="p-column-title">Serviço</span>
               {rowData.ServiceOrderService.name} <br />
			   <small styl={{color:"gray"}}>Valor total: R$ {rowData.ServiceTotalPrice.toFixed(2)}</small>
            </React.Fragment>
        );
    }
						
// Column Comission Template 
const comissionBodyTemplate = (rowData) => {
	
		var platformComissionArray = rowData.platformTotals.filter(item => item.type === "total");
		var platformComissionValue = platformComissionArray.length > 0 ? platformComissionArray[0].value : "-";
		
		var providerComissionArray = rowData.providerTotals.filter(item => item.type === "total");
		var providerComissionValue = providerComissionArray.length > 0 ? providerComissionArray[0].value : "-"
		
		var partnerComissionArray = rowData.partnerTotals.filter(item => item.type === "total");
		var partnerComissionValue = partnerComissionArray.length > 0 ? partnerComissionArray[0].value : "-"
	
        return (
            <React.Fragment>
                <span className="p-column-title">Comissões</span>
				<div style={{display:"flex",justifyContent:"flex-start",alignItems:"center",gap:"25px"}}>
					<div style={{fontSize:"12px",textAlign:"center"}}><span style={{fontSize:"14px"}}> Plataforma</span> <br />R$ {platformComissionValue.toFixed(2)}</div>
					<div style={{fontSize:"12px"}}><span style={{fontSize:"14px"}}> Prestador </span> <br />R$ {providerComissionValue.toFixed(2)}</div>
					<div style={{fontSize:"12px"}}><span style={{fontSize:"14px"}}> Parceiro </span> <br />R$ {partnerComissionValue.toFixed(2)}</div>
				</div>			
            </React.Fragment>
        );
    }

// Column CreatedAt Body Template  
const createdBodyTemplate = (rowData) => {

	return (
		<React.Fragment>
			<span className="p-column-title">Agendado para</span>
			{moment(rowData.scheduledDate).format("DD/MM/YYYY")}
		</React.Fragment>
	);
}	

// Column Activation Status Body Template  
const statusBodyTemplate = (rowData) => {
	
	/*0 - Scheduled   1 - Leaved to service  2 - arrived on service   3 - Started execution    4 - finished execution  5- Under Dispute, 6 - Dispute from customer   99 - Cancelled\\n*/

	if (rowData.status === 99) {
		var activationStatus = "ENCERRADO";
		var activationStatusLabel = "Cancelado";
	} // end if	
	else {
		var activationStatus = "BLOQUEADO";
		var activationStatusLabel = "Não identificado";
	} // end else
	 
	return (
	<>
	<span className={`product-badge status-${activationStatus.toLowerCase()}`}>{activationStatusLabel}</span>
	</>
	)
}	

// Template for Activation Status DropDown List
const statusItemTemplate = (option) => {	
	return <span className={`product-badge status-${option.id.toLowerCase()}`}>{option.label}</span>;
}	

// Column Actions Body Template  
// NEED TO ADD ROW ID AND ONCLICK EVENT
const actionBodyTemplate = (rowData) => {
	
	return (
		<React.Fragment>
			<Button label="Detalhes" icon="pi pi-search" className="p-button-outlined p-button-primary p-button-sm" style={{margin:"3px"}} onClick={() => { openViewDialog(rowData.id) }}  />
		</React.Fragment>
	);
}	

const filterDate = (value, filter) => {
	if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
		return true;
	}

	if (value === undefined || value === null) {
		return false;
	}

	return value === formatDate(filter);
}

const formatDate = (date) => {
	let month = date.getMonth() + 1;
	let day = date.getDate();

	if (month < 10) {
		month = '0' + month;
	}

	if (day < 10) {
		day = '0' + day;
	}

	return date.getFullYear() + '-' + month + '-' + day;
}		

// Apply Filter
const applyFilterAction = () => {
	
	// Check if any of the filters are not equal to NULL
	if (!filterByName && !filterByCreatedAt && !filterByStatus) {
		setAlertTitle("Verifique as informações");
		setAlertBody("Para aplicar o filtro você tem que ao menos selecionar ou preencher algum item para filtrar.");
		setAlert(true);
		return;
	} // end if
	
	var filters = {};
	filters.serviceName = filterByName ? filterByName:null;
	filters.createdAt = filterByCreatedAt ? filterByCreatedAt:null;
	filters.status = filterByStatus ? filterByStatus.code:null;
	
	// Apply filter on Data from service 
	let _lazyParams = { ...lazyParams, filters:filters };
	setLazyParams(_lazyParams);
	
}

// Reset Filter
const resetFilterAction = () => {
	
	// Set calendar range defautl. From first to last day of the current month
    let today = new Date();
	var firstDay = new Date(today.getFullYear(), 0, 1);
	var lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
	
	setFilterByName("");
	setFilterByCreatedAt([firstDay,lastDay]);	
	setFilterByStatus("");
	setFilterByStatus(null);
	
	var filters = null;
	
	// Apply filter on Data from service 
	let _lazyParams = { ...lazyParams, filters:filters };
	setLazyParams(_lazyParams);	
	
	// Load Data from service with defautl option
	// ...
}


// Filter fields and action buttons

	// Service Filter
	const serviceFilter = <InputText type="search" value={filterByName} onChange={(e) => setFilterByName(e.target.value)} className="p-column-filter" placeholder="Filtrar por Serviço" />;	
	
	// CreatedAt Filter
	const dateFilter = <Calendar locale="pt-BR" showButtonBar value={filterByCreatedAt} onChange={(e) => setFilterByCreatedAt(e.target.value)} selectionMode="range" dateFormat="dd/mm/yy" className="p-column-filter" placeholder="Filtrar por período"/>;	

	// Action Buttons 
	const actionFilter = <><Button label="Aplicar Filtro" icon="pi pi-check" className="p-button-outlined p-button-primary" onClick={() => applyFilterAction()} style={{marginRight:"5px"}} /> {lazyParams.filters && <Button title="Limpar Filtros" icon="pi  pi-filter-slash" className=" p-button-info p-button-outlined" onClick={() => resetFilterAction()} />}</>
		  
		  
// Dialog Footer
const renderFooter = () => {
	return (
		<div>
			<Button label="OK" icon="pi pi-check" onClick={() => setAlert(false)} />
		</div>
	);
}		
		
// Tasks to execute when mount component
React.useEffect(() => {

	// Set calendar range defautl. From first to last day of the current month
    let today = new Date();
	var firstDay = new Date(today.getFullYear(), 0, 1);
	var lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
	setFilterByCreatedAt([firstDay,lastDay]);

    return () => {
     setAppLoading(true);
    };			
}, []);

		
const retrieveIncomingsAction = () => {
	
	var data = {
		status:[99],
		...lazyParams
	}
	var propsParam = {
		setServiceOrdersRows:setServiceOrdersRows,
		setServiceComissions:setServiceComissions,
		setLoading:setLoading,
		setTotalRecords:setTotalRecords
	}	
	retrieveServiceOrdersIncomings(data,propsParam);	
	
} // end 


/* Function to format as currency */
const formatMoney = (number, decPlaces, decSep, thouSep) => {
    decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
    decSep = typeof decSep === "undefined" ? "." : decSep;
    thouSep = typeof thouSep === "undefined" ? "," : thouSep;
    var sign = number < 0 ? "-" : "";
    var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
    var j = (j = i.length) > 3 ? j % 3 : 0;

    return sign +
        (j ? i.substr(0, j) + thouSep : "") +
        i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
        (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
} // end function


// set Use of history for PUSH 
let history = useHistory();
	
	return (
	<>
		<Card>
			  <CardHeader color="info" icon>
				<CardIcon color="info">
				  <Assignment />
				</CardIcon>
				<h4 className={classes.cardIconTitle}>Recebimentos cancelados</h4>
			  </CardHeader>
	
			  <CardBody>	  

				<div className="datatable-responsive-demo" style={{marginTop:"20px"}}>
				
					<div className="card">
							
						<DataTable ref={dt} value={serviceOrdersRows} className="p-datatable-responsive-demo" rows={lazyParams.rows} header={header} paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords}" rowsPerPageOptions={[10,25,50,100]} emptyMessage="Nenhum recebimento encontrado." lazy paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage} onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder} onFilter={console.log("filtering...")} filters={lazyParams.filters} loading={loading} >
							
							<Column field="title" header="Serviço" body={serviceBodyTemplate} filter filterElement={serviceFilter} />
								
							<Column field="title" header="Comissões" body={comissionBodyTemplate} />
													
							<Column field="createdAt" header="Agendado para" body={createdBodyTemplate} filter filterElement={dateFilter} filterFunction={filterDate} />

							<Column header="Status" body={statusBodyTemplate} />
							
							<Column body={actionBodyTemplate} filter filterElement={actionFilter} ></Column>
							
						</DataTable>						
						
					</div>
				</div>
				
				<Card style={{ width: '20rem', marginBottom: '2em',marginRight: '2em', float:'left' }}>
					  <CardHeader color="info" icon>
						<h4 className={classes.cardIconTitle}>Recebimentos cancelados</h4>
						<small className={classes.cardIconTitle}>Conforme filtros acima</small>
					  </CardHeader>
					 <CardBody>
					 <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"start"}}>
						<div style={{padding:"5px",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
							<span style={{minWidth:"90px"}}>Plataforma:</span>
							<span className="product-badge status-encerrado">{serviceComissions.serviceCancelledPlatformComission ? "R$ "+formatMoney(serviceComissions.serviceCancelledPlatformComission,2,",",".") : "R$ 0,00"} </span>
						</div>
						<div style={{padding:"5px",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
							<span style={{minWidth:"90px"}}>Prestadores:</span>
							<span className="product-badge status-encerrado">{serviceComissions.serviceCancelledProviderComission ? "R$ "+formatMoney(serviceComissions.serviceCancelledProviderComission,2,",",".") : "R$ 0,00"}</span>
						</div>
						<div style={{padding:"5px",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
							<span style={{minWidth:"90px"}}>Parceiros:</span>
							<span className="product-badge status-encerrado">{serviceComissions.serviceCancelledPartnerComission ? "R$ "+formatMoney(serviceComissions.serviceCancelledPartnerComission,2,",",".") : "R$ 0,00"}</span>
						</div>
					 </div>						
					</CardBody>
				</Card>

								
			  </CardBody>
			</Card>	
			
			<ViewIncoming
				alertIncomingView={alertIncomingView}
				setAlertIncomingView={setAlertIncomingView}
				loadLazyData={loadLazyData}
				setAlertTitle={setAlertTitle}
				setAlertBody={setAlertBody}
				setAlert={setAlert}
				setParentLoading={setParentLoading}
				incomingId={incomingId}
				serviceOrderId={serviceOrderId}
			/>
			
			
			<Dialog header={alertTitle} visible={alert} style={{ width: '30vw' }} footer={renderFooter()} onHide={() => setAlert(false)} draggable={false} dismissableMask={true} closable={false} >
				<p>{alertBody}</p>
			</Dialog>			
	</>	
	);
	
});



// Tab2Content Object
export const Tab2ContentBackup = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {

// set vars from props
const { 
	appLoading,
	setAppLoading,
	retrieveAdminUsers
} = props;		

// Set classes
const classes = useStyles();	

// useRef
var dt = useRef(null);

// Scope data
const [providersRows, setAdminUsersRows] = useState([
{
	serviceName:"Limpeza de computador",
	comissions:[
		{platform:"R$ 15,00"},
		{provider:"R$ 25,00"},
		{partner:"R$ 5,00"}],
	createdAt:"2022-11-25",
	status:"0"
}
]);

// Filter fields
const [filterByName, setFilterByName] = useState("");
const [filterByEmail, setFilterByEmail] = useState("");
const [filterByContactName, setFilterByContactName] = useState("");
const [filterByCompanyDocument, setFilterByCompanyDocument] = useState("");
const [filterByDocument, setFilterByDocument] = useState("");
const [filterByCreatedAt, setFilterByCreatedAt] = useState("");
const [filterByStatus, setFilterByStatus] = useState(null); 

// Dialog control
const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico"); 

// DataGrid Params
const [loading, setLoading] = useState(false);
const [totalRecords, setTotalRecords] = useState(0);
const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
		filters:null,
		sortField:"createdAt",
		sortOrder:1
    });

React.useEffect(() => {

	loadLazyData();

},[lazyParams]);
 
const loadLazyData = () => {
	
	setLoading(true);
	
	// Retrieve List of AdminUsers
	retrieveAdminUsersAction();
}

const onPage = (event) => {
	let _lazyParams = { ...lazyParams, ...event };
	setLazyParams(_lazyParams);
}

const onSort = (event) => {	
	let _lazyParams = { ...lazyParams, ...event };
	setLazyParams(_lazyParams);
}

const onFilter = (event) => {
	let _lazyParams = { ...lazyParams, ...event };
	_lazyParams['first'] = 0;
	setLazyParams(_lazyParams);
}
  
// ToolBar Content  
const leftToolbarTemplate = () => {
	return (
		<React.Fragment>
			
		</React.Fragment>
	)
}

const rightToolbarTemplate = () => {
	return (
		<React.Fragment>
			
		</React.Fragment>
	)
}    
  
// Header Template  
const header = (
	<div className="table-header" style={{height:"40px"}}>
		<h5 style={{float:"left"}}> Recebimentos cancelados </h5>	
	</div>
);	
  
// Column Name Body Template  
const nameBodyTemplate = (rowData) => {
	return (
		<React.Fragment>
			<span className="p-column-title">Nome Empresa</span>
			{rowData.ProviderProfile.ProviderProfileCompany.realName}
		</React.Fragment>
	);
}  

// Column Email Body Template    
const emailBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Email</span>
                {rowData.email}
            </React.Fragment>
        );
    }
						
// Column Email Body Template    
const contactNameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Nome Contato</span>
                {rowData.ProviderProfile.ProviderProfilePerson.completeName}
            </React.Fragment>
        );
    }
					
// Column CNPJ Body Template  
const companyDocumentBodyTemplate = (rowData) => {
	
	// Clean and format
	var cleaned = ('' + rowData.ProviderProfile.ProviderProfileCompany.document).replace(/\D/g, '')
	  var match = cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/)
	  if (match) {
		var formattedDocument = '' + match[1] + '.' + match[2] + '.' + match[3] + '/' + match[4] + '-' + match[5];
	  } // end if
	  else {
		var formattedDocument = "CNPJ Inválido";
	  } // end else	  
	
	return (
		<React.Fragment>
			<span className="p-column-title">CNPJ</span>
			{formattedDocument}
		</React.Fragment>
	);
}

// Column CPF Body Template  
const documentBodyTemplate = (rowData) => {
	
	// Clean and format
	var cleaned = ('' + rowData.ProviderProfile.ProviderProfilePerson.personalDocument).replace(/\D/g, '')
	  var match = cleaned.match(/^(\d{3})(\d{3})(\d{3})(\d{2})$/)
	  if (match) {
		var formattedDocument = '' + match[1] + '.' + match[2] + '.' + match[3]+ '-' + match[4];
	  } // end if
	  else {
		var formattedDocument = "CPF Inválido";
	  } // end else	  
	
	return (
		<React.Fragment>
			<span className="p-column-title">CPF</span>
			{formattedDocument}
		</React.Fragment>
	);
}

// Column CreatedAt Body Template  
const createdBodyTemplate = (rowData) => {
	return (
		<React.Fragment>
			<span className="p-column-title">Criado em</span>
			{moment(rowData.createdAt).format("DD/MM/YYYY")}
		</React.Fragment>
	);
}	

// Column Activation Status Body Template  
const statusBodyTemplate = (rowData) => {

	var activationStatus = "BLOQUEADO";
	var activationStatusLabel = "Reprovado";

	return (
	<>
	<span className={`product-badge status-${activationStatus.toLowerCase()}`}>{activationStatusLabel}</span><br />
		{rowData.status == 1 && <small style={{color:"#666"}}> por {rowData.activationMethod}</small>}
	</>
	)
}	

// Template for Activation Status DropDown List
const statusItemTemplate = (option) => {	
	return <span className={`product-badge status-${option.id.toLowerCase()}`}>{option.label}</span>;
}	

// Column Actions Body Template  
// NEED TO ADD ROW ID AND ONCLICK EVENT
const actionBodyTemplate = (rowData) => {
	return (
		<React.Fragment>
			<Button label="Detalhes" icon="pi pi-search" className="p-button-outlined p-button-primary p-button-sm" style={{margin:"3px"}} onClick={() => { history.push("/admin/provider/"+rowData.id+"") }}/>
			<Button label="Editar" icon="pi pi-pencil" className="p-button-outlined p-button-secondary p-button-sm" style={{margin:"3px"}} onClick={() => { history.push("/admin/editprovider/"+rowData.id+"") }} />
		</React.Fragment>
	);
}	

const filterDate = (value, filter) => {
	if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
		return true;
	}

	if (value === undefined || value === null) {
		return false;
	}

	return value === formatDate(filter);
}

const formatDate = (date) => {
	let month = date.getMonth() + 1;
	let day = date.getDate();

	if (month < 10) {
		month = '0' + month;
	}

	if (day < 10) {
		day = '0' + day;
	}

	return date.getFullYear() + '-' + month + '-' + day;
}		

// Apply Filter
const applyFilterAction = () => {
	
	// Check if any of the filters are not equal to NULL
	if (!filterByName && !filterByEmail && !filterByContactName && !filterByCompanyDocument && !filterByDocument && !filterByCreatedAt && !filterByStatus) {
		setAlertTitle("Verifique as informações");
		setAlertBody("Para aplicar o filtro você tem que ao menos selecionar ou preencher algum item para filtrar.");
		setAlert(true);
		return;
	} // end if
	
	var filters = {};
	filters.realName = filterByName ? filterByName:null;
	filters.email = filterByEmail ? filterByEmail:null;
	filters.completeName = filterByContactName ? filterByContactName:null;
	filters.document = filterByCompanyDocument ? filterByCompanyDocument:null;
	filters.personalDocument = filterByDocument ? filterByDocument:null;
	filters.createdAt = filterByCreatedAt ? filterByCreatedAt:null;
	filters.status = filterByStatus ? filterByStatus.code:null;
	
	// Apply filter on Data from service 
	let _lazyParams = { ...lazyParams, filters:filters };
	setLazyParams(_lazyParams);
	
}

// Reset Filter
const resetFilterAction = () => {
	
	setFilterByName("");
	setFilterByEmail("");
	setFilterByContactName("");
	setFilterByCompanyDocument("");
	setFilterByDocument("");
	setFilterByCreatedAt("");
	setFilterByStatus("");
	setFilterByStatus(null);
	
	var filters = null;
	
	// Apply filter on Data from service 
	let _lazyParams = { ...lazyParams, filters:filters };
	setLazyParams(_lazyParams);	
	
	// Load Data from service with defautl option
	// ...
}

// Filter fields and action buttons

	// Name Filter
	const nameFilter = <InputText type="search" value={filterByName} onChange={(e) => setFilterByName(e.target.value)} className="p-column-filter" placeholder="Filtrar por nome" />;	
	
	// Email Filter
	const emailFilter = <InputText type="search" value={filterByEmail} onChange={(e) => setFilterByEmail(e.target.value)} className="p-column-filter" placeholder="Filtrar por email" />;	
	
	// Phone Filter
	const contactNameFilter = <InputText type="search" value={filterByContactName} onChange={(e) => setFilterByContactName(e.target.value)} className="p-column-filter" placeholder="Filtrar por nome" />;		
	
	// Company Document
	const companyDocumentFilter = <InputText type="search" value={filterByCompanyDocument} onChange={(e) => setFilterByCompanyDocument(e.target.value)} className="p-column-filter" placeholder="Filtrar por CNPJ" />;

	// Personal Document
	const documentFilter = <InputText type="search" value={filterByDocument} onChange={(e) => setFilterByDocument(e.target.value)} className="p-column-filter" placeholder="Filtrar por CPF" />;	
	
	// CreatedAt Filter
	const dateFilter = <Calendar locale="pt-BR" showButtonBar value={filterByCreatedAt} onChange={(e) => setFilterByCreatedAt(e.target.value)} dateFormat="dd/mm/yy" className="p-column-filter" placeholder="Filtrar por data"/>;	

	// Set Status Dropdown Content
	const statuses = [{"id":"BLOQUEADO","label":"Bloqueado","code":5},{"id":"ENCERRADO","label":"Encerrado","code":9}];

	// Activation Status Filter
	const statusFilter = <Dropdown value={filterByStatus} options={statuses} onChange={(e) => setFilterByStatus(e.target.value)} itemTemplate={statusItemTemplate} placeholder="Selecione o Status" className="p-column-filter" showClear />;

	// Action Buttons 
	const actionFilter = <><Button label="Aplicar Filtro" icon="pi pi-check" className="p-button-outlined p-button-primary" onClick={() => applyFilterAction()} style={{marginRight:"5px"}} /> {lazyParams.filters && <Button title="Limpar Filtros" icon="pi  pi-filter-slash" className=" p-button-info p-button-outlined" onClick={() => resetFilterAction()} />}</>
		

// Dialog Footer
const renderFooter = () => {
	return (
		<div>
			<Button label="OK" icon="pi pi-check" onClick={() => setAlert(false)} />
		</div>
	);
}		
		
// Tasks to execute when mount component
React.useEffect(() => {

	// on component mount
	//console.log("[MOUNTED COMPONENT...]--->");
	
    let today = new Date();
    let month = today.getMonth();
    let year = today.getFullYear();	
	var firstDay = new Date(today.getFullYear(), 0, 1);
	var lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
	var dateRange = firstDay+"/"+month+"/"+year+" - "+lastDay+"/"+month+"/"+year;

	console.log("[dateRange]--->"+JSON.stringify(dateRange,null,2));
	
	setFilterByCreatedAt(dateRange);

    return () => {
     setAppLoading(true);
    };			
}, []);

		
const retrieveAdminUsersAction = () => {

	var data = {
		status:[5,9],
		...lazyParams
	}
	var propsParam = {
		setAdminUsersRows:setAdminUsersRows,
		setLoading:setLoading,
		setTotalRecords:setTotalRecords
	}	
	//retrieveAdminUsers(data,propsParam);

} // end 
	
// set Use of history for PUSH 
let history = useHistory();	
	
	return (
	<>
		<Card>
			  <CardHeader color="info" icon>
				<CardIcon color="info">
				  <Assignment />
				</CardIcon>
				<h4 className={classes.cardIconTitle}>Recebimentos cancelados</h4>
			  </CardHeader>
	
			  <CardBody>	  

				<div className="datatable-responsive-demo" style={{marginTop:"20px"}}>
				
					<div className="card">
					
					{/*<Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>*/}
					
						<DataTable ref={dt} value={providersRows} className="p-datatable-responsive-demo" rows={lazyParams.rows} header={header} paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords}" rowsPerPageOptions={[10,20,50]} emptyMessage="Nenhum prestador bloqueado ou encerrado." lazy paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage} onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder} onFilter={console.log("filtering...")} filters={lazyParams.filters} loading={loading} >
							
							<Column field="ProviderProfile.ProviderProfileCompany.realName" header="Empresa" body={nameBodyTemplate} sortable filter filterPlaceholder="Filtrar por nome" sortable filterElement={nameFilter} />
							
							<Column field="ProviderProfile.ProviderProfileCompany.document" header="CNPJ" body={companyDocumentBodyTemplate} filter filterPlaceholder="Filtrar por CNJP" filterElement={companyDocumentFilter} />
							
							<Column field="ProviderProfile.ProviderProfilePerson.completeName" header="Nome Contato" body={contactNameBodyTemplate} sortable filter filterPlaceholder="Filtrar por nome" sortable filterElement={contactNameFilter} />
							
							<Column field="ProviderProfile.ProviderProfilePerson.personalDocument" header="CPF" body={documentBodyTemplate} filter filterPlaceholder="Filtrar por CPF" filterElement={documentFilter} />
							
							{/*<Column field="email" header="Email" body={emailBodyTemplate} filter filterPlaceholder="Filtrar por email" filterElement={emailFilter} />*/}
							
							<Column field="createdAt" header="Criado em" body={createdBodyTemplate} sortable filter filterElement={dateFilter} filterFunction={filterDate} />

							<Column header="Status" body={statusBodyTemplate} filter filterElement={statusFilter} />
							
							<Column body={actionBodyTemplate} filter filterElement={actionFilter} ></Column>
							
						</DataTable>
						
					</div>
				</div>
								
			  </CardBody>
			</Card>	
			
			<Dialog header={alertTitle} visible={alert} style={{ width: '30vw' }} footer={renderFooter()} onHide={() => setAlert(false)} draggable={false} dismissableMask={true} closable={false} >
				<p>{alertBody}</p>
			</Dialog>			
	</>	
	);
	
});



// UpdateService dialog component
export const ViewIncoming = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
		
// Set classes
const classes = useStyles();	

const screenStyleLoading = {
  alignItems: "middle",
  justifyContent: "center",
  width:"100%",
  display: "flex",
  flexDirection: "column"
};	

// set vars from props
const { 
	alertIncomingView,
	setAlertIncomingView,
	loadLazyData,
	buttonLoading,
	setAlertTitle,
	setAlertBody,
	setAlert,
	retrieveServiceOrderIncoming,
	incomingId,
	serviceOrderId
} = props;		

// Set service row
const [serviceRow, setIncomingRow] = useState({});
const [serviceOrder, setServiceOrderCol] = React.useState({});

const [statusHistory, setStatusHistory] = React.useState([]);
const [paymentHistory, setPaymentHistory] = React.useState([]);
const [penaltyTaxes, setPenaltyTaxes] = React.useState([]);

const [loadingService, setLoadingIncoming] = useState(true); 
const [saving, setSaving] = useState(false); 

const [serviceLink, setServiceLink] = useState(""); 
	
// Execute when dialog opens
React.useEffect(() => {

	// Only r]uns when  dialog opens
	if (alertIncomingView === true) {

		var data = {
			incomingId:incomingId,
			serviceOrderId:serviceOrderId
		}
		var propsParam = {
			setIncomingRow:setIncomingRow,
			setLoadingIncoming:setLoadingIncoming,
			setServiceOrderCol:setServiceOrderCol,
			setStatusHistory:setStatusHistory,
		}	
		retrieveServiceOrderIncoming(data,propsParam);
		
		let link = "/admin/service/"+serviceOrderId;
		setServiceLink(link);
		
	} // end if
	
	// Reset all fields when closes or open dialog
	// ...
	
	// Reset images when open interface
	setIncomingRow({});
	setLoadingIncoming(true);
		
},[alertIncomingView]);

// Set values for each field
React.useEffect(() => {

	// If service returned set values
	if (serviceRow.id) {
				
		
		
		
	} // end if

},[serviceRow]);

    const customizedMarker = (item) => {
        return (
            <span className="custom-marker p-shadow-2" style={{ backgroundColor:serviceOrder.status === item.status ? (item.status == 5 ? "#ffcdd2" : (item.status == 4 ? "#c8e6c9" : "#333") ) : "#333",color:item.status == 5 ? "#c63737" : item.status == 4 ? "#256029" : "#FFF" }}>
                <i className="pi pi-check"></i>
            </span>
        );
    };	
	
var formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});
    
	
	return (
	<>
	<Dialog header="Detlhes do Recebimento" visible={alertIncomingView} style={{ width: '70vw' }} footer={(<div>
			<Button label="FECHAR" disabled={saving} style={{marginTop:"20px"}} icon="pi pi-check" className="p-button-outlined p-button-secondary p-button-sm" onClick={() => {setAlertIncomingView(false)} } />
		</div>)} onHide={() => setAlertIncomingView(false)} draggable={false} dismissableMask={false} closable={saving ? false : true} closeOnEscape={false} >
				
				{loadingService && 
					<Box style={{...screenStyleLoading,height:"65vh"}}>
							<Box mt={0} mx={0} style={{textAlign:"center"}}>								
								<CircularProgress /> 
								<div> Carregando dados...Aguarde!</div>								
							</Box>	
					</Box>
				}
			
				{!loadingService &&
				<>	
				<GridContainer justify="center">
				  <GridItem xs={12} sm={12}>

					<Card>
					  <CardHeader color="info" icon>
						<CardIcon color="info">
						  <Assignment />
						</CardIcon>
						<h4 className={classes.cardIconTitle}>Detalhes do Recebimento</h4>
					  </CardHeader>
					  <CardBody>	 
						
						{/* Details Block */}
						<TableContainer component={Box} mt={2}>
						  <Table style={{minWwidth:"500px"}} aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Dados do serviço</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>
												

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Categoria</TableCell>
								  <TableCell align="left">{serviceOrder.ServiceOrderService.Service.ServiceCategories[0].breadcrumb}</TableCell>
								</TableRow>

								<TableRow >
								  <TableCell component="th" scope="row">Nome do serviço</TableCell>
								  <TableCell align="left">{serviceOrder.ServiceOrderService.Service.name}</TableCell>
								</TableRow>
								
								<TableRow >
								  <TableCell component="th" scope="row">Descrição</TableCell>
								  <TableCell align="left">{serviceOrder.ServiceOrderService.Service.description}</TableCell>
								</TableRow>								

								<TableRow >
								  <TableCell component="th" scope="row">Garantia do serviço</TableCell>
								  <TableCell align="left">{serviceOrder.ServiceOrderService.Service.warrantyTerm}</TableCell>
								</TableRow>
								
								<TableRow >
								  <TableCell component="th" scope="row">Tempo médio do serviço</TableCell>
								  <TableCell align="left">{serviceOrder.ServiceOrderService.Service.effort} {serviceOrder.ServiceOrderService.Service.effortUnity}</TableCell>
								</TableRow>	
								
								<TableRow >
								  <TableCell component="th" scope="row">Link para serviço</TableCell>
								  <TableCell align="left"><a href={serviceLink}> Ir para serviço </a></TableCell>
								</TableRow>								

							</TableBody>
						  </Table>
						</TableContainer>						
						
						{/* Status History */}												
						<TableContainer component={Box} mt={2}>
						  <Table style={{minWwidth:"500px"}} aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Histórico de Status</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">
									  Status atual <br />
									  <small style={{color:"silver"}}> Status que a ordem de serviço se encontra</small>
								  </TableCell>
								  <TableCell align="left">

								  {serviceOrder.status == 0 &&
								  <>
									<span class="product-badge status-agendado">Agendado</span> <small><i> Ordem de serviço está agendada aguardando a execução o serviço.</i></small>
								  </>
								  }		

								  {serviceOrder.status == 1 &&
								  <>
									<span class="product-badge status-review">Em trânsito</span> <small><i>Prestador está em trânsito para o local do serviço.</i></small>
								  </>
								  }		
								  
								  {serviceOrder.status == 3 &&
								  <>
									<span class="product-badge status-mesmodia">Em execução</span> <small><i>O prestador etá no local e está executando o serviço.</i></small>
		
								  </>
								  }
							  
								  {serviceOrder.status == 4 &&
								  <>
									<span class="product-badge status-ativado">Finalizado</span> <small><i> O prestador finalizou o serviço com sucesso.</i></small>
								  </>
								  }
								  
								  {serviceOrder.status == 5 &&
								  <>
									<span class="product-badge status-bloqueado">Em análise</span> <small><i> O prestador finalizou o serviço mas teve problemas.</i></small>
								  </>
								  }				

								  {serviceOrder.status == 6 &&
								  <>
									<span class="product-badge status-encerrado">Em disputa</span> <small><i> A ordem de serviço está em disputa devido a uma problema.</i></small>
								  </>
								  }	

								  {serviceOrder.status == 99 &&
								  <>
									<span class="product-badge status-bloqueado">Cancelada</span> <small><i> A ordem de serviço encontra-se cancelada. </i></small>
								  </>
								  }										  
								  
								  </TableCell>
								</TableRow>
								
								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">
									  Histórico do Status
								  </TableCell>
								  <TableCell align="left">
								  
								   {statusHistory.length > 0 &&
									<Timeline value={statusHistory} marker={customizedMarker} layout="horizontal" align="top" content={(item) => { return (<><span>{item.statusLabel}</span><br /><small className="p-text-secondary">{moment(item.createdAt).format("DD/MM/YYYY HH:mm")}</small></>)} } />
								   }
								   
								   {statusHistory.length == 0 &&
									<span>Não há histórico de status para esse pedido.</span>
								   }
									
								  </TableCell>
								</TableRow>
								
							
							</TableBody>
						  </Table>
						</TableContainer>
						
						{/* Detalhes da cobrança */}
						<TableContainer component={Box} mt={2}>
						  <Table style={{minWwidth:"500px"}} aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Detalhes da cobrança 
								{serviceOrder.status == 99 &&
									<span className="product-badge status-bloqueado" style={{marginLeft:"15px"}}>Cobrança cancelada</span>
								}
								</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Valor do Serviço</TableCell>
								  <TableCell align="left">{formatter.format(serviceOrder.ServiceBasisPrice)}</TableCell>
								</TableRow>
								
								{serviceOrder.type === 3 &&
									<TableRow >
									  <TableCell style={{width:"300px"}} component="th" scope="row">Taxa de Urgência</TableCell>
									  <TableCell align="left"><span className="product-badge status-bloqueado">{formatter.format(serviceOrder.ServiceUrgentFee)}</span></TableCell>
									</TableRow>	
								}								
			
								{serviceOrder.ServiceDiscount > 0 &&
									<TableRow >
									  <TableCell component="th" scope="row">Desconto (crédito, promoção etc)</TableCell>
									  <TableCell align="left">{formatter.format(serviceOrder.ServiceDiscount)}</TableCell>
									</TableRow>
								}

								<TableRow >
								  <TableCell component="th" scope="row" style={{fontWeight:"bold"}}>Valor Total</TableCell>
								  <TableCell align="left" style={{fontWeight:"bold"}}>{formatter.format(serviceOrder.ServiceTotalPrice)}</TableCell>
								</TableRow>		
		
							</TableBody>
						  </Table>
						</TableContainer>		
						
						{/* Detalhes da cobrança de taxa de penalidade */}
						{penaltyTaxes.length > 0 &&
						<>						
						
						<TableContainer component={Box} mt={2}>
						  <Table style={{minWwidth:"500px"}} aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Cobrança de taxa de penalidade por cancelamento ou alteração</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

										{penaltyTaxes.map( (item,index) => {

											var fieldLabel = item.history;
											
											if (item.valueType == "B" || item.valueType == "C" || item.valueType == "D") {
												var fieldValue = formatter.format(item.value);
											} // end if
											else {
												var fieldValue = item.value+"%";
											} // end else
												
										    var taxOrigin = item.InstanceType == "customer" ? "Cliente" : "Prestador";

											return (
											<>
											<TableRow >
											  <TableCell component="th" scope="row" colspan="2"><strong>Cobrança de taxa #{index+1}</strong></TableCell>
											</TableRow>		

											<TableRow >
											  <TableCell style={{width:"300px"}} component="th" scope="row">Origem</TableCell>
											  <TableCell align="left">{taxOrigin}</TableCell>
											</TableRow>	

											<TableRow >
											  <TableCell style={{width:"300px"}} component="th" scope="row">Descrição</TableCell>
											  <TableCell align="left">{item.memo}</TableCell>
											</TableRow>	

											<TableRow >
											  <TableCell style={{width:"300px"}} component="th" scope="row">Taxa cobrada</TableCell>
											  <TableCell align="left">{formatter.format(item.value)}</TableCell>
											</TableRow>							

											<TableRow >
											  <TableCell style={{width:"300px"}} component="th" scope="row">Data da cobrança</TableCell>
											  <TableCell align="left">{moment(item.createdAt).format("DD/MM/YYYY hh:mm")}</TableCell>
											</TableRow>
											
											<TableRow >
											  <TableCell style={{width:"300px"}} component="th" scope="row">Motivo da cobrança</TableCell>
											  <TableCell align="left">
											  
											  {item.penaltyType == 2 &&
											  <>
												<span className="product-badge status-encerrado">Alteração do pedido</span> <small> muito em cima da hora (faltando menos de 1h e 30min do horário de execução)</small>
											  </>
											  }
											  
											  {item.penaltyType == 1 &&
											  <>
												<span className="product-badge status-bloqueado">Cancelamento do pedido</span> <small>muito em cima da hora (faltando menos de 1h e 30min do horário de execução)</small>
											  </>
											  }											  
											  
											  </TableCell>
											</TableRow>		
											</>
											)}	

										 )}	

							</TableBody>
						  </Table>
						</TableContainer>
						</>
						}						
						
						{/* Histórico de Pagamento */}
						<TableContainer component={Box} mt={2}>
						  <Table style={{minWwidth:"500px"}} aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Histórico de pagamento</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell style={{width:"200px"}} component="th" scope="row">
									  Histórico <br />
									  </TableCell>
								  <TableCell align="left">

								   {paymentHistory.length > 0 &&
									<Timeline style={{width:"50%"}} value={paymentHistory} opposite={paymentTimelineTemplate} content={(item) => <small className="p-text-secondary">{moment(item.createdAt).format("DD/MM/YYYY HH:mm")}</small>} />
								   }
								   
								   {paymentHistory.length == 0 &&
									<span>Não há histórico de pagamento para esse pedido.</span>
								   }
																		
								  
								  </TableCell>
								</TableRow>
								
							
							</TableBody>
						  </Table>
						</TableContainer>						
						
						{/* Comission Block */}												
						{serviceOrder.platformTotals &&							
								<>
								<TableContainer component={Box} mt={2}>
									  <Table style={{minWwidth:"500px"}} aria-label="simple table">

										<TableHead>
										  <TableRow style={{backgroundColor:"#EEE"}}>
											<TableCell colSpan={3}>Comissão Plataforma</TableCell>
										  </TableRow>
										</TableHead>

										<TableBody>

										{serviceOrder.platformTotals.map( (item) => {

											var fieldLabel = item.history;
											
											if (item.valueType == "B" || item.valueType == "C" || item.valueType == "D") {
												var fieldValue = formatter.format(item.value);
											} // end if
											else {
												var fieldValue = item.value+"%";
											} // end else

											return (
											<>
											<TableRow >
											  <TableCell style={{width:"300px"}} component="th" scope="row">{fieldLabel}</TableCell>
											  <TableCell align="left" style={{fontWeight:item.valueType == "B" ? "bold" : "normal",color:item.valueType == "C" ? "green" : "#000"}}>{fieldValue}</TableCell>
											</TableRow>	
											</>
											)}	

										 )}											
					
										</TableBody>
									  </Table>
									</TableContainer>
								</>
							}
									
						{serviceOrder.providerTotals &&							
								<>
								<TableContainer component={Box} mt={2}>
									  <Table style={{minWwidth:"500px"}} aria-label="simple table">

										<TableHead>
										  <TableRow style={{backgroundColor:"#EEE"}}>
											<TableCell colSpan={3}>Comissão Prestador</TableCell>
										  </TableRow>
										</TableHead>

										<TableBody>

										{serviceOrder.providerTotals.map( (item) => {

											var fieldLabel = item.history;
											
											if (item.valueType == "B" || item.valueType == "C" || item.valueType == "D") {
												var fieldValue = formatter.format(item.value);
											} // end if
											else {
												var fieldValue = item.value+"%";
											} // end else

											return (
											<>
											<TableRow >
											  <TableCell style={{width:"300px"}} component="th" scope="row">{fieldLabel}</TableCell>
											  <TableCell align="left" style={{fontWeight:item.valueType == "B" ? "bold" : "normal",color:item.valueType == "C" ? "green" : item.valueType == "D" ? "red" : "#000"}}>{fieldValue}</TableCell>
											</TableRow>	
											</>
											)}	

										 )}											
					
										</TableBody>
									  </Table>
									</TableContainer>
								</>
							}									
								
						{serviceOrder.partnerTotals &&							
								<>
								<TableContainer component={Box} mt={2}>
									  <Table style={{minWwidth:"500px"}} aria-label="simple table">

										<TableHead>
										  <TableRow style={{backgroundColor:"#EEE"}}>
											<TableCell colSpan={3}>Comissão Parceiro</TableCell>
										  </TableRow>
										</TableHead>

										<TableBody>

										{serviceOrder.partnerTotals.map( (item) => {

											var fieldLabel = item.history;
											
											if (item.valueType == "B" || item.valueType == "C" || item.valueType == "D") {
												var fieldValue = formatter.format(item.value);
											} // end if
											else {
												var fieldValue = item.value+"%";
											} // end else

											return (
											<>
											<TableRow >
											  <TableCell style={{width:"300px"}} component="th" scope="row">{fieldLabel}</TableCell>
											  <TableCell align="left" style={{fontWeight:item.valueType == "B" ? "bold" : "normal",color:item.valueType == "C" ? "green" : item.valueType == "D" ? "red" : "#000"}}>{fieldValue}</TableCell>
											</TableRow>	
											</>
											)}	

										 )}											
					
										</TableBody>
									  </Table>
									</TableContainer>
								</>
							}								
						
					  </CardBody>
					</Card>	 
 

				  </GridItem>
				</GridContainer>
				</>
				}
				
			</Dialog>	
	</>	
	);
	
});




export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(AdminUsers);	
