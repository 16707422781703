import React from 'react';
import axios from 'axios';
import moment from 'moment';
import jwt_decode from "jwt-decode";
import * as types from '../actionTypes';
import * as config from '../../Config/api.js';

export const setButtonLoading  = (status) => { 
	return { 
		type: types.BUTTON_LOADING,
		payload: status 
	}
};

export const setDialog  = (target,status) => { 
	return { 
		type: types.SET_DIALOG,
		payload: {target:target,status:status} 
	}
};

export const setAppLoading = (value) => ({
  type: types.APP_LOADING,
  payload: value
});

export const setCurrentFilter  = (value) => { 
	return { 
		type: types.SET_CURRENT_FILTER,
		payload: value 
	}
};

export const setCustomers = (value) => ({
  type: types.SET_CUSTOMERS,
  payload: value
});

export const setCustomer = (value) => ({
  type: types.SET_CUSTOMER,
  payload: value
});

export const retrieveCustomers = (data,props) => {
	
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  

					// Prepare Params
					var preparedData = {}
					preparedData.status =  data.status;
					preparedData.pagination = {
						first:data.first,
						rows:data.rows,
						page:data.page,
						sortField:data.sortField ? data.sortField:null,
						sortOrder:data.sortOrder ? data.sortOrder:null,
						pageCount:data.pageCount
					}					
					preparedData.filters =  data.filters ? data.filters:null;
				
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/customer/list',
						   method : 'POST',
						   data   : preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					}) 						
					
					.then((response) => {
						
						// Retrieve data to controller
						props.setCustomersRows(response.data.customers);
						props.setTotalRecords(response.data.count);

						// set rowCount first
						props.setLoading(false);
						return;
						
					 })
					.catch(error=> {
						props.setLoading(false);			
						props.setCustomersRows();
						console.log(error);
						return;
					});		
					
		}
}

export const retrieveCustomer = (id,props) => {
 
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
					const urlEnd = config.API_URL+`/api/v1/customer/retrieve/${id}`;
				
					// Connect to Endpoint
					axios({
						   url    : urlEnd,
						   method : 'GET',
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})
					
					.then((response) => {
		
						dispatch(setCustomer(response.data.customer));
						
						// Turn array to flat
						const finalObj = response.data.customer;
						props.setCustomerCol(finalObj);	

						//const finalAddress = response.data.customer[0].CustomerProfile.CustomerAddress.streetType+" "+response.data.customer[0].CustomerProfile.CustomerAddress.streetName+", "+response.data.customer[0].CustomerProfile.CustomerAddress.streetNumber+", "+response.data.customer[0].CustomerProfile.CustomerAddress.suburb+", "+response.data.customer[0].CustomerProfile.CustomerAddress.city+", "+response.data.customer[0].CustomerProfile.CustomerAddress.state;

						dispatch(setAppLoading(false));	
						props.setContentVisible(true);			
						
					 })
					.catch(error=> {
						
						dispatch(setAppLoading(false));		
						props.setCustomerCol({});						
						console.log(error);
						
					});		
					
		}		
		
}

export const validateCustomer = (data,props) => {
	
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
								
				// Prepare for login 
				var preparedData = {
					ProviderUserId:""+data.ProviderUserId+""					
				};

				// Connect to Endpoint
				axios({
					   url    : config.API_URL+'/api/v1/customer/approve',
					   method : 'POST',
					   data   : preparedData,
					   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
				})			
				
				.then((response) => {
	
				// If an error occured
				if (response.data.error) {					
					props.setLoading(false);;
					props.setDialogMessage('Ocorreu um erro ao se comunicar com o servidor. Tente novamente.');
					dispatch(setDialog('alert',true));
					return;			
				} // end if	
	
  
				if (response.data.success) {
					
					props.setLoading(false);;
					
					// If company already exist
					if (response.data.notfound) {
						props.setDialogMessage('Não foi possível aprovar o prestador. Tente recarregar a página e tente novamente.');
						dispatch(setDialog('alert',true));						
					} // end if
					
						props.setDialogTitle("Tudo certo! Prestador aprovado!");
						props.setDialogMessage('O prestador foi aprovado com sucesso! O mesmo foi notificado via aplicativo e via email e já poderá acessar a plataforma.');
						props.setDialogAction("/admin/customers");
						dispatch(setDialog('alert',true));			
		
						return;
						
					
				} // end if
					
				}, (error) => {
															
					props.setLoading(false);;
					props.setDialogMessage('Ocorreu um erro ao se comunicar com o servidor. Tente novamente.');
					dispatch(setDialog('alert',true));				
										
				})
				.catch(error=> {
					
					console.log("error: "+JSON.stringify(error.message));
	
					props.setLoading(false);;
					props.setDialogMessage('Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.');	
					dispatch(setDialog('alert',true));					

				});

			
		} // end dispatch
	
};


// ----- TO DO ------

	// updateCustomer

	// blockCustomer

	// terminateCustomer



