import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// @material-ui/core components
import { makeStyles,lighten } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import * as config from '../../Config/api.js';

import { DataGrid } from '@material-ui/data-grid';
import moment from "moment";

import {Link,useParams, useHistory} from "react-router-dom";

import ReactStars from 'react-stars'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import CheckIcon from '@material-ui/icons/Check';
import NotIcon from '@material-ui/icons/Block';
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';

import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import MenuItem from '@material-ui/core/MenuItem';

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import Person from "@material-ui/icons/Person";
import View from '@material-ui/icons/Visibility';
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import RoomIcon from '@material-ui/icons/Room';

import { Button } from 'primereact/button';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Table from "components/Table/Table.js";
// import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

import visa from '../../assets/Images/visa.svg'; // Tell Webpack this JS file uses this image
import mastercard from '../../assets/Images/mastercard.svg'; // Tell Webpack this JS file uses this image
import amex from '../../assets/Images/amex.svg'; // Tell Webpack this JS file uses this image
import diners from '../../assets/Images/diners.svg'; // Tell Webpack this JS file uses this image
import elo from '../../assets/Images/elo.svg'; // Tell Webpack this JS file uses this image
import genericFlag from '../../assets/Images/genericFlag.svg'; // Tell Webpack this JS file uses this image

import android from '../../assets/Images/android-device.png'; // Tell Webpack this JS file uses this image
import ios from '../../assets/Images/ios-device.png'; // Tell Webpack this JS file uses this image
import genericDevice from '../../assets/Images/generic-device.png'; // Tell Webpack this JS file uses this image

// Load Actions
import * as AppActions from 'Store/Actions/App'
import * as CustomerActions from 'Store/Actions/Customer'


const useStyles = makeStyles(styles);

const CustomerView = props => {

// set vars from props
const { 
	appLoading,
	setDialog,
	dialog,
	retrieveCustomer,
	setAppLoading	
} = props;		

const { id } = useParams();

const [customer, setCustomerCol] = React.useState({});
const [mapImageUrl, setMapImageUrl] = React.useState("");  
const [avatar, setAvatar] = React.useState(null);  
const [contentVisible, setContentVisible] = React.useState(false);  

const [dialogAlert, setAlertDialog] = React.useState(false); // state for tab
const [dialogMessage, setDialogMessage] = React.useState(""); // state for tab
const [dialogTitle, setDialogTitle] = React.useState("Verifique as informações"); // state for tab
const [dialogAction, setDialogAction] = React.useState(false); // state for tab


//console.log("[customer]--->"+JSON.stringify(customer,null,2));


const [value, setValue] = React.useState(0);

const classes = useStyles();

 
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const handleChange = (event, newValue) => {
setValue(newValue);
};
 
const calculateAge = (birthday) => {

	var a = moment();
	var b = moment(birthday, 'YYYY');  
	var diff = a.diff(b, 'years'); // calculates patient's age in years
	return diff; // this prints out the age

} 

const maskDocument = (document) => {
	
	// Clean and format
	var cleaned = ('' + document).replace(/\D/g, '')
	  var match = cleaned.match(/^(\d{3})(\d{3})(\d{3})(\d{2})$/)
	  if (match) {
		return '' + match[1] + '.' + match[2] + '.' + match[3]+ '-' + match[4];
	  } // end if
	  else {
		return "CPF Inválido";
	  } // end else	  
	
}

const maskCompanyDocument = (document) => {
	
	// Clean and format
	var cleaned = ('' + document).replace(/\D/g, '')
	  var match = cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/)
	  if (match) {
		return '' + match[1] + '.' + match[2] + '.' + match[3] + '/' + match[4] + '-' + match[5];
	  } // end if
	  else {
		return "CNPJ Inválido";
	  } // end else	  
	
}

const maskPhone = (phone) => {
	
	// Clean and format
	var cleaned = ('' + phone).replace(/\D/g, '')
	  var match = cleaned.match(/^(\d{2})\D*(\d{5}|\d{4})\D*(\d{4})$/)
	  if (match) {
		return '(' + match[1] + ') ' + match[2] + '-' + match[3];
	  } // end if
	  else {
		return "Telefone Inválido";
	  } // end else	  
	
}

const maskCep = (cep) => {
	
	// Clean and format
	var cleaned = ('' + cep).replace(/\D/g, '')
	  var match = cleaned.match(/^(\d{5})(\d{3})$/)
	  if (match) {
		return match[1] + '-' + match[2];
	  } // end if
	  else {
		return "CEP Inválido";
	  } // end else	  
	
}

const maskGender = (gender) => {
	
	if (gender === 1) {
		return "Masculino";
	} // end if
	else if (gender === 2) {
		return "Feminino";
	} // end else if
	else {
		return "Indefinido";
	} // end else
	
}

// set Use of history for PUSH 
let history = useHistory();
  
// When mount component
React.useEffect(() => {

	setAppLoading(true);

	var propsParam = {
		setCustomerCol:setCustomerCol,
		setContentVisible:setContentVisible
	}
	retrieveCustomer(id,propsParam);

			
}, []);  

if(!contentVisible) {	  
	return (
		<div style={{width:"100%",height:"80vh",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
		
			<div style={{display:"flex",flex:1,alignItems:"center",justifyContent:"center"}}>
			
				<CircularProgress color="primary" />
				<div style={{fontSize:"18px",marginLeft:"15px"}}> Carregando dados...</div>
			
			</div>
		
		</div>
	)
return;
  }


if(contentVisible) {	 
  return ( 
  <>	
   <Box ml={3} mb={5}>
   
	 <Breadcrumbs aria-label="breadcrumb">
	  <Typography color="textSecondary">Gestão de Clientes</Typography>
	  <Typography color="textSecondary"><Link to="/admin/customers">Cadastro de clientes</Link></Typography>
	  <Typography color="textPrimary">Visualizando Cadastro</Typography>
	</Breadcrumbs>
	
	<Divider />

	{customer.status != "9" &&
	<span style={{float:"right",marginRight:"30px",marginTop:"30px"}}>
		<Button variant="contained"
			onClick={() => alert("Em desenvolvimento...") }
		 >
		  Editar Cadastro
		</Button>
	</span>	
	}
	
	<h3 style={{marginTop:"30px"}}>Visualizando <strong>{customer.CustomerProfile.completeName}</strong></h3>	
	<h5>Navegue nas informações do cliente abaixo.</h5>
		
	</Box>
  
    <GridContainer>
	
      <GridItem xs={12}>

		  <Tabs
			value={value}
			onChange={handleChange}
			indicatorColor="primary"
			textColor="primary"
			variant="scrollable"
			scrollButtons="auto"
			centered
		  >
			<Tab label="Dados de cadastro" />
			<Tab label="Dados de Faturamento" />
			<Tab label="Endereços" />
			<Tab label="Cartões de crédito" />
			<Tab label="Dispositivos" />
			<Tab disabled={customer.status == 0 ? true : false} label="Ordens de serviço" />
			<Tab disabled={customer.status == 0 ? true : false} label="Extrato Financeiro" />
			<Tab disabled={customer.status == 0 ? true : false} label="Chamados" />
		</Tabs>

     	  <Divider />
			<TabPanel value={value} index={0}>

					<Card>
					  <CardHeader color="info" icon>
						<CardIcon color="info">
						  <Assignment />
						</CardIcon>
						<h4 className={classes.cardIconTitle}>Dados de Cadastro</h4>
					  </CardHeader>
					  <CardBody>	 

						<TableContainer component={Box} mt={2}>
						  <Table style={{minWwidth:"500px"}} aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Status e qualificação</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">
									  Status do Cadastro <br />
									  
									  { (customer.status === 1 || customer.status === 0) && 
										<small style={{color:"silver"}}> Criado em {moment(customer.createdAt).format("DD/MM/YYYY")}</small>
									  }
									  
									  { customer.status === 3 && 
										<small style={{color:"silver"}}> Bloqueado em {moment(customer.updatedAt).format("DD/MM/YYYY")}</small>
									  }
									  
									  { customer.status === 9 && 
										<small style={{color:"silver"}}> Encerrado em {moment(customer.updatedAt).format("DD/MM/YYYY")}</small>
									  }
									  
								  </TableCell>
								  <TableCell align="left">
								  
									  { (customer.status === 1) && 
										  <>
											<span class="product-badge status-ativado">Validado</span> <small><i> por {customer.activationMethod} em {moment(customer.updatedAt).format("DD/MM/YYYY")}</i></small>
										  </>
									  }
									  
									  { (customer.status === 0) && 
										  <>
											<span class="product-badge status-bloqueado">Não validado</span> <small><i> Cliente não pode solicitar serviços </i></small>
											
											<Button label="Validar manualmente" style={{float:"right",marginLeft:"25px",height:"30px"}} className="p-button-raised p-button-sm p-button-primary p-button-text" onClick={() => alert("Em desenvolvimento...") }  />
										  </>
									  }
									  
									  { customer.status === 3 && 
										  <>
											<span class="product-badge status-bloqueado">Bloqueado</span> <small><i> Cliente não pode utilizar o app </i></small>
										  </>
									  }
									  
									  { customer.status === 9 && 
										  <>
											<span class="product-badge status-encerrado">Encerrado</span> <small><i> Cliente foi excluído da plataforma </i></small>
										  </>
									  }								  
								  
								  </TableCell>
								</TableRow>
								
							
								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Status da Conta IUGU</TableCell>
								  <TableCell align="left">
								  
								  	{customer.CustomerProfile.PaymentGatewayCustomerId &&
									  <>
										<span class="product-badge status-ativado">Conta criada na IUGU</span> <small><i> Token: {customer.CustomerProfile.PaymentGatewayCustomerId}</i></small>
									  </>
									}	

								  	{!customer.CustomerProfile.PaymentGatewayCustomerId &&
									  <>
										<span class="product-badge status-review">Conta  não criada na IUGU</span> <small><i>A conta será criada quando o cliente fizer seu primeiro pedido</i></small>
									  </>
									}										
								  
								  </TableCell>
								</TableRow>
								
								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Status envio PUSH</TableCell>
								  <TableCell align="left">
								  
								  	{customer.expoPushToken &&
									  <>
										<span class="product-badge status-ativado">Registrado com Sucesso</span> <small><i> Token: {customer.expoPushToken}</i></small>
									  </>
									}	

								  	{!customer.expoPushToken &&
									  <>
										<span class="product-badge status-review">Não foi registrado</span> <small><i>É provável que o cliente só acessou a versão web não podendo receber mensagens PUSH.</i></small>
									  </>
									}										
								  
								  </TableCell>
								</TableRow>								
								
								<TableRow >
								  <TableCell component="th" scope="row">Qualificação na plataforma</TableCell>
								  <TableCell align="left">
								  
										<div>
										
										
										{customer.CustomerRating &&
											<ReactStars
											  count={5}
											  edit={false}
											  value={customer.CustomerRating.generalRating}
											  half={true}
											  size={36}
											  color2={'#ffd700'} />	
										}
										
										{!customer.CustomerRating &&
												<div>Prestador ainda não foi qualificado</div>
										}										
										
										</div>								  
											
								  </TableCell>
								</TableRow>	
							 	

							</TableBody>
						  </Table>
						</TableContainer>
							  
				 		
						<TableContainer component={Box} mt={2}>
						  <Table style={{minWwidth:"500px"}} aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Dados do Cadastro</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Nome Completo</TableCell>
								  <TableCell align="left">{customer.CustomerProfile.completeName}</TableCell>
								</TableRow>
								
								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Como gosta de ser chamado</TableCell>
								  <TableCell align="left">{customer.CustomerProfile.alias}</TableCell>
								</TableRow>								

								<TableRow >
								  <TableCell component="th" scope="row">Email</TableCell>
								  <TableCell align="left">{customer.email}</TableCell>
								</TableRow>

								<TableRow >
								  <TableCell component="th" scope="row">Telefone Celular</TableCell>
								  <TableCell align="left">{maskPhone(customer.mobilePhone)}</TableCell>
								</TableRow>

								<TableRow >
								  <TableCell component="th" scope="row">Data de Nascimento</TableCell>
								  <TableCell align="left">{customer.CustomerProfile.birthday ? moment(customer.CustomerProfile.birthday).format("DD/MM/YYYY")+ "- ("+calculateAge(customer.CustomerProfile.birthday)+" anos)" : "Não informada"}</TableCell>
								</TableRow>
								
								<TableRow >
								  <TableCell component="th" scope="row">Gênero</TableCell>
								  <TableCell align="left">{customer.CustomerProfile.gender ? customer.CustomerProfile.gender === 1 ? "Masculino":"Feminino" : "Não informado"}</TableCell>
								</TableRow>								


							</TableBody>
						  </Table>
						</TableContainer>
						
						{customer.status != 0 &&
						<TableContainer component={Box} mt={2}>
						  <Table style={{minWwidth:"500px"}} aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Dados da Validação do Perfil</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Código de Validação</TableCell>
								  <TableCell align="left">{customer.activationCode}</TableCell>
								</TableRow>
								
								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Data de Validação</TableCell>
								  <TableCell align="left">{moment(customer.activationDate).format("DD/MM/YYYY")}</TableCell>
								</TableRow>								

								<TableRow >
								  <TableCell component="th" scope="row">Método de Validação</TableCell>
								  <TableCell align="left">{customer.activationMethod}</TableCell>
								</TableRow>

							</TableBody>
						  </Table>
						</TableContainer>
						}						
						

					  </CardBody>
					</Card>	 
 
			</TabPanel>
				  
			<TabPanel value={value} index={1}>

					<Card>
					  <CardHeader color="info" icon>
						<CardIcon color="info">
						  <RoomIcon />
						</CardIcon>
						<h4 className={classes.cardIconTitle}>Dados de Faturamento</h4>
					  </CardHeader>
					  <CardBody>	  
				 				
						{/* Results from API */}
						{customer.CustomerProfile.CustomerBillingDocuments.map((item) => {								

							return (
								<TableContainer component={Box} mt={2}>
								  <Table style={{minWwidth:"500px"}} aria-label="simple table">

									<TableHead>
									  <TableRow style={{backgroundColor:"#EEE"}}>
										<TableCell colSpan={3}>{item.type = "PF" ? "Faturamento Pessoa Física" : "Faturamento Pessoa Jurídica"}</TableCell>
									  </TableRow>
									</TableHead>

									<TableBody>
													
									<TableRow >
									  <TableCell style={{width:"300px"}} component="th" scope="row">Nome Completo</TableCell>
									  <TableCell align="left">{item.billingName}</TableCell>
									</TableRow>

									<TableRow >
									  <TableCell style={{width:"300px"}} component="th" scope="row">{item.type = "PF" ? "CPF" : "CNPJ"}</TableCell>
									  <TableCell align="left">{item.billingDocument}</TableCell>
									</TableRow>
									
									
									<TableRow >
									  <TableCell component="th" scope="row">Endereço</TableCell>
									  <TableCell align="left">{item.CustomerAddress.fullAddress}</TableCell>
									</TableRow>


									</TableBody>
								  </Table>
								</TableContainer>
							)}
						
						)}							
							
						{ customer.CustomerProfile.CustomerBillingDocuments.length === 0 && 
							<div> Ops! Nenhum dado de faturamento cadastrado... </div>						
						}							

	
					  </CardBody>
					</Card>	

			</TabPanel>	

			<TabPanel value={value} index={2}>

					<Card>
					  <CardHeader color="info" icon>
						<CardIcon color="info">
						  <Assignment />
						</CardIcon>
						<h4 className={classes.cardIconTitle}>Endereços cadastrados</h4>
					  </CardHeader>
					  <CardBody>	  
				 		
											
						<TableContainer component={Box} mt={2}>
						  <Table aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Locais para prestação de serviços</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell component="th" scope="row">

									<Box style={{display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"flex-start",flexWrap:"wrap"}}>

										  {/* Results from API */}
										  {customer.CustomerProfile.CustomerAddresses.map((item) => {
											  
												var finalAddress = item.streetType+" "+item.streetName+", "+item.streetNumber+", "+item.suburb+", "+item.city+", "+item.state;
												
												let coordinates = JSON.parse(item.googleCoordinates);
												var addressCoordinate = coordinates.lat+","+coordinates.lng;
												 
												 var googleMapsUrl = "https://maps.googleapis.com/maps/api/staticmap?center="+finalAddress+"&zoom=14&size=328x140&maptype=roadmap&key=AIzaSyBdE3uu0p9cJ_961a03aXMo0kGSzuUfTVE";
												 	
												 return (
													<Card style={{margin:"10px",maxWidth:"300px"}} key={item.id}>
													  <CardHeader color="info" icon>
															<h4 className={classes.cardIconTitle} style={{padding:"10px",paddingTop:"0px",fontWeight:"bold"}}>{item.label}</h4>
													  </CardHeader>
													  <Box><img src={googleMapsUrl} style={{width:"100%",height:"auto",backgroundColor:"whitesmoke"}} /></Box>
													  <CardBody>	
															
															<Box>{item.fullAddress}</Box>
													  </CardBody>
													</Card>
													)}
											)}	
									
											
											{ customer.CustomerProfile.CustomerAddresses.length === 0 && 
												<div> Ops! Nenhum endereço cadastrado... </div>						
											}

									</Box>


									</TableCell>
								</TableRow>


							</TableBody>
						  </Table>
						</TableContainer>	
					

					  </CardBody>
					</Card>	

			</TabPanel>	

			<TabPanel value={value} index={3}>

					<Card>
					  <CardHeader color="info" icon>
						<CardIcon color="info">
						  <Assignment />
						</CardIcon>
						<h4 className={classes.cardIconTitle}>Cartões de Crédito</h4>
					  </CardHeader>
					  <CardBody>	  
				 		
						<TableContainer component={Box} mt={2}>
						  <Table aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Cartões de Crédito cadastrados e armazenados</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell component="th" scope="row">

									<Box style={{display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"flex-start",flexWrap:"wrap"}}>

										  {/* Results from API */}
										  {customer.CustomerProfile.CustomerCreditcards.map((item) => {
											  
												if (item.cardFlag == "visa") {
													var flag = visa;
												} // end if
												else if  (item.cardFlag == "mastercard") {
													var flag = mastercard;
												} // end else if
												else if  (item.cardFlag == "amex") {
													var flag = amex;
												} // end else if	
												else if  (item.cardFlag == "diners") {
													var flag = diners;
												} // end else if	
												else if  (item.cardFlag == "elo") {
													var flag = elo;
												} // end else if	
												else if  (item.cardFlag == "generic") {
													var flag = genericFlag;
												} // end else if	
												 	
												 return (
													<Card style={{margin:"10px",maxWidth:"320px",padding:"10px"}} key={item.id}>

														<Box style={{display:"flex",flexdirection:"row",alignItems:"center",justifyContent:"start"}}>
															
															<Box>										
																<img src={flag} alt="{item.cardFlag}" style={{marginLeft:"10px",marginRight:"10px",width:"75px"}} />
															</Box>
															
															<Box m={1}>	
																	<div style={{textAlign:"left",fontSize:"0.85rem",lineHeight:"25px"}}>
																		{item.holderName}<br />
																	<h4 style={{marginTop:"0px",marginBottom:"0px",fontWeight:"bold"}}>{item.referenceNumber}</h4>
																	Expiração {item.expirationDate}
																	</div>	
															</Box>	
														
														</Box>
														
														{item.stored === 1 && 
														<>
															<Box m={1} style={{fontWeight:"bold",textAlign:"center"}}>Cartão armazenado para reutilização.</Box>
														</>															
														}

														{item.stored === 0 && 
														<>
															<Box m={1} style={{fontWeight:"bold",textAlign:"center"}}>Cartão mantido para transação.</Box>
														</>															
														}														

													</Card>
													)}
											)}	
									
											
											{ customer.CustomerProfile.CustomerCreditcards.length === 0 && 
												<div> Ops! Nenhum cartão de crédito cadastrado... </div>						
											}

									</Box>


									</TableCell>
								</TableRow>	


							</TableBody>
						  </Table>
						</TableContainer>	


					  </CardBody>
					</Card>	

			</TabPanel>	

			<TabPanel value={value} index={4}>

					<Card>
					  <CardHeader color="info" icon>
						<CardIcon color="info">
						  <Assignment />
						</CardIcon>
						<h4 className={classes.cardIconTitle}>Dispositivos</h4>
					  </CardHeader>
					  <CardBody>	  
									

						<TableContainer component={Box} mt={2}>
						  <Table aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Dispositivos utilizados para acessar o app</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell component="th" scope="row">

									<Box style={{display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"flex-start",flexWrap:"wrap"}}>

										  {/* Results from API */}
										  {customer.CustomerDevices.map((item) => {

											  
												if (item.deviceOs.indexOf("Android") >= 0) {
													var deviceOsIcon = android;
												} // end if
												else if (item.deviceOs.indexOf("iOS") >= 0) {
													var deviceOsIcon = ios;
												} // end else if
												else {
													var deviceOsIcon = genericDevice;
												} // end else
												
												 return (
													<Card style={{margin:"10px",maxWidth:"320px",padding:"10px"}} key={item.id}>

														<Box style={{display:"flex",flexdirection:"row",alignItems:"center",justifyContent:"start"}}>
															
															<Box>										
																<img src={deviceOsIcon} alt="{item.deviceOs}" style={{marginLeft:"10px",marginRight:"10px",width:"75px"}} />
															</Box>
															
															<Box m={1}>	
																	<div style={{textAlign:"left",fontSize:"0.85rem",lineHeight:"25px"}}>
																	<h4 style={{marginTop:"0px",marginBottom:"0px",fontWeight:"bold"}}>{item.deviceName} {item.deviceModel}</h4>
																	Tipo: {item.deviceType == 'mobile' ? "Smartphone": "Desktop"} <br />
																	Sistema: {item.deviceOs} <br />
																	Resolução: {item.deviceScreenResolution}
																	</div>	
															</Box>	
														
														</Box>
														
														{item.stored === 1 && 
														<>
															<Box m={1} style={{fontWeight:"bold",textAlign:"center"}}>Cartão armazenado para reutilização.</Box>
														</>															
														}

														{item.stored === 0 && 
														<>
															<Box m={1} style={{fontWeight:"bold",textAlign:"center"}}>Cartão mantido para transação.</Box>
														</>															
														}														

													</Card>
													)}
											)}	
									
											
											{ customer.CustomerDevices.length === 0 && 
												<div> Ops! Nenhum dispositivo cadastrado... </div>						
											}

									</Box>


									</TableCell>
								</TableRow>	


							</TableBody>
						  </Table>
						</TableContainer>	

										
					  </CardBody>
					</Card>	

			</TabPanel>	
			
			<TabPanel value={value} index={5}>

					<Card>
					  <CardHeader color="info" icon>
						<CardIcon color="info">
						  <Assignment />
						</CardIcon>
						<h4 className={classes.cardIconTitle}>Ordens de Serviço</h4>
					  </CardHeader>
					  <CardBody>	  

					  Lista de serviços solicitados
										
					  </CardBody>
					</Card>	

			</TabPanel>

			<TabPanel value={value} index={6}>

					<Card>
					  <CardHeader color="info" icon>
						<CardIcon color="info">
						  <Assignment />
						</CardIcon>
						<h4 className={classes.cardIconTitle}>Extrato Financeiro</h4>
					  </CardHeader>
					  <CardBody>	  

						Extrato com movimentação de pagamentos , créditos e estornos. 
										
					  </CardBody>
					</Card>	

			</TabPanel>
			

			<TabPanel value={value} index={7}>

					<Card>
					  <CardHeader color="info" icon>
						<CardIcon color="info">
						  <Assignment />
						</CardIcon>
						<h4 className={classes.cardIconTitle}>Chamados</h4>
					  </CardHeader>
					  <CardBody>	  

						Lista de chamados e tickets.
										
					  </CardBody>
					</Card>	

			</TabPanel>			

		
			<Box ml={3}> 

				{customer.status != "9" &&
				<Button variant="contained"
					onClick={() => alert("Em desenvolvimento...") }
				 >
				  Editar Cadastro
				</Button>
				}

					<ApproveDialog 
						dialogAlert={dialogAlert}
						setAlertDialog={setAlertDialog}
						dialogTitle={dialogTitle}
						dialogMessage={dialogMessage}
						setDialogTitle={setDialogTitle}
						setDialogMessage={setDialogMessage}
						setDialogAction={setDialogAction}
						customer={customer}
						history={history}
					/>

					<RepproveDialog 
						dialogAlert={dialogAlert}
						setAlertDialog={setAlertDialog}
						dialogTitle={dialogTitle}
						dialogMessage={dialogMessage}
						setDialogTitle={setDialogTitle}
						setDialogMessage={setDialogMessage}
						setDialogAction={setDialogAction}
						customer={customer}
						history={history}
					/>

			</Box>
	   
	  </GridItem>
	  
    </GridContainer>
	</>
  );
} // end if

return (null);

}

const mapStateToProps = store => ({
  buttonLoading:store.appReducer.buttonLoading,  
  appLoading:store.customerReducer.appLoading,
  dialog:store.customerReducer.dialog
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions, ...CustomerActions }, dispatch);


// Transition Slide for Dialog SLIDE UP	
const TransitionSlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;  
});	

/* APPROVE Dialog Component START */
export const ApproveDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
	
const useStyles = makeStyles((theme) => ({
  appBar: {
    
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const screenStyleDialog = {
  alignItems: "stretch",
  justifyContent: "top",
   display:"flex",
  flexDirection:"column",
  backgroundColor:"#FFF"
}; 
	
// set classes var
const classes = useStyles();	

// set vars from props
// Remember to add those who come from Redux
const { 
	setDialog,
	dialog,
	approveCustomer
} = props;	


/* Dialog END*/	

const [loading, setLoading] = React.useState(false);

const confirm = (props) => {




};	

var formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});


// useEffect only for dialgo changes
React.useEffect(() => {
		
	// execute only if this modal
	if (dialog.target == 'approveCustomer' && dialog.status) {
		

	
	}  // end if

}, [dialog]); // end useEffect only for dialgo changes

	return (
	<div>
		 <Dialog maxWidth="sm" open={dialog.target == 'approveCustomer' && dialog.status} onClose={()=>setDialog('approveCustomer',false)} TransitionComponent={TransitionSlideUp}>

		   <DialogTitle id="customized-dialog-title" onClose={()=>setDialog('approveCustomer',false)}>

			<IconButton aria-label="close" className={classes.closeButton} onClick={()=>setDialog('approveCustomer',false)}>
			  <CloseIcon />
			</IconButton>

			  Aprovando cadastro de cliente
			</DialogTitle>

			<DialogContent dividers style={{padding:0}}>
			<Box style={{...screenStyleDialog}} p={0}>
			
				<Box mt={0} ml={1} mr={1}>
					
					<Box style={{marginBottom:"5px",alignItems:"stretch",justifyContent:"flex-start",display:"flex",flexDirection:"column"}} p={0}>

								<h5 style={{fontSize:"1.2rem",color:"#A255A0",marginTop:"10px",textAlign:"center"}}>Aprovação de cadastro</h5>
								
								<Box mt={0}  mb={1} ml={2} mr={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  
										<div style={{marginRight:"10px"}}> 
											<WarningIcon style={{fontSize:"2rem",color:"red"}} />
										</div>
										
										<div> 
											<div style={{marginBottom:"10px",textAlign:"left"}}> Atenção, ao aprovar o cadastro o Prestador será notificado e poderá acessar a plataforma. Quer mesmo aprovar?  </div>
										</div>
								</Box>									
							
					</Box>
					
					<Divider style={{marginBottom:"5px"}} />
					
					{/* URGENT Date */}						
							
					<div>						
						<Box mt={2}  mb={2} ml={2} mr={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  
						
							Antes de aprovar o cadastro, verifique todas as informações enviadas, incluindo os documentos anexos. 

						</Box>								
					</div>
			
			
					<Divider style={{marginBottom:"5px"}} />
						
					<Box style={{display:"flex",flexDirection:"row",justifyContent:"center"}} ml={1} mr={1} mb={5} mt={2}>						
						
						<Button disabled={loading} color="secondary" variant="outlined" style={{flex:1,height:"48px",marginRight:"10px"}} onClick={()=>setDialog('approveCustomer',false)}>
						 Fechar
						</Button>		
						
						<Button disabled={loading}  variant="contained" style={{flex:2,height:"48px",color:"#FFF",backgroundColor:"green"}} onClick={()=>confirm(props)}>
							{ !loading &&
							   <div>Confirmar Aprovação</div>
							}
							
							{ loading &&
							<>
							   <CircularProgress size={30} color="inherit" />
							   <span style={{marginLeft:"10px"}}>Aprovando...</span>
							</>
							}
						</Button>
							
					</Box>			
			
				</Box>				
			
			</Box>
		  </DialogContent>

		  </Dialog>
	 
		 
		</div>		
	);
	
});


/* REPPROVE Dialog Component START */
export const RepproveDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
	
const useStyles = makeStyles((theme) => ({
  appBar: {
    
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const screenStyleDialog = {
  alignItems: "stretch",
  justifyContent: "top",
   display:"flex",
  flexDirection:"column",
  backgroundColor:"#FFF"
}; 
	
// set classes var
const classes = useStyles();	

// set vars from props
// Remember to add those who come from Redux
const { 
	setDialog,
	dialog,
	repproveCustomer
} = props;	


/* Dialog END*/	

const [loading, setLoading] = React.useState(false);
const [repproveReason, setRepproveReason] = React.useState("-");

const confirm = (props) => {




};	

var formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});


console.log("dialog >>>>>>>>>>>>>>>>>>>: "+JSON.stringify(dialog,null,2));


// useEffect only for dialgo changes
React.useEffect(() => {
		
	// execute only if this modal
	if (dialog.target == 'repproveCustomer' && dialog.status) {
		

	
	}  // end if

}, [dialog]); // end useEffect only for dialgo changes

	return (
	<div>
		 <Dialog maxWidth="sm" open={dialog.target == 'repproveCustomer' && dialog.status} onClose={()=>setDialog('repproveCustomer',false)} TransitionComponent={TransitionSlideUp}>

		   <DialogTitle id="customized-dialog-title" onClose={()=>setDialog('repproveCustomer',false)}>

			<IconButton aria-label="close" className={classes.closeButton} onClick={()=>setDialog('repproveCustomer',false)}>
			  <CloseIcon />
			</IconButton>

			  Reprovando cadastro de cliente
			</DialogTitle>

			<DialogContent dividers style={{padding:0}}>
			<Box style={{...screenStyleDialog}} p={0}>
			
				<Box mt={0} ml={1} mr={1}>
					
					<Box style={{marginBottom:"5px",alignItems:"stretch",justifyContent:"flex-start",display:"flex",flexDirection:"column"}} p={0}>

								<h5 style={{fontSize:"1.2rem",color:"#A255A0",marginTop:"10px",textAlign:"center"}}>Reprovação de cadastro</h5>
								
								<Box mt={0}  mb={1} ml={2} mr={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  
										<div style={{marginRight:"10px"}}> 
											<WarningIcon style={{fontSize:"2rem",color:"red"}} />
										</div>
										
										<div> 
											<div style={{marginBottom:"10px",textAlign:"left"}}> Atenção, ao reprovar o cadastro o Prestador será notificado sobre a reprovação de seu cadastro. Quer mesmo reprovar?  </div>
										</div>
								</Box>									
							
					</Box>
					
					<Divider style={{marginBottom:"5px"}} />
					
					{/* REASON REPPROVE Date */}						

						<Box mt={2}  mb={0} ml={2} mr={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  
								<div> 
									<div style={{marginBottom:"10px",textAlign:"center",fontWeight:"bold"}}> Selecione o motivo da reprovação: </div>
								</div>
						</Box>	
						
						<Box mt={0}  mb={2} ml={2} mr={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  
								<div style={{marginRight:"10px",width:"90%"}}> 

										<Select
										  label="Motivo do encerramento"
										  placeholder="Motivo do encerramento"
										  style={{ width:"100%",paddingTop:0,paddingBottom:0,margin:5,marginLeft:10,marginRight:0,backgroundColor:"#fff",width:"100%"}}
										  size="small"
										  variant="outlined"
										  value={repproveReason}
										  onChange={(event) => {
											  setRepproveReason(event.target.value)
										  }}	
										 >
											<MenuItem value="-">- Selecione um motivo -</MenuItem>
											<MenuItem value="Informações fornecidas são inválidas">Informações fornecidas são inválidas</MenuItem>
											<MenuItem value="Documentos anexos inválidos">Documentos anexos inválidos</MenuItem>
											<MenuItem value="Não tem experiência ou formação necessários">Não tem experiência ou formação necessários</MenuItem>
											<MenuItem value="Outro">Outro motivo não listado</MenuItem>
										</Select>
										
								</div>

						</Box>	
			
			
					<Divider style={{marginBottom:"5px"}} />
						
					<Box style={{display:"flex",flexDirection:"row",justifyContent:"center"}} ml={1} mr={1} mb={5} mt={2}>						
						
						<Button disabled={loading} color="secondary" variant="outlined" style={{flex:1,height:"48px",marginRight:"10px"}} onClick={()=>setDialog('repproveCustomer',false)}>
						 Fechar
						</Button>		
						
						<Button disabled={loading}  variant="contained" style={{flex:2,height:"48px",color:"#FFF",backgroundColor:"red"}} onClick={()=>confirm(props)}>
							{ !loading &&
							   <div>Confirmar Reprovação</div>
							}
							
							{ loading &&
							<>
							   <CircularProgress size={30} color="inherit" />
							   <span style={{marginLeft:"10px"}}>Reprovando...</span>
							</>
							}
						</Button>
							
					</Box>			
			
				</Box>				
			
			</Box>
		  </DialogContent>

		  </Dialog>
	 
		 
		</div>		
	);
	
});


export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(CustomerView);
	
