import React,{useState,useRef} from "react";
import {useHistory,Link} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// @material-ui/core components
import './DataTable.css';
import { makeStyles,lighten } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";

// import { DataGrid,GridToolbar,ptBR  } from '@material-ui/data-grid';
import moment from "moment";

// Import Prime React Components
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import 'primereact/resources/themes/saga-blue/theme.css'; 
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Calendar } from 'primereact/calendar';
import { locale, addLocale, updateLocaleOption, updateLocaleOptions, localeOption, localeOptions } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Backdrop from '@material-ui/core/Backdrop';
import { Tree } from 'primereact/tree';
import { Fieldset } from 'primereact/fieldset';
import { SelectButton } from 'primereact/selectbutton';
import { InputTextarea } from 'primereact/inputtextarea';
import { Chips } from 'primereact/chips';
import { Panel } from 'primereact/panel';
import { RadioButton } from 'primereact/radiobutton';
import Switch from '@material-ui/core/Switch';

// Add Locale
addLocale('pt-BR', {
    firstDayOfWeek: 1,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar'
});

locale('pt-BR');

import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import Person from "@material-ui/icons/Person";
import View from '@material-ui/icons/Visibility';
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import ZoomInIcon from '@material-ui/icons/ZoomIn';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Table from "components/Table/Table.js";
// import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

// Load Actions
import * as AppActions from 'Store/Actions/App'
import * as ProviderActions from 'Store/Actions/Provider'

const useStyles = makeStyles(styles);

const Services = (props) => {

// set vars from props
const { 
	appLoading,
	setAppLoading,
	buttonLoading
} = props;		


const [value, setValue] = React.useState(0);
const [contentVisible, setContentVisible] = React.useState(false); 

// console.log("[providers]: "+JSON.stringify(providers,null,2));
	
const classes = useStyles();

// Handle Tab changes
const handleChange = (event, newValue) => {
	
// Check which TAB was picked
//
		
setValue(newValue);

};

 
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

	
		
// set Use of history for PUSH 
let history = useHistory();

// Tasks to execute when mount component
React.useEffect(() => {
	
	setTimeout(function() {
			
		setAppLoading(false);
		setContentVisible(true);		
		
	},1000);

    return () => {
     setAppLoading(true);
    };	
	
}, []);

	
if(!contentVisible) {	  
	return (
		<div style={{width:"100%",height:"80vh",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
		
			<div style={{display:"flex",flex:1,alignItems:"center",justifyContent:"center"}}>
			
				<CircularProgress color="primary" />
				<div style={{fontSize:"18px",marginLeft:"15px"}}> Carregando dados...</div>
			
			</div>
		
		</div>
	)
  }

if(contentVisible) {
  return ( 
  <>	
   <Box ml={3} mb={5}>
   
	 <Breadcrumbs aria-label="breadcrumb">
	  <Typography color="textSecondary">Área Gerencial</Typography>
	  <Typography color="textPrimary">Cadastro de serviços</Typography>
	</Breadcrumbs>
	
	<Divider />
	
	<h3 style={{marginTop:"30px"}}>Cadastro de serviços</h3>
	<h5>Cadastro de serviços oferecidos na plataforma. Se desejar filtre ou ordene a busca para encontrar um serviço específico. </h5>
		
	</Box>
  
    <GridContainer>
	
      <GridItem xs={12}>
	  
		  <Tabs
			value={value}
			onChange={handleChange}
			indicatorColor="primary"
			textColor="primary"
		  >
			<Tab label="Ativos" />
			<Tab label="Inativos" />
		  </Tabs>
     	  <Divider />
		  
			<TabPanel value={value} index={0}>
			
					<Tab1Content
						setAppLoading={setAppLoading}
						setContentVisible={setContentVisible}
					/>
					
			</TabPanel>
				  
			<TabPanel value={value} index={1}>
										
					<Tab2Content
						setAppLoading={setAppLoading}
						setContentVisible={setContentVisible}
					/>
					
			</TabPanel>		
	   
	  </GridItem>
	  
    </GridContainer>
	</>
  );
}

return (null);

}

const mapStateToProps = store => ({
  buttonLoading:store.appReducer.buttonLoading
 });

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions }, dispatch);

// Tab1Content Object
export const Tab1Content = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {

// set vars from props
const { 
	appLoading,
	setAppLoading,
	retrieveServices,
	buttonLoading
} = props;		

// Set classes
const classes = useStyles();	

// useRef
var dt = useRef(null);

// Scope data
const [servicesRows, setServicesRows] = useState([]);

// Filter fields
const [filterByName, setFilterByName] = useState("");
const [filterByEmail, setFilterByEmail] = useState("");
const [filterByContactName, setFilterByContactName] = useState("");
const [filterByCompanyDocument, setFilterByCompanyDocument] = useState("");
const [filterByDocument, setFilterByDocument] = useState("");
const [filterByCreatedAt, setFilterByCreatedAt] = useState("");
const [filterByStatus, setFilterByStatus] = useState(null); 

// Dialog control
const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico"); 

// Service Dialog control
const [alertService, setAlertService] = React.useState(false); 
const [alertServiceUpdate, setAlertServiceUpdate] = React.useState(false); 
const [serviceId, setServiceId] = React.useState(null); 

// DataGrid Params
const [loading, setLoading] = useState(false);
const [parentLoading, setParentLoading] = useState(false);

const [totalRecords, setTotalRecords] = useState(0);
const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
		filters:null,
		sortField:"createdAt",
		sortOrder:1
    });


React.useEffect(() => {

	loadLazyData();

},[lazyParams]);

const loadLazyData = () => {
	
	setLoading(true);
	
	// Retrieve List of Services
	retrieveServicesAction();
}

const onPage = (event) => {
	let _lazyParams = { ...lazyParams, ...event };
	setLazyParams(_lazyParams);
}

const onSort = (event) => {	
	let _lazyParams = { ...lazyParams, ...event };
	setLazyParams(_lazyParams);
}

const onFilter = (event) => {
	let _lazyParams = { ...lazyParams, ...event };
	_lazyParams['first'] = 0;
	setLazyParams(_lazyParams);
}

// ToolBar Content  
const leftToolbarTemplate = () => {
	return (
		<React.Fragment>
			
		</React.Fragment>
	)
}

const rightToolbarTemplate = () => {
	return (
		<React.Fragment>
			
		</React.Fragment>
	)
}  
  
// Header Template  
const header = (
	<div className="table-header" style={{height:"40px"}}>
		<h5 style={{float:"left"}}> Serviços ativos </h5>
		<span style={{float:"right"}}>
			<Button 
				onClick={() => { setAlertService(true); }}
			 >
			  Novo Serviço
			</Button>			
		</span>			
	</div>
);	

// Column Name Body Template  
const nameBodyTemplate = (rowData) => {
	
	var imageFlag = false;
	if (rowData.icon) {
		var imageFlag = true;
	} // end if	
	
	// Select Region 1 from price
	var array = rowData.Price; // clone
	var index = array.filter(item => item.ServicePriceRegionId == 1);
	var filteredItem = index[0];
	
	return (
		<React.Fragment>
			<span className="p-column-title">Nome Serviço
			</span>
			<div style={{display:"flex",flexDirection:"rows",justifyContent:"center",alignItems:"center"}}>
				<div style={{marginRight:"20px"}}>
					{imageFlag &&
					<img src={rowData.icon} style={{width:"auto",height:"32px",maxWidth:"58px"}} />
					}
					
					{!imageFlag &&
					<span>-</span>
					}
				</div>
				
				<div style={{flex:1}}>
					<div style={{fontWeight:"bold",marginTop:"10px",marginBottom:"5px"}}>
					{rowData.name} 
					
					{rowData.highlight == 1 &&
					<>
						<i style={{color:"gold",marginLeft:"10px"}} className="pi pi-star"></i>
					</>
					}					
					
					</div>
					<div style={{marginBottom:"10px"}}> {rowData.shortDescription}</div>
					<div style={{marginBottom:"10px"}}>
						<span className="product-badge status-info" style={{marginRight:"5px"}}>R$ {filteredItem.price+",00"}</span>
						<span className="product-badge status-info" style={{marginRight:"5px"}}>[!] R$ {filteredItem.urgentTax+",00"}</span>
						<span className="product-badge status-info" style={{marginRight:"5px"}}>{rowData.effort} {rowData.effortUnity}</span>			
					</div>	
				</div>				
			</div>
		</React.Fragment>
	);
}  

// Column Name Body Template  
const categoriesBodyTemplate = (rowData) => {
	
	var categoriesList = "";
	for (var i = 0;i < rowData.ServiceCategories.length; i++) {
		categoriesList = categoriesList+"<div>"+rowData.ServiceCategories[i].breadcrumb+"</div><hr style='margin-top:5px;margin-bottom:5px' />";
	} // end for
	
	return (
		<React.Fragment>
			<span className="p-column-title">Categorias</span>
			 <div style={{fontSize:"12px"}} dangerouslySetInnerHTML={{__html: categoriesList}}></div>
		</React.Fragment>
	);
}  

// Column CreatedAt Body Template  
const createdBodyTemplate = (rowData) => {
	return (
		<React.Fragment>
			<span className="p-column-title">Criado em</span>
			{moment(rowData.createdAt).format("DD/MM/YYYY")}
		</React.Fragment>
	);
}	

// Column Activation Status Body Template  
const statusBodyTemplate = (rowData) => {
	 
	var activationStatus = "ATIVADO";
	var activationStatusLabel = "Ativo";	 
	 
	return (
	<>
	<span className={`product-badge status-${activationStatus.toLowerCase()}`}>{activationStatusLabel}</span>
	</>
	)
}	

// Template for Activation Status DropDown List
const statusItemTemplate = (option) => {	
	return <span className={`product-badge status-${option.id.toLowerCase()}`}>{option.label}</span>;
}	

// Column Actions Body Template  
// NEED TO ADD ROW ID AND ONCLICK EVENT
const actionBodyTemplate = (rowData) => {
	return (
		<React.Fragment>
			<Button label="Editar" icon="pi pi-pencil" className="p-button-outlined p-button-secondary p-button-sm" style={{margin:"3px"}} onClick={() => { openUpdateDialog(rowData.id) }} />
		</React.Fragment>
	);
}	

const filterDate = (value, filter) => {
	if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
		return true;
	}

	if (value === undefined || value === null) {
		return false;
	}

	return value === formatDate(filter);
}

const formatDate = (date) => {
	let month = date.getMonth() + 1;
	let day = date.getDate();

	if (month < 10) {
		month = '0' + month;
	}

	if (day < 10) {
		day = '0' + day;
	}

	return date.getFullYear() + '-' + month + '-' + day;
}		

// Apply Filter
const applyFilterAction = () => {
	
	// Check if any of the filters are not equal to NULL
	if (!filterByName && !filterByEmail && !filterByContactName && !filterByCompanyDocument && !filterByDocument && !filterByCreatedAt && !filterByStatus) {
		setAlertTitle("Verifique as informações");
		setAlertBody("Para aplicar o filtro você tem que ao menos selecionar ou preencher algum item para filtrar.");
		setAlert(true);
		return;
	} // end if
	
	var filters = {};
	filters.realName = filterByName ? filterByName:null;
	filters.email = filterByEmail ? filterByEmail:null;
	filters.completeName = filterByContactName ? filterByContactName:null;
	filters.document = filterByCompanyDocument ? filterByCompanyDocument:null;
	filters.personalDocument = filterByDocument ? filterByDocument:null;
	filters.createdAt = filterByCreatedAt ? filterByCreatedAt:null;
	filters.status = filterByStatus ? filterByStatus.code:null;
	
	// Apply filter on Data from service 
	let _lazyParams = { ...lazyParams, filters:filters };
	setLazyParams(_lazyParams);
	
}

// Reset Filter
const resetFilterAction = () => {
	
	setFilterByName("");
	setFilterByEmail("");
	setFilterByContactName("");
	setFilterByCompanyDocument("");
	setFilterByDocument("");
	setFilterByCreatedAt("");
	setFilterByStatus("");
	setFilterByStatus(null);
	
	var filters = null;
	
	// Apply filter on Data from service 
	let _lazyParams = { ...lazyParams, filters:filters };
	setLazyParams(_lazyParams);	
	
	// Load Data from service with defautl option
	// ...
}

// Filter fields and action buttons

	// Name Filter
	const nameFilter = <InputText style={{width:"100%"}} type="search" value={filterByName} onChange={(e) => setFilterByName(e.target.value)} className="p-column-filter" placeholder="Filtrar por nome ou descrição" />;	
	
	// CreatedAt Filter
	const dateFilter = <Calendar locale="pt-BR" showButtonBar value={filterByCreatedAt} onChange={(e) => setFilterByCreatedAt(e.target.value)} dateFormat="dd/mm/yy" className="p-column-filter" placeholder="Filtrar por data"/>;	

	// Set Status Dropdown Content
	const statuses = [{"id":"BLOQUEADO","label":"Complemento pendente","code":1},{"id":"REVIEW","label":"Validação pendente","code":2},{"id":"ATIVADO","label":"Completo","code":3}];

	// Activation Status Filter
	const statusFilter = <Dropdown value={filterByStatus} options={statuses} onChange={(e) => setFilterByStatus(e.target.value)} itemTemplate={statusItemTemplate} placeholder="Selecione o Status" className="p-column-filter" showClear />;

	// Action Buttons 
	const actionFilter = <><Button label="Aplicar Filtro" icon="pi pi-check" className="p-button-outlined p-button-primary" onClick={() => applyFilterAction()} style={{marginRight:"5px"}} /> {lazyParams.filters && <Button title="Limpar Filtros" icon="pi  pi-filter-slash" className=" p-button-info p-button-outlined" onClick={() => resetFilterAction()} />}</>
		  

// Dialog Footer
const renderFooter = () => {
	return (
		<div>
			<Button label="FECHAR" style={{marginTop:"20px"}}  icon="pi pi-check" className="p-button-outlined p-button-secondary p-button-sm" onClick={() => {setAlert(false)} } />
		</div>
	);
}		
		
// Tasks to execute when mount component
React.useEffect(() => {

    return () => {
     setAppLoading(true);
    };			
}, []);
	
const retrieveServicesAction = () => {

	var data = {
		status:[1],
		...lazyParams
	}
	var propsParam = {
		setServicesRows:setServicesRows,
		setLoading:setLoading,
		setTotalRecords:setTotalRecords
	}	
	retrieveServices(data,propsParam);

} // end 

// Open update dialog with instance id defined
const openUpdateDialog = (id) => {

	setServiceId(id);
	setAlertServiceUpdate(true);

} // end 

// set Use of history for PUSH 
let history = useHistory();
	
	return (
	<>
		<Backdrop style={{zIndex:"999999",flexDirection:"column"}} open={parentLoading} onClick={()=>{}}>
		  <CircularProgress style={{zIndex:"9999999",color:"#FFF"}} /><br />
		  <div style={{color:"#FFF"}}>Processando...aguarde!</div>
		</Backdrop>	
		
		<Card>
			  <CardHeader color="info" icon>
				<CardIcon color="info">
				  <Assignment />
				</CardIcon>
				<h4 className={classes.cardIconTitle}>Cadastros ativos</h4>
			  </CardHeader>
	
			  <CardBody>	  

				<div className="datatable-responsive-demo" style={{marginTop:"20px"}}>
				
					<div className="card">

						<DataTable ref={dt} value={servicesRows} className="p-datatable-responsive-demo" rows={lazyParams.rows} header={header} paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords}" rowsPerPageOptions={[10,20,50]} emptyMessage="Nenhum serviço encontrado." lazy paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage} onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder} onFilter={true} filters={lazyParams.filters} loading={loading} >
							
							<Column field="name" style={{width:"40%"}} header="Nome e Descrição" body={nameBodyTemplate} sortable filter filterPlaceholder="Filtrar por nome ou descrição" sortable filterElement={nameFilter} />
							
							<Column  header="Categorias" body={categoriesBodyTemplate} />

							<Column header="Status" body={statusBodyTemplate} />
							
							<Column body={actionBodyTemplate} filter filterElement={actionFilter} ></Column>
														
						</DataTable>						
						
					</div>
				</div>
								
			  </CardBody>
			</Card>	
			
			<NewService
				alertService={alertService}
				setAlertService={setAlertService}
				loadLazyData={loadLazyData}
				setAlertTitle={setAlertTitle}
				setAlertBody={setAlertBody}
				setAlert={setAlert}
				setParentLoading={setParentLoading}
			/>
			
			<UpdateService
				alertServiceUpdate={alertServiceUpdate}
				setAlertServiceUpdate={setAlertServiceUpdate}
				loadLazyData={loadLazyData}
				setAlertTitle={setAlertTitle}
				setAlertBody={setAlertBody}
				setAlert={setAlert}
				setParentLoading={setParentLoading}
				serviceId={serviceId}
			/>

			<Dialog header={alertTitle} visible={alert} style={{ width: '50vw' }} footer={renderFooter()} onHide={() => setAlert(false)} draggable={false} dismissableMask={true} closable={false} >
				<p>{alertBody}</p>
			</Dialog> 
			
	</>	
	);
	
});

// Tab2Content Object
export const Tab2Content = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {

// set vars from props
const { 
	appLoading,
	setAppLoading,
	retrieveServices,
	buttonLoading
} = props;		

// Set classes
const classes = useStyles();	

// useRef
var dt = useRef(null);

// Scope data
const [servicesRows, setServicesRows] = useState([]);

// Filter fields
const [filterByName, setFilterByName] = useState("");
const [filterByEmail, setFilterByEmail] = useState("");
const [filterByContactName, setFilterByContactName] = useState("");
const [filterByCompanyDocument, setFilterByCompanyDocument] = useState("");
const [filterByDocument, setFilterByDocument] = useState("");
const [filterByCreatedAt, setFilterByCreatedAt] = useState("");
const [filterByStatus, setFilterByStatus] = useState(null); 

// Dialog control
const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico"); 

// Service Dialog control
const [alertService, setAlertService] = React.useState(false); 
const [alertServiceUpdate, setAlertServiceUpdate] = React.useState(false); 
const [serviceId, setServiceId] = React.useState(null); 

// DataGrid Params
const [loading, setLoading] = useState(false);
const [parentLoading, setParentLoading] = useState(false);

const [totalRecords, setTotalRecords] = useState(0);
const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
		filters:null,
		sortField:"createdAt",
		sortOrder:1
    });

React.useEffect(() => {

	loadLazyData();

},[lazyParams]);
 
const loadLazyData = () => {
	
	setLoading(true);
	
	// Retrieve List of Services
	retrieveServicesAction();
}

const onPage = (event) => {
	let _lazyParams = { ...lazyParams, ...event };
	setLazyParams(_lazyParams);
}

const onSort = (event) => {	
	let _lazyParams = { ...lazyParams, ...event };
	setLazyParams(_lazyParams);
}

const onFilter = (event) => {
	let _lazyParams = { ...lazyParams, ...event };
	_lazyParams['first'] = 0;
	setLazyParams(_lazyParams);
}
  
// ToolBar Content  
const leftToolbarTemplate = () => {
	return (
		<React.Fragment>
			
		</React.Fragment>
	)
}

const rightToolbarTemplate = () => {
	return (
		<React.Fragment>
			
		</React.Fragment>
	)
}    
  
// Header Template  
const header = (
	<div className="table-header" style={{height:"40px"}}>
		<h5 style={{float:"left"}}> Serviços inativos ou excluídos </h5>
		<span style={{float:"right"}}>
			<Button 
				onClick={() => setAlertService(true)}
			 >
			  Novo Serviço
			</Button>			
		</span>		
	</div>
);	
  
// Column Name Body Template  
const nameBodyTemplate = (rowData) => {
	
	var imageFlag = false;
	if (rowData.icon) {
		var imageFlag = true;
	} // end if	
	
	// Select Region 1 from price
	var array = rowData.Price; // clone
	var index = array.filter(item => item.ServicePriceRegionId == 1);
	var filteredItem = index[0];
	
	return (
		<React.Fragment>
			<span className="p-column-title">Nome Serviço</span>
			<div style={{display:"flex",flexDirection:"rows",justifyContent:"center",alignItems:"center"}}>
				<div style={{marginRight:"20px"}}>
					{imageFlag &&
					<img src={rowData.icon} style={{width:"auto",height:"32px",maxWidth:"58px"}} />
					}
					
					{!imageFlag &&
					<span>-</span>
					}
				</div>
				
				<div style={{flex:1}}>
					<div style={{fontWeight:"bold",marginTop:"10px",marginBottom:"5px"}}>
					{rowData.name}
					
					{rowData.highlight == 1 &&
					<>
						<i style={{color:"gold",marginLeft:"10px"}} className="pi pi-star"></i>
					</>
					}						
					
					</div>
					<div style={{marginBottom:"10px"}}> {rowData.shortDescription}</div>
					<div style={{marginBottom:"10px"}}>
						<span className="product-badge status-info" style={{marginRight:"5px"}}>R$ {filteredItem.price+",00"}</span>
						<span className="product-badge status-info" style={{marginRight:"5px"}}>[!] R$ {filteredItem.urgentTax+",00"}</span>
						<span className="product-badge status-info" style={{marginRight:"5px"}}>{rowData.effort} {rowData.effortUnity}</span>			
					</div>	
				</div>				
			</div>
		</React.Fragment>
	);
}  

// Column Name Body Template  
const categoriesBodyTemplate = (rowData) => {
	
	var categoriesList = "";
	for (var i = 0;i < rowData.ServiceCategories.length; i++) {
		categoriesList = categoriesList+"<div>"+rowData.ServiceCategories[i].breadcrumb+"</div><hr style='margin-top:5px;margin-bottom:5px' />";
	} // end for
	
	return (
		<React.Fragment>
			<span className="p-column-title">Categorias</span>
			 <div style={{fontSize:"12px"}} dangerouslySetInnerHTML={{__html: categoriesList}}></div>
		</React.Fragment>
	);
}  

// Column CreatedAt Body Template  
const createdBodyTemplate = (rowData) => {
	return (
		<React.Fragment>
			<span className="p-column-title">Criado em</span>
			{moment(rowData.createdAt).format("DD/MM/YYYY")}
		</React.Fragment>
	);
}	

// Column Activation Status Body Template  
const statusBodyTemplate = (rowData) => {

	if (rowData.status == 0) {
		var activationStatus = "BLOQUEADO";
		var activationStatusLabel = "Inativo";
	} // end if
	else if (rowData.status == 9) {
		var activationStatus = "ENCERRADO";
		var activationStatusLabel = "Excluído";
	} // end if
	else  {
		var activationStatus = "ENCERRADO";
		var activationStatusLabel = "Indefinido";
	} // end if	

	return (
	<>
	<span className={`product-badge status-${activationStatus.toLowerCase()}`}>{activationStatusLabel}</span><br />
		{rowData.status == 1 && <small style={{color:"#666"}}> por {rowData.activationMethod}</small>}
	</>
	)
}	

// Template for Activation Status DropDown List
const statusItemTemplate = (option) => {	
	return <span className={`product-badge status-${option.id.toLowerCase()}`}>{option.label}</span>;
}	

// Column Actions Body Template  
// NEED TO ADD ROW ID AND ONCLICK EVENT
const actionBodyTemplate = (rowData) => {
	return (
		<React.Fragment>
			<Button label="Editar" icon="pi pi-pencil" className="p-button-outlined p-button-secondary p-button-sm" style={{margin:"3px"}} onClick={() => { openUpdateDialog(rowData.id) }} />
		</React.Fragment>
	);
}	

const filterDate = (value, filter) => {
	if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
		return true;
	}

	if (value === undefined || value === null) {
		return false;
	}

	return value === formatDate(filter);
}

const formatDate = (date) => {
	let month = date.getMonth() + 1;
	let day = date.getDate();

	if (month < 10) {
		month = '0' + month;
	}

	if (day < 10) {
		day = '0' + day;
	}

	return date.getFullYear() + '-' + month + '-' + day;
}		

// Apply Filter
const applyFilterAction = () => {
	
	// Check if any of the filters are not equal to NULL
	if (!filterByName && !filterByEmail && !filterByContactName && !filterByCompanyDocument && !filterByDocument && !filterByCreatedAt && !filterByStatus) {
		setAlertTitle("Verifique as informações");
		setAlertBody("Para aplicar o filtro você tem que ao menos selecionar ou preencher algum item para filtrar.");
		setAlert(true);
		return;
	} // end if
	
	var filters = {};
	filters.realName = filterByName ? filterByName:null;
	filters.email = filterByEmail ? filterByEmail:null;
	filters.completeName = filterByContactName ? filterByContactName:null;
	filters.document = filterByCompanyDocument ? filterByCompanyDocument:null;
	filters.personalDocument = filterByDocument ? filterByDocument:null;
	filters.createdAt = filterByCreatedAt ? filterByCreatedAt:null;
	filters.status = filterByStatus ? filterByStatus.code:null;
	
	// Apply filter on Data from service 
	let _lazyParams = { ...lazyParams, filters:filters };
	setLazyParams(_lazyParams);
	
}

// Reset Filter
const resetFilterAction = () => {
	
	setFilterByName("");
	setFilterByEmail("");
	setFilterByContactName("");
	setFilterByCompanyDocument("");
	setFilterByDocument("");
	setFilterByCreatedAt("");
	setFilterByStatus("");
	setFilterByStatus(null);
	
	var filters = null;
	
	// Apply filter on Data from service 
	let _lazyParams = { ...lazyParams, filters:filters };
	setLazyParams(_lazyParams);	
	
	// Load Data from service with defautl option
	// ...
}

// Filter fields and action buttons

	// Name Filter
	const nameFilter = <InputText style={{width:"100%"}} type="search" value={filterByName} onChange={(e) => setFilterByName(e.target.value)} className="p-column-filter" placeholder="Filtrar por nome ou descrição" />;	
	
	// CreatedAt Filter
	const dateFilter = <Calendar locale="pt-BR" showButtonBar value={filterByCreatedAt} onChange={(e) => setFilterByCreatedAt(e.target.value)} dateFormat="dd/mm/yy" className="p-column-filter" placeholder="Filtrar por data"/>;	

	// Set Status Dropdown Content
	const statuses = [{"id":"BLOQUEADO","label":"Inativo","code":1},{"id":"ENCERRADO","label":"Excluído","code":9}];

	// Activation Status Filter
	const statusFilter = <Dropdown value={filterByStatus} options={statuses} onChange={(e) => setFilterByStatus(e.target.value)} itemTemplate={statusItemTemplate} placeholder="Selecione o Status" className="p-column-filter" showClear />;

	// Action Buttons 
	const actionFilter = <><Button label="Aplicar Filtro" icon="pi pi-check" className="p-button-outlined p-button-primary" onClick={() => applyFilterAction()} style={{marginRight:"5px"}} /> {lazyParams.filters && <Button title="Limpar Filtros" icon="pi  pi-filter-slash" className=" p-button-info p-button-outlined" onClick={() => resetFilterAction()} />}</>
		

// Dialog Footer
const renderFooter = () => {
	return (
		<div>
			<Button label="OK" icon="pi pi-check" onClick={() => setAlert(false)} />
		</div>
	);
}		
		
// Tasks to execute when mount component
React.useEffect(() => {

	// on component mount
	//console.log("[MOUNTED COMPONENT...]--->");

    return () => {
     setAppLoading(true);
    };			
}, []);

		
const retrieveServicesAction = () => {

	var data = {
		status:[0,9],
		...lazyParams
	}
	var propsParam = {
		setServicesRows:setServicesRows,
		setLoading:setLoading,
		setTotalRecords:setTotalRecords
	}	
	retrieveServices(data,propsParam);

} // end 

const openUpdateDialog = (id) => {

	setServiceId(id);
	setAlertServiceUpdate(true);

} // end 


	
// set Use of history for PUSH 
let history = useHistory();	
	
	return (
	<>
		<Card>
			  <CardHeader color="info" icon>
				<CardIcon color="info">
				  <Assignment />
				</CardIcon>
				<h4 className={classes.cardIconTitle}>Cadastros inativos ou excluídos</h4>
			  </CardHeader>
	
			  <CardBody>	  

				<div className="datatable-responsive-demo" style={{marginTop:"20px"}}>
				
					<div className="card">
					
					{/*<Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>*/}
					
						<DataTable ref={dt} value={servicesRows} className="p-datatable-responsive-demo" rows={lazyParams.rows} header={header} paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords}" rowsPerPageOptions={[10,20,50]} emptyMessage="Nenhum serviço encontrado." lazy paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage} onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder} onFilter={console.log("filtering...")} filters={lazyParams.filters} loading={loading} >
								
							<Column style={{width:"40%"}} header="Nome e Descrição" body={nameBodyTemplate} sortable filter filterPlaceholder="Filtrar por nome ou descrição" sortable filterElement={nameFilter} />
							
							<Column  header="Categorias" body={categoriesBodyTemplate}  />

							<Column header="Status" body={statusBodyTemplate} filter filterElement={statusFilter} />
							
							<Column body={actionBodyTemplate} filter filterElement={actionFilter} ></Column>
							
						</DataTable>
							
					</div>
				</div>
								
			  </CardBody>
			</Card>	
			
			<NewService
				alertService={alertService}
				setAlertService={setAlertService}
				loadLazyData={loadLazyData}
				setAlertTitle={setAlertTitle}
				setAlertBody={setAlertBody}
				setAlert={setAlert}
				setParentLoading={setParentLoading}
			/>
			
			<UpdateService
				alertServiceUpdate={alertServiceUpdate}
				setAlertServiceUpdate={setAlertServiceUpdate}
				loadLazyData={loadLazyData}
				setAlertTitle={setAlertTitle}
				setAlertBody={setAlertBody}
				setAlert={setAlert}
				setParentLoading={setParentLoading}
				serviceId={serviceId}
			/>

			<Dialog header={alertTitle} visible={alert} style={{ width: '50vw' }} footer={renderFooter()} onHide={() => setAlert(false)} draggable={false} dismissableMask={true} closable={false} >
				<p>{alertBody}</p>
			</Dialog> 		
	</>	
	);
	
});

// NewService dialog component
export const NewService = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
		
// Set classes
const classes = useStyles();		

// set vars from props
const { 
	alertService,
	setAlertService,
	loadLazyData,
	buttonLoading,
	setAlertTitle,
	setAlertBody,
	setAlert,
	retrieveRegions,
	retrieveCategoriesTree,
	retrieveComissions,
	createService,
	setParentLoading
} = props;		

// Service Details 
const [serviceName, setServiceName] = useState(""); 
const [serviceShortDescription, setServiceShortDescription] = useState(""); 
const [serviceShortDescriptionCounter, setServiceShortDescriptionCounter] = useState(0); 
const [serviceDescription, setServiceDescription] = useState(""); 
const [serviceDescriptionCounter, setServiceDescriptionCounter] = useState(0); 
const [serviceWarrantyTerm, setServiceWarrantyTerm] = useState("O serviço tem a garantia de até 90 dias após a prestação do mesmo. A garantia de serviço contempla problemas ocasionados com o escopo do serviço."); 
const [serviceWarrantyTermCounter, setServiceWarrantyTermCounter] = useState(0); 
const [serviceTags, setServiceTags] = useState(""); 
const [serviceEffort, setServiceEffort] = useState(null); 
const [serviceEffortUnity, setServiceEffortUnity] = useState(""); 
const [serviceStatus, setServiceStatus] = useState(0); 
const [serviceHighlight, setServiceHighLight] = useState(false); 

// Set default comissionSum
const [comissionsRows, setComissionsRows] = useState([]);

// Set regions options and loading
const [regionsRows, setRegionsRows] = useState([]);
const [regionsLoading, setRegionsLoading] = useState(true);

// Set categories options and loading
const [categoriesTreeRows, setCategoriesTreeRows] = useState([]);
const [parentCategoriesTreeRows, setParentCategoriesTreeRows] = useState([]);
const [categoriesLoading, setCategoriesLoading] = useState(true);

const [checkedRegion, setCheckedRegion] = useState({});
const [regionValues, setRegionValues] = useState({});
const [categories, setCategories] = useState({});

const [comissionGlobal, setComissionGlobal] = useState("default");
const [comissionType, setComissionType] = useState("percentage");
const [comissionPlatform, setComissionPlatform] = useState(null);
const [comissionProvider, setComissionProvider] = useState(null);
const [comissionPartner, setComissionPartner] = useState(null);

const [saving, setSaving] = useState(false); 

const [iconImage, setIconImage] = useState({}); 
const [headerImage, setHeaderImage] = useState({}); 

const [iconImageType, setIconImageType] = useState("uploadedImage");
const [iconImageUrl, setIconImageUrl] = useState(""); 

const [headerImageType, setHeaderImageType] = useState("uploadedImage");
const [headerImageUrl, setHeaderImageUrl] = useState(""); 

// Options for regions status
const regions = [
        {name: 'Desabilitado', value: 0},
        {name: 'Habilitado', value: 1}
];

// Options for comissions types
const comissionsTypes = [
        {name: 'Percentual', value: "percentage"},
        {name: 'Valor Fixo', value: "fixed"}
];

// Options for comissions global options
const comissionGlobalOptions = [
        {name: 'Padrão', value: "default"},
        {name: 'Personalizada', value: "custom"}
];

// Options for unities of time	
const unities = [
    {label: 'Minutos', value: 'minutos'},
    {label: 'Horas', value: 'horas'},
	{label: 'Hora', value: 'hora'}
];	

// Options for status
const statuses = [
    {label: 'Inativo', value: '0'},
    {label: 'Ativo', value: '1'}
];	
	
// Execute when dialog opens
React.useEffect(() => {

	// Only r]uns when  dialog opens
	if (alertService === true) {
				
		// Retrieve regions and set to screen
		var propsParam = {
			setRegionsRows:setRegionsRows,
			setRegionsLoading:setRegionsLoading,
			setCheckedRegion:setCheckedRegion,
			setRegionValues:setRegionValues
		}	
		retrieveRegions(propsParam);	
		
		// Retrieve Categories and set to screen
		var propsParam = {
			setCategoriesTreeRows:setCategoriesTreeRows,
			setParentCategoriesTreeRows:setParentCategoriesTreeRows,
			setCategoriesLoading:setCategoriesLoading
		}	
		retrieveCategoriesTree(propsParam);

		// Retrieve Default comissions
		var propsParam = {
			setComissionsRows:setComissionsRows
		}	
		retrieveComissions(propsParam);
		
	} // end if
	
	// Reset all fields when closes or open dialog
	// ...
	
	// Reset images when open interface
	setIconImage({});
	setHeaderImage({});
		
},[alertService]);

// Set status of selected region (enabled or disabled)
const setRegion = (regionId,value) => {

	var currentValue = {...checkedRegion};
	currentValue[""+regionId+""] = value;
	setCheckedRegion(currentValue);	
	
} // end set Region		

// Open alert when save button is disabled
const openAlert = (section) => {

	if (section == 'service') {
		setAlertTitle("Verifique as informações");
		setAlertBody("Preencha os dados do serviço marcados com * para prosseguir com o cadastro.");
		setAlert(true);
	} // end if
	else if (section == 'images') {
		setAlertTitle("Verifique as imagens");
		setAlertBody("Você deve definir a imagem do ícone e do header do serviço enviando uma imagem ou definindo uma url externa para prosseguir com o cadastro.");
		setAlert(true);
	} // end if	
	else if (section == 'imagesurl') {
		setAlertTitle("Verifique a URL das imagens");
		setAlertBody("A URL informada para uma ou mais imagem é inválida. Verifique e corrija para prosseguir.");
		setAlert(true);
	} // end if	
	else if (section == 'categories') {
		setAlertTitle("Verifique a categoria do serviço");
		setAlertBody("É necessário que defina ao menos uma categoria do serviço para prosseguir com o cadastro.");
		setAlert(true);
	} // end if	
	else if (section == 'primaryRegion') {
		setAlertTitle("Verifique o preço do serviço");
		setAlertBody("É necessário que defina o preço e taxa de urgência global do serviço para prosseguir com o cadastro.");
		setAlert(true);
	} // end if	
	else if (section == 'secondaryRegion') {
		setAlertTitle("Verifique o preço do serviço");
		setAlertBody("É necessário que defina o preço e taxa de urgência das regiões habilitadas do serviço para prosseguir com o cadastro.");
		setAlert(true);
	} // end if	
	else if (section == 'comission') {
		setAlertTitle("Verifique a comissão do serviço");
		setAlertBody("É necessário que define a comissão da plataforma e do prestador do serviço para prosseguir com o cadastro.");
		setAlert(true);
	} // end if		
	else if (section == 'comissionSum') {
		setAlertTitle("Verifique a comissão do serviço");
		setAlertBody("A soma percentual das comissões das partes é maior ou menor que 100%. Verifique.");
		setAlert(true);
	} // end if			
	else {
		setAlertTitle("Verifique as informações");
		setAlertBody("Preencha os dados mínimos marcados com * para prosseguir com o cadastro.");
		setAlert(true);
	} // end if	
			
} // end set Region		

// Handle file selector click
const handleClick = event => {
hiddenFileInput.current.click();
};

// Handle file selector click
const handleClickHeader = event => {
hiddenFileInputHeader.current.click();
};

// Store file input for selection
const hiddenFileInput = React.useRef(null);
const hiddenFileInputHeader = React.useRef(null);

// Set sent image to interface
const onChangeHandlerIcon=event=>{

	// set image
	var image = event.target.files[0];

	// Extract file specs
	var imageObj = {
		name:image.name,
		size:image.size,
		type:image.type,
		image: URL.createObjectURL(event.target.files[0]),
		base64image:event.target.files[0]
	}	
	
	// set final obj
	setIconImage(imageObj);

}
	
// Set sent image to interface
const onChangeHandlerHeader=event=>{

	// set image
	var image = event.target.files[0];

	// Extract file specs
	var imageObj = {
		name:image.name,
		size:image.size,
		type:image.type,
		image: URL.createObjectURL(event.target.files[0]),
		base64image:event.target.files[0]
	}	
	
	// set final obj
	setHeaderImage(imageObj);

}
		
// Heade template of icon image upload
const iconUploadHeaderTemplate = (options) => {
	
        const className = `${options.className} justify-content-start`;

        return (
            <div className={className}>
			
			<RadioButton name="iconImageType" value="uploadedImage" onChange={(e) => setIconImageType(e.value)} checked={iconImageType === 'uploadedImage'} style={{marginRight:"20px"}} />
				  
				<Button variant="contained" disabled={iconImageType === 'urlImage'} color="primary" component="span" onClick={handleClick}>
					Selecionar imagem
				</Button>

				<input
					accept="image/*"
					style={{display:"none"}}
					ref={hiddenFileInput}
					onChange={onChangeHandlerIcon}
					id="iconUploadButton"
					type="file"
				/>	
				
            </div>
        )
} // end function

// Heade template of header image upload
const headerUploadHeaderTemplate = (options) => {
	
        const className = `${options.className} justify-content-start`;

        return (
            <div className={className}>
			
			<RadioButton name="headerImageType" value="uploadedImage" onChange={(e) => setHeaderImageType(e.value)} checked={headerImageType === 'uploadedImage'} style={{marginRight:"20px"}} />
				  
				<Button variant="contained" disabled={headerImageType === 'urlImage'} color="primary" component="span" onClick={handleClickHeader}>
					Selecionar imagem
				</Button>

				<input
					accept="image/*"
					style={{display:"none"}}
					ref={hiddenFileInputHeader}
					onChange={onChangeHandlerHeader}
					id="headerUploadButton"
					type="file"
				/>	
				
            </div>
        )
} // end function

// Save instance method	
const save = () => {

	// Check if min SERVICE DETAILS fields are filled
	if (!serviceName || !serviceDescription || !serviceWarrantyTerm || !serviceEffort || !serviceEffortUnity) {
		openAlert('service');
		return;
	} // end if

	// IMAGES CHECK  
	// check if icon image is set
	if (iconImageType == 'uploadedImage') {
		if (!iconImage.image)  {
			openAlert('images');
			return;			
		} // end if
	} // end if
	else {
		if (iconImageUrl == "")  {
			openAlert('images');
			return;			
		} // end if	
		else {
			var pattern = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
			if (!pattern.test(iconImageUrl)) {
				openAlert('imagesurl');
				return;	
			} // end if
		} // end else		
	} // end else
		
	// check if icon image is set
	if (headerImageType == 'uploadedImage') {
		if (!headerImage.image)  {
			openAlert('images');
			return;			
		} // end if
	} // end if
	else {
		if (headerImageUrl == "")  {
			openAlert('images');
			return;			
		} // end if	
		else {
			var pattern = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
			if (!pattern.test(headerImageUrl)) {
				openAlert('imagesurl');
				return;	
			} // end if
		} // end else
	} // end else	

	// CATEGORIES Check
	if (Object.keys(categories).length === 0) {
		openAlert('categories');
		return;
	} // end if
	
	// REGIONS Check
	if (!regionValues['region1'].price || !regionValues['region1'].urgentTax) {
		openAlert('primaryRegion');
		return;		
	} // end if
	else {
		var regionsLoop = Object.keys(checkedRegion);
		for (var x = 0; x < regionsLoop.length; x++) {
			// if region is enabled
			if (checkedRegion[regionsLoop[x]] === 1) {
				if (!regionValues[regionsLoop[x]].price || !regionValues[regionsLoop[x]].urgentTax) {
					openAlert('secondaryRegion');
					return;						
				} // end if			
			} // end if	
		} // end for
	} // end else
	
	// COMISSION check
	if (comissionGlobal == "custom") {
		if (!comissionPlatform || !comissionProvider) {
			openAlert('comission');
			return;					
		} // end if
		else {
			if (comissionType == "percentage") {
				var total = parseInt(comissionPlatform) + parseInt(comissionProvider) + parseInt(comissionPartner ? comissionPartner : 0);				
				if (total != 100) {
					openAlert('comissionSum');
					return;						
				} // end if
			} // end if
		} // end else
	} // end if
	
	// ALL OK, save service
	
	// Turn on loading on button
	setSaving(true);	
	
	// Turn on overlay loading
	setParentLoading(true);
	
	// Prepare Data
	var data = {
		serviceName:serviceName,
		serviceShortDescription:serviceShortDescription,
		serviceDescription:serviceDescription,
		serviceWarrantyTerm:serviceWarrantyTerm,
		serviceTags:serviceTags,
		serviceEffort:serviceEffort,
		serviceEffortUnity:serviceEffortUnity,
		serviceStatus:serviceStatus,
		serviceHighlight:serviceHighlight,
		regionValues:regionValues,
		categories:categories,
		checkedRegion:checkedRegion,
		comissionGlobal:comissionGlobal,
		comissionType:comissionType,
		comissionPlatform:comissionPlatform,
		comissionProvider:comissionProvider,
		comissionPartner:comissionPartner,
		iconImage:iconImage,
		headerImage:headerImage,
		iconImageType:iconImageType,
		iconImageUrl:iconImageUrl,
		headerImageType:headerImageType,
		headerImageUrl:headerImageUrl,
		parentCategoriesTreeRows:parentCategoriesTreeRows		
	}
	// Prepare Props
	var propsParam = {
		setSaving:setSaving,
		setParentLoading:setParentLoading,
		loadLazyData:loadLazyData,
		setAlertTitle:setAlertTitle,
		setAlertBody:setAlertBody,
		setAlert:setAlert,
		setAlertService:setAlertService 
	}
	// Save
	createService(data,propsParam);

} // end function save
	
	return (
	<>
	<Dialog header="Novo serviço" visible={alertService} style={{ width: '70vw' }} footer={(<div>
			<Button label="FECHAR" disabled={saving} style={{marginTop:"20px"}} icon="pi pi-check" className="p-button-outlined p-button-secondary p-button-sm" onClick={() => {setAlertService(false)} } />
		</div>)} onHide={() => setAlertService(false)} draggable={false} dismissableMask={false} closable={saving ? false : true} closeOnEscape={false} >
				
				<GridContainer justify="center">
				  <GridItem xs={12} sm={12}>

					<Card>
					  <CardHeader color="info" icon>
						<CardIcon color="info">
						  <Assignment />
						</CardIcon>
						<h4 className={classes.cardIconTitle}>Cadastro de novo serviço</h4>
					  </CardHeader>
					  <CardBody>	 
						
						{/* Details Block */}
						<TableContainer component={Box} mt={2}>
						  <Table  aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Dados do Serviço</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>
							

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Nome do Serviço <sup style={{color:"red"}}>*</sup></TableCell>
								  <TableCell align="left"><InputText style={{width:"100%"}} value={serviceName} onChange={(e) => setServiceName(e.target.value)} maxLength="45" /></TableCell>
								</TableRow>
								
								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Descrição Curta</TableCell>
								  <TableCell align="left"><InputTextarea  style={{width:"100%"}} rows={2} cols={30} value={serviceShortDescription} onChange={(e) => { if (event.target.value.length > 100) { return; }; setServiceShortDescription(event.target.value); setServiceShortDescriptionCounter(event.target.value.length)} } autoResize />
								  <small  style={{float:"left"}}>Breve descrição do serviço (até 100 chars).</small> <small style={{float:"right",color:"red"}}>{serviceShortDescriptionCounter} em 100 chars</small>
								  </TableCell>
								</TableRow>			

								<TableRow >
								  <TableCell component="th" scope="row">Descrição <sup style={{color:"red"}}>*</sup></TableCell>
								  <TableCell align="left"><InputTextarea  style={{width:"100%"}} rows={5} cols={30} value={serviceDescription} onChange={(e) => { if (event.target.value.length > 255) { return; }; setServiceDescription(event.target.value);setServiceDescriptionCounter(event.target.value.length)}} autoResize />
								   <small  style={{float:"left"}}>Descrição longa do serviço (até 255 chars).</small> <small style={{float:"right",color:"red"}}>{serviceDescriptionCounter} em 255 chars</small>
								  
								  </TableCell>
								</TableRow>
								
								<TableRow >
								  <TableCell component="th" scope="row">Termo de garantia do serviço <sup style={{color:"red"}}>*</sup></TableCell>
								  <TableCell align="left"><InputTextarea  style={{width:"100%"}} rows={5} cols={30} value={serviceWarrantyTerm} onChange={(e) => {if (event.target.value.length > 1000) { return; };setServiceWarrantyTerm(event.target.value);setServiceWarrantyTermCounter(event.target.value.length) }} autoResize />
								   <small  style={{float:"left"}}>Insira o termo de garantia do serviço. (até 1000 chars).</small> <small style={{float:"right",color:"red"}}>{serviceWarrantyTermCounter} em 1000 chars</small>
								  </TableCell>
								</TableRow>			

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Tags</TableCell>
								  <TableCell align="left"><Chips value={serviceTags} onChange={(e) => setServiceTags(e.value)} separator="," />
								  <small className="block" style={{marginTop:"5px"}}>Defina uma ou mais tags relacionadas com o serviço. Digite e aperte enter por tag.</small>
								  </TableCell>
								</TableRow>	

								<TableRow >
								  <TableCell component="th" scope="row">Tempo do serviço <sup style={{color:"red"}}>*</sup></TableCell>
								  <TableCell align="left">
									  <InputNumber inputId="withoutgrouping" value={serviceEffort} onValueChange={(e) => setServiceEffort(e.value)} mode="decimal" placeholder="Tempo" useGrouping={false} style={{marginRight:"10px"}} />
									  <Dropdown value={serviceEffortUnity} options={unities} onChange={(e) => setServiceEffortUnity(e.value)} placeholder="Unidade"/>
								  </TableCell>
								</TableRow>

								<TableRow >
								  <TableCell component="th" scope="row">Serviço em destaque</TableCell>
								  <TableCell align="left">
									 <Switch
										checked={serviceHighlight}
										onChange={(event) => {
											setServiceHighLight(event.target.checked);
										}}											
										color="primary"
									  />									  
								  </TableCell>
								</TableRow>
								
								<TableRow >
								  <TableCell component="th" scope="row">Status <sup style={{color:"red"}}>*</sup></TableCell>
								  <TableCell align="left">
									  <Dropdown value={serviceStatus} options={statuses} onChange={(e) => setServiceStatus(e.value)} placeholder="Status"/>
								  </TableCell>
								</TableRow>

							</TableBody>
						  </Table>
						</TableContainer>
						
						{/* Image Block */}
						<TableContainer component={Box} mt={2}>
						  <Table  aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Imagens do Serviço <small style={{color:"red",marginLeft:"10px"}}>informação obrigatória </small></TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Imagem da ícone do Serviço <sup style={{color:"red"}}>*</sup>  <br /> <small>Defina se vai enviar uma imagem ou se vai colocar uma url da mesma. <br /><br /> Tamanho sugerido é 110px x 110px</small></TableCell>
								  <TableCell align="left">
																	  
						            <Panel headerTemplate={iconUploadHeaderTemplate}>
										
										{iconImage.image &&
											<div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
											
												<div style={{flex:1}}>
													<img src={iconImage.image} style={{width:"75px",height:"auto"}} />
												</div>
												
												<div style={{flex:5,marginLeft:"20px",fontSize:"12px"}}>
													Arquivo: {iconImage.name} <br /> 
													Tamanho: {iconImage.size} bytes  <br />
													Tipo: {iconImage.type}
												</div>
												
												<div style={{flex:1}}>
													<Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => setIconImage({})} />
												</div>										
																					
											</div>
										}
										
										{!iconImage.image &&
											<div>Selecione a imagem do ícone do serviço</div>
										}
										
									</Panel>

									<div style={{padding: "1rem",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
									
										<div style={{marginRight:"20px"}}>
											<RadioButton name="iconImageType" value="urlImage" onChange={(e) => setIconImageType(e.value)} checked={iconImageType === 'urlImage'} />
										</div>
										
										<div style={{flex:1}}>									
										
											<InputText disabled={iconImageType != 'urlImage'} style={{width:"100%"}} value={iconImageUrl} onChange={(e) => setIconImageUrl(e.target.value)}  placeholder="Endereço URL Imagem" />

										</div>
									
									</div>
									
								  
								  </TableCell>
								</TableRow>
								
								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Imagem do header do Serviço <sup style={{color:"red"}}>*</sup>  <br /> <small>Defina se vai enviar uma imagem ou se vai colocar uma url da mesma. <br /><br /> Tamanho obrigatório é 828px x 240px</small></TableCell>
								  <TableCell align="left">
																	  
						            <Panel headerTemplate={headerUploadHeaderTemplate}>
										
										{headerImage.image &&
											<div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
											
												<div style={{flex:1}}>
													<img src={headerImage.image} style={{width:"150px",height:"auto"}} />
												</div>
												
												<div style={{flex:5,marginLeft:"20px",fontSize:"12px"}}>
													Arquivo: {headerImage.name} <br /> 
													Tamanho: {headerImage.size} bytes  <br />
													Tipo: {headerImage.type}
												</div>
												
												<div style={{flex:1}}>
													<Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => setHeaderImage({})} />
												</div>										
																					
											</div>
										}
										
										{!headerImage.image &&
											<div>Selecione a imagem do header do serviço</div>
										}
										
									</Panel>

									<div style={{padding: "1rem",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
									
										<div style={{marginRight:"20px"}}>
											<RadioButton name="headerImageType" value="urlImage" onChange={(e) => setHeaderImageType(e.value)} checked={headerImageType === 'urlImage'} />
										</div>
										
										<div style={{flex:1}}>									
											
												<InputText disabled={headerImageType != 'urlImage'} style={{width:"100%"}} value={headerImageUrl} onChange={(e) => setHeaderImageUrl(e.target.value)} placeholder="Endereço URL Imagem" />
											
										</div>
									
									</div>						           
								   
								   
								  </TableCell>
								</TableRow>							

							</TableBody>
						  </Table>
						</TableContainer>						
						
						{/* Category Block */}
						<TableContainer component={Box} mt={2}>
						  <Table  aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Categorias do Serviço <small style={{color:"red",marginLeft:"10px"}}> obrigatório ao menos uma categoria </small></TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Marque uma ou mais categorias em que o serviço será apresentado</TableCell>
								  <TableCell align="left">
								  
									  <Tree value={categoriesTreeRows} selectionMode="checkbox" selectionKeys={categories} onSelectionChange={e => setCategories(e.value)} />
								  
								  </TableCell>
								</TableRow>
								

							</TableBody>
						  </Table>
						</TableContainer>
						
						{/* Price Block */}
						<TableContainer component={Box} mt={2}>
						  <Table aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Definição de preços do Serviço <small style={{color:"red",marginLeft:"10px"}}>preço global obrigatório </small></TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Defina o preço global do serviço em R$, assim como a taxa de urgência. <br /><br /> <small>Se desejar criar preços dinstintos por região, ative e informe.</small> </TableCell>
								  <TableCell align="left">
								  
									<Fieldset legend="Região Global" style={{marginBottom:"20px"}}>

										<div style={{marginTop:0,paddingTop:0,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",marginBottom:"20px"}}>
										
										  <div style={{width:"150px"}}>Preço do serviço <sup style={{color:"red"}}>*</sup></div>
										  
										  <div style={{flex:1}}>
											<InputNumber value={regionValues['region1'] ? regionValues['region1'].price: null} onValueChange={(e) => { var values = {...regionValues}; values['region1'].price = e.value; setRegionValues(values); } } mode="currency" currency="BRL" locale="pt-BR" />
										  </div>
										  
										</div>
										
										<div style={{marginTop:0,paddingTop:0,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
										
										  <div style={{width:"150px"}}>Taxa de urgência<sup style={{color:"red"}}>*</sup></div>
										  
										  <div style={{flex:1}}>
											<InputNumber value={regionValues['region1'] ? regionValues['region1'].urgentTax:null} onValueChange={(e) => { var values = {...regionValues}; values['region1'].urgentTax = e.value; setRegionValues(values); } } mode="currency" currency="BRL" locale="pt-BR" />
										  </div>
										  
										</div>										

									</Fieldset>
									
									
									{/* Regions Results from API */}
									{regionsRows.map((item) => {
										 return (
											<Fieldset legend={item.title} collapsed={true} toggleable key={item.id}  style={{marginBottom:"20px"}}>

												<div style={{marginTop:0,paddingTop:0,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",marginBottom:"20px"}}>
												
												  <div style={{width:"150px"}}>Status da região<sup style={{color:"red"}}>*</sup></div>
												  
												  <div style={{flex:1}}>
													 <SelectButton value={checkedRegion['region'+item.id+'']} optionLabel="name" options={regions} onChange={(e) => setRegion('region'+item.id+'',e.value)} />
												  </div>
												  
												</div>
												
												<div style={{marginTop:0,paddingTop:0,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",marginBottom:"20px"}}>
												
												  <div style={{width:"150px"}}>Preço do serviço <sup style={{color:"red"}}>*</sup></div>
												  
												  <div style={{flex:1}}>
													<InputNumber value={regionValues['region'+item.id+''].price} 
														onValueChange={(e) => { var values = {...regionValues}; values['region'+item.id+''].price = e.value; setRegionValues(values); } } mode="currency" currency="BRL" locale="pt-BR" />
												  </div>
												  
												</div>
												
												<div style={{marginTop:0,paddingTop:0,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
												
												  <div style={{width:"150px"}}>Taxa de urgência<sup style={{color:"red"}}>*</sup></div>
												  
												  <div style={{flex:1}}>
													<InputNumber value={regionValues['region'+item.id+''].urgentTax} 
														onValueChange={(e) => { var values = {...regionValues}; values['region'+item.id+''].urgentTax = e.value; setRegionValues(values); } } mode="currency" currency="BRL" locale="pt-BR" />
												  </div>
												  
												</div>	

											</Fieldset>
											)}
									 )}	
							
									
									{regionsRows.length === 0 && 
										<div> Nenhuma região cadastrada... </div>						
									}									
									
								  </TableCell>
								</TableRow>
								

							</TableBody>
						  </Table>
						</TableContainer>
						
						{/* Comission Block */}
						<TableContainer component={Box} mt={2}>
						  <Table aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Definição de comissões do Serviço <small style={{color:"red",marginLeft:"10px"}}>informação obrigatória</small></TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Confirme a comissão padrão da plataforma ou defina o tipo de comissão e os valores específicos para esse serviço.<br /><br /> <small>Plataforma e Prestador são obrigatórios, se for personalizado para esse serviço.</small> </TableCell>
								  <TableCell align="left">

									<div style={{marginTop:0,paddingTop:0,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",marginBottom:"20px"}}>
									
									  <div style={{width:"150px"}}>Comissão do serviço</div>
									  
									  <div style={{flex:1}}>
										 <SelectButton value={comissionGlobal} optionLabel="name" options={comissionGlobalOptions} onChange={(e) => setComissionGlobal(e.value)} />
									  </div>
									  
									</div>
								  
								  {comissionGlobal == "custom" &&
									<Fieldset legend="Estrutura de comissão personalizada" style={{marginBottom:"20px"}}>
									
										<div style={{marginTop:0,paddingTop:0,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",marginBottom:"20px"}}>
										
										  <div style={{width:"150px"}}>Tipo de comissão<sup style={{color:"red"}}>*</sup></div>
										  
										  <div style={{flex:1}}>
											 <SelectButton value={comissionType} optionLabel="name" options={comissionsTypes} onChange={(e) => setComissionType(e.value)} />
										  </div>
										  
										</div>
										
										{/* Platform comission */}
										<div style={{marginTop:0,paddingTop:0,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",marginBottom:"20px"}}>
										
										  <div style={{width:"150px"}}>Comissão Plataforma <sup style={{color:"red"}}>*</sup></div>
										  
										  <div style={{flex:1}}>
											  {comissionType == "percentage" &&
											  <>
												<InputNumber inputId="percent" value={comissionPlatform} onValueChange={(e) => setComissionPlatform(e.value)} max={99} />
											  </>
											  }
											  
											  {comissionType == "fixed" &&
											  <>
												<InputNumber value={comissionPlatform} onValueChange={(e) => setComissionPlatform(e.value)} mode="currency" currency="BRL" locale="pt-BR" />
											  </>
											  }
										  </div>
										  
										</div>
										
										{/* Provider comission */}
										<div style={{marginTop:0,paddingTop:0,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",marginBottom:"20px"}}>
										
										  <div style={{width:"150px"}}>Comissão Prestador <sup style={{color:"red"}}>*</sup></div>
										  
										  <div style={{flex:1}}>
											  {comissionType == "percentage" &&
											  <>
												<InputNumber inputId="percent" value={comissionProvider} onValueChange={(e) => setComissionProvider(e.value)} max={99} />
											  </>
											  }
											  
											  {comissionType == "fixed" &&
											  <>
												<InputNumber value={comissionProvider} onValueChange={(e) => setComissionProvider(e.value)} mode="currency" currency="BRL" locale="pt-BR" />
											  </>
											  }
										  </div>
										  
										</div>	

										{/* Partner comission */}
										<div style={{marginTop:0,paddingTop:0,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",marginBottom:"20px"}}>
										
										  <div style={{width:"150px"}}>Comissão Parceiro</div>
										  
										  <div style={{flex:1}}>
											  {comissionType == "percentage" &&
											  <>
												<InputNumber inputId="percent" value={comissionPartner} onValueChange={(e) => setComissionPartner(e.value)} max={99} />
											  </>
											  }
											  
											  {comissionType == "fixed" &&
											  <>
												<InputNumber value={comissionPartner} onValueChange={(e) => setComissionPartner(e.value)} mode="currency" currency="BRL" locale="pt-BR" />
											  </>
											  }
										  </div>
										  
										</div>										
													

									</Fieldset>
								   }
							  
								   {comissionGlobal == "default" &&
									<Fieldset legend="Estrutura de comissão padrão" style={{marginBottom:"20px"}}>
										
										{/* Platform comission */}
										<div style={{marginTop:0,paddingTop:0,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",marginBottom:"20px"}}>
										
										  <div style={{width:"150px"}}>Comissão Plataforma</div>
										  
										  <div style={{flex:1}}>{comissionsRows.platformComission ? comissionsRows.platformComission+"%" : "carregando..."}</div>
										  
										</div>
										
										{/* Provider comission */}
										<div style={{marginTop:0,paddingTop:0,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",marginBottom:"20px"}}>
										
										  <div style={{width:"150px"}}>Comissão Prestador</div>
										  
										  <div style={{flex:1}}>{comissionsRows.providerComission ? comissionsRows.providerComission+"%" : "carregando..."}</div>
										  
										</div>	

										{/* Partner comission */}
										<div style={{marginTop:0,paddingTop:0,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",marginBottom:"20px"}}>
										
										  <div style={{width:"150px"}}>Comissão Parceiro</div>
										  
										  <div style={{flex:1}}>{comissionsRows.partnerComission ? comissionsRows.partnerComission+"%" : "carregando..."}</div>
										  
										</div>										
													

									</Fieldset>
								   }								  
								  
								  </TableCell>
								</TableRow>
								
							</TableBody>
						  </Table>
						</TableContainer>
						
					  </CardBody>
					</Card>	 
 
					<div style={{textAlign:"right"}}>
						<Button label="FINALIZAR CADASTRO" icon="pi pi-check" className="p-button-lg"  loading={saving} onClick={() => { save(); } } />
					</div>

				  </GridItem>
				</GridContainer>				
				
			</Dialog>	
	</>	
	);
	
});

// UpdateService dialog component
export const UpdateService = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
		
// Set classes
const classes = useStyles();	

const screenStyleLoading = {
  alignItems: "middle",
  justifyContent: "center",
  width:"100%",
  display: "flex",
  flexDirection: "column"
};	

// set vars from props
const { 
	alertServiceUpdate,
	setAlertServiceUpdate,
	loadLazyData,
	buttonLoading,
	setAlertTitle,
	setAlertBody,
	setAlert,
	retrieveRegions,
	retrieveCategoriesTree,
	retrieveComissions,
	updateService,
	retrieveService,
	setParentLoading,
	serviceId
} = props;		

// Service Details 
const [serviceName, setServiceName] = useState(""); 
const [serviceShortDescription, setServiceShortDescription] = useState(""); 
const [serviceShortDescriptionCounter, setServiceShortDescriptionCounter] = useState(0); 
const [serviceDescription, setServiceDescription] = useState(""); 
const [serviceDescriptionCounter, setServiceDescriptionCounter] = useState(0); 
const [serviceWarrantyTerm, setServiceWarrantyTerm] = useState("O serviço tem a garantia de até 90 dias após a prestação do mesmo. A garantia de serviço contempla problemas ocasionados com o escopo do serviço."); 
const [serviceWarrantyTermCounter, setServiceWarrantyTermCounter] = useState(0); 
const [serviceTags, setServiceTags] = useState(""); 
const [serviceEffort, setServiceEffort] = useState(null); 
const [serviceEffortUnity, setServiceEffortUnity] = useState(""); 
const [serviceStatus, setServiceStatus] = useState(0); 
const [serviceHighlight, setServiceHighLight] = useState(false); 

// Set service row
const [serviceRow, setServiceRow] = useState({});

// Set default comissionSum
const [comissionsRows, setComissionsRows] = useState([]);

// Set regions options and loading
const [regionsRows, setRegionsRows] = useState([]);
const [regionsLoading, setRegionsLoading] = useState(true);

// Set categories options and loading
const [categoriesTreeRows, setCategoriesTreeRows] = useState([]);
const [parentCategoriesTreeRows, setParentCategoriesTreeRows] = useState([]);
const [categoriesLoading, setCategoriesLoading] = useState(true);
const [categoriesChecked, setCategoriesChecked] = useState([]);

const [checkedRegion, setCheckedRegion] = useState({});
const [regionValues, setRegionValues] = useState({});
const [categories, setCategories] = useState({});
const [categoriesExpand, setCategoriesExpand] = useState([]);

const [comissionGlobal, setComissionGlobal] = useState("default");
const [comissionType, setComissionType] = useState("percentage");
const [comissionPlatform, setComissionPlatform] = useState(null);
const [comissionProvider, setComissionProvider] = useState(null);
const [comissionPartner, setComissionPartner] = useState(null);

const [saving, setSaving] = useState(false); 
const [loadingService, setLoadingService] = useState(true); 

const [iconImage, setIconImage] = useState({}); 
const [headerImage, setHeaderImage] = useState({}); 

const [iconImageType, setIconImageType] = useState("uploadedImage");
const [iconImageUrl, setIconImageUrl] = useState(""); 
const [icon, setIcon] = useState(""); 

const [headerImageType, setHeaderImageType] = useState("uploadedImage");
const [headerImageUrl, setHeaderImageUrl] = useState(""); 
const [header, setHeader] = useState(""); 

// Options for regions status
const regions = [
        {name: 'Desabilitado', value: 0},
        {name: 'Habilitado', value: 1}
];

// Options for comissions types
const comissionsTypes = [
        {name: 'Percentual', value: "percentage"},
        {name: 'Valor Fixo', value: "fixed"}
];

// Options for comissions global options
const comissionGlobalOptions = [
        {name: 'Padrão', value: "default"},
        {name: 'Personalizada', value: "custom"}
];

// Options for unities of time	
const unities = [
    {label: 'Minutos', value: 'minutos'},
    {label: 'Horas', value: 'horas'},
	{label: 'Hora', value: 'hora'}
];	

// Options for status
const statuses = [
    {label: 'Inativo', value: '0'},
    {label: 'Ativo', value: '1'}
];	
	
// Execute when dialog opens
React.useEffect(() => {

	// Only r]uns when  dialog opens
	if (alertServiceUpdate === true) {
				
		// Retrieve regions and set to screen
		var propsParam = {
			setRegionsRows:setRegionsRows,
			setRegionsLoading:setRegionsLoading,
			setCheckedRegion:setCheckedRegion,
			setRegionValues:setRegionValues
		}	
		retrieveRegions(propsParam);	
		
		// Retrieve Categories and set to screen
		var propsParam = {
			setCategoriesTreeRows:setCategoriesTreeRows,
			setParentCategoriesTreeRows:setParentCategoriesTreeRows,
			setCategoriesLoading:setCategoriesLoading
		}	
		retrieveCategoriesTree(propsParam);

		// Retrieve Default comissions
		var propsParam = {
			setComissionsRows:setComissionsRows
		}	
		retrieveComissions(propsParam);

		// Retrieve Service - add delay to wait for previous returns
		setTimeout(function() {
			var data = {
				serviceId:serviceId
			}
			var propsParam = {
				setServiceRow:setServiceRow,
				setLoadingService:setLoadingService
			}	
			retrieveService(data,propsParam);
		},1000);
		
	} // end if
	
	// Reset all fields when closes or open dialog
	// ...
	
	// Reset images when open interface
	setIconImage({});
	setHeaderImage({});
	setServiceRow({});
	setLoadingService(true);
		
},[alertServiceUpdate]);

// Set values for each field
React.useEffect(() => {

	// If service returned set values
	if (serviceRow.id) {
				
		// Service details
		setServiceName(serviceRow.name);
		
		setServiceShortDescription(serviceRow.shortDescription);
		setServiceShortDescriptionCounter(serviceRow.shortDescription.length);
		
		setServiceDescription(serviceRow.description);
		setServiceDescriptionCounter(serviceRow.description.length);
		
		setServiceWarrantyTerm(serviceRow.warrantyTerm);
		setServiceWarrantyTermCounter(serviceRow.warrantyTerm.length);
		
		var tagsArray = serviceRow.tags.split(",");	// create array for chips
		setServiceTags(tagsArray);
		
		setServiceEffort(serviceRow.effort);
		setServiceEffortUnity(serviceRow.effortUnity);
		
		var status = ""+serviceRow.status+"";
		setServiceStatus(status);
		
		
		var highlight = serviceRow.highlight;
		setServiceHighLight(highlight);

		// Set current images of icon and header
		setIcon(serviceRow.icon);
		setHeader(serviceRow.iconHeader);		

		// Service Categories
		var serviceCategories = {};
		var serviceCategoriesExpanded ={};
		var categoriesArray = serviceRow.ServiceCategories;
		for (var x = 0; x < categoriesArray.length; x++) {
			serviceCategories[categoriesArray[x].id] = {}
			serviceCategories[categoriesArray[x].id].checked = true;
			serviceCategories[categoriesArray[x].id].partialChecked = false;
		} // end for
		for (var x = 0; x < parentCategoriesTreeRows.length; x++) {
			serviceCategoriesExpanded[parentCategoriesTreeRows[x].value] = true;
		} // end for		
		setCategories(serviceCategories);
		setCategoriesExpand(serviceCategoriesExpanded);

		// Set Prices
		try {
			// Select Region 1
			var array = [...serviceRow.Price]; // clone
			var index = array.filter(item => item.ServicePriceRegionId == 1);
			var filteredItem = index[0];		
			
			// Set Region 1
			var regionObj = {...regionValues};
			regionObj['region1'].price = filteredItem.price;
			regionObj['region1'].urgentTax = filteredItem.urgentTax;
			regionObj['region1'].ServicePriceRegionId = filteredItem.ServicePriceRegionId;
	
			// set others regions
			var currentValue = {...checkedRegion}; // clone checked regions
			var array = [...serviceRow.Price]; // clone
			var index = array.filter(item => item.ServicePriceRegionId != 1);
			var filteredItems = index;	
			for (var i = 0; i < filteredItems.length; i++) {
				regionObj['region'+filteredItems[i].ServicePriceRegionId+''].price = filteredItems[i].price;
				regionObj['region'+filteredItems[i].ServicePriceRegionId+''].urgentTax = filteredItems[i].urgentTax;
				regionObj['region'+filteredItems[i].ServicePriceRegionId+''].ServicePriceRegionId = filteredItems[i].ServicePriceRegionId;
				
				// set selected item
				currentValue["region"+filteredItems[i].ServicePriceRegionId+""] = 1;
				
			} // end for
			
			// Set modified obj
			setRegionValues(regionObj); 

			// update check
			setCheckedRegion(currentValue);
		} // end try
		catch(e) {
			console.log("[CATCH]--->"+JSON.stringify(e.message,null,2));
		} // end catch
				
		// Set COMISSION layer
		if (!serviceRow.ServiceComissionTable) {
			setComissionGlobal("default");			
		} // end if	
		else {
			setComissionGlobal("custom");	
			setComissionType(serviceRow.ServiceComissionTable.type == 2 ? "percentage" : "fixed");
			setComissionPlatform(serviceRow.ServiceComissionTable.platformComission);
			setComissionProvider(serviceRow.ServiceComissionTable.providerComission);
			setComissionPartner(serviceRow.ServiceComissionTable.partnerComission);
		} // end else	
		
		
	} // end if

},[serviceRow]);

// Set status of selected region (enabled or disabled)
const setRegion = (regionId,value) => {

	var currentValue = {...checkedRegion};
	currentValue[""+regionId+""] = value;
	setCheckedRegion(currentValue);	
	
} // end set Region		

// Open alert when save button is disabled
const openAlert = (section) => {

	if (section == 'service') {
		setAlertTitle("Verifique as informações");
		setAlertBody("Preencha os dados do serviço marcados com * para prosseguir com a atualização.");
		setAlert(true);
	} // end if
	else if (section == 'images') {
		setAlertTitle("Verifique as imagens");
		setAlertBody("Você deve definir a imagem do ícone e do header do serviço enviando uma imagem ou definindo uma url externa para prosseguir com a atualização.");
		setAlert(true);
	} // end if	
	else if (section == 'imagesurl') {
		setAlertTitle("Verifique a URL das imagens");
		setAlertBody("A URL informada para uma ou mais imagem é inválida. Verifique e corrija para prosseguir.");
		setAlert(true);
	} // end if	
	else if (section == 'categories') {
		setAlertTitle("Verifique a categoria do serviço");
		setAlertBody("É necessário que defina ao menos uma categoria do serviço para prosseguir com a atualização.");
		setAlert(true);
	} // end if	
	else if (section == 'primaryRegion') {
		setAlertTitle("Verifique o preço do serviço");
		setAlertBody("É necessário que defina o preço e taxa de urgência global do serviço para prosseguir com a atualização.");
		setAlert(true);
	} // end if	
	else if (section == 'secondaryRegion') {
		setAlertTitle("Verifique o preço do serviço");
		setAlertBody("É necessário que defina o preço e taxa de urgência das regiões habilitadas do serviço para prosseguir com a atualização.");
		setAlert(true);
	} // end if	
	else if (section == 'comission') {
		setAlertTitle("Verifique a comissão do serviço");
		setAlertBody("É necessário que define a comissão da plataforma e do prestador do serviço para prosseguir com a atualização.");
		setAlert(true);
	} // end if		
	else if (section == 'comissionSum') {
		setAlertTitle("Verifique a comissão do serviço");
		setAlertBody("A soma percentual das comissões das partes é maior ou menor que 100%. Verifique.");
		setAlert(true);
	} // end if			
	else {
		setAlertTitle("Verifique as informações");
		setAlertBody("Preencha os dados mínimos marcados com * para prosseguir com a atualização.");
		setAlert(true);
	} // end if	
			
} // end set Region		

// Handle file selector click
const handleClick = event => {
hiddenFileInput.current.click();
};

// Handle file selector click
const handleClickHeader = event => {
hiddenFileInputHeader.current.click();
};

// Store file input for selection
const hiddenFileInput = React.useRef(null);
const hiddenFileInputHeader = React.useRef(null);

// Set sent image to interface
const onChangeHandlerIcon=event=>{

	// set image
	var image = event.target.files[0];

	// Extract file specs
	var imageObj = {
		name:image.name,
		size:image.size,
		type:image.type,
		image: URL.createObjectURL(event.target.files[0]),
		base64image:event.target.files[0]
	}	
	
	// set final obj
	setIconImage(imageObj);

}
	
// Set sent image to interface
const onChangeHandlerHeader=event=>{

	// set image
	var image = event.target.files[0];

	// Extract file specs
	var imageObj = {
		name:image.name,
		size:image.size,
		type:image.type,
		image: URL.createObjectURL(event.target.files[0]),
		base64image:event.target.files[0]
	}	
	
	// set final obj
	setHeaderImage(imageObj);

}
		
// Heade template of icon image upload
const iconUploadHeaderTemplate = (options) => {
	
        const className = `${options.className} justify-content-start`;

        return (
            <div className={className}>
			
			<RadioButton name="iconImageType" value="uploadedImage" onChange={(e) => setIconImageType(e.value)} checked={iconImageType === 'uploadedImage'} style={{marginRight:"20px"}} />
				  
				<Button variant="contained" disabled={iconImageType === 'urlImage'} color="primary" component="span" onClick={handleClick}>
					Trocar imagem
				</Button>

				<input
					accept="image/*"
					style={{display:"none"}}
					ref={hiddenFileInput}
					onChange={onChangeHandlerIcon}
					id="iconUploadButton"
					type="file"
				/>	
				
            </div>
        )
} // end function

// Heade template of header image upload
const headerUploadHeaderTemplate = (options) => {
	
        const className = `${options.className} justify-content-start`;

        return (
            <div className={className}>
			
			<RadioButton name="headerImageType" value="uploadedImage" onChange={(e) => setHeaderImageType(e.value)} checked={headerImageType === 'uploadedImage'} style={{marginRight:"20px"}} />
				  
				<Button variant="contained" disabled={headerImageType === 'urlImage'} color="primary" component="span" onClick={handleClickHeader}>
					Trocar imagem
				</Button>

				<input
					accept="image/*"
					style={{display:"none"}}
					ref={hiddenFileInputHeader}
					onChange={onChangeHandlerHeader}
					id="headerUploadButton"
					type="file"
				/>	
				
            </div>
        )
} // end function

// Save instance method	
const save = () => {

	// Check if min SERVICE DETAILS fields are filled
	if (!serviceName || !serviceDescription || !serviceWarrantyTerm || !serviceEffort || !serviceEffortUnity) {
		openAlert('service');
		return;
	} // end if

	// IMAGES CHECK  
	// check if icon image is set - DISABLED ON UPDATE since there is an image already
	if (iconImageType == 'uploadedImage') {
		if (!iconImage.image)  {
			// openAlert('images');
			// return;			
		} // end if
	} // end if
	else {
		if (iconImageUrl == "")  {
			// openAlert('images');
			// return;			
		} // end if	
		else {
			var pattern = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
			if (!pattern.test(iconImageUrl)) {
				openAlert('imagesurl');
				return;	
			} // end if
		} // end else		
	} // end else
		
	// check if header image is set - DISABLED ON UPDATE since there is an image already
	if (headerImageType == 'uploadedImage') {
		if (!headerImage.image)  {
			// openAlert('images');
			// return;			
		} // end if
	} // end if
	else {
		if (headerImageUrl == "")  {
			// openAlert('images');
			// return;			
		} // end if	
		else {
			var pattern = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
			if (!pattern.test(headerImageUrl)) {
				openAlert('imagesurl');
				return;	
			} // end if
		} // end else
	} // end else	

	// CATEGORIES Check
	if (Object.keys(categories).length === 0) {
		openAlert('categories');
		return;
	} // end if
	
	// REGIONS Check
	if (!regionValues['region1'].price || !regionValues['region1'].urgentTax) {
		openAlert('primaryRegion');
		return;		
	} // end if
	else {
		var regionsLoop = Object.keys(checkedRegion);
		for (var x = 0; x < regionsLoop.length; x++) {
			// if region is enabled
			if (checkedRegion[regionsLoop[x]] === 1) {
				if (!regionValues[regionsLoop[x]].price || !regionValues[regionsLoop[x]].urgentTax) {
					openAlert('secondaryRegion');
					return;						
				} // end if			
			} // end if	
		} // end for
	} // end else
	
	// COMISSION check
	if (comissionGlobal == "custom") {
		if (!comissionPlatform || !comissionProvider) {
			openAlert('comission');
			return;					
		} // end if
		else {
			if (comissionType == "percentage") {
				var total = parseInt(comissionPlatform) + parseInt(comissionProvider) + parseInt(comissionPartner ? comissionPartner : 0);				
				if (total != 100) {
					openAlert('comissionSum');
					return;						
				} // end if
			} // end if
		} // end else
	} // end if
	
	// ALL OK, save service
	
	// Turn on loading on button
	setSaving(true);	
	
	// Turn on overlay loading
	setParentLoading(true);
	
	// Prepare Data
	var data = {
		serviceId:serviceId,
		serviceName:serviceName,
		serviceShortDescription:serviceShortDescription,
		serviceDescription:serviceDescription,
		serviceWarrantyTerm:serviceWarrantyTerm,
		serviceTags:serviceTags,
		serviceEffort:serviceEffort,
		serviceEffortUnity:serviceEffortUnity,
		serviceStatus:serviceStatus,
		serviceHighlight:serviceHighlight,
		regionValues:regionValues,
		categories:categories,
		checkedRegion:checkedRegion,
		comissionGlobal:comissionGlobal,
		comissionType:comissionType,
		comissionPlatform:comissionPlatform,
		comissionProvider:comissionProvider,
		comissionPartner:comissionPartner,
		iconImage:iconImage,
		headerImage:headerImage,
		iconImageType:iconImageType,
		iconImageUrl:iconImageUrl,
		headerImageType:headerImageType,
		headerImageUrl:headerImageUrl,
		parentCategoriesTreeRows:parentCategoriesTreeRows		
	}
	// Prepare Props
	var propsParam = {
		setSaving:setSaving,
		setParentLoading:setParentLoading,
		loadLazyData:loadLazyData,
		setAlertTitle:setAlertTitle,
		setAlertBody:setAlertBody,
		setAlert:setAlert,
		setAlertServiceUpdate:setAlertServiceUpdate
	}
	// Save
	updateService(data,propsParam);

} // end function save
	
	return (
	<>
	<Dialog header="Editar serviço" visible={alertServiceUpdate} style={{ width: '70vw' }} footer={(<div>
			<Button label="FECHAR" disabled={saving} style={{marginTop:"20px"}} icon="pi pi-check" className="p-button-outlined p-button-secondary p-button-sm" onClick={() => {setAlertServiceUpdate(false)} } />
		</div>)} onHide={() => setAlertServiceUpdate(false)} draggable={false} dismissableMask={false} closable={saving ? false : true} closeOnEscape={false} >
				
				{loadingService && 
					<Box style={{...screenStyleLoading,height:"65vh"}}>
							<Box mt={0} mx={0} style={{textAlign:"center"}}>								
								<CircularProgress /> 
								<div> Carregando dados...Aguarde!</div>								
							</Box>	
					</Box>
				}
			
				{!loadingService &&
				<>	
				<GridContainer justify="center">
				  <GridItem xs={12} sm={12}>

					<Card>
					  <CardHeader color="info" icon>
						<CardIcon color="info">
						  <Assignment />
						</CardIcon>
						<h4 className={classes.cardIconTitle}>Edição de serviço</h4>
					  </CardHeader>
					  <CardBody>	 
						
						{/* Details Block */}
						<TableContainer component={Box} mt={2}>
						  <Table  aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Dados do Serviço</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>
							

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Nome do Serviço <sup style={{color:"red"}}>*</sup></TableCell>
								  <TableCell align="left"><InputText style={{width:"100%"}} value={serviceName} onChange={(e) => setServiceName(e.target.value)} maxLength="45" /></TableCell>
								</TableRow>
								
								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Descrição Curta</TableCell>
								  <TableCell align="left"><InputTextarea  style={{width:"100%"}} rows={2} cols={30} value={serviceShortDescription} onChange={(e) => { if (event.target.value.length > 100) { return; }; setServiceShortDescription(event.target.value); setServiceShortDescriptionCounter(event.target.value.length)} } autoResize />
								  <small  style={{float:"left"}}>Breve descrição do serviço (até 100 chars).</small> <small style={{float:"right",color:"red"}}>{serviceShortDescriptionCounter} em 100 chars</small>
								  </TableCell>
								</TableRow>			

								<TableRow >
								  <TableCell component="th" scope="row">Descrição <sup style={{color:"red"}}>*</sup></TableCell>
								  <TableCell align="left"><InputTextarea  style={{width:"100%"}} rows={5} cols={30} value={serviceDescription} onChange={(e) => { if (event.target.value.length > 255) { return; }; setServiceDescription(event.target.value);setServiceDescriptionCounter(event.target.value.length)}} autoResize />
								   <small  style={{float:"left"}}>Descrição longa do serviço (até 255 chars).</small> <small style={{float:"right",color:"red"}}>{serviceDescriptionCounter} em 255 chars</small>
								  
								  </TableCell>
								</TableRow>
								
								<TableRow >
								  <TableCell component="th" scope="row">Termo de garantia do serviço <sup style={{color:"red"}}>*</sup></TableCell>
								  <TableCell align="left"><InputTextarea  style={{width:"100%"}} rows={5} cols={30} value={serviceWarrantyTerm} onChange={(e) => {if (event.target.value.length > 1000) { return; };setServiceWarrantyTerm(event.target.value);setServiceWarrantyTermCounter(event.target.value.length) }} autoResize />
								   <small  style={{float:"left"}}>Insira o termo de garantia do serviço. (até 1000 chars).</small> <small style={{float:"right",color:"red"}}>{serviceWarrantyTermCounter} em 1000 chars</small>
								  </TableCell>
								</TableRow>			

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Tags</TableCell>
								  <TableCell align="left"><Chips value={serviceTags} onChange={(e) => setServiceTags(e.value)} separator="," />
								  <small className="block" style={{marginTop:"5px"}}>Defina uma ou mais tags relacionadas com o serviço. Digite e aperte enter por tag.</small>
								  </TableCell>
								</TableRow>	

								<TableRow >
								  <TableCell component="th" scope="row">Tempo do serviço <sup style={{color:"red"}}>*</sup></TableCell>
								  <TableCell align="left">
									  <InputNumber inputId="withoutgrouping" value={serviceEffort} onValueChange={(e) => setServiceEffort(e.value)} mode="decimal" placeholder="Tempo" useGrouping={false} style={{marginRight:"10px"}} />
									  <Dropdown value={serviceEffortUnity} options={unities} onChange={(e) => setServiceEffortUnity(e.value)} placeholder="Unidade"/>
								  </TableCell>
								</TableRow>
								
								<TableRow >
								  <TableCell component="th" scope="row">Serviço em destaque</TableCell>
								  <TableCell align="left">
									 <Switch
										checked={serviceHighlight}
										onChange={(event) => {
											setServiceHighLight(event.target.checked);
										}}											
										color="primary"
									  />									  
								  </TableCell>
								</TableRow>								

								<TableRow >
								  <TableCell component="th" scope="row">Status <sup style={{color:"red"}}>*</sup></TableCell>
								  <TableCell align="left">
									  <Dropdown value={serviceStatus} options={statuses} onChange={(e) => setServiceStatus(e.value)} placeholder="Status"/>
								  </TableCell>
								</TableRow>

							</TableBody>
						  </Table>
						</TableContainer>
						
						{/* Image Block */}
						<TableContainer component={Box} mt={2}>
						  <Table  aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Imagens do Serviço <small style={{color:"red",marginLeft:"10px"}}>informação obrigatória </small></TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Imagem da ícone do Serviço <sup style={{color:"red"}}>*</sup>  <br /> <small>Defina se vai enviar uma imagem ou se vai colocar uma url da mesma. <br /><br /> Tamanho sugerido é 110px x 110px</small></TableCell>
								  <TableCell align="left">
																	  
						            <Panel headerTemplate={iconUploadHeaderTemplate}>
										
										{iconImage.image &&
											<div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
											
												<div style={{flex:1}}>
													<img src={iconImage.image} style={{width:"75px",height:"auto"}} />
												</div>
												
												<div style={{flex:5,marginLeft:"20px",fontSize:"12px"}}>
													Arquivo: {iconImage.name} <br /> 
													Tamanho: {iconImage.size} bytes  <br />
													Tipo: {iconImage.type}
												</div>
												
												<div style={{flex:1}}>
													<Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => setIconImage({})} />
												</div>										
																					
											</div>
										}
										
										{!iconImage.image &&
										<>
											<div>Ícone atual</div>
											<div><img src={icon} style={{width:"75px",height:"auto"}} /></div>
										</>
										}
										
									</Panel>

									<div style={{padding: "1rem",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
									
										<div style={{marginRight:"20px"}}>
											<RadioButton name="iconImageType" value="urlImage" onChange={(e) => setIconImageType(e.value)} checked={iconImageType === 'urlImage'} />
										</div>
										
										<div style={{flex:1}}>									
										
											<InputText disabled={iconImageType != 'urlImage'} style={{width:"100%"}} value={iconImageUrl} onChange={(e) => setIconImageUrl(e.target.value)}  placeholder="Endereço URL Imagem" />

										</div>
									
									</div>
									
								  
								  </TableCell>
								</TableRow>
								
								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Imagem do header do Serviço <sup style={{color:"red"}}>*</sup>  <br /> <small>Defina se vai enviar uma imagem ou se vai colocar uma url da mesma. <br /><br /> Tamanho obrigatório é 828px x 240px</small></TableCell>
								  <TableCell align="left">
																	  
						            <Panel headerTemplate={headerUploadHeaderTemplate}>
										
										{headerImage.image &&
											<div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
											
												<div style={{flex:1}}>
													<img src={headerImage.image} style={{width:"150px",height:"auto"}} />
												</div>
												
												<div style={{flex:5,marginLeft:"20px",fontSize:"12px"}}>
													Arquivo: {headerImage.name} <br /> 
													Tamanho: {headerImage.size} bytes  <br />
													Tipo: {headerImage.type}
												</div>
												
												<div style={{flex:1}}>
													<Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => setHeaderImage({})} />
												</div>										
																					
											</div>
										}
										
										{!headerImage.image &&
										<>
											<div>Header atual</div>
											<div><img src={header} style={{width:"150px",height:"auto"}} /></div>
										</>
										}
										
									</Panel>

									<div style={{padding: "1rem",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
									
										<div style={{marginRight:"20px"}}>
											<RadioButton name="headerImageType" value="urlImage" onChange={(e) => setHeaderImageType(e.value)} checked={headerImageType === 'urlImage'} />
										</div>
										
										<div style={{flex:1}}>									
											
												<InputText disabled={headerImageType != 'urlImage'} style={{width:"100%"}} value={headerImageUrl} onChange={(e) => setHeaderImageUrl(e.target.value)} placeholder="Endereço URL Imagem" />
											
										</div>
									
									</div>						           
								   
								   
								  </TableCell>
								</TableRow>							

							</TableBody>
						  </Table>
						</TableContainer>						
						
						{/* Category Block */}
						<TableContainer component={Box} mt={2}>
						  <Table  aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Categorias do Serviço <small style={{color:"red",marginLeft:"10px"}}> obrigatório ao menos uma categoria </small></TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Marque uma ou mais categorias em que o serviço será apresentado</TableCell>
								  <TableCell align="left">
								  
									  <Tree value={categoriesTreeRows} selectionMode="checkbox" expandedKeys={categoriesExpand} selectionKeys={categories} onSelectionChange={e => setCategories(e.value)} style={{maxHeight:"370px",overflowY:"scroll"}} />
								  
								  </TableCell>
								</TableRow>
								

							</TableBody>
						  </Table>
						</TableContainer>
						
						{/* Price Block */}
						<TableContainer component={Box} mt={2}>
						  <Table aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Definição de preços do Serviço <small style={{color:"red",marginLeft:"10px"}}>preço global obrigatório </small></TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Defina o preço global do serviço em R$, assim como a taxa de urgência. <br /><br /> <small>Se desejar criar preços dinstintos por região, ative e informe.</small> </TableCell>
								  <TableCell align="left">
								  
									<Fieldset legend="Região Global" style={{marginBottom:"20px"}}>

										<div style={{marginTop:0,paddingTop:0,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",marginBottom:"20px"}}>
										
										  <div style={{width:"150px"}}>Preço do serviço <sup style={{color:"red"}}>*</sup></div>
										  
										  <div style={{flex:1}}>
											<InputNumber value={regionValues['region1'] ? regionValues['region1'].price: null} onValueChange={(e) => { var values = {...regionValues}; values['region1'].price = e.value; setRegionValues(values); } } mode="currency" currency="BRL" locale="pt-BR" />
										  </div>
										  
										</div>
										
										<div style={{marginTop:0,paddingTop:0,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
										
										  <div style={{width:"150px"}}>Taxa de urgência<sup style={{color:"red"}}>*</sup></div>
										  
										  <div style={{flex:1}}>
											<InputNumber value={regionValues['region1'] ? regionValues['region1'].urgentTax:null} onValueChange={(e) => { var values = {...regionValues}; values['region1'].urgentTax = e.value; setRegionValues(values); } } mode="currency" currency="BRL" locale="pt-BR" />
										  </div>
										  
										</div>										

									</Fieldset>
									
									
									{/* Regions Results from API */}
									{regionsRows.map((item) => {
										 return (
											<Fieldset legend={item.title} collapsed={!checkedRegion['region'+item.id+'']} toggleable key={item.id}  style={{marginBottom:"20px"}}>

												<div style={{marginTop:0,paddingTop:0,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",marginBottom:"20px"}}>
												
												  <div style={{width:"150px"}}>Status da região<sup style={{color:"red"}}>*</sup></div>
												  
												  <div style={{flex:1}}>
													 <SelectButton value={checkedRegion['region'+item.id+'']} optionLabel="name" options={regions} onChange={(e) => setRegion('region'+item.id+'',e.value)} />
												  </div>
												  
												</div>
												
												<div style={{marginTop:0,paddingTop:0,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",marginBottom:"20px"}}>
												
												  <div style={{width:"150px"}}>Preço do serviço <sup style={{color:"red"}}>*</sup></div>
												  
												  <div style={{flex:1}}>
													<InputNumber value={regionValues['region'+item.id+''].price} 
														onValueChange={(e) => { var values = {...regionValues}; values['region'+item.id+''].price = e.value; setRegionValues(values); } } mode="currency" currency="BRL" locale="pt-BR" />
												  </div>
												  
												</div>
												
												<div style={{marginTop:0,paddingTop:0,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
												
												  <div style={{width:"150px"}}>Taxa de urgência<sup style={{color:"red"}}>*</sup></div>
												  
												  <div style={{flex:1}}>
													<InputNumber value={regionValues['region'+item.id+''].urgentTax} 
														onValueChange={(e) => { var values = {...regionValues}; values['region'+item.id+''].urgentTax = e.value; setRegionValues(values); } } mode="currency" currency="BRL" locale="pt-BR" />
												  </div>
												  
												</div>	

											</Fieldset>
											)}
									 )}	
							
									
									{regionsRows.length === 0 && 
										<div> Nenhuma região cadastrada... </div>						
									}									
									
								  </TableCell>
								</TableRow>
								

							</TableBody>
						  </Table>
						</TableContainer>
						
						{/* Comission Block */}
						<TableContainer component={Box} mt={2}>
						  <Table aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Definição de comissões do Serviço <small style={{color:"red",marginLeft:"10px"}}>informação obrigatória</small></TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Confirme a comissão padrão da plataforma ou defina o tipo de comissão e os valores específicos para esse serviço.<br /><br /> <small>Plataforma e Prestador são obrigatórios, se for personalizado para esse serviço.</small> </TableCell>
								  <TableCell align="left">

									<div style={{marginTop:0,paddingTop:0,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",marginBottom:"20px"}}>
									
									  <div style={{width:"150px"}}>Comissão do serviço</div>
									  
									  <div style={{flex:1}}>
										 <SelectButton value={comissionGlobal} optionLabel="name" options={comissionGlobalOptions} onChange={(e) => setComissionGlobal(e.value)} />
									  </div>
									  
									</div>
								  
								  {comissionGlobal == "custom" &&
									<Fieldset legend="Estrutura de comissão personalizada" style={{marginBottom:"20px"}}>
									
										<div style={{marginTop:0,paddingTop:0,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",marginBottom:"20px"}}>
										
										  <div style={{width:"150px"}}>Tipo de comissão<sup style={{color:"red"}}>*</sup></div>
										  
										  <div style={{flex:1}}>
											 <SelectButton value={comissionType} optionLabel="name" options={comissionsTypes} onChange={(e) => setComissionType(e.value)} />
										  </div>
										  
										</div>
										
										{/* Platform comission */}
										<div style={{marginTop:0,paddingTop:0,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",marginBottom:"20px"}}>
										
										  <div style={{width:"150px"}}>Comissão Plataforma <sup style={{color:"red"}}>*</sup></div>
										  
										  <div style={{flex:1}}>
											  {comissionType == "percentage" &&
											  <>
												<InputNumber inputId="percent" value={comissionPlatform} onValueChange={(e) => setComissionPlatform(e.value)} max={99} />
											  </>
											  }
											  
											  {comissionType == "fixed" &&
											  <>
												<InputNumber value={comissionPlatform} onValueChange={(e) => setComissionPlatform(e.value)} mode="currency" currency="BRL" locale="pt-BR" />
											  </>
											  }
										  </div>
										  
										</div>
										
										{/* Provider comission */}
										<div style={{marginTop:0,paddingTop:0,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",marginBottom:"20px"}}>
										
										  <div style={{width:"150px"}}>Comissão Prestador <sup style={{color:"red"}}>*</sup></div>
										  
										  <div style={{flex:1}}>
											  {comissionType == "percentage" &&
											  <>
												<InputNumber inputId="percent" value={comissionProvider} onValueChange={(e) => setComissionProvider(e.value)} max={99} />
											  </>
											  }
											  
											  {comissionType == "fixed" &&
											  <>
												<InputNumber value={comissionProvider} onValueChange={(e) => setComissionProvider(e.value)} mode="currency" currency="BRL" locale="pt-BR" />
											  </>
											  }
										  </div>
										  
										</div>	

										{/* Partner comission */}
										<div style={{marginTop:0,paddingTop:0,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",marginBottom:"20px"}}>
										
										  <div style={{width:"150px"}}>Comissão Parceiro</div>
										  
										  <div style={{flex:1}}>
											  {comissionType == "percentage" &&
											  <>
												<InputNumber inputId="percent" value={comissionPartner} onValueChange={(e) => setComissionPartner(e.value)} max={99} />
											  </>
											  }
											  
											  {comissionType == "fixed" &&
											  <>
												<InputNumber value={comissionPartner} onValueChange={(e) => setComissionPartner(e.value)} mode="currency" currency="BRL" locale="pt-BR" />
											  </>
											  }
										  </div>
										  
										</div>										
													

									</Fieldset>
								   }
							  
								   {comissionGlobal == "default" &&
									<Fieldset legend="Estrutura de comissão padrão" style={{marginBottom:"20px"}}>
										
										{/* Platform comission */}
										<div style={{marginTop:0,paddingTop:0,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",marginBottom:"20px"}}>
										
										  <div style={{width:"150px"}}>Comissão Plataforma</div>
										  
										  <div style={{flex:1}}>{comissionsRows.platformComission ? comissionsRows.platformComission+"%" : "carregando..."}</div>
										  
										</div>
										
										{/* Provider comission */}
										<div style={{marginTop:0,paddingTop:0,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",marginBottom:"20px"}}>
										
										  <div style={{width:"150px"}}>Comissão Prestador</div>
										  
										  <div style={{flex:1}}>{comissionsRows.providerComission ? comissionsRows.providerComission+"%" : "carregando..."}</div>
										  
										</div>	

										{/* Partner comission */}
										<div style={{marginTop:0,paddingTop:0,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",marginBottom:"20px"}}>
										
										  <div style={{width:"150px"}}>Comissão Parceiro</div>
										  
										  <div style={{flex:1}}>{comissionsRows.partnerComission ? comissionsRows.partnerComission+"%" : "carregando..."}</div>
										  
										</div>										
													

									</Fieldset>
								   }								  
								  
								  </TableCell>
								</TableRow>
								
							</TableBody>
						  </Table>
						</TableContainer>
						
					  </CardBody>
					</Card>	 
 
					<div style={{textAlign:"right"}}>
					
					{/*<Button label="EXCLUIR SERVIÇO" icon="pi pi-trash" className="p-button-lg p-button-danger"  loading={saving} onClick={() => { console.log("deletar..."); } } style={{marginRight:"50px"}} />	*/}			
					
						<Button label="FINALIZAR EDIÇÃO" icon="pi pi-check" className="p-button-lg"  loading={saving} onClick={() => { save(); } } />
					</div>

				  </GridItem>
				</GridContainer>
				</>
				}
				
			</Dialog>	
	</>	
	);
	
});




export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(Services);	
