import React from 'react';
import axios from 'axios';
import moment from 'moment';
import jwt_decode from "jwt-decode";
import * as types from '../actionTypes';
import * as config from '../../Config/api.js';

export const setAppLoading = (value) => ({
  type: types.APP_LOADING,
  payload: value
});

export const setServiceOrders = (value) => ({
  type: types.SET_SERVICE_ORDERS,
  payload: value
});

export const setServiceOrder = (value) => ({
  type: types.SET_SERVICE_ORDER,
  payload: value
});

export const retrieveServiceOrders = (data,props) => {
	
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  

					// Prepare Params
					var preparedData = {}
					preparedData.status =  data.status;
					preparedData.pagination = {
						first:data.first,
						rows:data.rows,
						page:data.page,
						sortField:data.sortField ? data.sortField:null,
						sortOrder:data.sortOrder ? data.sortOrder:null,
						pageCount:data.pageCount
					}					
					preparedData.filters =  data.filters ? data.filters:null;
				
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/service/list',
						   method : 'POST',
						   data   : preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					}) 						
					
					.then((response) => {

						
						// Retrieve data to controller
						props.setServiceOrdersRows(response.data.services);
						props.setTotalRecords(response.data.count);
						
						// set rowCount first
						props.setLoading(false);
						return;
						
					 })
					.catch(error=> {
						props.setLoading(false);			
						props.setServiceOrdersRows();
						console.log(error);
						return;
					});		
					
		}
}

export const retrieveServiceOrder = (id,props) => {
	
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
					// Prepare Params
					var preparedData = {}
					preparedData.serviceOrderId =  id;
												
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+"/api/v1/service/retrieve",
						   method : 'POST',
						   data   : preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})
					
					.then((response) => {
		
						dispatch(setServiceOrder(response.data.service));
						
						var ProviderUserId = response.data.service.ProviderUser ? response.data.service.ProviderUser.id : null;
						var avatar = response.data.service.ProviderUser ? response.data.service.ProviderUser.ProviderProfile.avatar:null;
						var avatarImageUrl = config.API_URL+"/provider/avatar/retrieve/"+ProviderUserId+"/"+avatar+"";
						props.setAvatar(avatarImageUrl);		

						// Set Status History
						props.setStatusHistory(response.data.service.ServiceOrderStatusHistories);
						
						// Set Payment History
						props.setPaymentHistory(response.data.service.ServiceOrderPaymentHistories);
						
						// Set Payment Tax History
						props.setPenaltyTaxes(response.data.service.ServiceOrderPaymentPenaltyTaxes);						
					

						const finalAddress = response.data.service.ServiceOrderAddress.streetType+" "+response.data.service.ServiceOrderAddress.streetName+", "+response.data.service.ServiceOrderAddress.streetNumber+", "+response.data.service.ServiceOrderAddress.suburb+", "+response.data.service.ServiceOrderAddress.city+", "+response.data.service.ServiceOrderAddress.state;
				
						 const googleMapsUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${finalAddress}&zoom=13&size=400x250&maptype=roadmap&markers=color:blue%7Clabel:S%7C40.702147,-74.015794&markers=color:green%7Clabel:G%7C40.711614,-74.012318&markers=color:red%7Clabel:C%7C40.718217,-73.998284&key=AIzaSyBdE3uu0p9cJ_961a03aXMo0kGSzuUfTVE`;
						 props.setMapImageUrl(googleMapsUrl);						
						
						// Turn array to flat
						const finalObj = response.data.service;
						props.setServiceOrderCol(finalObj);	
						props.setContentVisible(true);	
						return;						
						
					 })
					.catch(error=> {	
						props.setServiceOrderCol({});						
						console.log(error);
						return;						
					});		
					
		}
}

export const retrieveServiceOrdersIncomings = (data,props) => {
	
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  

					// Prepare Params
					var preparedData = {}
					preparedData.status =  data.status;
					preparedData.pagination = {
						first:data.first,
						rows:data.rows,
						page:data.page,
						sortField:data.sortField ? data.sortField:null,
						sortOrder:data.sortOrder ? data.sortOrder:null,
						pageCount:data.pageCount
					}					
					preparedData.filters =  data.filters ? data.filters:null;
				
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/service/incoming/list',
						   method : 'POST',
						   data   : preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					}) 						
					
					.then((response) => {

						// Calculate totals based on status
						//console.log("HOLLY [response.data.services]--->"+JSON.stringify(response.data.services,null,2));
						
						// Filter service orders with status 
						
							var serviceReviewPlatformComission = 0;
							var serviceReviewProviderComission = 0;
							var serviceReviewPartnerComission = 0;
							
							var serviceConfirmedPlatformComission = 0;
							var serviceConfirmedProviderComission = 0;
							var serviceConfirmedPartnerComission = 0;

							var serviceDisputePlatformComission = 0;
							var serviceDisputeProviderComission = 0;
							var serviceDisputePartnerComission = 0;		

							var serviceCancelledPlatformComission = 0;
							var serviceCancelledProviderComission = 0;
							var serviceCancelledPartnerComission = 0;									
							
							var servicesArray = response.data.services;							
							for (var i = 0; i < servicesArray.length; i++) {

								// filter status SCHEDULED AND ONGOING
								if (servicesArray[i].status === 0 || servicesArray[i].status === 1 || servicesArray[i].status == 2 || servicesArray[i].status == 3) {

									// Sum Platform comission
									var platformComissionArray = servicesArray[i].platformTotals.filter(item => item.type === "total");
									var platformComissionValue = platformComissionArray.length > 0 ? platformComissionArray[0].value : 0;
									serviceReviewPlatformComission += platformComissionValue;
									
									// Sum Provider comission
									var providerComissionArray = servicesArray[i].providerTotals.filter(item => item.type === "total");
									var providerComissionValue = providerComissionArray.length > 0 ? providerComissionArray[0].value : 0;
									serviceReviewProviderComission += providerComissionValue;

									// Sum Partner comission
									var partnerComissionArray = servicesArray[i].partnerTotals.filter(item => item.type === "total");
									var partnerComissionValue = partnerComissionArray.length > 0 ? partnerComissionArray[0].value : 0;
									serviceReviewPartnerComission += partnerComissionValue;
								
								}// end if
								
								// filter status CONFIRMED
								else if (servicesArray[i].status === 4) {

									// Sum Platform comission
									var platformComissionArray = servicesArray[i].platformTotals.filter(item => item.type === "total");
									var platformComissionValue = platformComissionArray.length > 0 ? platformComissionArray[0].value : 0;
									serviceConfirmedPlatformComission += platformComissionValue;
									
									// Sum Provider comission
									var providerComissionArray = servicesArray[i].providerTotals.filter(item => item.type === "total");
									var providerComissionValue = providerComissionArray.length > 0 ? providerComissionArray[0].value : 0;
									serviceConfirmedProviderComission += providerComissionValue;

									// Sum Partner comission
									var partnerComissionArray = servicesArray[i].partnerTotals.filter(item => item.type === "total");
									var partnerComissionValue = partnerComissionArray.length > 0 ? partnerComissionArray[0].value : 0;
									serviceConfirmedPartnerComission += partnerComissionValue;
								
								}// end if	

								// filter status DISPUTE
								else if (servicesArray[i].status === 4) {

									// Sum Platform comission
									var platformComissionArray = servicesArray[i].platformTotals.filter(item => item.type === "total");
									var platformComissionValue = platformComissionArray.length > 0 ? platformComissionArray[0].value : 0;
									serviceDisputePlatformComission += platformComissionValue;
									
									// Sum Provider comission
									var providerComissionArray = servicesArray[i].providerTotals.filter(item => item.type === "total");
									var providerComissionValue = providerComissionArray.length > 0 ? providerComissionArray[0].value : 0;
									serviceDisputeProviderComission += providerComissionValue;

									// Sum Partner comission
									var partnerComissionArray = servicesArray[i].partnerTotals.filter(item => item.type === "total");
									var partnerComissionValue = partnerComissionArray.length > 0 ? partnerComissionArray[0].value : 0;
									serviceDisputePartnerComission += partnerComissionValue;
								
								}// end if	

								// filter status CANCELLED
								else if (servicesArray[i].status === 99) {

									// Sum Platform comission
									var platformComissionArray = servicesArray[i].platformTotals.filter(item => item.type === "total");
									var platformComissionValue = platformComissionArray.length > 0 ? platformComissionArray[0].value : 0;
									serviceCancelledPlatformComission += platformComissionValue;
									
									// Sum Provider comission
									var providerComissionArray = servicesArray[i].providerTotals.filter(item => item.type === "total");
									var providerComissionValue = providerComissionArray.length > 0 ? providerComissionArray[0].value : 0;
									serviceCancelledProviderComission += providerComissionValue;

									// Sum Partner comission
									var partnerComissionArray = servicesArray[i].partnerTotals.filter(item => item.type === "total");
									var partnerComissionValue = partnerComissionArray.length > 0 ? partnerComissionArray[0].value : 0;
									serviceCancelledPartnerComission += partnerComissionValue;
								
								}// end if									
								
							} // end for

						// Set obj comissions
						var comissionsObj = {
							serviceReviewPlatformComission:serviceReviewPlatformComission,
							serviceReviewProviderComission:serviceReviewProviderComission,
							serviceReviewPartnerComission:serviceReviewPartnerComission,
							
							serviceConfirmedPlatformComission:serviceConfirmedPlatformComission,
							serviceConfirmedProviderComission:serviceConfirmedProviderComission,
							serviceConfirmedPartnerComission:serviceConfirmedPartnerComission,
							
							serviceDisputePlatformComission:serviceDisputePlatformComission,
							serviceDisputeProviderComission:serviceDisputeProviderComission,
							serviceDisputePartnerComission:serviceDisputePartnerComission,
							
							serviceCancelledPlatformComission:serviceCancelledPlatformComission,
							serviceCancelledProviderComission:serviceCancelledProviderComission,
							serviceCancelledPartnerComission:serviceCancelledPartnerComission
						} // end var							

						// Set totals comissions
						props.setServiceComissions(comissionsObj);
						
						// Retrieve data to controller
						props.setServiceOrdersRows(response.data.services);
						props.setTotalRecords(response.data.count);
						
						// set rowCount first
						props.setLoading(false);
						return;
						
					 })
					.catch(error=> {
						props.setLoading(false);			
						props.setServiceOrdersRows();
						console.log(error);
						return;
					});		
					
		}
}

export const retrieveServiceOrderIncoming = (data,props) => {
	
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
					// Prepare Params
					var preparedData = {}
					preparedData.serviceOrderId =  data.serviceOrderId;
												
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+"/api/v1/service/incoming/retrieve",
						   method : 'POST',
						   data   : preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})
					
					.then((response) => {
								
						// Set Status History
						props.setStatusHistory(response.data.service.ServiceOrderStatusHistories);
						// Turn array to flat
						const finalObj = response.data.service;
						props.setServiceOrderCol(finalObj);	
						props.setLoadingIncoming(false);	
						return;						
						
					 })
					.catch(error=> {	
						props.setServiceOrderCol({});						
						console.log(error);
						return;						
					});		
					
		}
}

