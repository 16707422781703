import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux'
import store from './Store/store'
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";

import "assets/scss/material-dashboard-pro-react.scss?v=1.10.1";

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
<Provider store={store}>
  <BrowserRouter>
    <Switch>
      <Route path="/auth" component={AuthLayout} />
      <Route path="/admin" component={AdminLayout} />
      <Redirect from="/" to="/admin/dashboard" />
    </Switch>
  </BrowserRouter>
 </Provider>
);

