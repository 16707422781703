import React from 'react';
import axios from 'axios';
import moment from 'moment';
import jwt_decode from "jwt-decode";
import * as types from '../actionTypes';
import * as config from '../../Config/api.js';

export const setButtonLoading  = (status) => { 
	return { 
		type: types.BUTTON_LOADING,
		payload: status 
	}
};

export const setDialog  = (target,status) => { 
	return { 
		type: types.SET_DIALOG,
		payload: {target:target,status:status} 
	}
};

export const setAppLoading = (value) => ({
  type: types.APP_LOADING,
  payload: value
});


export const setProviders = (value) => ({
  type: types.SET_PROVIDERS,
  payload: value
});

export const setProvider = (value) => ({
  type: types.SET_PROVIDER,
  payload: value
});

export const retrieveApprovalProviders = (data,props) => {
	
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  

					// Prepare Params
					var preparedData = {}
					preparedData.status =  data.status;
					preparedData.pagination = {
						first:data.first,
						rows:data.rows,
						page:data.page,
						sortField:data.sortField ? data.sortField:null,
						sortOrder:data.sortOrder ? data.sortOrder:null,
						pageCount:data.pageCount
					}					
					preparedData.filters =  data.filters ? data.filters:null;
				
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/provider/retrieveApprovals',
						   method : 'POST',
						   data   : preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					}) 						
					
					.then((response) => {
						
						// Retrieve data to controller
						props.setProvidersRows(response.data.providers);
						props.setTotalRecords(response.data.count);

						// set rowCount first
						props.setLoading(false);
						return;
						
					 })
					.catch(error=> {
						props.setLoading(false);			
						props.setProvidersRows();
						console.log(error);
						return;
					});		
					
		}
}

export const retrieveApprovalProvider = (id,props) => {
	
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
							
					const urlEnd = config.API_URL+`/api/v1/provider/retrieveApproval/${id}`;
					
					// Retrieve CURRENT user STATUS
					axios({
						   url    : urlEnd,
						   method : 'GET',
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})	
					
					.then((response) => {
		
						dispatch(setProvider(response.data.provider[0]));
						
						// Turn array to flat
						const finalObj = response.data.provider[0];

						props.setProviderCol(finalObj);				

						 const googleMapsUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${response.data.provider[0].ProviderProfile.ProviderAddress.fullAddress}&zoom=13&size=600x400&maptype=roadmap&markers=color:blue%7Clabel:S%7C40.702147,-74.015794&markers=color:green%7Clabel:G%7C40.711614,-74.012318&markers=color:red%7Clabel:C%7C40.718217,-73.998284&key=AIzaSyBdE3uu0p9cJ_961a03aXMo0kGSzuUfTVE`;

						 props.setMapImageUrl(googleMapsUrl);	

						dispatch(setAppLoading(false));	
						props.setContentVisible(true);			
						
					 })
					.catch(error=> {
						
						dispatch(setAppLoading(false));		
						props.setProviderCol({});						
						console.log(error);
						
					});		
					
		}
}

export const retrieveProviders = (data,props) => {
	
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  

					// Prepare Params
					var preparedData = {}
					preparedData.status =  data.status;
					preparedData.pagination = {
						first:data.first,
						rows:data.rows,
						page:data.page,
						sortField:data.sortField ? data.sortField:null,
						sortOrder:data.sortOrder ? data.sortOrder:null,
						pageCount:data.pageCount
					}					
					preparedData.filters =  data.filters ? data.filters:null;
				
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/provider/list',
						   method : 'POST',
						   data   : preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					}) 						
					
					.then((response) => {
						
						// Retrieve data to controller
						props.setProvidersRows(response.data.providers);
						props.setTotalRecords(response.data.count);

						// set rowCount first
						props.setLoading(false);
						return;
						
					 })
					.catch(error=> {
						props.setLoading(false);			
						props.setProvidersRows();
						console.log(error);
						return;
					});		
					
		}
}

export const retrieveProvider = (id,props) => {
	
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
					const urlEnd = config.API_URL+`/api/v1/provider/retrieve/${id}`;
				
					// Connect to Endpoint
					axios({
						   url    : urlEnd,
						   method : 'GET',
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})
					.then((response) => {

		
						dispatch(setProvider(response.data.provider[0]));
						
						// Turn array to flat
						const finalObj = response.data.provider[0];
						
						// Force Iugu Status [DEBUG]
						finalObj.iuguStatus = response.data.provider[0].ProviderIuguAccount ? response.data.provider[0].ProviderIuguAccount.status : null;
						finalObj.iuguBankStatus = response.data.provider[0].ProviderIuguAccount ? response.data.provider[0].ProviderIuguAccount.bankAccountStatus : null;
						props.setProviderCol(finalObj);	

						const finalAddress = response.data.provider[0].ProviderProfile.ProviderAddress.streetType+" "+response.data.provider[0].ProviderProfile.ProviderAddress.streetName+", "+response.data.provider[0].ProviderProfile.ProviderAddress.streetNumber+", "+response.data.provider[0].ProviderProfile.ProviderAddress.suburb+", "+response.data.provider[0].ProviderProfile.ProviderAddress.city+", "+response.data.provider[0].ProviderProfile.ProviderAddress.state;

						
						// Set Agenda Grid Array
						var agendaGridArray = response.data.provider[0].ProviderServiceBookingCalendars;
						var agendaGridObj = [];
						for (var i = 0; i < agendaGridArray.length; i++) {
							var value = agendaGridArray[i].weekDay+","+agendaGridArray[i].period;							
							agendaGridObj.push(value);
						} // end for
						 props.setAgenda(agendaGridObj); // set agenda
						
						 const googleMapsUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${finalAddress}&zoom=13&size=600x400&maptype=roadmap&markers=color:blue%7Clabel:S%7C40.702147,-74.015794&markers=color:green%7Clabel:G%7C40.711614,-74.012318&markers=color:red%7Clabel:C%7C40.718217,-73.998284&key=AIzaSyBdE3uu0p9cJ_961a03aXMo0kGSzuUfTVE`;
						 props.setMapImageUrl(googleMapsUrl);	
							
						var ProviderUserId = finalObj.id ? finalObj.id : null;
						var avatar = finalObj.ProviderProfile.avatar ? finalObj.ProviderProfile.avatar:null;
						var avatarImageUrl = config.API_URL+"/provider/avatar/retrieve/"+ProviderUserId+"/"+avatar+"";
						props.setAvatar(avatarImageUrl);

						dispatch(setAppLoading(false));	
						props.setContentVisible(true);			
						
					 })
					.catch(error=> {
						
						dispatch(setAppLoading(false));		
						props.setProviderCol({});						
						console.log(error);
						
					});		
					
		}
}


export const approveProvider = (data,props) => {
	
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
								
				// Prepare for login 
				var preparedData = {
					ProviderUserId:""+data.ProviderUserId+""					
				};

				// Connect to Endpoint
				axios({
					   url    : config.API_URL+'/api/v1/provider/approve',
					   method : 'POST',
					   data   : preparedData,
					   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
				})			
				
				.then((response) => {
	
				// If an error occured
				if (response.data.error) {					
					props.setLoading(false);;
					props.setDialogMessage('Ocorreu um erro ao se comunicar com o servidor. Tente novamente.');
					dispatch(setDialog('alert',true));
					return;			
				} // end if	
	
  
				if (response.data.success) {
					
					props.setLoading(false);;
					
					// If company already exist
					if (response.data.notfound) {
						props.setDialogMessage('Não foi possível aprovar o prestador. Tente recarregar a página e tente novamente.');
						dispatch(setDialog('alert',true));						
					} // end if
					
						props.setDialogTitle("Tudo certo! Prestador aprovado!");
						props.setDialogMessage('O prestador foi aprovado com sucesso! O mesmo foi notificado via aplicativo e via email e já poderá acessar a plataforma.');
						props.setDialogAction("/admin/providers");
						dispatch(setDialog('alert',true));			
		
						return;
						
					
				} // end if
					
				}, (error) => {
															
					props.setLoading(false);;
					props.setDialogMessage('Ocorreu um erro ao se comunicar com o servidor. Tente novamente.');
					dispatch(setDialog('alert',true));				
										
				})
				.catch(error=> {
					
					console.log("error: "+JSON.stringify(error.message));
	
					props.setLoading(false);;
					props.setDialogMessage('Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.');	
					dispatch(setDialog('alert',true));					

				});

			
		} // end dispatch
	
};

export const reproveProvider = (data,props) => {
	
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
								
				// Prepare for login 
				var preparedData = {
					ProviderUserId:data.ProviderUserId,
					reproveReason:data.reproveReason
					
				};

				// Connect to Endpoint
				axios({
					   url    : config.API_URL+'/api/v1/provider/reprove',
					   method : 'POST',
					   data   : preparedData,
					   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
				})			
				
				.then((response) => {
	
				// If an error occured
				if (response.data.error) {					
					props.setLoading(false);;
					props.setDialogMessage('Ocorreu um erro ao se comunicar com o servidor. Tente novamente.');
					dispatch(setDialog('alert',true));
					return;			
				} // end if	
	
  
				if (response.data.success) {
					
					props.setLoading(false);;
					
					// If company already exist
					if (response.data.notfound) {
						props.setDialogMessage('Não foi possível aprovar o prestador. Tente recarregar a página e tente novamente.');
						dispatch(setDialog('alert',true));						
					} // end if
					
						props.setDialogTitle("Tudo certo! Prestador foi reprovado!");
						props.setDialogMessage('O prestador foi reprovado com sucesso! O mesmo foi notificado via aplicativo e via email.');
						props.setDialogAction("/admin/approveproviders");
						dispatch(setDialog('alert',true));			
		
						return;
						
					
				} // end if
					
				}, (error) => {
															
					props.setLoading(false);;
					props.setDialogMessage('Ocorreu um erro ao se comunicar com o servidor. Tente novamente.');
					dispatch(setDialog('alert',true));				
										
				})
				.catch(error=> {
					
					console.log("error: "+JSON.stringify(error.message));
	
					props.setLoading(false);;
					props.setDialogMessage('Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.');	
					dispatch(setDialog('alert',true));					

				});

			
		} // end dispatch
	
};




