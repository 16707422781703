import React,{useState,useRef} from "react";
import {useHistory,Link} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// @material-ui/core components
import './DataTable.css';
import { makeStyles,lighten } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";

// import { DataGrid,GridToolbar,ptBR  } from '@material-ui/data-grid';
import moment from "moment";

// Import Prime React Components
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import 'primereact/resources/themes/saga-blue/theme.css'; 
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Calendar } from 'primereact/calendar';
import { locale, addLocale, updateLocaleOption, updateLocaleOptions, localeOption, localeOptions } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import { TreeTable } from 'primereact/treetable';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Backdrop from '@material-ui/core/Backdrop';
import { Fieldset } from 'primereact/fieldset';
import { SelectButton } from 'primereact/selectbutton';
import { InputTextarea } from 'primereact/inputtextarea';
import { Chips } from 'primereact/chips';
import { Panel } from 'primereact/panel';
import { RadioButton } from 'primereact/radiobutton';


// Add Locale
addLocale('pt-BR', {
    firstDayOfWeek: 1,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar'
});

locale('pt-BR');

import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';


// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import Person from "@material-ui/icons/Person";
import View from '@material-ui/icons/Visibility';
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import ZoomInIcon from '@material-ui/icons/ZoomIn';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Table from "components/Table/Table.js";
// import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

// Load Actions
import * as AppActions from 'Store/Actions/App'
import * as ProviderActions from 'Store/Actions/Provider'

const useStyles = makeStyles(styles);

const Categories = (props) => {

// set vars from props
const { 
	appLoading,
	setAppLoading,
} = props;		


const [value, setValue] = React.useState(0);
const [contentVisible, setContentVisible] = React.useState(false); 

// console.log("[providers]: "+JSON.stringify(providers,null,2));
	
const classes = useStyles();

// Handle Tab changes
const handleChange = (event, newValue) => {
	
// Check which TAB was picked
//
		
setValue(newValue);

};

 
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

	
		
// set Use of history for PUSH 
let history = useHistory();

// Tasks to execute when mount component
React.useEffect(() => {
	
	setTimeout(function() {
			
		setAppLoading(false);
		setContentVisible(true);		
		
	},1000);

    return () => {
     setAppLoading(true);
    };	
	
}, []);

	
if(!contentVisible) {	  
	return (
		<div style={{width:"100%",height:"80vh",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
		
			<div style={{display:"flex",flex:1,alignItems:"center",justifyContent:"center"}}>
			
				<CircularProgress color="primary" />
				<div style={{fontSize:"18px",marginLeft:"15px"}}> Carregando dados...</div>
			
			</div>
		
		</div>
	)
  }

if(contentVisible) {
  return ( 
  <>	
   <Box ml={3} mb={5}>
   
	 <Breadcrumbs aria-label="breadcrumb">
	  <Typography color="textSecondary">Área Gerencial</Typography>
	  <Typography color="textPrimary">Cadastro de categorias</Typography>
	</Breadcrumbs>
	
	<Divider />
	
	<h3 style={{marginTop:"30px"}}>Cadastro de categorias</h3>
	<h5>Cadastro de categorias onde os serviços são indexados.</h5>	
		
	</Box>
  
    <GridContainer>
	
      <GridItem xs={12}>

		<Tab1Content
			setAppLoading={setAppLoading}
			setContentVisible={setContentVisible}
		/>
		

	  </GridItem>
	  
    </GridContainer>
	</>
  );
}

return (null);

}

const mapStateToProps = store => ({
  buttonLoading:store.appReducer.buttonLoading
 });

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions }, dispatch);


// Tab1Content Object
export const Tab1Content = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {

// set vars from props
const { 
	appLoading,
	setAppLoading,
	retrieveCategories
} = props;		

// Set classes
const classes = useStyles();	

// useRef
var dt = useRef(null);

// Scope data
const [categoriesRows, setCategoriesRows] = useState([]);

// Filter fields
const [filterByName, setFilterByName] = useState("");
const [filterByEmail, setFilterByEmail] = useState("");
const [filterByContactName, setFilterByContactName] = useState("");
const [filterByCompanyDocument, setFilterByCompanyDocument] = useState("");
const [filterByDocument, setFilterByDocument] = useState("");
const [filterByCreatedAt, setFilterByCreatedAt] = useState("");
const [filterByStatus, setFilterByStatus] = useState(null); 

// Dialog control
const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico"); 

// Service Dialog control
const [alertCategory, setAlertCategory] = React.useState(false); 
const [alertCategoryUpdate, setAlertCategoryUpdate] = React.useState(false); 
const [categoryId, setCategoryId] = React.useState(null); 

// DataGrid Params
const [loading, setLoading] = useState(false);
const [parentLoading, setParentLoading] = useState(false);

// DataGrid Params
const [totalRecords, setTotalRecords] = useState(0);
const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
		filters:null,
		sortField:"createdAt",
		sortOrder:1
    });

React.useEffect(() => {

	loadLazyData();

},[lazyParams]);
 
const loadLazyData = () => {
	
	setLoading(true);
	
	// Retrieve List of Categories
	retrieveCategoriesAction();
}

const onPage = (event) => {
	let _lazyParams = { ...lazyParams, ...event };
	setLazyParams(_lazyParams);
}

const onSort = (event) => {	
	let _lazyParams = { ...lazyParams, ...event };
	setLazyParams(_lazyParams);
}

const onFilter = (event) => {
	let _lazyParams = { ...lazyParams, ...event };
	_lazyParams['first'] = 0;
	setLazyParams(_lazyParams);
}

// ToolBar Content  
const leftToolbarTemplate = () => {
	return (
		<React.Fragment>
			
		</React.Fragment>
	)
}

const rightToolbarTemplate = () => {
	return (
		<React.Fragment>
			
		</React.Fragment>
	)
}  
  
// Header Template  
const header = (
	<div className="table-header" style={{height:"40px"}}>
		<h5 style={{float:"left"}}> Lista categorias e subcategorias </h5>
		<span style={{float:"right"}}>
			<Button 
					onClick={() => { setAlertCategory(true); }}
			 >
			  Nova Categoria
			</Button>			
		</span>			
	</div>
);	

// Column Name Body Template  
const nameBodyTemplate = (rowData) => {
	return (
		<React.Fragment>
			{rowData.data.name}
		</React.Fragment>
	);
}  

// Column Description Body Template  
const descriptionBodyTemplate = (rowData) => {

	return (
		<React.Fragment>
			{rowData.data.description}
		</React.Fragment>
	);
} 

// Column Description Body Template  
const iconBodyTemplate = (rowData) => {

	var imageFlag = false;
	if (rowData.data.icon) {
		var imageFlag = true;
	} // end if

	return (
		<React.Fragment>
			
			{imageFlag &&
			<img src={rowData.data.icon} style={{width:"auto",height:"32px",maxWidth:"58px"}} />
			}
			
			{!imageFlag &&
			<span>-</span>
			}
			
		</React.Fragment>
	);
}  
	
// Column CreatedAt Body Template  
const createdBodyTemplate = (rowData) => {
	return (
		<React.Fragment>
			<span className="p-column-title">Criado em</span>
			{moment(rowData.createdAt).format("DD/MM/YYYY")}
		</React.Fragment>
	);
}	

// Column Activation Status Body Template  
const statusBodyTemplate = (rowData) => {
	
	if (rowData.data.status == 0) {
		var activationStatus = "BLOQUEADO";
		var activationStatusLabel = "Inativo";
	} // end if
	else if (rowData.data.status == 1) {
		var activationStatus = "ATIVADO";
		var activationStatusLabel = "Ativo";
	} // end if
	else if (rowData.data.status == 9) {
		var activationStatus = "ATIVADO";
		var activationStatusLabel = "Ativo";
	} // end if
	else {
		var activationStatus = "ENCERRADO";
		var activationStatusLabel = "Indefinido";
	} // end else
	 
	return (
	<>
	<span className={`product-badge status-${activationStatus.toLowerCase()}`}>{activationStatusLabel}</span>
	</>
	)
}	

// Template for Activation Status DropDown List
const statusItemTemplate = (option) => {	
	return <span className={`product-badge status-${option.id.toLowerCase()}`}>{option.label}</span>;
}	

// Column Actions Body Template  
// NEED TO ADD ROW ID AND ONCLICK EVENT
const actionBodyTemplate = (rowData) => {
	
	return (
		<React.Fragment>
			<Button label="Editar" icon="pi pi-pencil" className="p-button-outlined p-button-secondary p-button-sm" style={{margin:"3px"}} onClick={() => { openUpdateDialog(rowData.key); }} />
		</React.Fragment>
	);
}	

const filterDate = (value, filter) => {
	if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
		return true;
	}

	if (value === undefined || value === null) {
		return false;
	}

	return value === formatDate(filter);
}

const formatDate = (date) => {
	let month = date.getMonth() + 1;
	let day = date.getDate();

	if (month < 10) {
		month = '0' + month;
	}

	if (day < 10) {
		day = '0' + day;
	}

	return date.getFullYear() + '-' + month + '-' + day;
}		

// Apply Filter
const applyFilterAction = () => {
	
	// Check if any of the filters are not equal to NULL
	if (!filterByName && !filterByEmail && !filterByContactName && !filterByCompanyDocument && !filterByDocument && !filterByCreatedAt && !filterByStatus) {
		setAlertTitle("Verifique as informações");
		setAlertBody("Para aplicar o filtro você tem que ao menos selecionar ou preencher algum item para filtrar.");
		setAlert(true);
		return;
	} // end if
	
	var filters = {};
	filters.realName = filterByName ? filterByName:null;
	filters.email = filterByEmail ? filterByEmail:null;
	filters.completeName = filterByContactName ? filterByContactName:null;
	filters.document = filterByCompanyDocument ? filterByCompanyDocument:null;
	filters.personalDocument = filterByDocument ? filterByDocument:null;
	filters.createdAt = filterByCreatedAt ? filterByCreatedAt:null;
	filters.status = filterByStatus ? filterByStatus.code:null;
	
	// Apply filter on Data from service 
	let _lazyParams = { ...lazyParams, filters:filters };
	setLazyParams(_lazyParams);
	
}

// Reset Filter
const resetFilterAction = () => {
	
	setFilterByName("");
	setFilterByEmail("");
	setFilterByContactName("");
	setFilterByCompanyDocument("");
	setFilterByDocument("");
	setFilterByCreatedAt("");
	setFilterByStatus("");
	setFilterByStatus(null);
	
	var filters = null;
	
	// Apply filter on Data from service 
	let _lazyParams = { ...lazyParams, filters:filters };
	setLazyParams(_lazyParams);	
	
	// Load Data from service with defautl option
	// ...
}

// Filter fields and action buttons

	// Name Filter
	const nameFilter = <InputText type="search" value={filterByName} onChange={(e) => setFilterByName(e.target.value)} className="p-column-filter" placeholder="Filtrar por nome" />;	
	
	// Email Filter
	const emailFilter = <InputText type="search" value={filterByEmail} onChange={(e) => setFilterByEmail(e.target.value)} className="p-column-filter" placeholder="Filtrar por email" />;	
	
	// Phone Filter
	const contactNameFilter = <InputText type="search" value={filterByContactName} onChange={(e) => setFilterByContactName(e.target.value)} className="p-column-filter" placeholder="Filtrar por nome" />;		
	
	// Company Document
	const companyDocumentFilter = <InputText type="search" value={filterByCompanyDocument} onChange={(e) => setFilterByCompanyDocument(e.target.value)} className="p-column-filter" placeholder="Filtrar por CNPJ" />;

	// Personal Document
	const documentFilter = <InputText type="search" value={filterByDocument} onChange={(e) => setFilterByDocument(e.target.value)} className="p-column-filter" placeholder="Filtrar por CPF" />;	
	
	// CreatedAt Filter
	const dateFilter = <Calendar locale="pt-BR" showButtonBar value={filterByCreatedAt} onChange={(e) => setFilterByCreatedAt(e.target.value)} dateFormat="dd/mm/yy" className="p-column-filter" placeholder="Filtrar por data"/>;	

	// Set Status Dropdown Content
	const statuses = [{"id":"BLOQUEADO","label":"Complemento pendente","code":1},{"id":"REVIEW","label":"Validação pendente","code":2},{"id":"ATIVADO","label":"Completo","code":3}];

	// Activation Status Filter
	const statusFilter = <Dropdown value={filterByStatus} options={statuses} onChange={(e) => setFilterByStatus(e.target.value)} itemTemplate={statusItemTemplate} placeholder="Selecione o Status" className="p-column-filter" showClear />;

	// Action Buttons 
	const actionFilter = <><Button label="Aplicar Filtro" icon="pi pi-check" className="p-button-outlined p-button-primary" onClick={() => applyFilterAction()} style={{marginRight:"5px"}} /> {lazyParams.filters && <Button title="Limpar Filtros" icon="pi  pi-filter-slash" className=" p-button-info p-button-outlined" onClick={() => resetFilterAction()} />}</>
		  

// Dialog Footer
const renderFooter = () => {
	return (
		<div>
			<Button label="OK" icon="pi pi-check" onClick={() => setAlert(false)} />
		</div>
	);
}		
		
// Tasks to execute when mount component
React.useEffect(() => {

	// on component mount
	//console.log("[MOUNTED COMPONENT...]--->");

    return () => {
     setAppLoading(true);
    };			
}, []);

		
const retrieveCategoriesAction = () => {

	var data = {
		status:[1,2,3],
		...lazyParams
	}
	var propsParam = {
		setCategoriesRows:setCategoriesRows,
		setLoading:setLoading,
		setTotalRecords:setTotalRecords
	}	
	retrieveCategories(data,propsParam);

} // end 


// Open Category Update Dialog
const openUpdateDialog = (id) => {

	setCategoryId(id);
	setAlertCategoryUpdate(true);

} // end 

// set Use of history for PUSH 
let history = useHistory();
	
	return (
	<>
		<Backdrop style={{zIndex:"999999",flexDirection:"column"}} open={parentLoading} onClick={()=>{}}>
		  <CircularProgress style={{zIndex:"9999999",color:"#FFF"}} /><br />
		  <div style={{color:"#FFF"}}>Processando...aguarde!</div>
		</Backdrop>		
		<Card>
			  <CardHeader color="info" icon>
				<CardIcon color="info">
				  <Assignment />
				</CardIcon>
				
			  </CardHeader>
	
			  <CardBody>	  

				<div className="datatable-responsive-demo" style={{marginTop:"20px"}}>
				
					<div className="card">
							
						<TreeTable value={categoriesRows} header={header} loading={loading} columnResizeMode="fit" >
							<Column header="Nome" body={nameBodyTemplate} expander></Column>
							<Column header="Descrição" body={descriptionBodyTemplate}  ></Column>
							<Column header="Ícone" body={iconBodyTemplate} ></Column>
							<Column field="order" header="Ordem" ></Column>
							<Column header="Status" body={statusBodyTemplate} ></Column>
							<Column body={actionBodyTemplate} style={{ textAlign: 'center', width: '10rem' }} />
						</TreeTable>
									
						
					</div>
				</div>
								
			  </CardBody>
			</Card>	
			
			<NewCategory
				alertCategory={alertCategory}
				setAlertCategory={setAlertCategory}
				loadLazyData={loadLazyData}
				setAlertTitle={setAlertTitle}
				setAlertBody={setAlertBody}
				setAlert={setAlert}
				setParentLoading={setParentLoading}
			/>
			
			<UpdateCategory
				alertCategoryUpdate={alertCategoryUpdate}
				setAlertCategoryUpdate={setAlertCategoryUpdate}
				loadLazyData={loadLazyData}
				setAlertTitle={setAlertTitle}
				setAlertBody={setAlertBody}
				setAlert={setAlert}
				setParentLoading={setParentLoading}
				categoryId={categoryId}
			/>

			<Dialog header={alertTitle} visible={alert} style={{ width: '50vw' }} footer={renderFooter()} onHide={() => setAlert(false)} draggable={false} dismissableMask={true} closable={false} >
				<p>{alertBody}</p>
			</Dialog> 
			
	</>	
	);
	
});


// NewService dialog component
export const NewCategory = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
		
// Set classes
const classes = useStyles();		

// set vars from props
const { 
	alertCategory,
	setAlertCategory,
	loadLazyData,
	buttonLoading,
	setAlertTitle,
	setAlertBody,
	setAlert,
	retrieveCategoriesTreeDroplist,
	createCategory,
	setParentLoading
} = props;		

// Service Details 
const [categoryName, setCategoryName] = useState(""); 
const [categoryDescription, setCategoryDescription] = useState(""); 
const [categoryDescriptionCounter, setCategoryDescriptionCounter] = useState(0); 
const [categoryTags, setCategoryTags] = useState(""); 
const [categoryBreadcrumb, setCategoryBreadcrumb] = useState(""); 
const [categoryParent, setCategoryParent] = useState(""); 
const [categoryOrder, setCategoryOrder] = useState(0); 
const [categoryStatus, setCategoryStatus] = useState(0); 

// Set categories options and loading
const [categoriesTreeRows, setCategoriesTreeRows] = useState([]);
const [parentCategoriesTreeRows, setParentCategoriesTreeRows] = useState([]);
const [categoriesLoading, setCategoriesLoading] = useState(true);

const [saving, setSaving] = useState(false); 

const [iconImage, setIconImage] = useState({}); 
const [iconImageType, setIconImageType] = useState("uploadedImage");
const [iconImageUrl, setIconImageUrl] = useState(""); 

// Options for status
const statuses = [
    {label: 'Inativo', value: '0'},
    {label: 'Ativo', value: '1'}
];	
	
// Execute when dialog opens
React.useEffect(() => {

	// Only r]uns when  dialog opens
	if (alertCategory === true) {
	
		// Retrieve Categories and set to screen
		var propsParam = {
			setCategoriesTreeRows:setCategoriesTreeRows,
			setParentCategoriesTreeRows:setParentCategoriesTreeRows,
			setCategoriesLoading:setCategoriesLoading
		}	
		retrieveCategoriesTreeDroplist(propsParam);
		
	} // end if
	
	// Reset all fields when closes or open dialog
	// ...
	
	// Reset images when open interface
	setIconImage({});
	
		
},[alertCategory]);

// Open alert when save button is disabled
const openAlert = (section) => {

	if (section == 'category') {
		setAlertTitle("Verifique as informações");
		setAlertBody("Preencha os dados da categoria marcados com * para prosseguir com o cadastro.");
		setAlert(true);
	} // end if
	else if (section == 'images') {
		setAlertTitle("Verifique as imagens");
		setAlertBody("Você deve definir a imagem do ícone da categoria enviando uma imagem ou definindo uma url externa para prosseguir com o cadastro.");
		setAlert(true);
	} // end if	
	else if (section == 'imagesurl') {
		setAlertTitle("Verifique a URL das imagens");
		setAlertBody("A URL informada para uma ou mais imagem é inválida. Verifique e corrija para prosseguir.");
		setAlert(true);
	} // end if	
	else {
		setAlertTitle("Verifique as informações");
		setAlertBody("Preencha os dados mínimos marcados com * para prosseguir com o cadastro.");
		setAlert(true);
	} // end if	
			
} // end set Region		

// Handle file selector click
const handleClick = event => {
hiddenFileInput.current.click();
};

// Store file input for selection
const hiddenFileInput = React.useRef(null);

// Set sent image to interface
const onChangeHandlerIcon=event=>{

	// set image
	var image = event.target.files[0];

	// Extract file specs
	var imageObj = {
		name:image.name,
		size:image.size,
		type:image.type,
		image: URL.createObjectURL(event.target.files[0]),
		base64image:event.target.files[0]
	}	
	
	// set final obj
	setIconImage(imageObj);

}
		
// Heade template of icon image upload
const iconUploadHeaderTemplate = (options) => {
	
        const className = `${options.className} justify-content-start`;

        return (
            <div className={className}>
			
			<RadioButton name="iconImageType" value="uploadedImage" onChange={(e) => setIconImageType(e.value)} checked={iconImageType === 'uploadedImage'} style={{marginRight:"20px"}} />
				  
				<Button variant="contained" disabled={iconImageType === 'urlImage'} color="primary" component="span" onClick={handleClick}>
					Selecionar imagem
				</Button>

				<input
					accept="image/*"
					style={{display:"none"}}
					ref={hiddenFileInput}
					onChange={onChangeHandlerIcon}
					id="iconUploadButton"
					type="file"
				/>	
				
            </div>
        )
} // end function

// Save instance method	
const save = () => {

	// Check if min CATEGORY DETAILS fields are filled
	if (!categoryName || !categoryTags || !categoryParent || !categoryStatus) {
		openAlert('category');
		return;
	} // end if

	// IMAGES CHECK  
	// check if icon image is set
	if (iconImageType == 'uploadedImage') {
		if (!iconImage.image)  {
			openAlert('images');
			return;			
		} // end if
	} // end if
	else {
		if (iconImageUrl == "")  {
			openAlert('images');
			return;			
		} // end if	
		else {
			var pattern = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
			if (!pattern.test(iconImageUrl)) {
				openAlert('imagesurl');
				return;	
			} // end if
		} // end else		
	} // end else
	
	// ALL OK, save service
	
	// Turn on loading on button
	setSaving(true);	
	
	// Turn on overlay loading
	setParentLoading(true);
	
	// Prepare Data
	var data = {
		categoryName:categoryName,
		categoryDescription:categoryDescription,
		categoryTags:categoryTags,
		categoryParent:categoryParent,
		categoryOrder:categoryOrder,
		categoryStatus:categoryStatus,
		iconImage:iconImage,
		iconImageType:iconImageType,
		iconImageUrl:iconImageUrl,
		parentCategoriesTreeRows:parentCategoriesTreeRows		
	}
	// Prepare Props
	var propsParam = {
		setSaving:setSaving,
		setParentLoading:setParentLoading,
		loadLazyData:loadLazyData,
		setAlertTitle:setAlertTitle,
		setAlertBody:setAlertBody,
		setAlert:setAlert,
		setAlertCategory:setAlertCategory 
	}
	// Save
	createCategory(data,propsParam);

} // end function save
	
	return (
	<>
	<Dialog header="Nova categoria" visible={alertCategory} style={{ width: '70vw' }} footer={(<div>
			<Button label="FECHAR" disabled={saving} style={{marginTop:"20px"}} icon="pi pi-check" className="p-button-outlined p-button-secondary p-button-sm" onClick={() => {setAlertCategory(false)} } />
		</div>)} onHide={() => setAlertCategory(false)} draggable={false} dismissableMask={false} closable={saving ? false : true} closeOnEscape={false} >
				
				<GridContainer justify="center">
				  <GridItem xs={12} sm={12}>

					<Card>
					  <CardHeader color="info" icon>
						<CardIcon color="info">
						  <Assignment />
						</CardIcon>
						<h4 className={classes.cardIconTitle}>Cadastro da nova categoria de serviço</h4>
					  </CardHeader>
					  <CardBody>	 
						
						{/* Details Block */}
						<TableContainer component={Box} mt={2}>
						  <Table  aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Dados da Categoria</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Nome da Categoria <sup style={{color:"red"}}>*</sup></TableCell>
								  <TableCell align="left"><InputText style={{width:"100%"}} value={categoryName} onChange={(e) => setCategoryName(e.target.value)} maxLength="45" /></TableCell>
								</TableRow>

								<TableRow >
								  <TableCell component="th" scope="row">Descrição</TableCell>
								  <TableCell align="left"><InputTextarea  style={{width:"100%"}} rows={5} cols={30} value={categoryDescription} onChange={(e) => { if (event.target.value.length > 255) { return; }; setCategoryDescription(event.target.value);setCategoryDescriptionCounter(event.target.value.length)}} autoResize />
								   <small  style={{float:"left"}}>Descrição da categoria (até 255 chars).</small> <small style={{float:"right",color:"red"}}>{categoryDescriptionCounter} em 255 chars</small>
								  
								  </TableCell>
								</TableRow>
								
								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Tags  <sup style={{color:"red"}}>*</sup></TableCell>
								  <TableCell align="left"><Chips value={categoryTags} onChange={(e) => setCategoryTags(e.value)} separator="," />
								  <small className="block" style={{marginTop:"5px"}}>Defina uma ou mais tags relacionadas com a categoria. Digite e aperte enter por tag.</small>
								  </TableCell>
								</TableRow>	
								
								<TableRow >
								  <TableCell component="th" scope="row">Defina a categoria raiz <sup style={{color:"red"}}>*</sup></TableCell>
								  <TableCell align="left">
									  <Dropdown value={categoryParent} options={parentCategoriesTreeRows} onChange={(e) => setCategoryParent(e.value)} placeholder="Categoria raiz"/>
								  </TableCell>
								</TableRow>

								<TableRow >
								  <TableCell component="th" scope="row">Ordem</TableCell>
								  <TableCell align="left">
								  
								  <InputNumber inputId="withoutgrouping" value={categoryOrder} onValueChange={(e) => setCategoryOrder(e.value)} mode="decimal" useGrouping={false} style={{marginBottom:"10px"}} />
								   <small className="block">Defina a ordem na lista. Quanto menor o número primeiro aparece.</small>
								  </TableCell>
								</TableRow>

								<TableRow >
								  <TableCell component="th" scope="row">Status <sup style={{color:"red"}}>*</sup></TableCell>
								  <TableCell align="left">
									  <Dropdown value={categoryStatus} options={statuses} onChange={(e) => setCategoryStatus(e.value)} placeholder="Status"/>
								  </TableCell>
								</TableRow>

							</TableBody>
						  </Table>
						</TableContainer>
						
						{/* Image Block */}
						<TableContainer component={Box} mt={2}>
						  <Table  aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Imagem do ícone da categoria <small style={{color:"red",marginLeft:"10px"}}>informação obrigatória </small></TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Imagem da ícone da Categoria <sup style={{color:"red"}}>*</sup>  <br /> <small>Defina se vai enviar uma imagem ou se vai colocar uma url da mesma. <br /><br /> Tamanho sugerido é 110px x 110px</small></TableCell>
								  <TableCell align="left">
																	  
						            <Panel headerTemplate={iconUploadHeaderTemplate}>
										
										{iconImage.image &&
											<div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
											
												<div style={{flex:1}}>
													<img src={iconImage.image} style={{width:"75px",height:"auto"}} />
												</div>
												
												<div style={{flex:5,marginLeft:"20px",fontSize:"12px"}}>
													Arquivo: {iconImage.name} <br /> 
													Tamanho: {iconImage.size} bytes  <br />
													Tipo: {iconImage.type}
												</div>
												
												<div style={{flex:1}}>
													<Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => setIconImage({})} />
												</div>										
																					
											</div>
										}
										
										{!iconImage.image &&
											<div>Selecione a imagem do ícone da categoria</div>
										}
										
									</Panel>

									<div style={{padding: "1rem",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
									
										<div style={{marginRight:"20px"}}>
											<RadioButton name="iconImageType" value="urlImage" onChange={(e) => setIconImageType(e.value)} checked={iconImageType === 'urlImage'} />
										</div>
										
										<div style={{flex:1}}>									
										
											<InputText disabled={iconImageType != 'urlImage'} style={{width:"100%"}} value={iconImageUrl} onChange={(e) => setIconImageUrl(e.target.value)}  placeholder="Endereço URL Imagem" />

										</div>
									
									</div>
									
								  
								  </TableCell>
								</TableRow>
								
							</TableBody>
						  </Table>
						</TableContainer>						
						
					  </CardBody>
					</Card>	 
 
					<div style={{textAlign:"right"}}>
						<Button label="FINALIZAR CADASTRO" icon="pi pi-check" className="p-button-lg" loading={saving} onClick={() => { save(); } } />
					</div>

				  </GridItem>
				</GridContainer>				
				
			</Dialog>	
	</>	
	);
	
});

// UpdateService dialog component
export const UpdateCategory = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
		
// Set classes
const classes = useStyles();	

const screenStyleLoading = {
  alignItems: "middle",
  justifyContent: "center",
  width:"100%",
  display: "flex",
  flexDirection: "column"
};	

// set vars from props
const { 
	alertCategoryUpdate,
	setAlertCategoryUpdate,
	loadLazyData,
	buttonLoading,
	setAlertTitle,
	setAlertBody,
	setAlert,
	retrieveCategoriesTreeDroplist,
	updateCategory,
	retrieveCategory,
	categoryId,
	setParentLoading
} = props;		

// Service Details 
const [categoryName, setCategoryName] = useState(""); 
const [categoryDescription, setCategoryDescription] = useState(""); 
const [categoryDescriptionCounter, setCategoryDescriptionCounter] = useState(0); 
const [categoryTags, setCategoryTags] = useState(""); 
const [categoryBreadcrumb, setCategoryBreadcrumb] = useState(""); 
const [categoryParent, setCategoryParent] = useState(0); 
const [categoryOrder, setCategoryOrder] = useState(0); 
const [categoryStatus, setCategoryStatus] = useState(0); 

// Set categories options and loading
const [categoriesTreeRows, setCategoriesTreeRows] = useState([]);
const [parentCategoriesTreeRows, setParentCategoriesTreeRows] = useState([]);
const [categoriesLoading, setCategoriesLoading] = useState(true);

const [categoryRow, setCategoryRow] = useState({});

const [saving, setSaving] = useState(false); 
const [loadingCategory, setLoadingCategory] = useState(true); 

const [iconImage, setIconImage] = useState({}); 
const [iconImageType, setIconImageType] = useState("uploadedImage");
const [iconImageUrl, setIconImageUrl] = useState(""); 
const [icon, setIcon] = useState(""); 

// Options for status
const statuses = [
    {label: 'Inativo', value: '0'},
    {label: 'Ativo', value: '1'}
];	
	
// Execute when dialog opens
React.useEffect(() => {

	// Only r]uns when  dialog opens
	if (alertCategoryUpdate === true) {
		
		// Retrieve Categories and set to screen
		var propsParam = {
			setCategoriesTreeRows:setCategoriesTreeRows,
			setParentCategoriesTreeRows:setParentCategoriesTreeRows,
			setCategoriesLoading:setCategoriesLoading
		}	
		retrieveCategoriesTreeDroplist(propsParam);

		// Retrieve Category - add delay to wait for previous returns
		setTimeout(function() {
			var data = {
				categoryId:categoryId
			}
			var propsParam = {
				setCategoryRow:setCategoryRow,
				setLoadingCategory:setLoadingCategory
			}	
			retrieveCategory(data,propsParam);
		},1000);
		
	} // end if
	
	// Reset all fields when closes or open dialog
	// ...
	
	// Reset images when open interface
	setIconImage({});
	setCategoryRow({});
	setLoadingCategory(true);
		
},[alertCategoryUpdate]);

// Set values for each field
React.useEffect(() => {

	// If service returned set values
	if (categoryRow.id) {
		
		console.log("[categoryRow]--->"+JSON.stringify(categoryRow,null,2));
				
		// Category details
		setCategoryName(categoryRow.name);
		
		setCategoryDescription(categoryRow.description);
		setCategoryDescriptionCounter(categoryRow.description.length);
		
		if (categoryRow.tags) { var tagsArray = categoryRow.tags.split(","); setCategoryTags(tagsArray); } // array for chips
		
		setCategoryOrder(categoryRow.order);

		setCategoryParent(categoryRow.parent);
		
		var status = ""+categoryRow.status+"";
		setCategoryStatus(status);

		// Set current images of icon and header
		setIcon(categoryRow.icon);		
		
	} // end if

},[categoryRow]);

// Open alert when save button is disabled
const openAlert = (section) => {

	if (section == 'service') {
		setAlertTitle("Verifique as informações");
		setAlertBody("Preencha os dados da categoria marcados com * para prosseguir com a atualização.");
		setAlert(true);
	} // end if
	else if (section == 'images') {
		setAlertTitle("Verifique as imagens");
		setAlertBody("Você deve definir a imagem do ícone da categoria enviando uma imagem ou definindo uma url externa para prosseguir com a atualização.");
		setAlert(true);
	} // end if	
	else if (section == 'imagesurl') {
		setAlertTitle("Verifique a URL das imagens");
		setAlertBody("A URL informada para uma ou mais imagem é inválida. Verifique e corrija para prosseguir.");
		setAlert(true);
	} // end if	
	else {
		setAlertTitle("Verifique as informações");
		setAlertBody("Preencha os dados mínimos marcados com * para prosseguir com a atualização.");
		setAlert(true);
	} // end if	
			
} // end set Region		

// Handle file selector click
const handleClick = event => {
hiddenFileInput.current.click();
};

// Store file input for selection
const hiddenFileInput = React.useRef(null);

// Set sent image to interface
const onChangeHandlerIcon=event=>{

	// set image
	var image = event.target.files[0];

	// Extract file specs
	var imageObj = {
		name:image.name,
		size:image.size,
		type:image.type,
		image: URL.createObjectURL(event.target.files[0]),
		base64image:event.target.files[0]
	}	
	
	// set final obj
	setIconImage(imageObj);

}
		
// Heade template of icon image upload
const iconUploadHeaderTemplate = (options) => {
	
        const className = `${options.className} justify-content-start`;

        return (
            <div className={className}>
			
			<RadioButton name="iconImageType" value="uploadedImage" onChange={(e) => setIconImageType(e.value)} checked={iconImageType === 'uploadedImage'} style={{marginRight:"20px"}} />
				  
				<Button variant="contained" disabled={iconImageType === 'urlImage'} color="primary" component="span" onClick={handleClick}>
					Trocar imagem
				</Button>

				<input
					accept="image/*"
					style={{display:"none"}}
					ref={hiddenFileInput}
					onChange={onChangeHandlerIcon}
					id="iconUploadButton"
					type="file"
				/>	
				
            </div>
        )
} // end function

// Save instance method	
const save = () => {

	// Check if min CATEGORY DETAILS fields are filled
	if (!categoryName || (!categoryTags || categoryTags.length == 0) || !categoryParent || !categoryStatus) {
		openAlert('category');
		return;
	} // end if

	// IMAGES CHECK  
	// check if icon image is set
	if (iconImageType == 'uploadedImage') {
		if (!iconImage.image)  {
			// openAlert('images');
			// return;			
		} // end if
	} // end if
	else {
		if (iconImageUrl == "")  {
			// openAlert('images');
			// return;			
		} // end if	
		else {
			var pattern = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
			if (!pattern.test(iconImageUrl)) {
				openAlert('imagesurl');
				return;	
			} // end if
		} // end else		
	} // end else
	
	// ALL OK, save service
	
	// Turn on loading on button
	setSaving(true);	
	
	// Turn on overlay loading
	setParentLoading(true);
	
	// Prepare Data
	var data = {
		categoryId:categoryId,
		categoryName:categoryName,
		categoryDescription:categoryDescription,
		categoryTags:categoryTags,
		categoryParent:categoryParent,
		categoryOrder:categoryOrder,
		categoryStatus:categoryStatus,
		iconImage:iconImage,
		iconImageType:iconImageType,
		iconImageUrl:iconImageUrl,
		parentCategoriesTreeRows:parentCategoriesTreeRows		
	}
	// Prepare Props
	var propsParam = {
		setSaving:setSaving,
		setParentLoading:setParentLoading,
		loadLazyData:loadLazyData,
		setAlertTitle:setAlertTitle,
		setAlertBody:setAlertBody,
		setAlert:setAlert,
		setAlertCategoryUpdate:setAlertCategoryUpdate 
	}
	// Save
	updateCategory(data,propsParam);

} // end function save
	
	return (
	<>
	<Dialog header="Editar categoria" visible={alertCategoryUpdate} style={{ width: '70vw' }} footer={(<div>
			<Button label="FECHAR" disabled={saving} style={{marginTop:"20px"}} icon="pi pi-check" className="p-button-outlined p-button-secondary p-button-sm" onClick={() => {setAlertCategoryUpdate(false)} } />
		</div>)} onHide={() => setAlertCategoryUpdate(false)} draggable={false} dismissableMask={false} closable={saving ? false : true} closeOnEscape={false} >
				
				{loadingCategory && 
					<Box style={{...screenStyleLoading,height:"65vh"}}>
							<Box mt={0} mx={0} style={{textAlign:"center"}}>								
								<CircularProgress /> 
								<div> Carregando dados...Aguarde!</div>								
							</Box>	
					</Box>
				}
			
				{!loadingCategory &&
				<>	
				<GridContainer justify="center">
				  <GridItem xs={12} sm={12}>

					<Card>
					  <CardHeader color="info" icon>
						<CardIcon color="info">
						  <Assignment />
						</CardIcon>
						<h4 className={classes.cardIconTitle}>Edição categoria de serviço</h4>
					  </CardHeader>
					  <CardBody>	 
						
						{/* Details Block */}
						<TableContainer component={Box} mt={2}>
						  <Table  aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Dados da Categoria</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Nome da Categoria <sup style={{color:"red"}}>*</sup></TableCell>
								  <TableCell align="left"><InputText style={{width:"100%"}} value={categoryName} onChange={(e) => setCategoryName(e.target.value)} maxLength="45" /></TableCell>
								</TableRow>

								<TableRow >
								  <TableCell component="th" scope="row">Descrição</TableCell>
								  <TableCell align="left"><InputTextarea  style={{width:"100%"}} rows={5} cols={30} value={categoryDescription} onChange={(e) => { if (event.target.value.length > 255) { return; }; setCategoryDescription(event.target.value);setCategoryDescriptionCounter(event.target.value.length)}} autoResize />
								   <small  style={{float:"left"}}>Descrição da categoria (até 255 chars).</small> <small style={{float:"right",color:"red"}}>{categoryDescriptionCounter} em 255 chars</small>
								  
								  </TableCell>
								</TableRow>
								
								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Tags  <sup style={{color:"red"}}>*</sup></TableCell>
								  <TableCell align="left"><Chips value={categoryTags} onChange={(e) => setCategoryTags(e.value)} separator="," />
								  <small className="block" style={{marginTop:"5px"}}>Defina uma ou mais tags relacionadas com a categoria. Digite e aperte enter por tag.</small>
								  </TableCell>
								</TableRow>	
								
								<TableRow >
								  <TableCell component="th" scope="row">Defina a categoria raiz <sup style={{color:"red"}}>*</sup></TableCell>
								  <TableCell align="left">
									  <Dropdown value={categoryParent} options={parentCategoriesTreeRows} onChange={(e) => setCategoryParent(e.value)} placeholder="Categoria raiz"/>
								  </TableCell>
								</TableRow>

								<TableRow >
								  <TableCell component="th" scope="row">Ordem</TableCell>
								  <TableCell align="left">
								  
								  <InputNumber inputId="withoutgrouping" value={categoryOrder} onValueChange={(e) => setCategoryOrder(e.value)} mode="decimal" useGrouping={false} style={{marginBottom:"10px"}} />
								   <small className="block">Defina a ordem na lista. Quanto menor o número primeiro aparece.</small>
								  </TableCell>
								</TableRow>

								<TableRow >
								  <TableCell component="th" scope="row">Status <sup style={{color:"red"}}>*</sup></TableCell>
								  <TableCell align="left">
									  <Dropdown value={categoryStatus} options={statuses} onChange={(e) => setCategoryStatus(e.value)} placeholder="Status"/>
								  </TableCell>
								</TableRow>

							</TableBody>
						  </Table>
						</TableContainer>
						
						{/* Image Block */}
						<TableContainer component={Box} mt={2}>
						  <Table  aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Imagem do ícone da categoria <small style={{color:"red",marginLeft:"10px"}}>informação obrigatória </small></TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Imagem da ícone da Categoria <sup style={{color:"red"}}>*</sup>  <br /> <small>Defina se vai enviar uma imagem ou se vai colocar uma url da mesma. <br /><br /> Tamanho sugerido é 110px x 110px</small></TableCell>
								  <TableCell align="left">
																	  
						            <Panel headerTemplate={iconUploadHeaderTemplate}>
										
										{iconImage.image &&
											<div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
											
												<div style={{flex:1}}>
													<img src={iconImage.image} style={{width:"75px",height:"auto"}} />
												</div>
												
												<div style={{flex:5,marginLeft:"20px",fontSize:"12px"}}>
													Arquivo: {iconImage.name} <br /> 
													Tamanho: {iconImage.size} bytes  <br />
													Tipo: {iconImage.type}
												</div>
												
												<div style={{flex:1}}>
													<Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => setIconImage({})} />
												</div>										
																					
											</div>
										}
										
										{!iconImage.image &&
										<>
											<div>Ícone atual</div>
											<div><img src={icon} style={{width:"75px",height:"auto"}} /></div>
										</>
										}
										
									</Panel>

									<div style={{padding: "1rem",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
									
										<div style={{marginRight:"20px"}}>
											<RadioButton name="iconImageType" value="urlImage" onChange={(e) => setIconImageType(e.value)} checked={iconImageType === 'urlImage'} />
										</div>
										
										<div style={{flex:1}}>									
										
											<InputText disabled={iconImageType != 'urlImage'} style={{width:"100%"}} value={iconImageUrl} onChange={(e) => setIconImageUrl(e.target.value)}  placeholder="Endereço URL Imagem" />

										</div>
									
									</div>
									
								  
								  </TableCell>
								</TableRow>
								
							</TableBody>
						  </Table>
						</TableContainer>						
						
					  </CardBody>
					</Card>	 					
					<div style={{textAlign:"right"}}>
					
						<Button label="EXCLUIR CATEGORIA" icon="pi pi-trash" className="p-button-lg p-button-danger"  loading={saving} onClick={() => { console.log("deletar..."); } } style={{marginRight:"50px"}} />					
					
						<Button label="FINALIZAR EDIÇÃO" icon="pi pi-check" className="p-button-lg"  loading={saving} onClick={() => { save(); } } />
					</div>					

				  </GridItem>
				</GridContainer>
				</>
				}
				
			</Dialog>	
	</>	
	);
	
});


export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(Categories);	
