import React from 'react';
import axios from 'axios';
import moment from 'moment';
import jwt_decode from "jwt-decode";
import * as types from '../actionTypes';
import * as config from '../../Config/api.js';

	
export const setButtonLoading  = (status) => { 
	return { 
		type: types.BUTTON_LOADING,
		payload: status 
	}
};

export const setDialog  = (target,status) => { 

	return { 
		type: types.SET_DIALOG,
		payload: {target:target,status:status} 
	}
};

export const setAppLoading = (value) => ({
  type: types.APP_LOADING,
  payload: value
});

export const setCurrentFilter  = (value) => { 
	return { 
		type: types.SET_CURRENT_FILTER,
		payload: value 
	}
};

export const setAdminUsers = (value) => ({
  type: types.SET_ADMINUSERS,
  payload: value
});

export const setAdminUser = (value) => ({
  type: types.SET_ADMINUSER,
  payload: value
});

export const setServices = (value) => ({
  type: types.SET_SERVICES,
  payload: value
});

export const setService = (value) => ({
  type: types.SET_SERVICE,
  payload: value
});

export const setCategoriesTree = (value) => ({
  type: types.SET_CATEGORIESTREE,
  payload: value
});

export const setCategories = (value) => ({
  type: types.SET_CATEGORIES,
  payload: value
});

export const setCategory = (value) => ({
  type: types.SET_CATEGORY,
  payload: value
});

export const setPayments = (value) => ({
  type: types.SET_PAYMENTS,
  payload: value
});

export const setPayment = (value) => ({
  type: types.SET_PAYMENT,
  payload: value
});

export const setReport = (value) => ({
  type: types.SET_REPORT,
  payload: value
});

export const setRegions = (value) => {
	return({
	  type: types.SET_REGIONS,
	  payload: value
	});
};


export const retrieveAdminUsers = (data,props) => {
	
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  

					// Prepare Params
					var preparedData = {}
					preparedData.status =  data.status;
					preparedData.pagination = {
						first:data.first,
						rows:data.rows,
						page:data.page,
						sortField:data.sortField ? data.sortField:null,
						sortOrder:data.sortOrder ? data.sortOrder:null,
						pageCount:data.pageCount
					}					
					preparedData.filters =  data.filters ? data.filters:null;
				
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/manager/backenduser/list',
						   method : 'POST',
						   data   : preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					}) 						
					
					.then((response) => {
						
						// Dispatch
						dispatch(setAdminUsers(response.data.managerUsers));
						
						// Retrieve data to controller
						props.setAdminUsersRows(response.data.managerUsers);
						props.setTotalRecords(response.data.count);

						// set rowCount first
						props.setLoading(false);
						return;
						
					 })
					.catch(error=> {
						props.setLoading(false);			
						props.setAdminUsersRows([]);
						console.log(error);
						return;
					});		
					
		}
}

export const retrieveAdminUser = (data,props) => {
 
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
					// Prepare Params
					var preparedData = {}
					preparedData.managerUserId =  data.userId;	
				
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/manager/backenduser/retrieve',
						   method : 'POST',
						   data   : preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})					
					.then((response) => {
						
						// Turn array to flat
						const finalObj = response.data.managerUser;
						props.setAdminUserRow(finalObj);	
						
						props.setLoadingUser(false);
						
					 })
					.catch(error=> {
						
						props.setAdminUserRow({});
						props.setLoadingUser(false);						
						console.log(error);
						
					});		
					
		}		
		
}

export const createAdminUser = (data,props) => {
 
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);

					// Prepare Params
					var preparedData = {
						completeName:data.userName,
						email:data.userEmail,
						mobilePhone:data.userPhone,
						password:data.userPassword,
						groupId:data.userGroupId,
						accessRole:data.userLevelId,
						status:data.userStatus
					}
				
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/manager/backenduser/create',
						   method : 'POST',
						   data   : preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})								
					.then((response) => {
						
							// If an error occured. Check error is blocking or non-blocking.
							if (response.data.error) {														
								props.setSaving(false);
								props.setParentLoading(false);	
		
								props.setAlertTitle('Ops! Ocorreu um erro (cod 1)');		
								props.setAlertBody('Ocorreu um erro ao criar o usuário. Tente novamente. [1]');		
								props.setAlert(true);							
								return;					
							} // end if	*/
					
							if (response.data.success) {

							// Erro creating service
							if (response.data.userExists) {						
								props.setSaving(false);
								props.setParentLoading(false);
								props.setAlertTitle('Ops! Ocorreu um erro (cod 2)');		
								props.setAlertBody('Já existe um usuário cadastrado com o email ou telefone informado. Verifique.');		
								props.setAlert(true);							
								return;					
							} // end if
							
							// Erro creating service
							if (response.data.failedCreation) {						
								props.setSaving(false);
								props.setParentLoading(false);
								props.setAlertTitle('Ops! Ocorreu um erro (cod 2)');		
								props.setAlertBody('Ocorreu um erro ao criar o usuário. Tente novamente. [1]');		
								props.setAlert(true);							
								return;					
							} // end if	

							// refresh table
							props.loadLazyData();
							
							// close service dialog
							props.setAlertUser(false);
							
							// Stop loading
							setTimeout(function() {
								props.setSaving(false);
								props.setParentLoading(false);
							},1000);							
							
							// show success message
							setTimeout(function() {
								props.setAlertTitle('Feito! Usuário criado!');		
								props.setAlertBody('O Usuário foi criado com sucesso.');		
								props.setAlert(true);
							},1500);							
							
							return;
							} // end if
						
					 })
					.catch(error=> {

						props.setSaving(false);
						props.setParentLoading(false);
						props.setAlertTitle('Ops! Ocorreu um erro (cod 3)');		
						props.setAlertBody('Ocorreu um erro ao criar o usuário (erro: '+error.message+').');		
						props.setAlert(true);
						
					});		
					
		}		
		
}

// TO-DO
export const updateAdminUser = (data,props) => {
 
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);

					// Prepare Params
					var preparedData = {
						userId:data.userId,
						completeName:data.userName,
						email:data.userEmail,
						mobilePhone:data.userPhone,
						password:data.userPassword,
						groupId:data.userGroupId,
						accessRole:data.userLevelId,
						status:data.userStatus
					}
				
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/manager/backenduser/update',
						   method : 'PUT',
						   data   : preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})								
					.then((response) => {
						
							// If an error occured. Check error is blocking or non-blocking.
							if (response.data.error) {														
								props.setSaving(false);
								props.setParentLoading(false);	
		
								props.setAlertTitle('Ops! Ocorreu um erro (cod 1)');		
								props.setAlertBody('Ocorreu um erro ao atualizar o usuário. Tente novamente. [1]');		
								props.setAlert(true);							
								return;					
							} // end if	*/
					
							if (response.data.success) {

							// Erro creating service
							if (response.data.userExists) {						
								props.setSaving(false);
								props.setParentLoading(false);
								props.setAlertTitle('Ops! Ocorreu um erro (cod 2)');		
								props.setAlertBody('Já existe um usuário cadastrado com o email ou telefone informado. Verifique.');		
								props.setAlert(true);							
								return;					
							} // end if
							
							// Erro creating service
							if (response.data.failedCreation) {						
								props.setSaving(false);
								props.setParentLoading(false);
								props.setAlertTitle('Ops! Ocorreu um erro (cod 2)');		
								props.setAlertBody('Ocorreu um erro ao atualizar o usuário. Tente novamente. [1]');		
								props.setAlert(true);							
								return;					
							} // end if	

							// refresh table
							props.loadLazyData();
							
							// close service dialog
							props.setAlertUser(false);
							
							// Stop loading
							setTimeout(function() {
								props.setSaving(false);
								props.setParentLoading(false);
							},1000);							
							
							// show success message
							setTimeout(function() {
								props.setAlertTitle('Feito! Usuário criado!');		
								props.setAlertBody('O Usuário foi atualizado com sucesso.');		
								props.setAlert(true);
							},1500);							
							
							return;
							} // end if
						
					 })
					.catch(error=> {

						props.setSaving(false);
						props.setParentLoading(false);
						props.setAlertTitle('Ops! Ocorreu um erro (cod 3)');		
						props.setAlertBody('Ocorreu um erro ao atualizar o usuário (erro: '+error.message+').');		
						props.setAlert(true);
						
					});		
					
		}		
		
}

// TO-DO
export const deleteAdminUser = (data,props) => {
 
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);

					// Prepare Params
					var preparedData = {
						completeName:data.userName,
						email:data.userEmail,
						mobilePhone:data.userPhone,
						password:data.userPassword,
						groupId:data.userGroupId,
						accessRole:data.userLevelId,
						status:data.userStatus
					}
				
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/manager/backenduser/create',
						   method : 'POST',
						   data   : preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})								
					.then((response) => {
						
							// If an error occured. Check error is blocking or non-blocking.
							if (response.data.error) {														
								props.setSaving(false);
								props.setParentLoading(false);	
		
								props.setAlertTitle('Ops! Ocorreu um erro (cod 1)');		
								props.setAlertBody('Ocorreu um erro ao criar o usuário. Tente novamente. [1]');		
								props.setAlert(true);							
								return;					
							} // end if	*/
					
							if (response.data.success) {

							// Erro creating service
							if (response.data.userExists) {						
								props.setSaving(false);
								props.setParentLoading(false);
								props.setAlertTitle('Ops! Ocorreu um erro (cod 2)');		
								props.setAlertBody('Já existe um usuário cadastrado com o email ou telefone informado. Verifique.');		
								props.setAlert(true);							
								return;					
							} // end if
							
							// Erro creating service
							if (response.data.failedCreation) {						
								props.setSaving(false);
								props.setParentLoading(false);
								props.setAlertTitle('Ops! Ocorreu um erro (cod 2)');		
								props.setAlertBody('Ocorreu um erro ao criar o usuário. Tente novamente. [1]');		
								props.setAlert(true);							
								return;					
							} // end if	

							// refresh table
							props.loadLazyData();
							
							// close service dialog
							props.setAlertUser(false);
							
							// Stop loading
							setTimeout(function() {
								props.setSaving(false);
								props.setParentLoading(false);
							},1000);							
							
							// show success message
							setTimeout(function() {
								props.setAlertTitle('Feito! Usuário criado!');		
								props.setAlertBody('O Usuário foi criado com sucesso.');		
								props.setAlert(true);
							},1500);							
							
							return;
							} // end if
						
					 })
					.catch(error=> {

						props.setSaving(false);
						props.setParentLoading(false);
						props.setAlertTitle('Ops! Ocorreu um erro (cod 3)');		
						props.setAlertBody('Ocorreu um erro ao criar o usuário (erro: '+error.message+').');		
						props.setAlert(true);
						
					});		
					
		}		
		
}


export const retrieveServices = (data,props) => {
	
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  

					// Prepare Params
					var preparedData = {}
					preparedData.status =  data.status;
					preparedData.pagination = {
						first:data.first,
						rows:data.rows,
						page:data.page,
						sortField:data.sortField ? data.sortField:null,
						sortOrder:data.sortOrder ? data.sortOrder:null,
						pageCount:data.pageCount
					}					
					preparedData.filters =  data.filters ? data.filters:null;
				
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/manager/service/list',
						   method : 'POST',
						   data   : preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					}) 						
					
					.then((response) => {
						
						// Dispatch
						dispatch(setServices(response.data.services));
						
						// Retrieve data to controller
						props.setServicesRows(response.data.services);
						props.setTotalRecords(response.data.count);

						// set rowCount first
						props.setLoading(false);
						return;
						
					 })
					.catch(error=> {
						props.setLoading(false);			
						props.setServicesRows([]);
						console.log(error);
						return;
					});		
					
		}
}

export const retrieveService = (data,props) => {
 
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
					// Prepare Params
					var preparedData = {}
					preparedData.serviceId =  data.serviceId;			
				
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/manager/service/retrieve',
						   method : 'POST',
						   data   : preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})					
					.then((response) => {
		
						dispatch(setService(response.data.service));
						
						// Turn array to flat
						const finalObj = response.data.service;
						props.setServiceRow(finalObj);	

						props.setLoadingService(false);			
						
					 })
					.catch(error=> {
						props.setLoadingService(false);				
						props.setServiceRow({});						
					});		
					
		}		
		
}

export const createService = (data,props) => {
 
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);

					// Prepare Categories
					var categoriesLoop = Object.keys(data.categories);
					var serviceCategories = [];
					
					// Set parent array from object
					var parentCategoriesArray = [];
					for (var z=0; z < data.parentCategoriesTreeRows.length; z++) {
						parentCategoriesArray.push(data.parentCategoriesTreeRows[z].value);
					}					
					var forbiddenCategories = parentCategoriesArray;
					for (var x = 0; x < categoriesLoop.length; x++) {
						if (!forbiddenCategories.includes(parseInt(categoriesLoop[x]))) {
							serviceCategories.push(categoriesLoop[x]);
						} // end if
					} // end for
					
					// Prepare Regions Prices - set defaut region as first array
					var regionsLoop = Object.keys(data.checkedRegion);
					var servicePrice = [{
							price:data.regionValues['region1'].price,
							urgentTax:data.regionValues['region1'].urgentTax,
							ServicePriceRegionId:1
					}];
					for (var x = 0; x < regionsLoop.length; x++) {
						// if region is enabled						
						if (data.checkedRegion[regionsLoop[x]] === 1) {							
							var regionObj = {}
							regionObj.price = data.regionValues[regionsLoop[x]].price;
							regionObj.urgentTax = data.regionValues[regionsLoop[x]].urgentTax;
							regionObj.ServicePriceRegionId = data.regionValues[regionsLoop[x]].ServicePriceRegionId;
							servicePrice.push(regionObj);		
						} // end if	
					} // end for

					// Prepare Tags
					var tagsLoop = data.serviceTags;
					var serviceTagsTmp = "";
					for (var x = 0; x < tagsLoop.length; x++) {
							serviceTagsTmp = serviceTagsTmp+tagsLoop[x]+",";
					} // end for
					if (tagsLoop.length > 0) var serviceTags = serviceTagsTmp.substr(0,(serviceTagsTmp.length-1)); // strip last char - comma

					// Prepare Service Comission
					if (data.comissionGlobal != 'default') {					
						var serviceComission = {
							type:data.comissionType == 'percentage' ? 2 : 1,
							platformComission:data.comissionPlatform,
							providerComission:data.comissionProvider,
							partnerComission:data.comissionPartner
						}
					} // end if
					else {
						var serviceComission = null;
					} // end else					
			
					// Prepare Params
					var preparedData = {
						name:data.serviceName,
						shortDescription:data.serviceShortDescription,
						description:data.serviceDescription,
						warrantyTerm:data.serviceWarrantyTerm,
						tags:serviceTags,
						effort:data.serviceEffort,
						effortUnity:data.serviceEffortUnity,
						status:data.serviceStatus,
						highlight:data.serviceHighlight,
						servicePrice:servicePrice,
						serviceCategories:serviceCategories,
						serviceComissions:serviceComission,
						iconUrl:data.iconImageUrl,
						headerUrl:data.headerImageUrl
					}
					
					// Configura form data for upload
					const formData = new FormData();					
						
					// If iconImage append to formData
					if (data.iconImage.name) {
						// append image to formData
						formData.append('serviceIcon',data.iconImage.base64image);
					} // end if	

					// If headerImage append to formData
					if (data.headerImage.name) {
						// append image to formData
						formData.append('serviceHeader',data.headerImage.base64image);
					} // end if					
	 
					// append JSON Data as string to be converted at API
					formData.append("jsonData", JSON.stringify(preparedData)); // add json 
				
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/manager/service/create',
						   method : 'POST',
						   data   : formData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json',
							'Content-Type': 'multipart/form-data'
						}
					})					
					.then((response) => {
						
							// If an error occured. Check error is blocking or non-blocking.
							if (response.data.error) {														
								props.setSaving(false);
								props.setParentLoading(false);	
		
								props.setAlertTitle('Ops! Ocorreu um erro (cod 1)');		
								props.setAlertBody('Ocorreu um erro ao criar o serviço. Tente novamente. [1]');		
								props.setAlert(true);							
								return;					
							} // end if	*/
					
							if (response.data.success) {
							
							// Erro creating service
							if (response.data.failedCreation) {						
								props.setSaving(false);
								props.setParentLoading(false);
								props.setAlertTitle('Ops! Ocorreu um erro (cod 2)');		
								props.setAlertBody('Ocorreu um erro ao criar o serviço. Tente novamente. [1]');		
								props.setAlert(true);							
								return;					
							} // end if	

							// refresh table
							props.loadLazyData();
							
							// close service dialog
							props.setAlertService(false);
							
							// Stop loading
							setTimeout(function() {
								props.setSaving(false);
								props.setParentLoading(false);
							},1000);							
							
							// show success message
							setTimeout(function() {
								props.setAlertTitle('Feito! Serviço criado!');		
								props.setAlertBody('O serviço foi criado com sucesso.');		
								props.setAlert(true);
							},1500);							
							
							return;
							} // end if
						
					 })
					.catch(error=> {

						props.setSaving(false);
						props.setParentLoading(false);
						props.setAlertTitle('Ops! Ocorreu um erro (cod 3)');		
						props.setAlertBody('Ocorreu um erro ao criar o serviço (erro: '+error.message+').');		
						props.setAlert(true);
						
					});		
					
		}		
		
}

export const updateService = (data,props) => {
 
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);

					// Prepare Categories
					var categoriesLoop = Object.keys(data.categories);
					var serviceCategories = [];
					
					// Set parent array from object
					var parentCategoriesArray = [];
					for (var z=0; z < data.parentCategoriesTreeRows.length; z++) {
						parentCategoriesArray.push(data.parentCategoriesTreeRows[z].value);
					}					
					var forbiddenCategories = parentCategoriesArray;
					for (var x = 0; x < categoriesLoop.length; x++) {
						if (!forbiddenCategories.includes(parseInt(categoriesLoop[x]))) {
							serviceCategories.push(categoriesLoop[x]);
						} // end if
					} // end for
					
					// Prepare Regions Prices - set defaut region as first array
					var regionsLoop = Object.keys(data.checkedRegion);
					var servicePrice = [{
							price:data.regionValues['region1'].price,
							urgentTax:data.regionValues['region1'].urgentTax,
							ServicePriceRegionId:1
					}];
					for (var x = 0; x < regionsLoop.length; x++) {
						// if region is enabled						
						if (data.checkedRegion[regionsLoop[x]] === 1) {							
							var regionObj = {}
							regionObj.price = data.regionValues[regionsLoop[x]].price;
							regionObj.urgentTax = data.regionValues[regionsLoop[x]].urgentTax;
							regionObj.ServicePriceRegionId = data.regionValues[regionsLoop[x]].ServicePriceRegionId;
							servicePrice.push(regionObj);		
						} // end if	
					} // end for

					// Prepare Tags
					var tagsLoop = data.serviceTags;
					var serviceTagsTmp = "";
					for (var x = 0; x < tagsLoop.length; x++) {
							serviceTagsTmp = serviceTagsTmp+tagsLoop[x]+",";
					} // end for
					if (tagsLoop.length > 0) var serviceTags = serviceTagsTmp.substr(0,(serviceTagsTmp.length-1)); // strip last char - comma

					// Prepare Service Comission
					if (data.comissionGlobal != 'default') {					
						var serviceComission = {
							type:data.comissionType == 'percentage' ? 2 : 1,
							platformComission:data.comissionPlatform,
							providerComission:data.comissionProvider,
							partnerComission:data.comissionPartner
						}
					} // end if
					else {
						var serviceComission = null;
					} // end else					
			
					// Prepare Params
					var preparedData = {
						serviceId:data.serviceId,
						name:data.serviceName,
						shortDescription:data.serviceShortDescription,
						description:data.serviceDescription,
						warrantyTerm:data.serviceWarrantyTerm,
						tags:serviceTags,
						effort:data.serviceEffort,
						effortUnity:data.serviceEffortUnity,
						status:data.serviceStatus,
						highlight:data.serviceHighlight,
						servicePrice:servicePrice,
						serviceCategories:serviceCategories,
						serviceComissions:serviceComission,
						iconUrl:data.iconImageUrl,
						headerUrl:data.headerImageUrl
					}
					
					// Configura form data for upload
					const formData = new FormData();					
						
					// If iconImage append to formData
					if (data.iconImage.name) {
						// append image to formData
						formData.append('serviceIcon',data.iconImage.base64image);
					} // end if	

					// If headerImage append to formData
					if (data.headerImage.name) {
						// append image to formData
						formData.append('serviceHeader',data.headerImage.base64image);
					} // end if					
	 
					// append JSON Data as string to be converted at API
					formData.append("jsonData", JSON.stringify(preparedData)); // add json 
				
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/manager/service/update',
						   method : 'PUT',
						   data   : formData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json',
							'Content-Type': 'multipart/form-data'
						}
					})					
					.then((response) => {
						
							// If an error occured. Check error is blocking or non-blocking.
							if (response.data.error) {														
								props.setSaving(false);
								props.setParentLoading(false);	
		
								props.setAlertTitle('Ops! Ocorreu um erro (cod 1)');		
								props.setAlertBody('Ocorreu um erro ao atualizar o serviço. Tente novamente. [1]');		
								props.setAlert(true);							
								return;					
							} // end if	*/
					
							if (response.data.success) {
							
							// Erro creating service
							if (response.data.failedCreation) {						
								props.setSaving(false);
								props.setParentLoading(false);
								props.setAlertTitle('Ops! Ocorreu um erro (cod 2)');		
								props.setAlertBody('Ocorreu um erro ao atualizar o serviço. Tente novamente. [1]');		
								props.setAlert(true);							
								return;					
							} // end if	

							// refresh table
							props.loadLazyData();
							
							// close service dialog
							props.setAlertServiceUpdate(false);
							
							// Stop loading
							setTimeout(function() {
								props.setSaving(false);
								props.setParentLoading(false);
							},1000);							
							
							// show success message
							setTimeout(function() {
								props.setAlertTitle('Feito! Serviço atualizado!');		
								props.setAlertBody('O serviço foi atualizado com sucesso.');		
								props.setAlert(true);
							},1500);							
							
							return;
							} // end if
						
					 })
					.catch(error=> {

						props.setSaving(false);
						props.setParentLoading(false);
						props.setAlertTitle('Ops! Ocorreu um erro (cod 3)');		
						props.setAlertBody('Ocorreu um erro ao atualizar o serviço (erro: '+error.message+').');		
						props.setAlert(true);
						
					});		
					
		}		
		
}

export const retrieveCategories = (data,props) => {
	
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  

					// Prepare Params
					var preparedData = {}
					preparedData.status =  data.status;
					preparedData.pagination = {
						first:data.first,
						rows:data.rows,
						page:data.page,
						sortField:data.sortField ? data.sortField:null,
						sortOrder:data.sortOrder ? data.sortOrder:null,
						pageCount:data.pageCount
					}					
					preparedData.filters =  data.filters ? data.filters:null;
				
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/manager/serviceCategory/list',
						   method : 'POST',
						   data   : preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					}) 						
					
					.then((response) => {
																	
						var finalObj = [];
						var counterParent = 0;
						var categoriesRows = response.data.categories;
						
						for (var i = 0;i < categoriesRows.length; i++) {
														
							var categoryObj = {}
							// categoryObj.key = counterParent;
							categoryObj.key = categoriesRows[i].id;
							categoryObj.data = {};	
							categoryObj.data.name = categoriesRows[i].name;
							categoryObj.data.description = categoriesRows[i].description;
							categoryObj.data.icon = categoriesRows[i].icon;
							categoryObj.data.order = categoriesRows[i].order;
							categoryObj.data.status = categoriesRows[i].status;
						
							if (categoriesRows[i].childs.length > 0) {
								
								var finalSubcategoryObj = [];	
								var counterChild = 0;								
								for (var z = 0;z < categoriesRows[i].childs.length; z++) {

									var subcategoryObj = {}
									// subcategoryObj.key = counterParent+"-"+counterChild;
									subcategoryObj.key = categoriesRows[i].childs[z].id;
									subcategoryObj.data = {};	
									subcategoryObj.data.name = categoriesRows[i].childs[z].name;
									subcategoryObj.data.description = categoriesRows[i].childs[z].description;
									subcategoryObj.data.icon = categoriesRows[i].childs[z].icon;
									subcategoryObj.data.order = categoriesRows[i].childs[z].order;
									subcategoryObj.data.status = categoriesRows[i].childs[z].status;									
									// add to final object
									finalSubcategoryObj.push(subcategoryObj);

									counterChild++;									
									
								} // end for 								
								
								// add to childs array
								categoryObj.children = finalSubcategoryObj;
								
							} // end if
								
							// add to final object
							finalObj.push(categoryObj);
								
							counterParent++;
							
						} // end for
						
						// Dispatch
						dispatch(setCategories(finalObj));
						
						// Retrieve data to controller
						props.setCategoriesRows(finalObj);
						props.setTotalRecords(response.data.count);

						// set rowCount first
						props.setLoading(false);
						return;
						
					 })
					.catch(error=> {
						props.setLoading(false);			
						props.setCategoriesRows([]);
						console.log(error);
						return;
					});		
					
		}
}

export const retrieveCategory = (data,props) => {
 
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
					// Prepare Params
					var preparedData = {}
					preparedData.categoryId =  data.categoryId;		
				
				
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/manager/serviceCategory/retrieve',
						   method : 'POST',
						   data   : preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})					
					.then((response) => {
		
						dispatch(setCategory(response.data.category));
						
						// Turn array to flat
						const finalObj = response.data.category;
						props.setCategoryRow(finalObj);	

						props.setLoadingCategory(false);				
						
					 })
					.catch(error=> {
						
						props.setLoadingCategory(false);				
						props.setCategoryRow({});						
						console.log(error);
						
					});		
					
		}		
		
}

export const createCategory = (data,props) => {
 
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);

					// Prepare Tags
					var tagsLoop = data.categoryTags;
					var categoryTagsTmp = "";
					for (var x = 0; x < tagsLoop.length; x++) {
							categoryTagsTmp = categoryTagsTmp+tagsLoop[x]+",";
					} // end for
					if (tagsLoop.length > 0) var categoryTags = categoryTagsTmp.substr(0,(categoryTagsTmp.length-1)); // strip last char - comma
					
					// Generate Bredcrumb
					var breadcrumb = "";
					for (var i = 0; i < data.parentCategoriesTreeRows.length; i++) {
						if (data.parentCategoriesTreeRows[i].value == data.categoryParent) {
							breadcrumb = breadcrumb+data.parentCategoriesTreeRows[i].label+" > ";
						} // end if						
					} // end for
					breadcrumb = breadcrumb+data.categoryName; // set current category name;
					
					// Prepare Params
					var preparedData = {
						name:data.categoryName,
						description:data.categoryDescription,
						tags:categoryTags,
						parent:data.categoryParent,
						breadcrumb:breadcrumb,
						order:data.categoryOrder,
						status:data.categoryStatus,
						iconUrl:data.iconImageUrl
					}
					
					// Configura form data for upload
					const formData = new FormData();					
						
					// If iconImage append to formData
					if (data.iconImage.name) {
						// append image to formData
						formData.append('categoryIcon',data.iconImage.base64image);
					} // end if	

					// append JSON Data as string to be converted at API
					formData.append("jsonData", JSON.stringify(preparedData)); // add json 
				
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/manager/serviceCategory/create',
						   method : 'POST',
						   data   : formData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json',
							'Content-Type': 'multipart/form-data'
						}
					})					
					.then((response) => {
						
							// If an error occured. Check error is blocking or non-blocking.
							if (response.data.error) {														
								props.setSaving(false);
								props.setParentLoading(false);	
		
								props.setAlertTitle('Ops! Ocorreu um erro (cod 1)');		
								props.setAlertBody('Ocorreu um erro ao criar a categoria. Tente novamente. [1]');		
								props.setAlert(true);							
								return;					
							} // end if	*/
					
							if (response.data.success) {
							
							// Erro creating service
							if (response.data.failedCreation) {						
								props.setSaving(false);
								props.setParentLoading(false);
								props.setAlertTitle('Ops! Ocorreu um erro (cod 2)');		
								props.setAlertBody('Ocorreu um erro ao criar a categoria. Tente novamente. [1]');		
								props.setAlert(true);							
								return;					
							} // end if	

							// refresh table
							props.loadLazyData();
							
							// close service dialog
							props.setAlertCategory(false);
							
							// Stop loading
							setTimeout(function() {
								props.setSaving(false);
								props.setParentLoading(false);
							},1000);							
							
							// show success message
							setTimeout(function() {
								props.setAlertTitle('Feito! Categoria criada!');		
								props.setAlertBody('A Categoria foi criada com sucesso.');		
								props.setAlert(true);
							},1500);							
							
							return;
							} // end if
						
					 })
					.catch(error=> {

						props.setSaving(false);
						props.setParentLoading(false);
						props.setAlertTitle('Ops! Ocorreu um erro (cod 3)');		
						props.setAlertBody('Ocorreu um erro ao criar a categoria (erro: '+error.message+').');		
						props.setAlert(true);
						
					});		
					
		}		
		
}

export const updateCategory = (data,props) => {
 
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);

					// Prepare Tags
					var tagsLoop = data.categoryTags;
					var categoryTagsTmp = "";
					for (var x = 0; x < tagsLoop.length; x++) {
							categoryTagsTmp = categoryTagsTmp+tagsLoop[x]+",";
					} // end for
					if (tagsLoop.length > 0) var categoryTags = categoryTagsTmp.substr(0,(categoryTagsTmp.length-1)); // strip last char - comma
					
					// Generate Bredcrumb
					var breadcrumb = "";
					for (var i = 0; i < data.parentCategoriesTreeRows.length; i++) {
						if (data.parentCategoriesTreeRows[i].value == data.categoryParent) {
							breadcrumb = breadcrumb+data.parentCategoriesTreeRows[i].label+" > ";
						} // end if						
					} // end for
					breadcrumb = breadcrumb+data.categoryName; // set current category name;
					
					// Prepare Params
					var preparedData = {
						categoryId:data.categoryId,
						name:data.categoryName,
						description:data.categoryDescription,
						tags:categoryTags,
						parent:data.categoryParent,
						breadcrumb:breadcrumb,
						order:data.categoryOrder,
						status:data.categoryStatus,
						iconUrl:data.iconImageUrl
					}
					
					// Configura form data for upload
					const formData = new FormData();					
						
					// If iconImage append to formData
					if (data.iconImage.name) {
						// append image to formData
						formData.append('categoryIcon',data.iconImage.base64image);
					} // end if	

					// append JSON Data as string to be converted at API
					formData.append("jsonData", JSON.stringify(preparedData)); // add json 
				
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/manager/serviceCategory/update',
						   method : 'PUT',
						   data   : formData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json',
							'Content-Type': 'multipart/form-data'
						}
					})					
					.then((response) => {
						
							// If an error occured. Check error is blocking or non-blocking.
							if (response.data.error) {														
								props.setSaving(false);
								props.setParentLoading(false);	
		
								props.setAlertTitle('Ops! Ocorreu um erro (cod 1)');		
								props.setAlertBody('Ocorreu um erro ao atualizar a categoria. Tente novamente. [1]');		
								props.setAlert(true);							
								return;					
							} // end if	*/
					
							if (response.data.success) {
							
							// Erro creating service
							if (response.data.failedCreation) {						
								props.setSaving(false);
								props.setParentLoading(false);
								props.setAlertTitle('Ops! Ocorreu um erro (cod 2)');		
								props.setAlertBody('Ocorreu um erro ao atualizar a categoria. Tente novamente. [1]');		
								props.setAlert(true);							
								return;					
							} // end if	

							// refresh table
							props.loadLazyData();
							
							// close service dialog
							props.setAlertCategoryUpdate(false);
							
							// Stop loading
							setTimeout(function() {
								props.setSaving(false);
								props.setParentLoading(false);
							},1000);							
							
							// show success message
							setTimeout(function() {
								props.setAlertTitle('Feito! Categoria atualizada!');		
								props.setAlertBody('A Categoria foi atualizada com sucesso.');		
								props.setAlert(true);
							},1500);							
							
							return;
							} // end if
						
					 })
					.catch(error=> {

						props.setSaving(false);
						props.setParentLoading(false);
						props.setAlertTitle('Ops! Ocorreu um erro (cod 3)');		
						props.setAlertBody('Ocorreu um erro ao atualizar a categoria (erro: '+error.message+').');		
						props.setAlert(true);
						
					});		
					
		}		
		
}

export const retrievePayments = (data,props) => {
	
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  

					// Prepare Params
					var preparedData = {}
					preparedData.status =  data.status;
					preparedData.pagination = {
						first:data.first,
						rows:data.rows,
						page:data.page,
						sortField:data.sortField ? data.sortField:null,
						sortOrder:data.sortOrder ? data.sortOrder:null,
						pageCount:data.pageCount
					}					
					preparedData.filters =  data.filters ? data.filters:null;
				
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/manager/backenduser/list',
						   method : 'POST',
						   data   : preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					}) 						
					
					.then((response) => {
						
						// Dispatch
						dispatch(setAdminUsers(response.data.managerUsers));
						
						// Retrieve data to controller
						props.setAdminUsersRows(response.data.managerUsers);
						props.setTotalRecords(response.data.count);

						// set rowCount first
						props.setLoading(false);
						return;
						
					 })
					.catch(error=> {
						props.setLoading(false);			
						props.setAdminUsersRows([]);
						console.log(error);
						return;
					});		
					
		}
}

export const retrievePayment = (data,props) => {
 
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
					// Prepare Params
					var preparedData = {}
					preparedData.managerUserId =  data.managerUserId;			
				
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/manager/backenduser/retrieve',
						   method : 'POST',
						   data   : preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})					
					.then((response) => {
		
						dispatch(setAdminUser(response.data.managerUser));
						
						// Turn array to flat
						const finalObj = response.data.managerUser;
						props.setAdminUsersRow(finalObj);	

						dispatch(setAppLoading(false));	
						props.setContentVisible(true);			
						
					 })
					.catch(error=> {
						
						dispatch(setAppLoading(false));		
						props.setAdminUsersRow({});						
						console.log(error);
						
					});		
					
		}		
		
}

export const generateReport = (data,props) => {
 
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
					// Prepare Params
					var preparedData = {}
					preparedData.managerUserId =  data.managerUserId;			
				
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/manager/backenduser/retrieve',
						   method : 'POST',
						   data   : preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})					
					.then((response) => {
		
						dispatch(setAdminUser(response.data.managerUser));
						
						// Turn array to flat
						const finalObj = response.data.managerUser;
						props.setAdminUsersRow(finalObj);	

						dispatch(setAppLoading(false));	
						props.setContentVisible(true);			
						
					 })
					.catch(error=> {
						
						dispatch(setAppLoading(false));		
						props.setAdminUsersRow({});						
						console.log(error);
						
					});		
					
		}		
		
}

export const retrieveCategoriesTree = (props) => {
	
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  

					// Prepare Params
					var preparedData = {}
				
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/manager/serviceCategoryTree/list',
						   method : 'GET',
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})
					.then((response) => {
																
						var finalObj = [];
						var counterParent = 0;
						var categoriesRows = response.data.categories;
						for (var i = 0;i < categoriesRows.length; i++) {
														
							var categoryObj = {}
							categoryObj.key = categoriesRows[i].id;
							categoryObj.label = categoriesRows[i].name;
							categoryObj.data = categoriesRows[i].name;
							categoryObj.icon = categoriesRows[i].icon;
							categoryObj.selectable = false;
						
							if (categoriesRows[i].childs.length > 0) {
								
								var finalSubcategoryObj = [];	
								var counterChild = 0;								
								for (var z = 0;z < categoriesRows[i].childs.length; z++) {

									var subcategoryObj = {}
									subcategoryObj.key = categoriesRows[i].childs[z].id; 
									subcategoryObj.label = categoriesRows[i].childs[z].name;
									subcategoryObj.data = categoriesRows[i].childs[z].name;
									subcategoryObj.icon = categoriesRows[i].childs[z].icon;
									categoryObj.checked = true;
											
									// add to final object
									finalSubcategoryObj.push(subcategoryObj);

									counterChild++;									
									
								} // end for 								
								
								// add to childs array
								categoryObj.children = finalSubcategoryObj;
								
							} // end if
								
							// add to final object
							finalObj.push(categoryObj);
								
							counterParent++;
							
						} // end for
												
						// Dispatch
						dispatch(setCategoriesTree(finalObj));
						
						// Retrieve data to controller
						props.setCategoriesTreeRows(finalObj);
						
						// Set Parent Categories
						props.setParentCategoriesTreeRows(response.data.parentCategories);

						// set rowCount first
						props.setCategoriesLoading(false);
						return;
						
					 })
					.catch(error=> {
						props.setCategoriesLoading(false);			
						props.setCategoriesTreeRows([]);
						console.log(error);
						return;
					});		
					
		}
}

export const retrieveCategoriesTreeDroplist = (props) => {
	
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  

					// Prepare Params
					var preparedData = {}
				
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/manager/serviceCategoryTree/list',
						   method : 'GET',
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})
					.then((response) => {
																
						var finalObj = [];
						var counterParent = 0;
						var categoriesRows = response.data.categories;
						for (var i = 0;i < categoriesRows.length; i++) {
														
							var categoryObj = {}
							categoryObj.key = categoriesRows[i].id;
							categoryObj.label = categoriesRows[i].name;
							categoryObj.data = categoriesRows[i].name;
							categoryObj.icon = categoriesRows[i].icon;
							categoryObj.selectable = false;
						
							if (categoriesRows[i].childs.length > 0) {
								
								var finalSubcategoryObj = [];	
								var counterChild = 0;								
								for (var z = 0;z < categoriesRows[i].childs.length; z++) {

									var subcategoryObj = {}
									subcategoryObj.key = categoriesRows[i].childs[z].id; 
									subcategoryObj.label = categoriesRows[i].childs[z].name;
									subcategoryObj.data = categoriesRows[i].childs[z].name;
									subcategoryObj.icon = categoriesRows[i].childs[z].icon;
									categoryObj.checked = true;
											
									// add to final object
									finalSubcategoryObj.push(subcategoryObj);

									counterChild++;									
									
								} // end for 								
								
								// add to childs array
								categoryObj.children = finalSubcategoryObj;
								
							} // end if
								
							// add to final object
							finalObj.push(categoryObj);
								
							counterParent++;
							
						} // end for
												
						// Dispatch
						dispatch(setCategoriesTree(finalObj));
						
						// Retrieve data to controller
						props.setCategoriesTreeRows(finalObj);
						
						// Set Parent Categories
						var dropdownList = response.data.parentCategories;
						dropdownList.unshift({label:"- Categoria Raiz -",value:0});
						props.setParentCategoriesTreeRows(dropdownList);

						// set rowCount first
						props.setCategoriesLoading(false);
						return;
						
					 })
					.catch(error=> {
						props.setCategoriesLoading(false);			
						props.setCategoriesTreeRows([]);
						console.log(error);
						return;
					});		
					
		}
}

export const retrieveRegions = (props) => {
	
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  

					// Prepare Params
					var preparedData = {}
				
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/manager/regions/list',
						   method : 'GET',
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					}) 	
					.then((response) => {
						
						// Dispatch
						dispatch(setRegions(response.data.regions));
						
						//var regionsValues = {"region2":0,"region3":0,"region4":0};
						
						// construct regions stats object
						var regionsObj = response.data.regions;
						var regionsControl = {};
						var regionsValues = {};
						regionsValues['region1'] = {}
						regionsValues['region1'].price = null;
						regionsValues['region1'].urgentTax = null;						
						regionsValues['region1'].ServicePriceRegionId = 1;
						for(var i = 0; i < regionsObj.length; i++) {
							
							// region controls
							regionsControl['region'+regionsObj[i].id+''] = 0;
							
							// region values
							regionsValues['region'+regionsObj[i].id+''] = {}
							regionsValues['region'+regionsObj[i].id+''].price = null;
							regionsValues['region'+regionsObj[i].id+''].urgentTax = null;
							regionsValues['region'+regionsObj[i].id+''].ServicePriceRegionId = regionsObj[i].id;
							
							
						}// end for						
						props.setCheckedRegion(regionsControl);
						props.setRegionValues(regionsValues);
						
						// Retrieve data to controller
						props.setRegionsRows(response.data.regions);

						// set rowCount first
						props.setRegionsLoading(false);
						return;
						
					 })
					.catch(error=> {
						props.setRegionsLoading(false);			
						props.setRegionsRows([]);
						console.log(error);
						return;
					});		
					
		}
}

export const retrieveUserGroups = (props) => {
	
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  

					// Prepare Params
					var preparedData = {}
				
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/manager/backenduser/droplist',
						   method : 'GET',
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					}) 	
					.then((response) => {
						
						// Retrieve data to controller
						props.setUserGroupsRows(response.data.groups);
						props.setUserLevelsRows(response.data.levels);
						return;
						
					 })
					.catch(error=> {	
						props.setUserGroupsRows([]);
						console.log(error);
						return;
					});		
					
		}
}

export const retrieveConfigRegions = (props) => {
	
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  

					// Prepare Params
					var preparedData = {}
				
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/manager/regions/list',
						   method : 'GET',
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					}) 	
					.then((response) => {
						
						// Retrieve data to controller
						props.setRegionsRows(response.data.regions);

						// set rowCount first
						props.setLoading(false);
						return;
						
					 })
					.catch(error=> {
						props.setLoading(false);			
						props.setRegionsRows([]);
						console.log(error);
						return;
					});		
					
		}
}

export const retrieveConfigRegion = (data,props) => {
	
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  

					// Prepare Params
					var preparedData = {regionId:data.regionId}
				
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/manager/regions/retrieve',
						   method : 'POST',
						   data   : preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					}) 	
					.then((response) => {
						
						// Retrieve data to controller
						props.setRegionRow(response.data.region);

						// set rowCount first
						props.setLoadingRegion(false);
						return;
						
					 })
					.catch(error=> {
						props.setLoadingRegion(false);			
						props.setRegionRow({});
						console.log(error);
						return;
					});		
					
		}
}

export const createConfigRegion = (data,props) => {
 
		return dispatch => {

				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);
					
					// Prepare Params
					var preparedData = {
						name:data.regionName,
						state:data.regionState,
						/*city:data.regionCity*/
						city:"*"
					}
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/manager/regions/create',
						   method : 'POST',
						   data   : preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})					
					.then((response) => {
						
							// If an error occured. Check error is blocking or non-blocking.
							if (response.data.error) {														
								props.setSaving(false);
								props.setParentLoading(false);	
		
								props.setAlertTitle('Ops! Ocorreu um erro (cod 1)');		
								props.setAlertBody('Ocorreu um erro ao criar a região. Tente novamente. [1]');		
								props.setAlert(true);							
								return;					
							} // end if	*/
					
							if (response.data.success) {
							
							// Erro creating service
							if (response.data.failedCreation) {						
								props.setSaving(false);
								props.setParentLoading(false);
								props.setAlertTitle('Ops! Ocorreu um erro (cod 2)');		
								props.setAlertBody('Ocorreu um erro ao criar a região. Tente novamente. [1]');		
								props.setAlert(true);							
								return;					
							} // end if	
							
							// Erro creating service
							if (response.data.alreadyExist) {						
								props.setSaving(false);
								props.setParentLoading(false);
								props.setAlertTitle('Ops! Ocorreu um problema');		
								props.setAlertBody('A região que está criando já existe. Verifique.');		
								props.setAlert(true);							
								return;					
							} // end if	

							// refresh table
							props.loadLazyData();
							
							// close service dialog
							props.setAlertRegion(false);
							
							// Stop loading
							setTimeout(function() {
								props.setSaving(false);
								props.setParentLoading(false);
							},1000);							
							
							// show success message
							setTimeout(function() {
								props.setAlertTitle('Feito! Região criada!');		
								props.setAlertBody('A Região foi criada com sucesso.');		
								props.setAlert(true);
							},1000);							
							
							return;
							} // end if
						
					 })
					.catch(error=> {

						props.setSaving(false);
						props.setParentLoading(false);
						props.setAlertTitle('Ops! Ocorreu um erro (cod 3)');		
						props.setAlertBody('Ocorreu um erro ao criar a região (erro: '+error.message+').');		
						props.setAlert(true);
						
					});		
					
		}		
		
}

export const updateConfigRegion = (data,props) => {
 
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);
					
					// Prepare Params
					var preparedData = {
						regionId:data.regionId,
						name:data.regionName,
						state:data.regionState,
						/*city:data.regionCity*/
						city:"*"
					}					
				
					
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/manager/regions/update',
						   method : 'PUT',
						   data   : preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})					
					.then((response) => {
						
							// If an error occured. Check error is blocking or non-blocking.
							if (response.data.error) {														
								props.setSaving(false);
								props.setParentLoading(false);	
		
								props.setAlertTitle('Ops! Ocorreu um erro (cod 1)');		
								props.setAlertBody('Ocorreu um erro ao atualizar a região. Tente novamente. [1]');		
								props.setAlert(true);							
								return;					
							} // end if	*/
					
							if (response.data.success) {
							
							// Erro creating service
							if (response.data.failedCreation) {						
								props.setSaving(false);
								props.setParentLoading(false);
								props.setAlertTitle('Ops! Ocorreu um erro (cod 2)');		
								props.setAlertBody('Ocorreu um erro ao atualizar a região. Tente novamente. [1]');		
								props.setAlert(true);							
								return;					
							} // end if	
							
							// Erro creating service
							if (response.data.alreadyExist) {						
								props.setSaving(false);
								props.setParentLoading(false);
								props.setAlertTitle('Ops! Ocorreu um problema');		
								props.setAlertBody('Essa região que está gravando já existe. Só é possível uma região por estado. Verifique.');		
								props.setAlert(true);							
								return;					
							} // end if								

							// refresh table
							props.loadLazyData();
							
							// close service dialog
							props.setAlertRegionUpdate(false);
							
							// Stop loading
							setTimeout(function() {
								props.setSaving(false);
								props.setParentLoading(false);
							},1000);							
							
							// show success message
							setTimeout(function() {
								props.setAlertTitle('Feito! Região atualizada');		
								props.setAlertBody('A Região foi atualizada com sucesso.');		
								props.setAlert(true);
							},1000);							
							
							return;
							} // end if
						
					 })
					.catch(error=> {

						props.setSaving(false);
						props.setParentLoading(false);
						props.setAlertTitle('Ops! Ocorreu um erro (cod 3)');		
						props.setAlertBody('Ocorreu um erro ao atualizar a região (erro: '+error.message+').');		
						props.setAlert(true);
						
					});		

		}		
		
}

export const deleteConfigRegion = (data,props) => {
 
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);
					
					// Prepare Params
					var preparedData = {
						regionId:data.regionId
					}					
				
					
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/manager/regions/delete',
						   method : 'PUT',
						   data   : preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})					
					.then((response) => {
						
							// If an error occured. Check error is blocking or non-blocking.
							if (response.data.error) {														
								props.setSaving(false);
								props.setParentLoading(false);	
		
								props.setAlertTitle('Ops! Ocorreu um erro (cod 1)');		
								props.setAlertBody('Ocorreu um erro ao excluir a região. Tente novamente. [1]');		
								props.setAlert(true);							
								return;					
							} // end if	*/
					
							if (response.data.success) {
							
							// Erro creating service
							if (response.data.failedDelete) {						
								props.setSaving(false);
								props.setParentLoading(false);
								props.setAlertTitle('Ops! Ocorreu um erro (cod 2)');		
								props.setAlertBody('Ocorreu um erro ao excluir a região. Tente novamente. [1]');		
								props.setAlert(true);							
								return;					
							} // end if	
							
							// Erro creating service
							if (response.data.beingUsed) {						
								props.setSaving(false);
								props.setParentLoading(false);
								props.setAlertTitle('Ops! Ocorreu um problema');		
								props.setAlertBody('Essa região está sendo utilizada em um ou mais serviços. Para excluir você deve desvincular dos serviços. Verifique.');		
								props.setAlert(true);							
								return;					
							} // end if								

							// refresh table
							props.loadLazyData();
							
							// close service dialog
							props.setAlertRegionUpdate(false);
							
							// Stop loading
							setTimeout(function() {
								props.setSaving(false);
								props.setParentLoading(false);
							},1000);							
							
							// show success message
							setTimeout(function() {
								props.setAlertTitle('Feito! Região excluída');		
								props.setAlertBody('A Região foi excluída com sucesso.');		
								props.setAlert(true);
							},1000);							
							
							return;
							} // end if
						
					 })
					.catch(error=> {

						props.setSaving(false);
						props.setParentLoading(false);
						props.setAlertTitle('Ops! Ocorreu um erro (cod 3)');		
						props.setAlertBody('Ocorreu um erro ao excluir a região (erro: '+error.message+').');		
						props.setAlert(true);
						
					});		

		}		
		
}

/* TO-DO Delete REGION - If used on any service, can not delete. */
/* ... */

export const retrieveConfig = (props) => {
	
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  

					// Prepare Params
					var preparedData = {}
				
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/manager/config/list',
						   method : 'GET',
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					}) 	
					.then((response) => {
						
						// Retrieve data to controller
						props.setConfigRows(response.data.config);

						// set rowCount first
						props.setLoading(false);
						return;
						
					 })
					.catch(error=> {
						props.setLoading(false);			
						props.setConfigRows([]);
						console.log(error);
						return;
					});		
					
		}
}

export const saveConfig = (data,props) => {
 
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);
					
					// Prepare Params
					var preparedData = {
						tab:data.tab,
						
						// Tab 1 fields
						providerLocalDirectory:data.providerLocalDirectory,
						providerAvatarDirectory:data.providerAvatarDirectory,
						providerGeneralDirectory:data.providerGeneralDirectory,
						customerGeneralDirectory:data.customerGeneralDirectory,
						fileUploadTempPath:data.fileUploadTempPath,
						mediaGeneralDirectory:data.mediaGeneralDirectory,
						mediaGeneralUrl:data.mediaGeneralUrl,
					
						// Tab 2 fields
						provider_canceltax:data.provider_canceltax,
						customer_canceltax:data.customer_canceltax,
						customer_altertax:data. customer_altertax,
						platform_default_comission:data.platform_default_comission,
						provider_default_comission:data.provider_default_comission,
						partner_default_comission:data.partner_default_comission,	

						// Tab 3 fields
						tokenChatKey:data.tokenChatKey,
						streamChatToken:data.streamChatToken,
						sendgrid_api_key:data.sendgrid_api_key,
						zenvia_senderid:data.zenvia_senderid,
						zenvia_token:data.zenvia_token,
						google_maps_token:data.google_maps_token,
						freshdesk_api_url:data.freshdesk_api_url,
						freshdesk_api_token:data.freshdesk_api_token,	

						// Tab 4 fields
						partner_account_iugu_id:data.partner_account_iugu_id,
						provider_account_iugu_id:data.provider_account_iugu_id,
						partneruser_id:data.partneruser_id,
						token_api_iugu:data.token_api_iugu,
						token_api_iugu_prod:data.token_api_iugu_prod,
						platform_mode:data.platform_mode
					}	
					
					console.log("[preparedData]--->"+JSON.stringify(preparedData,null,2));

						/*props.setTabLoading(false);				
						props.setParentLoading(false);	
						props.setAlertTitle('DEBUGANDO....');		
						props.setAlertBody('Retorno antes da chamada AJAX... [1]');		
						props.setAlert(true);							
						return;	*/
					
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/manager/config/update',
						   method : 'PUT',
						   data   : preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})					
					.then((response) => {
						
							// If an error occured. Check error is blocking or non-blocking.
							if (response.data.error) {														
								props.setTabLoading(false);
								props.setParentLoading(false);	
		
								props.setAlertTitle('Ops! Ocorreu um erro (cod 1)');		
								props.setAlertBody('Ocorreu um erro ao atualizar a configuração. Tente novamente. [1]');		
								props.setAlert(true);							
								return;					
							} // end if	*/
					
							if (response.data.success) {
							
							// Erro creating service
							if (response.data.failedCreation) {						
								props.setTabLoading(false);
								props.setParentLoading(false);
								props.setAlertTitle('Ops! Ocorreu um erro (cod 2)');		
								props.setAlertBody('Ocorreu um erro ao atualizar a configuração. Tente novamente. [1]');		
								props.setAlert(true);							
								return;					
							} // end if	


							props.setAlertTitle('Feito! Configuração atualizada');		
							props.setAlertBody('As configurações foram atualizadas com sucesso. Aguarde até 60 segundos para que as configurações sejam atualizadas.');		
							props.setAlert(true);

							// Set loading Off
							props.setTabLoading(false);
							props.setParentLoading(false);
												
							
							return;
							} // end if
						
					 })
					.catch(error=> {

						props.setTabLoading(false);
						props.setParentLoading(false);
						props.setAlertTitle('Ops! Ocorreu um erro (cod 3)');		
						props.setAlertBody('Ocorreu um erro ao atualizar a configuração (erro: '+error.message+').');		
						props.setAlert(true);
						
					});		

		}		
		
}

export const retrieveComplaints = (data,props) => {
	
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  

					// Prepare Params
					var preparedData = {}
					preparedData.pagination = {
						first:data.first,
						rows:data.rows,
						page:data.page,
						sortField:data.sortField ? data.sortField:null,
						sortOrder:data.sortOrder ? data.sortOrder:null,
						pageCount:data.pageCount
					}					
					preparedData.filters =  data.filters ? data.filters:null;
				
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/manager/complaint/listAll',
						   method : 'POST',
						   data   : preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					}) 						
					
					.then((response) => {
						
						// Retrieve data to controller
						props.setComplaintsRows(response.data.complaints);
						props.setTotalRecords(response.data.count);

						// set rowCount first
						props.setLoading(false);
						return;
						
					 })
					.catch(error=> {
						props.setLoading(false);			
						props.setComplaintsRows([]);
						console.log(error);
						return;
					});		
					
		}
}
	
export const retrieveComplaintsGroups = (props) => {
	
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  

					// Prepare Params
					var preparedData = {}
				
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/manager/complaint/categories/list',
						   method : 'GET',
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					}) 	
					.then((response) => {

						// Retrieve data to controller
						props.setComplaintsGroupsRows(response.data.categories);
						return;
						
					 })
					.catch(error=> {	
						props.setComplaintsGroupsRows([]);
						console.log(error);
						return;
					});		
					
		}
}

export const retrieveComplaint = (data,props) => {
	
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  

					// Prepare Params
					var preparedData = {}			
					preparedData.serviceComplaintId =  data.complaintId;
				
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/manager/complaint/retrieve',
						   method : 'POST',
						   data   : preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					}) 						
					
					.then((response) => {
						
						// Retrieve data to controller
						props.setComplaintRow(response.data.complaint);

						// set rowCount first
						props.setLoadingComplaint(false);
						return;
						
					 })
					.catch(error=> {
						props.setLoadingComplaint(false);			
						props.setComplaintRow([]);
						console.log(error);
						return;
					});		
					
		}
}

export const retrieveInstance = (data,props) => {
	
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
					var instanceType = data.instanceType;
					var id = data.instanceId;
				
					const urlEnd = config.API_URL+`/api/v1/manage/${instanceType}/retrieveInfo/${id}`;
				
					// Connect to Endpoint
					axios({
						   url    : urlEnd,
						   method : 'GET',
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})
					.then((response) => {
		
						props.setLoadingInstance(false);
						props.setInstanceRow(response.data.instance);
	
					 })
					.catch(error=> {
						
						props.setLoadingInstance(false);
						props.setInstanceRow({});						
						console.log(error);
						
					});		
					
		}
}

export const retrieveServiceOrder = (data,props) => {
	
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
					var instanceType = data.instanceType;
					var id = data.serviceOrderId;
				
					const urlEnd = config.API_URL+`/api/v1/manage/service/retrieveInfo/${id}`;
				
					// Connect to Endpoint
					axios({
						   url    : urlEnd,
						   method : 'GET',
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})
					.then((response) => {
		
						props.setLoadingServiceOrder(false);
						props.setServiceOrderRow(response.data.service);
	
					 })
					.catch(error=> {
						
						props.setLoadingServiceOrder(false);
						props.setServiceOrderRow({});						
						console.log(error);
						
					});		
					
		}
}
	
export const createComplaintComment = (data,props) => {
 
		return dispatch => {

				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);
					
					// Prepare Params
					var preparedData = {
						serviceComplaintId:data.serviceComplaintId,
						comment:data.comment,
						notifyOrigin:data.notifyOrigin		
					}
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/manager/complaint/comment/create',
						   method : 'PUT',
						   data   : preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})					
					.then((response) => {
						
							// If an error occured. Check error is blocking or non-blocking.
							if (response.data.error) {														
								props.setSaving(false);
		
								props.setAlertTitle('Ops! Ocorreu um erro (cod 1)');		
								props.setAlertBody('Ocorreu um erro ao criar o comentário. Tente novamente. [1]');		
								props.setAlert(true);							
								return;					
							} // end if	*/
					
							if (response.data.success) {
							
							// Erro creating service
							if (response.data.failedCreation) {						
								props.setSaving(false);
								props.setAlertTitle('Ops! Ocorreu um erro (cod 2)');		
								props.setAlertBody('Ocorreu um erro ao criar o comentário. Tente novamente. [1]');		
								props.setAlert(true);							
								return;					
							} // end if	

							// refresh table
							props.loadLazyData();
							props.loadData();
							props.setComment("");
							
							// close service dialog
							props.setSaving(false);						
							
							// show success message
							setTimeout(function() {
								props.setAlertTitle('Feito! Comentário adicionado!');		
								props.setAlertBody('O comentário foi adicionado com sucesso.');		
								props.setAlert(true);
							},1000);							
							
							return;
							} // end if
						
					 })
					.catch(error=> {

						props.setSaving(false);
						props.setParentLoading(false);
						props.setAlertTitle('Ops! Ocorreu um erro (cod 3)');		
						props.setAlertBody('Ocorreu um erro ao criar o comentário (erro: '+error.message+').');		
						props.setAlert(true);
						
					});		
					
		}		
		
}
	
export const replyComplaintComment = (data,props) => {
 
		return dispatch => {

				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);
					
					// Prepare Params
					var preparedData = {
						serviceComplaintId:data.serviceComplaintId,
						serviceComplaintCommentId:data.serviceComplaintCommentId,
						reply:data.reply						
					}
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/manager/complaint/reply',
						   method : 'PUT',
						   data   : preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})					
					.then((response) => {
						
							// If an error occured. Check error is blocking or non-blocking.
							if (response.data.error) {														
								props.setSaving(false);
		
								props.setAlertTitle('Ops! Ocorreu um erro (cod 1)');		
								props.setAlertBody('Ocorreu um erro ao criar a resposta. Tente novamente. [1]');		
								props.setAlert(true);							
								return;					
							} // end if	*/
					
							if (response.data.success) {
							
							// Erro creating service
							if (response.data.failedCreation) {						
								props.setSaving(false);
								props.setAlertTitle('Ops! Ocorreu um erro (cod 2)');		
								props.setAlertBody('Ocorreu um erro ao criar a resposta. Tente novamente. [1]');		
								props.setAlert(true);							
								return;					
							} // end if	
							
							// Erro creating service
							if (response.data.closedComplaint) {						
								props.setSaving(false);
								props.setAlertTitle('Ops! Temos um problema!');		
								props.setAlertBody('Não é possível adicionar respostas a reclamações encerradas. [1]');		
								props.setAlert(true);							
								return;					
							} // end if	

							// refresh table
							props.loadLazyData();
							props.loadData();
							props.setReply("");
							
							// close service dialog
							props.setSaving(false);						
							
							// show success message
							setTimeout(function() {
								props.setAlertTitle('Feito! Resposta adicionada!');		
								props.setAlertBody('A resposta foi adicionada com sucesso.');		
								props.setAlert(true);
							},1000);							
							
							return;
							} // end if
						
					 })
					.catch(error=> {

						props.setSaving(false);
						props.setParentLoading(false);
						props.setAlertTitle('Ops! Ocorreu um erro (cod 3)');		
						props.setAlertBody('Ocorreu um erro ao criar a resposta (erro: '+error.message+').');		
						props.setAlert(true);
						
					});		
					
		}		
		
}

export const updateComplaint = (data,props) => {
 
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);
					
					// Prepare Params
					var preparedData = {
						serviceComplaintId:data.serviceComplaintId,
						newStatus:data.newStatus	
					}			
					console.log("[preparedData]--->"+JSON.stringify(preparedData,null,2));

					
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/manager/complaint/update',
						   method : 'PUT',
						   data   : preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})					
					.then((response) => {
						
							// If an error occured. Check error is blocking or non-blocking.
							if (response.data.error) {														
								props.setSaving(false);
		
								props.setAlertTitle('Ops! Ocorreu um erro (cod 1)');		
								props.setAlertBody('Ocorreu um erro ao atualizar o status. Tente novamente. [1]');		
								props.setAlert(true);							
								return;					
							} // end if	*/
					
							if (response.data.success) {
							
							// Erro creating service
							if (response.data.failedCreation) {						
								props.setSaving(false);
								props.setAlertTitle('Ops! Ocorreu um erro (cod 2)');		
								props.setAlertBody('Ocorreu um erro ao atualizar o status. Tente novamente. [1]');		
								props.setAlert(true);							
								return;					
							} // end if	

							// refresh table
							props.loadLazyData();
							props.loadData();
							
							// Turn off loading
							props.setSaving(false);
							
							// Show alert
							props.setAlertTitle('Feito! Status atualizado');		
							props.setAlertBody('O Status foi atualizado com sucesso.');		
							props.setAlert(true);
							return;
							} // end if
						
					 })
					.catch(error=> {

						props.setSaving(false);
						props.setAlertTitle('Ops! Ocorreu um erro (cod 3)');		
						props.setAlertBody('Ocorreu um erro ao atualizar o status (erro: '+error.message+').');		
						props.setAlert(true);
						
					});		

		}		
		
}


/* COMISSIONS */

export const retrieveComissions = (props) => {
	
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  

					// Prepare Params
					var preparedData = {}
				
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/manager/comissions/list',
						   method : 'GET',
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					}) 	
					.then((response) => {

	
						// Retrieve data to controller
						props.setComissionsRows(response.data.comissions);
						props.setTotalRecords(response.data.count);
						props.setLoading(false);
						return;
						
					 })
					.catch(error=> {
						props.setComissionsRows([]);
						props.setTotalRecords(0);
						props.setLoading(false);
						return;
					});		
					
		}
}

export const retrieveComission = (data,props) => {

		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  

					// Prepare Params
					var preparedData = {
						comissionId:data.comissionId						
					}
				
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/manager/comission/retrieve',
						   method : 'POST',
						   data   : preparedData,						   
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					}) 	
					.then((response) => {
	
						console.log("[response.data.comission]--->"+JSON.stringify(response.data.comission,null,2));

	
						// Retrieve data to controller
						props.setComissionRow(response.data.comission);
						props.setLoadingComission(false);
						return;
						
					 })
					.catch(error=> {
						props.setComissionRow({});
						props.setLoadingComission(false);
						return;
					});		
					
		} 

}

export const updateComission = (data,props) => {
 
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);

					// Prepare Params
					var preparedData = {
						comissionId:data.comissionId,
						comissionType:data.comissionType,
						comissionPlatform:data.comissionPlatform,
						comissionProvider:data.comissionProvider,
						comissionPartner:data.comissionPartner,
						serviceId:data.serviceId
					}

					
					console.log("[preparedData]--->"+JSON.stringify(preparedData,null,2));

				
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/manager/comission/update',
						   method : 'PUT',
						   data   : preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})								
					.then((response) => {
						
							// If an error occured. Check error is blocking or non-blocking.
							if (response.data.error) {														
								props.setSaving(false);
								props.setParentLoading(false);	
		
								props.setAlertTitle('Ops! Ocorreu um erro (cod 1)');		
								props.setAlertBody('Ocorreu um erro ao atualizar a comissão. Tente novamente. [1]');		
								props.setAlert(true);							
								return;					
							} // end if	*/
					
							if (response.data.success) {
							
							// Erro creating service
							if (response.data.failedUpdate) {						
								props.setSaving(false);
								props.setParentLoading(false);
								props.setAlertTitle('Ops! Ocorreu um erro (cod 2)');		
								props.setAlertBody('Ocorreu um erro ao atualizar a comissão. Tente novamente. [1]');		
								props.setAlert(true);							
								return;					
							} // end if	

							// refresh table
							props.loadLazyData();
							
							// close service dialog
							props.setAlertComissionUpdate(false);
							
							// Stop loading
							setTimeout(function() {
								props.setSaving(false);
								props.setParentLoading(false);
							},1000);							
							
							// show success message
							setTimeout(function() {
								props.setAlertTitle('Feito! Comissão atualizada!');		
								props.setAlertBody('A comissão foi atualizada com sucesso.');		
								props.setAlert(true);
							},1500);							
							
							return;
							} // end if
						
					 })
					.catch(error=> {

						props.setSaving(false);
						props.setParentLoading(false);
						props.setAlertTitle('Ops! Ocorreu um erro (cod 3)');		
						props.setAlertBody('Ocorreu um erro ao atualizar a comissão (erro: '+error.message+').');		
						props.setAlert(true);
						
					});		
					
		}		
		
}

/*
export const retrieveIncomings = (props) => {
	
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  

					// Prepare Params
					var preparedData = {}
				
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/manager/comissions/list',
						   method : 'GET',
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					}) 	
					.then((response) => {
						
						// Calculate totals based on status

						

	
						// Retrieve data to controller
						props.setComissionsRows(response.data.comissions);
						props.setTotalRecords(response.data.count);
						props.setLoading(false);
						return;
						
					 })
					.catch(error=> {
						props.setComissionsRows([]);
						props.setTotalRecords(0);
						props.setLoading(false);
						return;
					});		
					
		}
}

export const retrieveIncoming = (data,props) => {

		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  

					// Prepare Params
					var preparedData = {
						comissionId:data.comissionId						
					}
				
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/manager/comission/retrieve',
						   method : 'POST',
						   data   : preparedData,						   
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					}) 	
					.then((response) => {
	
						console.log("[response.data.comission]--->"+JSON.stringify(response.data.comission,null,2));

	
						// Retrieve data to controller
						props.setComissionRow(response.data.comission);
						props.setLoadingComission(false);
						return;
						
					 })
					.catch(error=> {
						props.setComissionRow({});
						props.setLoadingComission(false);
						return;
					});		
					
		} 

}

*/
