import React,{useState,useRef} from "react";
import {useHistory,Link} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// @material-ui/core components
import './DataTable.css';
import { makeStyles,lighten } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";

// import { DataGrid,GridToolbar,ptBR  } from '@material-ui/data-grid';
import moment from "moment";

// Import Prime React Components
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import 'primereact/resources/themes/saga-blue/theme.css'; 
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Calendar } from 'primereact/calendar';
import { locale, addLocale, updateLocaleOption, updateLocaleOptions, localeOption, localeOptions } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import { Fieldset } from 'primereact/fieldset';
import { MultiSelect } from 'primereact/multiselect';

import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// Add Locale
addLocale('pt-BR', {
    firstDayOfWeek: 1,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar'
});

// Set calendar locale
addLocale('br', {
	firstDayOfWeek: 1,
	dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
	dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
	dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
	monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
	monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
	today: 'Hoje',
	clear: 'Limpar'
});

locale('pt-BR');

import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import Person from "@material-ui/icons/Person";
import View from '@material-ui/icons/Visibility';
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import ZoomInIcon from '@material-ui/icons/ZoomIn';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Table from "components/Table/Table.js";
// import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

import reportIcon from '../../assets/Images/reportIcon.png'; 
import pdfIcon from '../../assets/Images/pdfIcon.png'; 
import csvIcon from '../../assets/Images/csvIcon.png'; 

// Components
import AlertView from '../../components/AlertModal';

// Load Actions
import * as AppActions from 'Store/Actions/App'
import * as ProviderActions from 'Store/Actions/Provider'

const useStyles = makeStyles(styles);

const AdminUsers = (props) => {

// set vars from props
const { 
	appLoading,
	setAppLoading,
	dialog,
	setDialog
} = props;		


const [value, setValue] = React.useState(0);
const [contentVisible, setContentVisible] = React.useState(false); 

// Alert control
const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertAction, setAlertAction] = React.useState(""); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico");  

// console.log("[providers]: "+JSON.stringify(providers,null,2));
	
const classes = useStyles();

// Handle Tab changes
const handleChange = (event, newValue) => {
	
// Check which TAB was picked
//
		
setValue(newValue);

};

 
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

		
// set Use of history for PUSH 
let history = useHistory();

// Tasks to execute when mount component
React.useEffect(() => {
	
	setTimeout(function() {
			
		setAppLoading(false);
		setContentVisible(true);		
		
	},1000);

    return () => {
     setAppLoading(true);
    };	
	
}, []);

	
if(!contentVisible) {	  
	return (
		<div style={{width:"100%",height:"80vh",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
		
			<div style={{display:"flex",flex:1,alignItems:"center",justifyContent:"center"}}>
			
				<CircularProgress color="primary" />
				<div style={{fontSize:"18px",marginLeft:"15px"}}> Carregando dados...</div>
			
			</div>
		
		</div>
	)
  }

if(contentVisible) {
  return ( 
  <>	
   <Box ml={3} mb={5}>
   
	 <Breadcrumbs aria-label="breadcrumb">
	  <Typography color="textSecondary">Área Gerencial</Typography>
	  <Typography color="textPrimary">Relatórios</Typography>
	</Breadcrumbs>
	
	<Divider />
	
	<h3 style={{marginTop:"30px"}}>Relatórios</h3>
	<h5>Ferramentas para geração de relatórios. Selecione o relatório desejado, configure e gere os dados.</h5>	
		
	</Box>
  
    <GridContainer>
	
      <GridItem xs={12}>

		<Tab1Content
			setAppLoading={setAppLoading}
			setContentVisible={setContentVisible}
			setDialog={setDialog}
			dialog={dialog}
			setAlertTitle={setAlertTitle}
			setAlert={setAlert}
			setAlertBody={setAlertBody}
		/>
	   
	  </GridItem>
	  
    </GridContainer>
	
	</>
  );
}

return (null);

}

const mapStateToProps = store => ({
  buttonLoading:store.appReducer.buttonLoading,
  dialog:store.appReducer.dialog
 });

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions, ...ProviderActions }, dispatch);


// Tab1Content Object
export const Tab1Content = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {

// set vars from props
const { 
	appLoading,
	setAppLoading,
	setDialog,
	dialog,
	setAlertTitle,
	setAlert,
	setAlertBody
} = props;		

// Set classes
const classes = useStyles();	

// DataGrid Params
const [loading, setLoading] = useState(false);
const [parentLoading, setParentLoading] = useState(false);

// Dialog Footer
const renderFooter = () => {
	return (
		<div>
			<Button label="OK" icon="pi pi-check" onClick={() => setAlert(false)} />
		</div>
	);
}		
		
// Tasks to execute when mount component
React.useEffect(() => {

	// on component mount
	//console.log("[MOUNTED COMPONENT...]--->");

    return () => {
     setAppLoading(true);
    };			
}, []);


const openReportConfiguration = (reportId) => {

	// Set modal and open	
	setDialog('report'+reportId+'Config',true);
	
} // end function


// set Use of history for PUSH 
let history = useHistory();
	
	return (
	<>
		<Card>
			  <CardHeader color="info" icon>
				<CardIcon color="info">
				  <Assignment />
				</CardIcon>
				<h4 className={classes.cardIconTitle}>Geração de relatório</h4>
			  </CardHeader>
	
			  <CardBody>	  

				<div className="datatable-responsive-demo" style={{marginTop:"20px"}}>
				
					<div className="card">
					
		
<Fieldset legend="Financeiro e Comercial" style={{marginBottom:"30px"}}>

<Card style={{margin:"10px",maxWidth:"420px",padding:"10px",float:"left"}} >
	<Box style={{display:"flex",flexdirection:"row",alignItems:"center",justifyContent:"start"}}>
		<Box>
			<img src={csvIcon} alt="Relatório" style={{marginLeft:"10px",marginRight:"10px",width:"75px"}} />
		</Box>
		<Box m={1}>	
				<div style={{textAlign:"left",fontSize:"0.85rem",lineHeight:"25px"}}>
				<h4 style={{marginTop:"0px",marginBottom:"0px",fontWeight:"bold"}}>Pagamento de prestadores</h4>
				
				<div style={{marginTop:"10px",marginBottom:"10px"}}>Relatório de pagamento de prestadores por período</div>
				
				<Button variant="contained"
					onClick={() => openReportConfiguration(1) }
				 >
				  Configurar e gerar
				</Button>				
				
				</div>	
		</Box>	
	</Box>
</Card>		



<Card style={{margin:"10px",maxWidth:"420px",padding:"10px",float:"left"}} >
	<Box style={{display:"flex",flexdirection:"row",alignItems:"center",justifyContent:"start"}}>
		<Box>
			<img src={csvIcon} alt="Relatório" style={{marginLeft:"10px",marginRight:"10px",width:"75px"}} />
		</Box>
		<Box m={1}>	
				<div style={{textAlign:"left",fontSize:"0.85rem",lineHeight:"25px"}}>
				<h4 style={{marginTop:"0px",marginBottom:"0px",fontWeight:"bold"}}> Recebimento de clientes</h4>
				
				<div style={{marginTop:"10px",marginBottom:"10px"}}>Relatório de Recebimento de clientes por período</div>
				
				<Button variant="contained"
					onClick={() => openReportConfiguration(2) }
				 >
				  Configurar e gerar
				</Button>					
				
				</div>	
		</Box>	
	</Box>
</Card>	


</Fieldset>		
		
<Fieldset legend="Análise de dados" style={{marginBottom:"30px"}}>


<Card style={{margin:"10px",maxWidth:"420px",padding:"10px",float:"left"}} >
	<Box style={{display:"flex",flexdirection:"row",alignItems:"center",justifyContent:"start"}}>
		<Box>
			<img src={pdfIcon} alt="Relatório" style={{marginLeft:"10px",marginRight:"10px",width:"75px"}} />
		</Box>
		<Box m={1}>	
				<div style={{textAlign:"left",fontSize:"0.85rem",lineHeight:"25px"}}>
				<h4 style={{marginTop:"0px",marginBottom:"0px",fontWeight:"bold"}}>Tempo de atendimento</h4>
				
				<div style={{marginTop:"10px",marginBottom:"10px"}}>Relatório de Tempo de atendimento médio por período</div>
				
				<Button variant="contained"
					onClick={() => openReportConfiguration(3) }
				 >
				  Configurar e gerar
				</Button>			
				
				</div>	
		</Box>	
	</Box>
</Card>		

{/*<Card style={{margin:"10px",maxWidth:"420px",padding:"10px",float:"left"}} >
	<Box style={{display:"flex",flexdirection:"row",alignItems:"center",justifyContent:"start"}}>
		<Box>
			<img src={pdfIcon} alt="Relatório" style={{marginLeft:"10px",marginRight:"10px",width:"75px"}} />
		</Box>
		<Box m={1}>	
				<div style={{textAlign:"left",fontSize:"0.85rem",lineHeight:"25px"}}>
				<h4 style={{marginTop:"0px",marginBottom:"0px",fontWeight:"bold"}}>Resumo de utilização periódica</h4>
				
				<div style={{marginTop:"10px",marginBottom:"10px"}}>Relatório de Resumo de utilização periódica de clientes e parceiros</div>
				
				<Button variant="contained"
					onClick={() => alert("Em desenvolvimento...") }
				 >
				  Configurar e gerar
				</Button>				
				
				</div>	
		</Box>	
	</Box>
	</Card>*/}			


<Card style={{margin:"10px",maxWidth:"420px",padding:"10px",float:"left"}} >
	<Box style={{display:"flex",flexdirection:"row",alignItems:"center",justifyContent:"start"}}>
		<Box>
			<img src={pdfIcon} alt="Relatório" style={{marginLeft:"10px",marginRight:"10px",width:"75px"}} />
		</Box>
		<Box m={1}>	
				<div style={{textAlign:"left",fontSize:"0.85rem",lineHeight:"25px"}}>
				<h4 style={{marginTop:"0px",marginBottom:"0px",fontWeight:"bold"}}>Qualificação de atendimentos</h4>
				
				<div style={{marginTop:"10px",marginBottom:"10px"}}>Relatório de Qualificação de atendimentos por período</div>
				
				<Button variant="contained"
					onClick={() => openReportConfiguration(4) }
				 >
				  Configurar e gerar
				</Button>				
				
				</div>	
		</Box>	
	</Box>
</Card>	

	
<Card style={{margin:"10px",maxWidth:"420px",padding:"10px",float:"left"}} >
	<Box style={{display:"flex",flexdirection:"row",alignItems:"center",justifyContent:"start"}}>
		<Box>
			<img src={reportIcon} alt="Relatório" style={{marginLeft:"10px",marginRight:"10px",width:"75px"}} />
		</Box>
		<Box m={1}>	
				<div style={{textAlign:"left",fontSize:"0.85rem",lineHeight:"25px"}}>
				<h4 style={{marginTop:"0px",marginBottom:"0px",fontWeight:"bold"}}>Ranking de desempenho</h4>
				
				<div style={{marginTop:"10px",marginBottom:"10px"}}>Relatório de Ranking de desempenho por prestador e período</div>
				
				<Button variant="contained"
					onClick={() => openReportConfiguration(5) }
				 >
				  Configurar e gerar
				</Button>				
				
				</div>	
		</Box>	
	</Box>
</Card>		

</Fieldset>					
					

<Fieldset legend="Geral" style={{marginBottom:"30px"}}>


<Card style={{margin:"10px",maxWidth:"420px",padding:"10px",float:"left"}} >
	<Box style={{display:"flex",flexdirection:"row",alignItems:"center",justifyContent:"start"}}>
		<Box>
			<img src={csvIcon} alt="Relatório" style={{marginLeft:"10px",marginRight:"10px",width:"75px"}} />
		</Box>
		<Box m={1}>	
				<div style={{textAlign:"left",fontSize:"0.85rem",lineHeight:"25px"}}>
				<h4 style={{marginTop:"0px",marginBottom:"0px",fontWeight:"bold"}}>Relatório customizado</h4>
				
				<div style={{marginTop:"10px",marginBottom:"10px"}}>Relatório customizado para exportação de dados</div>
				
				<Button variant="contained"
					onClick={() => openReportConfiguration(6) }
				 >
				  Configurar e gerar
				</Button>					
				
				</div>	
		</Box>	
	</Box>
</Card>		

</Fieldset>		
	

{/*<div style={{width:"100%"}}>
									
		o	Relatório de Resumo de utilização periódica de clientes e parceiros <br />
				Formato PDF <br /><br />
		o	Relatório de Tempo de atendimento médio por período <br />
				Formato PDF  <br /><br />
		o	Relatório de ordem de pagamento de prestadores por período <br />
				Formato CSV <br /><br />
		o	Relatório de Recebimento de clientes por período <br />
				Formato CSV <br /><br />
		o	Relatório de Qualificação de atendimentos por período <br />
				Formato PDF <br /><br />
		o	Relatório de Ranking de desempenho por prestador e período <br />
				Formato PDF e CSV <br /><br />
		o	Customizado para exportação de dados  <br />
				Exportar dados selecionando as instâncias: <br />
			•	Usuário e Prestador <br />
			•	Serviços e Pagamentos <br />
			•	Recebimentos <br /><br />
				Exportação em Arquivo CSV;
				
	</div>*/}

			
	</div>
</div>
				
</CardBody>
</Card>	
			
			{/*<Dialog header={alertTitle} visible={alert} style={{ width: '30vw' }} footer={renderFooter()} onHide={() => setAlert(false)} draggable={false} dismissableMask={true} closable={false} >
				<p>{alertBody}</p>
			</Dialog>*/}
			
			<Report1
				setAlertTitle={setAlertTitle}
				setAlert={setAlert}
				setAlertBody={setAlertBody}
				setParentLoading={setParentLoading}
				setDialog={setDialog}
				dialog={dialog}				
			/>		

			<Report2
				setAlertTitle={setAlertTitle}
				setAlert={setAlert}
				setAlertBody={setAlertBody}
				setParentLoading={setParentLoading}
				setDialog={setDialog}
				dialog={dialog}				
			/>	


			<Report3
				setAlertTitle={setAlertTitle}
				setAlert={setAlert}
				setAlertBody={setAlertBody}
				setParentLoading={setParentLoading}
				setDialog={setDialog}
				dialog={dialog}				
			/>

			<Report4
				setAlertTitle={setAlertTitle}
				setAlert={setAlert}
				setAlertBody={setAlertBody}
				setParentLoading={setParentLoading}
				setDialog={setDialog}
				dialog={dialog}				
			/>			

			<Report5
				setAlertTitle={setAlertTitle}
				setAlert={setAlert}
				setAlertBody={setAlertBody}
				setParentLoading={setParentLoading}
				setDialog={setDialog}
				dialog={dialog}				
			/>	

			<Report6
				setAlertTitle={setAlertTitle}
				setAlert={setAlert}
				setAlertBody={setAlertBody}
				setParentLoading={setParentLoading}
				setDialog={setDialog}
				dialog={dialog}				
			/>				

			
	</>	
	);
	
});


// Report 1: Pagamento de Prestadores
export const Report1 = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
		
// Set classes
const classes = useStyles();		

// set vars from props
const { 
	dialog,
	setDialog,
} = props;		

const [saving, setSaving] = useState(false); 

const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertAction, setAlertAction] = React.useState(""); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico");  

// Form fields
const [value, setValue] = React.useState("");

// CUSTOM ADVANCED FILTER FIELDS
const [filterByService, setFilterByService] = React.useState({"code":1,name:"Todos"});
const [filterByCreateAtFrom, setFilterByCreateAtFrom] = React.useState(null);
const [filterByCreateAtTo, setFilterByCreateAtTo] = React.useState(null);
const [filterByProvider, setFilterByProvider] = React.useState({"code":1,name:"Todos"});
const [filterByStatus, setFilterByStatus] = React.useState({"code":0,name:"Todos"});

// Options for status
const statuses = [
    {label: 'Inativo', value: '0'},
    {label: 'Ativo', value: '1'}
];	

// Execute when dialog opens
React.useEffect(() => {

	// Only r]uns when  dialog opens
	if (dialog.target == 'report1Config' && dialog.status) {
	
	
		
	} // end if	
		
},[dialog]);

// Open alert when save button is disabled
const openAlert = (section) => {

	if (section == 'user') {
		setAlertTitle("Verifique as informações");
		setAlertBody("Preencha os dados da região marcados com * para prosseguir com o cadastro.");
		setAlert(true);
	} // end if
	else {
		setAlertTitle("Verifique as informações");
		setAlertBody("Preencha os dados mínimos marcados com * para prosseguir com o cadastro.");
		setAlert(true);
	} // end if	
			
} // end set Region		

// Save instance method	
const generate = () => {

	// Check if min CATEGORY DETAILS fields are filled
	if (1 == 2) {
		openAlert('user');
		return;
	} // end if

	// ALL OK, save service
	
	// Turn on loading on button
	setSaving(true);	

	setTimeout(function() {
			setSaving(false);	
			
		setAlertTitle("Relatório gerado!");
		setAlertBody("Relatório foi gerado com sucesso! Aguarde alguns segundos e confirme o download do mesmo. Caso o download não comece, gere o relatório novamente.");
		setAlert(true);			
			
	},2000);

	/*// Prepare Data
	var data = {
		regionName:regionName,
		regionState:regionState,
		regionCity:regionCity ? regionCity : null
	}
	// Prepare Props
	var propsParam = {
		setSaving:setSaving,
		setParentLoading:setParentLoading,
		setAlertTitle:setAlertTitle,
		setAlertBody:setAlertBody,
		setAlert:setAlert,
	}
	// Save
	createConfigRegion(data,propsParam);*/

} // end function save

const footerModal = ()=> {
	return (
	<>
		<div style={{display:"flex",flexDirection:"row",gap:"10px",alignItems:"center",justifyContent:"flex-end"}}>
			<div>
				<Button label="Fechar" disabled={saving}  icon="pi pi-check" className="p-button-outlined p-button-secondary p-button-lg" onClick={() => {setDialog('report1Config',false);} } />
			</div>	
			<div>
				<Button label="Gerar Relatório" icon="pi pi-check" className="p-button-lg" loading={saving} onClick={() => { generate(); } } />	
			</div>
		</div>
	</>
	);
	
}
	 
	return (
	<>
	<Dialog header="Relatório: Pagamento de Prestadores" visible={dialog.target == 'report1Config' && dialog.status} footer={footerModal} style={{ width: '50vw',height:"75vh" }} breakpoints={{ '1400px': '75vw','960px': '80vw', '641px': '100vw' }} onHide={() => setDialog('report1Config',false)} draggable={false} dismissableMask={false} closable={saving ? false : true} closeOnEscape={false} >
				
				<GridContainer justify="center">
				  <GridItem xs={12} sm={12}>

					<Card>
					  <CardHeader color="info" icon>
						<CardIcon color="info">
						  <Assignment />
						</CardIcon>
						<h4 className={classes.cardIconTitle}>Configuração do relatório</h4>
					  </CardHeader>
					  <CardBody>	 
					  
							{/* Details Block */}
							<Grid container spacing={4} style={{marginTop:"20px",marginBottom:"20px"}}>
							
							
									{/*>> Agendado de - a
										>> Serviços: [seleção] - todos		>> Prestador: [seleção] - Todos
									>> Status: Em disputa, Previsto e Confirmado , TODOS*/	}						
							
								  <Grid item xs={12} sm={12}>
								  
									  <div style={{display:"flex",flexDirection:"row",justifyContent:"stretch",alignItems:"center",gap:"10px"}}>
									  
										<label style={{minWidth:"250px"}}>Data do agendamento do serviço: <span className="requiredField" style={{color:"red"}}>*</span></label>
										<div style={{flex:1,display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}}>
											<span className="p-float-label" style={{width:"49%",marginRight:"5px"}}>
												<Calendar value={filterByCreateAtFrom} onChange={(e) => {setFilterByCreateAtFrom(e.target.value);setFilterByCreateAtTo(null)}} showButtonBar locale="br"  id="fromDate" style={{width:"100%"}} inputStyle={{width:"100%"}} showIcon dateFormat="dd/mm/yy"  />
												<label>Agendado de</label>
											</span>										
											<span className="p-float-label" style={{width:"49%"}}>
												<Calendar value={filterByCreateAtTo} minDate={filterByCreateAtFrom} disabled={!filterByCreateAtFrom} onChange={(e) => setFilterByCreateAtTo(e.target.value)} showButtonBar locale="br"  id="todate" style={{width:"100%"}} inputStyle={{width:"100%"}} showIcon dateFormat="dd/mm/yy"  />
												<label>Agendado até</label>
											</span>	
										</div>
										
									  </div>
									
								  </Grid>	

								  <Grid item xs={12} sm={6}>
									<div style={{display:"flex",flexDirection:"row",justifyContent:"stretch",alignItems:"center",gap:"10px"}}>
										<label style={{minWidth:"100px"}}>Serviço: <span className="requiredField" style={{color:"red"}}>*</span></label>
										<Dropdown filter value={filterByService} onChange={(e) => setFilterByService(e.target.value)} options={[{"code":1,name:"Todos"},{"code":2,name:"Formatação sem Backup"},{"code":3,name:"Limpeza e diagnóstico"},{"code":4,name:"Montagem de PC"}]} optionLabel="name" placeholder="Selecione um ou mais serviços" style={{flex:1}} className="w100"></Dropdown>
									</div>									
								  </Grid>

								  <Grid item xs={12} sm={6}>
									<div style={{display:"flex",flexDirection:"row",justifyContent:"stretch",alignItems:"center",gap:"10px"}}>
										<label style={{minWidth:"100px"}}>Prestador: <span className="requiredField" style={{color:"red"}}>*</span></label>
										<Dropdown showClear filter value={filterByProvider} onChange={(e) => setFilterByProvider(e.target.value)} options={[{"code":1,name:"Todos"},{"code":2,name:"Realweb Soluções"},{"code":3,name:"IT Web Solutions"},{"code":4,name:"Iknet Tech house"}]} optionLabel="name" placeholder="Selecione um ou mais prestadores" style={{flex:1}} className="w100"></Dropdown>
									</div>									
								  </Grid>								  
							
								  <Grid item xs={12} sm={6}>
									<div style={{display:"flex",flexDirection:"row",justifyContent:"stretch",alignItems:"center",gap:"10px"}}>
									<label style={{minWidth:"100px"}}>Status do pagamento: <span className="requiredField" style={{color:"red"}}>*</span></label>
										<Dropdown showClear value={filterByStatus} onChange={(e) => setFilterByStatus(e.target.value)} options={[{"code":0,name:"Todos"},{"code":1,name:"Confirmado"},{"code":2,name:"Previsto"},{"code":3,name:"Cancelado"},{"code":4,name:"Em disputa"}]} optionLabel="name" placeholder="Selecione o Status"  style={{flex:1}} className="w100"></Dropdown>
									</div>										
								  </Grid>						 	  
								  
								</Grid>						  
								{/* Details Block */}
						
						
					  </CardBody>
					</Card>	 
 
				  </GridItem>
				</GridContainer>	

			<AlertView
				alert={alert}
				setAlert={setAlert}
				alertTitle={alertTitle}
				alertBody={alertBody}
				alertAction={alertAction}
				scrollBlock={true}				
			/>						
				
			</Dialog>	
	</>	
	);
	
});

// Report 2: Recebimento de clientes
export const Report2 = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
		
// Set classes
const classes = useStyles();		

// set vars from props
const { 
	dialog,
	setDialog,
} = props;		

const [saving, setSaving] = useState(false); 

const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertAction, setAlertAction] = React.useState(""); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico");  

// Form fields
const [value, setValue] = React.useState("");

// CUSTOM ADVANCED FILTER FIELDS
const [filterByService, setFilterByService] = React.useState({"code":1,name:"Todos"});
const [filterByCreateAtFrom, setFilterByCreateAtFrom] = React.useState(null);
const [filterByCreateAtTo, setFilterByCreateAtTo] = React.useState(null);
const [filterByProvider, setFilterByProvider] = React.useState({"code":1,name:"Todos"});
const [filterByStatus, setFilterByStatus] = React.useState({"code":0,name:"Todos"});

// Options for status
const statuses = [
    {label: 'Inativo', value: '0'},
    {label: 'Ativo', value: '1'}
];	

// Execute when dialog opens
React.useEffect(() => {

	// Only r]uns when  dialog opens
	if (dialog.target == 'report2Config' && dialog.status) {
	
	
		
	} // end if	
		
},[dialog]);

// Open alert when save button is disabled
const openAlert = (section) => {

	if (section == 'user') {
		setAlertTitle("Verifique as informações");
		setAlertBody("Preencha os dados da região marcados com * para prosseguir com o cadastro.");
		setAlert(true);
	} // end if
	else {
		setAlertTitle("Verifique as informações");
		setAlertBody("Preencha os dados mínimos marcados com * para prosseguir com o cadastro.");
		setAlert(true);
	} // end if	
			
} // end set Region		

// Save instance method	
const generate = () => {

	// Check if min CATEGORY DETAILS fields are filled
	if (1 == 2) {
		openAlert('user');
		return;
	} // end if

	// ALL OK, save service
	
	// Turn on loading on button
	setSaving(true);	

	setTimeout(function() {
			setSaving(false);	
			
		setAlertTitle("Relatório gerado!");
		setAlertBody("Relatório foi gerado com sucesso! Aguarde alguns segundos e confirme o download do mesmo. Caso o download não comece, gere o relatório novamente.");
		setAlert(true);			
			
	},2000);

	/*// Prepare Data
	var data = {
		regionName:regionName,
		regionState:regionState,
		regionCity:regionCity ? regionCity : null
	}
	// Prepare Props
	var propsParam = {
		setSaving:setSaving,
		setParentLoading:setParentLoading,
		setAlertTitle:setAlertTitle,
		setAlertBody:setAlertBody,
		setAlert:setAlert,
	}
	// Save
	createConfigRegion(data,propsParam);*/

} // end function save

const footerModal = ()=> {
	return (
	<>
		<div style={{display:"flex",flexDirection:"row",gap:"10px",alignItems:"center",justifyContent:"flex-end"}}>
			<div>
				<Button label="Fechar" disabled={saving}  icon="pi pi-check" className="p-button-outlined p-button-secondary p-button-lg" onClick={() => {setDialog('report2Config',false);} } />
			</div>	
			<div>
				<Button label="Gerar Relatório" icon="pi pi-check" className="p-button-lg" loading={saving} onClick={() => { generate(); } } />	
			</div>
		</div>
	</>
	);
	
}
	 
	return (
	<>
	<Dialog header="Relatório: Recebimento de clientes" visible={dialog.target == 'report2Config' && dialog.status} footer={footerModal} style={{ width: '50vw',height:"75vh" }} breakpoints={{ '1400px': '75vw','960px': '80vw', '641px': '100vw' }} onHide={() => setDialog('report2Config',false)} draggable={false} dismissableMask={false} closable={saving ? false : true} closeOnEscape={false} >
				
				<GridContainer justify="center">
				  <GridItem xs={12} sm={12}>

					<Card>
					  <CardHeader color="info" icon>
						<CardIcon color="info">
						  <Assignment />
						</CardIcon>
						<h4 className={classes.cardIconTitle}>Configuração do relatório</h4>
					  </CardHeader>
					  <CardBody>	 
					  
							{/* Details Block */}
							<Grid container spacing={4} style={{marginTop:"20px",marginBottom:"20px"}}>
							
							
									{/*>> Agendado de - a
										>> Serviços: [seleção] - todos		>> Prestador: [seleção] - Todos
									>> Status: Em disputa, Previsto e Confirmado , TODOS*/	}						
							
								  <Grid item xs={12} sm={12}>
								  
									  <div style={{display:"flex",flexDirection:"row",justifyContent:"stretch",alignItems:"center",gap:"10px"}}>
									  
										<label style={{minWidth:"250px"}}>Data do agendamento do serviço: <span className="requiredField" style={{color:"red"}}>*</span></label>
										<div style={{flex:1,display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}}>
											<span className="p-float-label" style={{width:"49%",marginRight:"5px"}}>
												<Calendar value={filterByCreateAtFrom} onChange={(e) => {setFilterByCreateAtFrom(e.target.value);setFilterByCreateAtTo(null)}} showButtonBar locale="br"  id="fromDate" style={{width:"100%"}} inputStyle={{width:"100%"}} showIcon dateFormat="dd/mm/yy"  />
												<label>Agendado de</label>
											</span>										
											<span className="p-float-label" style={{width:"49%"}}>
												<Calendar value={filterByCreateAtTo} minDate={filterByCreateAtFrom} disabled={!filterByCreateAtFrom} onChange={(e) => setFilterByCreateAtTo(e.target.value)} showButtonBar locale="br"  id="todate" style={{width:"100%"}} inputStyle={{width:"100%"}} showIcon dateFormat="dd/mm/yy"  />
												<label>Agendado até</label>
											</span>	
										</div>
										
									  </div>
									
								  </Grid>	

								  <Grid item xs={12} sm={6}>
									<div style={{display:"flex",flexDirection:"row",justifyContent:"stretch",alignItems:"center",gap:"10px"}}>
										<label style={{minWidth:"100px"}}>Serviço: <span className="requiredField" style={{color:"red"}}>*</span></label>
										<Dropdown filter value={filterByService} onChange={(e) => setFilterByService(e.target.value)} options={[{"code":1,name:"Todos"},{"code":2,name:"Formatação sem Backup"},{"code":3,name:"Limpeza e diagnóstico"},{"code":4,name:"Montagem de PC"}]} optionLabel="name" placeholder="Selecione um ou mais serviços" style={{flex:1}} className="w100"></Dropdown>
									</div>									
								  </Grid>
							  
							
								  <Grid item xs={12} sm={6}>
									<div style={{display:"flex",flexDirection:"row",justifyContent:"stretch",alignItems:"center",gap:"10px"}}>
									<label style={{minWidth:"100px"}}>Status do pagamento: <span className="requiredField" style={{color:"red"}}>*</span></label>
										<Dropdown showClear value={filterByStatus} onChange={(e) => setFilterByStatus(e.target.value)} options={[{"code":0,name:"Todos"},{"code":1,name:"Confirmado"},{"code":2,name:"Previsto"},{"code":3,name:"Cancelado"},{"code":4,name:"Em disputa"}]} optionLabel="name" placeholder="Selecione o Status"  style={{flex:1}} className="w100"></Dropdown>
									</div>										
								  </Grid>						 	  
								  
								</Grid>						  
								{/* Details Block */}
						
						
					  </CardBody>
					</Card>	 
 
				  </GridItem>
				</GridContainer>	

			<AlertView
				alert={alert}
				setAlert={setAlert}
				alertTitle={alertTitle}
				alertBody={alertBody}
				alertAction={alertAction}
				scrollBlock={true}				
			/>						
				
			</Dialog>	
	</>	
	);
	
});

// Report 3: Tempo de atendimento
export const Report3 = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
		
// Set classes
const classes = useStyles();		

// set vars from props
const { 
	dialog,
	setDialog,
} = props;		

const [saving, setSaving] = useState(false); 

const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertAction, setAlertAction] = React.useState(""); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico");  

// Form fields
const [value, setValue] = React.useState("");

// CUSTOM ADVANCED FILTER FIELDS
const [filterByService, setFilterByService] = React.useState({"code":1,name:"Todos"});
const [filterByCreateAtFrom, setFilterByCreateAtFrom] = React.useState(null);
const [filterByCreateAtTo, setFilterByCreateAtTo] = React.useState(null);
const [filterByProvider, setFilterByProvider] = React.useState({"code":1,name:"Todos"});
const [filterByStatus, setFilterByStatus] = React.useState({"code":0,name:"Não comparar"});

// Options for status
const statuses = [
    {label: 'Inativo', value: '0'},
    {label: 'Ativo', value: '1'}
];	

// Execute when dialog opens
React.useEffect(() => {

	// Only r]uns when  dialog opens
	if (dialog.target == 'report3Config' && dialog.status) {
	
	
		
	} // end if	
		
},[dialog]);

// Open alert when save button is disabled
const openAlert = (section) => {

	if (section == 'user') {
		setAlertTitle("Verifique as informações");
		setAlertBody("Preencha os dados da região marcados com * para prosseguir com o cadastro.");
		setAlert(true);
	} // end if
	else {
		setAlertTitle("Verifique as informações");
		setAlertBody("Preencha os dados mínimos marcados com * para prosseguir com o cadastro.");
		setAlert(true);
	} // end if	
			
} // end set Region		

// Save instance method	
const generate = () => {

	// Check if min CATEGORY DETAILS fields are filled
	if (1 == 2) {
		openAlert('user');
		return;
	} // end if

	// ALL OK, save service
	
	// Turn on loading on button
	setSaving(true);	

	setTimeout(function() {
			setSaving(false);	
			
		setAlertTitle("Relatório gerado!");
		setAlertBody("Relatório foi gerado com sucesso! Aguarde alguns segundos e confirme o download do mesmo. Caso o download não comece, gere o relatório novamente.");
		setAlert(true);			
			
	},2000);

	/*// Prepare Data
	var data = {
		regionName:regionName,
		regionState:regionState,
		regionCity:regionCity ? regionCity : null
	}
	// Prepare Props
	var propsParam = {
		setSaving:setSaving,
		setParentLoading:setParentLoading,
		setAlertTitle:setAlertTitle,
		setAlertBody:setAlertBody,
		setAlert:setAlert,
	}
	// Save
	createConfigRegion(data,propsParam);*/

} // end function save

const footerModal = ()=> {
	return (
	<>
		<div style={{display:"flex",flexDirection:"row",gap:"10px",alignItems:"center",justifyContent:"flex-end"}}>
			<div>
				<Button label="Fechar" disabled={saving}  icon="pi pi-check" className="p-button-outlined p-button-secondary p-button-lg" onClick={() => {setDialog('report3Config',false);} } />
			</div>	
			<div>
				<Button label="Gerar Relatório" icon="pi pi-check" className="p-button-lg" loading={saving} onClick={() => { generate(); } } />	
			</div>
		</div>
	</>
	);
	
}
	 
	return (
	<>
	<Dialog header="Relatório: Tempo de Atendimento" visible={dialog.target == 'report3Config' && dialog.status} footer={footerModal} style={{ width: '50vw',height:"75vh" }} breakpoints={{ '1400px': '75vw','960px': '80vw', '641px': '100vw' }} onHide={() => setDialog('report3Config',false)} draggable={false} dismissableMask={false} closable={saving ? false : true} closeOnEscape={false} >
				
				<GridContainer justify="center">
				  <GridItem xs={12} sm={12}>

					<Card>
					  <CardHeader color="info" icon>
						<CardIcon color="info">
						  <Assignment />
						</CardIcon>
						<h4 className={classes.cardIconTitle}>Configuração do relatório</h4>
					  </CardHeader>
					  <CardBody>	 
					  
							{/* Details Block */}
							<Grid container spacing={4} style={{marginTop:"20px",marginBottom:"20px"}}>
							
							
									{/*	>> Configuração
		>> Executado de - a
		>> Comparar com mês anterior?
		>> Serviços: [seleção] - todos
		>> APENAS serviços executados...*/	}						
							
								  <Grid item xs={12} sm={12}>
								  
									  <div style={{display:"flex",flexDirection:"row",justifyContent:"stretch",alignItems:"center",gap:"10px"}}>
									  
										<label style={{minWidth:"250px"}}>Data da execução do serviço: <span className="requiredField" style={{color:"red"}}>*</span></label>
										<div style={{flex:1,display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}}>
											<span className="p-float-label" style={{width:"49%",marginRight:"5px"}}>
												<Calendar value={filterByCreateAtFrom} onChange={(e) => {setFilterByCreateAtFrom(e.target.value);setFilterByCreateAtTo(null)}} showButtonBar locale="br"  id="fromDate" style={{width:"100%"}} inputStyle={{width:"100%"}} showIcon dateFormat="dd/mm/yy"  />
												<label>Executado de</label>
											</span>										
											<span className="p-float-label" style={{width:"49%"}}>
												<Calendar value={filterByCreateAtTo} minDate={filterByCreateAtFrom} disabled={!filterByCreateAtFrom} onChange={(e) => setFilterByCreateAtTo(e.target.value)} showButtonBar locale="br"  id="todate" style={{width:"100%"}} inputStyle={{width:"100%"}} showIcon dateFormat="dd/mm/yy"  />
												<label>Executado até</label>
											</span>	
										</div>
										
									  </div>
									
								  </Grid>	

								  <Grid item xs={12} sm={6}>
									<div style={{display:"flex",flexDirection:"row",justifyContent:"stretch",alignItems:"center",gap:"10px"}}>
										<label style={{minWidth:"100px"}}>Serviço: <span className="requiredField" style={{color:"red"}}>*</span></label>
										<Dropdown filter value={filterByService} onChange={(e) => setFilterByService(e.target.value)} options={[{"code":1,name:"Todos"},{"code":2,name:"Formatação sem Backup"},{"code":3,name:"Limpeza e diagnóstico"},{"code":4,name:"Montagem de PC"}]} optionLabel="name" placeholder="Selecione um ou mais serviços" style={{flex:1}} className="w100"></Dropdown>
									</div>									
								  </Grid>
							  
							
								  <Grid item xs={12} sm={6}>
									<div style={{display:"flex",flexDirection:"row",justifyContent:"stretch",alignItems:"center",gap:"10px"}}>
									<label style={{minWidth:"100px"}}>Comparar com: <span className="requiredField" style={{color:"red"}}>*</span></label>
										<Dropdown showClear value={filterByStatus} onChange={(e) => setFilterByStatus(e.target.value)} options={[{"code":0,name:"Não comparar"},{"code":1,name:"Mês anterior"},{"code":2,name:"Trimestre anterior"},{"code":3,name:"Semestre anterior"},{"code":4,name:"Ano anterior"}]} optionLabel="name" placeholder="Selecione o Status"  style={{flex:1}} className="w100"></Dropdown>
									</div>										
								  </Grid>						 	  
								  
								</Grid>						  
								{/* Details Block */}
						
						
					  </CardBody>
					</Card>	 
 
				  </GridItem>
				</GridContainer>	

			<AlertView
				alert={alert}
				setAlert={setAlert}
				alertTitle={alertTitle}
				alertBody={alertBody}
				alertAction={alertAction}
				scrollBlock={true}				
			/>						
				
			</Dialog>	
	</>	
	);
	
});

// Report 4: Qualificação de atendimentos
export const Report4 = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
		
// Set classes
const classes = useStyles();		

// set vars from props
const { 
	dialog,
	setDialog,
} = props;		

const [saving, setSaving] = useState(false); 

const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertAction, setAlertAction] = React.useState(""); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico");  

// Form fields
const [value, setValue] = React.useState("");

// CUSTOM ADVANCED FILTER FIELDS
const [filterByService, setFilterByService] = React.useState({"code":1,name:"Todos"});
const [filterByCreateAtFrom, setFilterByCreateAtFrom] = React.useState(null);
const [filterByCreateAtTo, setFilterByCreateAtTo] = React.useState(null);
const [filterByProvider, setFilterByProvider] = React.useState({"code":1,name:"Todos"});
const [filterByStatus, setFilterByStatus] = React.useState({"code":0,name:"Não comparar"});

// Options for status
const statuses = [
    {label: 'Inativo', value: '0'},
    {label: 'Ativo', value: '1'}
];	

// Execute when dialog opens
React.useEffect(() => {

	// Only r]uns when  dialog opens
	if (dialog.target == 'report4Config' && dialog.status) {
	
	
		
	} // end if	
		
},[dialog]);

// Open alert when save button is disabled
const openAlert = (section) => {

	if (section == 'user') {
		setAlertTitle("Verifique as informações");
		setAlertBody("Preencha os dados da região marcados com * para prosseguir com o cadastro.");
		setAlert(true);
	} // end if
	else {
		setAlertTitle("Verifique as informações");
		setAlertBody("Preencha os dados mínimos marcados com * para prosseguir com o cadastro.");
		setAlert(true);
	} // end if	
			
} // end set Region		

// Save instance method	
const generate = () => {

	// Check if min CATEGORY DETAILS fields are filled
	if (1 == 2) {
		openAlert('user');
		return;
	} // end if

	// ALL OK, save service
	
	// Turn on loading on button
	setSaving(true);	

	setTimeout(function() {
			setSaving(false);	
			
		setAlertTitle("Relatório gerado!");
		setAlertBody("Relatório foi gerado com sucesso! Aguarde alguns segundos e confirme o download do mesmo. Caso o download não comece, gere o relatório novamente.");
		setAlert(true);			
			
	},2000);

	/*// Prepare Data
	var data = {
		regionName:regionName,
		regionState:regionState,
		regionCity:regionCity ? regionCity : null
	}
	// Prepare Props
	var propsParam = {
		setSaving:setSaving,
		setParentLoading:setParentLoading,
		setAlertTitle:setAlertTitle,
		setAlertBody:setAlertBody,
		setAlert:setAlert,
	}
	// Save
	createConfigRegion(data,propsParam);*/

} // end function save

const footerModal = ()=> {
	return (
	<>
		<div style={{display:"flex",flexDirection:"row",gap:"10px",alignItems:"center",justifyContent:"flex-end"}}>
			<div>
				<Button label="Fechar" disabled={saving}  icon="pi pi-check" className="p-button-outlined p-button-secondary p-button-lg" onClick={() => {setDialog('report4Config',false);} } />
			</div>	
			<div>
				<Button label="Gerar Relatório" icon="pi pi-check" className="p-button-lg" loading={saving} onClick={() => { generate(); } } />	
			</div>
		</div>
	</>
	);
	
}
	 
	return (
	<>
	<Dialog header="Relatório: Qualificação de atendimentos" visible={dialog.target == 'report4Config' && dialog.status} footer={footerModal} style={{ width: '50vw',height:"75vh" }} breakpoints={{ '1400px': '75vw','960px': '80vw', '641px': '100vw' }} onHide={() => setDialog('report4Config',false)} draggable={false} dismissableMask={false} closable={saving ? false : true} closeOnEscape={false} >
				
				<GridContainer justify="center">
				  <GridItem xs={12} sm={12}>

					<Card>
					  <CardHeader color="info" icon>
						<CardIcon color="info">
						  <Assignment />
						</CardIcon>
						<h4 className={classes.cardIconTitle}>Configuração do relatório</h4>
					  </CardHeader>
					  <CardBody>	 
					  
							{/* Details Block */}
							<Grid container spacing={4} style={{marginTop:"20px",marginBottom:"20px"}}>
							
							
									{/*	>> Configuração
		>> Executado de - a
		>> Comparar com mês anterior?
		>> Serviços: [seleção] - todos
		>> APENAS serviços executados...*/	}						
							
								  <Grid item xs={12} sm={12}>
								  
									  <div style={{display:"flex",flexDirection:"row",justifyContent:"stretch",alignItems:"center",gap:"10px"}}>
									  
										<label style={{minWidth:"100px"}}>Período: <span className="requiredField" style={{color:"red"}}>*</span></label>
										<div style={{flex:1,display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}}>
											<span className="p-float-label" style={{width:"49%",marginRight:"5px"}}>
												<Calendar value={filterByCreateAtFrom} onChange={(e) => {setFilterByCreateAtFrom(e.target.value);setFilterByCreateAtTo(null)}} showButtonBar locale="br"  id="fromDate" style={{width:"100%"}} inputStyle={{width:"100%"}} showIcon dateFormat="dd/mm/yy"  />
												<label> de</label>
											</span>										
											<span className="p-float-label" style={{width:"49%"}}>
												<Calendar value={filterByCreateAtTo} minDate={filterByCreateAtFrom} disabled={!filterByCreateAtFrom} onChange={(e) => setFilterByCreateAtTo(e.target.value)} showButtonBar locale="br"  id="todate" style={{width:"100%"}} inputStyle={{width:"100%"}} showIcon dateFormat="dd/mm/yy"  />
												<label> até</label>
											</span>	
										</div>
										
									  </div>
									
								  </Grid>	

								  <Grid item xs={12} sm={6}>
									<div style={{display:"flex",flexDirection:"row",justifyContent:"stretch",alignItems:"center",gap:"10px"}}>
										<label style={{minWidth:"100px"}}>Serviço: <span className="requiredField" style={{color:"red"}}>*</span></label>
										<Dropdown filter value={filterByService} onChange={(e) => setFilterByService(e.target.value)} options={[{"code":1,name:"Todos"},{"code":2,name:"Formatação sem Backup"},{"code":3,name:"Limpeza e diagnóstico"},{"code":4,name:"Montagem de PC"}]} optionLabel="name" placeholder="Selecione um ou mais serviços" style={{flex:1}} className="w100"></Dropdown>
									</div>									
								  </Grid>
					  				 	  
								  
								</Grid>						  
								{/* Details Block */}
						
						
					  </CardBody>
					</Card>	 
 
				  </GridItem>
				</GridContainer>	

			<AlertView
				alert={alert}
				setAlert={setAlert}
				alertTitle={alertTitle}
				alertBody={alertBody}
				alertAction={alertAction}
				scrollBlock={true}				
			/>						
				
			</Dialog>	
	</>	
	);
	
});

// Report 5: Ranking de desempenho
export const Report5 = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
		
// Set classes
const classes = useStyles();		

// set vars from props
const { 
	dialog,
	setDialog,
} = props;		

const [saving, setSaving] = useState(false); 

const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertAction, setAlertAction] = React.useState(""); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico");  

// Form fields
const [value, setValue] = React.useState("");

// CUSTOM ADVANCED FILTER FIELDS
const [filterByService, setFilterByService] = React.useState({"code":1,name:"Todos"});
const [filterByCreateAtFrom, setFilterByCreateAtFrom] = React.useState(null);
const [filterByCreateAtTo, setFilterByCreateAtTo] = React.useState(null);
const [filterByProvider, setFilterByProvider] = React.useState({"code":1,name:"Todos"});
const [filterByStatus, setFilterByStatus] = React.useState({"code":0,name:"Não comparar"});
const [filterByFormat, setFilterByFormat] = React.useState({"code":1,name:"PDF"})

// Options for status
const statuses = [
    {label: 'Inativo', value: '0'},
    {label: 'Ativo', value: '1'}
];	

// Execute when dialog opens
React.useEffect(() => {

	// Only r]uns when  dialog opens
	if (dialog.target == 'report1Config' && dialog.status) {
	
	
		
	} // end if	
		
},[dialog]);

// Open alert when save button is disabled
const openAlert = (section) => {

	if (section == 'user') {
		setAlertTitle("Verifique as informações");
		setAlertBody("Preencha os dados da região marcados com * para prosseguir com o cadastro.");
		setAlert(true);
	} // end if
	else {
		setAlertTitle("Verifique as informações");
		setAlertBody("Preencha os dados mínimos marcados com * para prosseguir com o cadastro.");
		setAlert(true);
	} // end if	
			
} // end set Region		

// Save instance method	
const generate = () => {

	// Check if min CATEGORY DETAILS fields are filled
	if (1 == 2) {
		openAlert('user');
		return;
	} // end if

	// ALL OK, save service
	
	// Turn on loading on button
	setSaving(true);	

	setTimeout(function() {
			setSaving(false);	
			
		setAlertTitle("Relatório gerado!");
		setAlertBody("Relatório foi gerado com sucesso! Aguarde alguns segundos e confirme o download do mesmo. Caso o download não comece, gere o relatório novamente.");
		setAlert(true);			
			
	},2000);

	/*// Prepare Data
	var data = {
		regionName:regionName,
		regionState:regionState,
		regionCity:regionCity ? regionCity : null
	}
	// Prepare Props
	var propsParam = {
		setSaving:setSaving,
		setParentLoading:setParentLoading,
		setAlertTitle:setAlertTitle,
		setAlertBody:setAlertBody,
		setAlert:setAlert,
	}
	// Save
	createConfigRegion(data,propsParam);*/

} // end function save

const footerModal = ()=> {
	return (
	<>
		<div style={{display:"flex",flexDirection:"row",gap:"10px",alignItems:"center",justifyContent:"flex-end"}}>
			<div>
				<Button label="Fechar" disabled={saving}  icon="pi pi-check" className="p-button-outlined p-button-secondary p-button-lg" onClick={() => {setDialog('report5Config',false);} } />
			</div>	
			<div>
				<Button label="Gerar Relatório" icon="pi pi-check" className="p-button-lg" loading={saving} onClick={() => { generate(); } } />	
			</div>
		</div>
	</>
	);
	
}
	 
	return (
	<>
	<Dialog header="Relatório: Ranking e desempenho" visible={dialog.target == 'report5Config' && dialog.status} footer={footerModal} style={{ width: '50vw',height:"75vh" }} breakpoints={{ '1400px': '75vw','960px': '80vw', '641px': '100vw' }} onHide={() => setDialog('report5Config',false)} draggable={false} dismissableMask={false} closable={saving ? false : true} closeOnEscape={false} >
				
				<GridContainer justify="center">
				  <GridItem xs={12} sm={12}>

					<Card>
					  <CardHeader color="info" icon>
						<CardIcon color="info">
						  <Assignment />
						</CardIcon>
						<h4 className={classes.cardIconTitle}>Configuração do relatório</h4>
					  </CardHeader>
					  <CardBody>	 
					  
							{/* Details Block */}
							<Grid container spacing={4} style={{marginTop:"20px",marginBottom:"20px"}}>
						
							
								  <Grid item xs={12} sm={12}>
								  
									  <div style={{display:"flex",flexDirection:"row",justifyContent:"stretch",alignItems:"center",gap:"10px"}}>
									  
										<label style={{minWidth:"100px"}}>Período: <span className="requiredField" style={{color:"red"}}>*</span></label>
										<div style={{flex:1,display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}}>
											<span className="p-float-label" style={{width:"49%",marginRight:"5px"}}>
												<Calendar value={filterByCreateAtFrom} onChange={(e) => {setFilterByCreateAtFrom(e.target.value);setFilterByCreateAtTo(null)}} showButtonBar locale="br"  id="fromDate" style={{width:"100%"}} inputStyle={{width:"100%"}} showIcon dateFormat="dd/mm/yy"  />
												<label> de</label>
											</span>										
											<span className="p-float-label" style={{width:"49%"}}>
												<Calendar value={filterByCreateAtTo} minDate={filterByCreateAtFrom} disabled={!filterByCreateAtFrom} onChange={(e) => setFilterByCreateAtTo(e.target.value)} showButtonBar locale="br"  id="todate" style={{width:"100%"}} inputStyle={{width:"100%"}} showIcon dateFormat="dd/mm/yy"  />
												<label> até</label>
											</span>	
										</div>
										
									  </div>
									
								  </Grid>	

								  <Grid item xs={12} sm={6}>
									<div style={{display:"flex",flexDirection:"row",justifyContent:"stretch",alignItems:"center",gap:"10px"}}>
										<label style={{minWidth:"100px"}}>Serviço: <span className="requiredField" style={{color:"red"}}>*</span></label>
										<Dropdown filter value={filterByService} onChange={(e) => setFilterByService(e.target.value)} options={[{"code":1,name:"Todos"},{"code":2,name:"Formatação sem Backup"},{"code":3,name:"Limpeza e diagnóstico"},{"code":4,name:"Montagem de PC"}]} optionLabel="name" placeholder="Selecione um ou mais serviços" style={{flex:1}} className="w100"></Dropdown>
									</div>									
								  </Grid>		

								  <Grid item xs={12} sm={6}>
									<div style={{display:"flex",flexDirection:"row",justifyContent:"stretch",alignItems:"center",gap:"10px"}}>
										<label style={{minWidth:"100px"}}>Formato do relatório: <span className="requiredField" style={{color:"red"}}>*</span></label>
										<Dropdown  value={filterByFormat} onChange={(e) => setFilterByFormat(e.target.value)} options={[{"code":1,name:"PDF"},{"code":2,name:"CSV Excel"}]} optionLabel="name" placeholder="Selecione o formato do elatório" style={{flex:1}} className="w100"></Dropdown>
									</div>									
								  </Grid>								  
								  
								</Grid>						  
								{/* Details Block */}
						
						
					  </CardBody>
					</Card>	 
 
				  </GridItem>
				</GridContainer>	

			<AlertView
				alert={alert}
				setAlert={setAlert}
				alertTitle={alertTitle}
				alertBody={alertBody}
				alertAction={alertAction}
				scrollBlock={true}				
			/>						
				
			</Dialog>	
	</>	
	);
	
});

// Report 6: Relatório Customizado
export const Report6 = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
		
// Set classes
const classes = useStyles();		

// set vars from props
const { 
	dialog,
	setDialog,
} = props;		

const [saving, setSaving] = useState(false); 

const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertAction, setAlertAction] = React.useState(""); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico");  

// Form fields
const [value, setValue] = React.useState("");

// CUSTOM ADVANCED FILTER FIELDS
const [filterByService, setFilterByService] = React.useState({"code":1,name:"Todos"});
const [filterByCreateAtFrom, setFilterByCreateAtFrom] = React.useState(null);
const [filterByCreateAtTo, setFilterByCreateAtTo] = React.useState(null);
const [filterByProvider, setFilterByProvider] = React.useState({"code":1,name:"Todos"});
const [filterByStatus, setFilterByStatus] = React.useState({"code":0,name:"Não comparar"});
const [filterByInstance, setFilterByInstance] = React.useState("");
const [filterByFormat, setFilterByFormat] = React.useState({"code":1,name:"PDF"});
const [filterByColumn, setFilterByColumn] = React.useState([]);


// Options for status
const statuses = [
    {label: 'Inativo', value: '0'},
    {label: 'Ativo', value: '1'}
];	

// Execute when dialog opens
React.useEffect(() => {

	// Only r]uns when  dialog opens
	if (dialog.target == 'report1Config' && dialog.status) {
	
	
		
	} // end if	
	else {
		setFilterByInstance("");
	} // end else
		
},[dialog]);

// Open alert when save button is disabled
const openAlert = (section) => {

	if (section == 'user') {
		setAlertTitle("Verifique as informações");
		setAlertBody("Preencha os dados da região marcados com * para prosseguir com o cadastro.");
		setAlert(true);
	} // end if
	else {
		setAlertTitle("Verifique as informações");
		setAlertBody("Preencha os dados mínimos marcados com * para prosseguir com o cadastro.");
		setAlert(true);
	} // end if	
			
} // end set Region		

// Save instance method	
const generate = () => {

	// Check if min CATEGORY DETAILS fields are filled
	if (1 == 2) {
		openAlert('user');
		return;
	} // end if

	// ALL OK, save service
	
	// Turn on loading on button
	setSaving(true);	

	setTimeout(function() {
			setSaving(false);	
			
		setAlertTitle("Relatório gerado!");
		setAlertBody("Relatório foi gerado com sucesso! Aguarde alguns segundos e confirme o download do mesmo. Caso o download não comece, gere o relatório novamente.");
		setAlert(true);			
			
	},2000);

	/*// Prepare Data
	var data = {
		regionName:regionName,
		regionState:regionState,
		regionCity:regionCity ? regionCity : null
	}
	// Prepare Props
	var propsParam = {
		setSaving:setSaving,
		setParentLoading:setParentLoading,
		setAlertTitle:setAlertTitle,
		setAlertBody:setAlertBody,
		setAlert:setAlert,
	}
	// Save
	createConfigRegion(data,propsParam);*/

} // end function save

const footerModal = ()=> {
	return (
	<>
		<div style={{display:"flex",flexDirection:"row",gap:"10px",alignItems:"center",justifyContent:"flex-end"}}>
			<div>
				<Button label="Fechar" disabled={saving}  icon="pi pi-check" className="p-button-outlined p-button-secondary p-button-lg" onClick={() => {setDialog('report6Config',false);} } />
			</div>	
			<div>
				<Button label="Gerar Relatório" icon="pi pi-check" className="p-button-lg" loading={saving} onClick={() => { generate(); } } />	
			</div>
		</div>
	</>
	);
	
}

// Selectable columns
const selectUserColumns = [
	{"code":1,name:"Código"},
	{"code":2,name:"Nome completo"},
	{"code":3,name:"Primeiro nome"},
	{"code":4,name:"Sobrenome"},
	{"code":5,name:"Apelido"},
	{"code":6,name:"Email"},
	{"code":7,name:"Celular"},
	{"code":8,name:"Criado em"},
	{"code":9,name:"Atualizado em"},
	{"code":10,name:"Status"}
]; 


const selectProviderColumns = [
	{"code":1,name:"Código"},
	{"code":11,name:"Nome da Empresa"},
	{"code":2,name:"Nome completo"},
	{"code":3,name:"Primeiro nome"},
	{"code":4,name:"Sobrenome"},
	{"code":5,name:"Dt Nascimento"},
	{"code":6,name:"Email"},
	{"code":7,name:"Celular"},
	{"code":8,name:"Criado em"},
	{"code":9,name:"Atualizado em"},
	{"code":12,name:"Disponibilidade"},
	{"code":10,name:"Status"}
]; 

const selectServiceColumns = [
	{"code":1,name:"Código da Ordem de Serviço"},
	{"code":2,name:"Código do Serviço"},
	{"code":3,name:"Nome do serviço"},
	{"code":4,name:"Código do cliente"},
	{"code":5,name:"Código do prestador"},
	{"code":6,name:"Código do executor"},
	{"code":7,name:"Tipo"},
	{"code":8,name:"Data de Agendamento"},
	{"code":9,name:"Período de agendamento"},
	{"code":10,name:"Forma de pagamento"},
	{"code":11,name:"Preço Base"},
	{"code":12,name:"Taxa de urgência"},
	{"code":13,name:"Preço Total"},
	{"code":14,name:"Valor de desconto"},
	{"code":15,name:"Criado em"},
	{"code":16,name:"Atualizado em"},
	{"code":17,name:"Saiu para o serviço em"},
	{"code":18,name:"Chegaria no serviço em"},
	{"code":19,name:"Cancelou o serviço em"},
	{"code":20,name:"Razão do cancelamento"},	
	{"code":21,name:"Razão da alteração de data"},
	{"code":22,name:"Iniciou serviço em"},
	{"code":23,name:"Terminou serviço em"},
	{"code":24,name:"Serviço terminado por"},
	{"code":25,name:"Razão da disputa"},
	{"code":26,name:"Status"}
]; 
	 
	return (
	<>
	<Dialog header="Relatório: Relatório customizado" visible={dialog.target == 'report6Config' && dialog.status} footer={footerModal} style={{ width: '50vw',height:"75vh" }} breakpoints={{ '1400px': '75vw','960px': '80vw', '641px': '100vw' }} onHide={() => setDialog('report6Config',false)} draggable={false} dismissableMask={false} closable={saving ? false : true} closeOnEscape={false} >
				
				<GridContainer justify="center">
				  <GridItem xs={12} sm={12}>

					<Card>
					  <CardHeader color="info" icon>
						<CardIcon color="info">
						  <Assignment />
						</CardIcon>
						<h4 className={classes.cardIconTitle}>Configuração do relatório</h4>
					  </CardHeader>
					  <CardBody>	 
					  
							{/* Details Block */}
							<Grid container spacing={4} style={{marginTop:"20px",marginBottom:"20px"}}>
							
							
									{/*	>> Configuração
		>> Executado de - a
		>> Comparar com mês anterior?
		>> Serviços: [seleção] - todos
		>> APENAS serviços executados...*/	}		

								  <Grid item xs={12} sm={6}>
									<div style={{display:"flex",flexDirection:"row",justifyContent:"stretch",alignItems:"center",gap:"10px"}}>
									<label style={{minWidth:"100px"}}>Selecione a instância: <span className="requiredField" style={{color:"red"}}>*</span></label>
										<Dropdown value={filterByInstance} onChange={(e) => setFilterByInstance(e.target.value)} options={[{"code":1,name:"Usuário"},{"code":2,name:"Prestador"},{"code":3,name:"Ordem de Serviço"}]} optionLabel="name" placeholder="Selecione a instância"  style={{flex:1}} className="w100"></Dropdown>
									</div>										
								  </Grid>

								  {/* User instance */}
								  {filterByInstance.code == 1 &&
								  <>
								  
								  <Grid container spacing={4} style={{marginTop:"0px",marginBottom:"20px",padding:"20px"}}>
								  <div style={{fontSize:"16px",padding:"20px",width:"100%"}}>Relatório customizado Usuário</div>
									  
									  <Grid item xs={12} sm={8}>
										<div style={{display:"flex",flexDirection:"row",justifyContent:"stretch",alignItems:"center",gap:"10px"}}>
											<label style={{minWidth:"100px"}}>Colunas: <span className="requiredField" style={{color:"red"}}>*</span></label>
											<MultiSelect filter value={filterByColumn} onChange={(e) => setFilterByColumn(e.target.value)} options={selectUserColumns} optionLabel="name" placeholder="Selecione ao menos uma coluna" style={{flex:1,maxWidth:"133%"}} className="w100" display="chip"></MultiSelect>
										</div>									
									  </Grid>
									  
									  <Grid item xs={12} sm={12}>
									  
										  <div style={{display:"flex",flexDirection:"row",justifyContent:"stretch",alignItems:"center",gap:"10px"}}>
										   
											<label style={{minWidth:"100px"}}>Data de cadastro: <span className="requiredField" style={{color:"red"}}>*</span></label>
											<div style={{flex:1,display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}}>
												<span className="p-float-label" style={{width:"49%",marginRight:"5px"}}>
													<Calendar value={filterByCreateAtFrom} onChange={(e) => {setFilterByCreateAtFrom(e.target.value);setFilterByCreateAtTo(null)}} showButtonBar locale="br"  id="fromDate" style={{width:"100%"}} inputStyle={{width:"100%"}} showIcon dateFormat="dd/mm/yy"  />
													<label> de</label>
												</span>										
												<span className="p-float-label" style={{width:"49%"}}>
													<Calendar value={filterByCreateAtTo} minDate={filterByCreateAtFrom} disabled={!filterByCreateAtFrom} onChange={(e) => setFilterByCreateAtTo(e.target.value)} showButtonBar locale="br"  id="todate" style={{width:"100%"}} inputStyle={{width:"100%"}} showIcon dateFormat="dd/mm/yy"  />
													<label> até</label>
												</span>	
											</div>
											
										  </div>
										
									  </Grid>	
								  
								
									  <Grid item xs={12} sm={6}>
										<div style={{display:"flex",flexDirection:"row",justifyContent:"stretch",alignItems:"center",gap:"10px"}}>
										<label style={{minWidth:"100px"}}>Status Cadastro: <span className="requiredField" style={{color:"red"}}>*</span></label>
											<Dropdown  value={filterByStatus} onChange={(e) => setFilterByStatus(e.target.value)} options={[{"code":0,name:"Todos"},{"code":1,name:"Ativo"},{"code":2,name:"Inativo"},{"code":3,name:"Bloqueado"},{"code":4,name:"Encerrado"}]} optionLabel="name" placeholder="Selecione o Status"  style={{flex:1}} className="w100"></Dropdown>
										</div>										
									  </Grid>
									  
									  <Grid item xs={12} sm={6}>
										<div style={{display:"flex",flexDirection:"row",justifyContent:"stretch",alignItems:"center",gap:"10px"}}>
										<label style={{minWidth:"100px"}}>Status Validação: <span className="requiredField" style={{color:"red"}}>*</span></label>
											<Dropdown  value={filterByStatus} onChange={(e) => setFilterByStatus(e.target.value)} options={[{"code":0,name:"Em aberto"},{"code":1,name:"Finalizada"},{"code":2,name:"Todas"}]} optionLabel="name" placeholder="Selecione o Status da validação"  style={{flex:1}} className="w100"></Dropdown>
										</div>										
									  </Grid>									  

									<Grid item xs={12} sm={6}>
										<div style={{display:"flex",flexDirection:"row",justifyContent:"stretch",alignItems:"center",gap:"10px"}}>
											<label style={{minWidth:"100px"}}>Dispositivo: <span className="requiredField" style={{color:"red"}}>*</span></label>
											<Dropdown value={filterByFormat} onChange={(e) => setFilterByFormat(e.target.value)} options={[{"code":0,name:"Todos"},{"code":1,name:"Webapp"},{"code":2,name:"Android"},{"code":3,name:"iOS"}]} optionLabel="name" placeholder="Selecione o tipo do dispositivo" style={{flex:1}} className="w100"></Dropdown>
										</div>									
									 </Grid>	
									 
									<Grid item xs={12} sm={6}>
										<div style={{display:"flex",flexDirection:"row",justifyContent:"stretch",alignItems:"center",gap:"10px"}}>
											<label style={{minWidth:"100px"}}>Avaliação: <span className="requiredField" style={{color:"red"}}>*</span></label>
											<Dropdown value={filterByFormat} onChange={(e) => setFilterByFormat(e.target.value)} options={[{"code":0,name:"Todos"},{"code":1,name:"Sem avaliação"},{"code":2,name:"Com avaliação"}]} optionLabel="name" placeholder="Selecione uma opção" style={{flex:1}} className="w100"></Dropdown>
										</div>									
									 </Grid>	

									 
									</Grid>
								  </>
								  }
								  {/* User instance */}
								  
								  
								  {/* Provider instance */}
								  {filterByInstance.code == 2 &&
								  <>
								  <Grid container spacing={4} style={{marginTop:"0px",marginBottom:"20px",padding:"20px"}}>
								  <div style={{fontSize:"16px",padding:"20px",width:"100%"}}>Relatório customizado Prestador</div>
								  

									  <Grid item xs={12} sm={8}>
										<div style={{display:"flex",flexDirection:"row",justifyContent:"stretch",alignItems:"center",gap:"10px"}}>
											<label style={{minWidth:"100px"}}>Colunas: <span className="requiredField" style={{color:"red"}}>*</span></label>
											<MultiSelect filter value={filterByColumn} onChange={(e) => setFilterByColumn(e.target.value)} options={selectProviderColumns} optionLabel="name" placeholder="Selecione ao menos uma coluna" style={{flex:1,maxWidth:"133%"}} className="w100" display="chip"></MultiSelect>
										</div>									
									  </Grid>
								  								  
								  
									  <Grid item xs={12} sm={12}>
									  
										  <div style={{display:"flex",flexDirection:"row",justifyContent:"stretch",alignItems:"center",gap:"10px"}}>
										   
											<label style={{minWidth:"100px"}}>Data de cadastro: <span className="requiredField" style={{color:"red"}}>*</span></label>
											<div style={{flex:1,display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}}>
												<span className="p-float-label" style={{width:"49%",marginRight:"5px"}}>
													<Calendar value={filterByCreateAtFrom} onChange={(e) => {setFilterByCreateAtFrom(e.target.value);setFilterByCreateAtTo(null)}} showButtonBar locale="br"  id="fromDate" style={{width:"100%"}} inputStyle={{width:"100%"}} showIcon dateFormat="dd/mm/yy"  />
													<label> de</label>
												</span>										
												<span className="p-float-label" style={{width:"49%"}}>
													<Calendar value={filterByCreateAtTo} minDate={filterByCreateAtFrom} disabled={!filterByCreateAtFrom} onChange={(e) => setFilterByCreateAtTo(e.target.value)} showButtonBar locale="br"  id="todate" style={{width:"100%"}} inputStyle={{width:"100%"}} showIcon dateFormat="dd/mm/yy"  />
													<label> até</label>
												</span>	
											</div>
											
										  </div>
										
									  </Grid>	
								
									  <Grid item xs={12} sm={6}>
										<div style={{display:"flex",flexDirection:"row",justifyContent:"stretch",alignItems:"center",gap:"10px"}}>
										<label style={{minWidth:"100px"}}>Status Cadastro: <span className="requiredField" style={{color:"red"}}>*</span></label>
											<Dropdown  value={filterByStatus} onChange={(e) => setFilterByStatus(e.target.value)} options={[{"code":0,name:"Todos"},{"code":1,name:"Ativo"},{"code":2,name:"Inativo"},{"code":5,name:"Cadastro Etapa 1"},{"code":3,name:"Bloqueado"},{"code":4,name:"Encerrado"}]} optionLabel="name" placeholder="Selecione o Status"  style={{flex:1}} className="w100"></Dropdown>
										</div>										
									  </Grid>
									  
									  <Grid item xs={12} sm={6}>
										<div style={{display:"flex",flexDirection:"row",justifyContent:"stretch",alignItems:"center",gap:"10px"}}>
										<label style={{minWidth:"100px"}}>Status Validação: <span className="requiredField" style={{color:"red"}}>*</span></label>
											<Dropdown  value={filterByStatus} onChange={(e) => setFilterByStatus(e.target.value)} options={[{"code":0,name:"Em aberto"},{"code":1,name:"Finalizada"},{"code":2,name:"Todas"}]} optionLabel="name" placeholder="Selecione o Status da validação"  style={{flex:1}} className="w100"></Dropdown>
										</div>										
									  </Grid>									  

									<Grid item xs={12} sm={6}>
										<div style={{display:"flex",flexDirection:"row",justifyContent:"stretch",alignItems:"center",gap:"10px"}}>
											<label style={{minWidth:"100px"}}>Dispositivo: <span className="requiredField" style={{color:"red"}}>*</span></label>
											<Dropdown value={filterByFormat} onChange={(e) => setFilterByFormat(e.target.value)} options={[{"code":0,name:"Todos"},{"code":2,name:"Android"},{"code":3,name:"iOS"}]} optionLabel="name" placeholder="Selecione o tipo do dispositivo" style={{flex:1}} className="w100"></Dropdown>
										</div>									
									 </Grid>	
									 
									<Grid item xs={12} sm={6}>
										<div style={{display:"flex",flexDirection:"row",justifyContent:"stretch",alignItems:"center",gap:"10px"}}>
											<label style={{minWidth:"100px"}}>Avaliação: <span className="requiredField" style={{color:"red"}}>*</span></label>
											<Dropdown value={filterByFormat} onChange={(e) => setFilterByFormat(e.target.value)} options={[{"code":0,name:"Todos"},{"code":1,name:"Sem avaliação"},{"code":2,name:"Com avaliação"}]} optionLabel="name" placeholder="Selecione uma opção" style={{flex:1}} className="w100"></Dropdown>
										</div>									
									 </Grid>										 
									  
									</Grid>
								  </>
								  }								  
								  {/* Provider instance */}
		
								  
								  {/* ServiceOrder instance */}
								  {filterByInstance.code == 3 &&
								  <>
								  <Grid container spacing={4} style={{marginTop:"0px",marginBottom:"20px",padding:"20px"}}>
								  <div style={{fontSize:"16px",padding:"20px",width:"100%"}}>Relatório customizado Serviço</div>
								  

									  <Grid item xs={12} sm={8}>
										<div style={{display:"flex",flexDirection:"row",justifyContent:"stretch",alignItems:"center",gap:"10px"}}>
											<label style={{minWidth:"100px"}}>Colunas: <span className="requiredField" style={{color:"red"}}>*</span></label>
											<MultiSelect filter value={filterByColumn} onChange={(e) => setFilterByColumn(e.target.value)} options={selectServiceColumns} optionLabel="name" placeholder="Selecione ao menos uma coluna" style={{flex:1,maxWidth:"133%"}} className="w100" display="chip"></MultiSelect>
										</div>									
									  </Grid>	
							  
								  
									  <Grid item xs={12} sm={12}>
									  
										  <div style={{display:"flex",flexDirection:"row",justifyContent:"stretch",alignItems:"center",gap:"10px"}}>
										   
											<label style={{minWidth:"100px"}}>Data de agendamento: <span className="requiredField" style={{color:"red"}}>*</span></label>
											<div style={{flex:1,display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}}>
												<span className="p-float-label" style={{width:"49%",marginRight:"5px"}}>
													<Calendar value={filterByCreateAtFrom} onChange={(e) => {setFilterByCreateAtFrom(e.target.value);setFilterByCreateAtTo(null)}} showButtonBar locale="br"  id="fromDate" style={{width:"100%"}} inputStyle={{width:"100%"}} showIcon dateFormat="dd/mm/yy"  />
													<label> de</label>
												</span>										
												<span className="p-float-label" style={{width:"49%"}}>
													<Calendar value={filterByCreateAtTo} minDate={filterByCreateAtFrom} disabled={!filterByCreateAtFrom} onChange={(e) => setFilterByCreateAtTo(e.target.value)} showButtonBar locale="br"  id="todate" style={{width:"100%"}} inputStyle={{width:"100%"}} showIcon dateFormat="dd/mm/yy"  />
													<label> até</label>
												</span>	
											</div>
											
										  </div>
										
									  </Grid>	
									  
								  
									  <Grid item xs={12} sm={12}>
									  
										  <div style={{display:"flex",flexDirection:"row",justifyContent:"stretch",alignItems:"center",gap:"10px"}}>
										   
											<label style={{minWidth:"100px"}}>Data de finalização: </label>
											<div style={{flex:1,display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}}>
												<span className="p-float-label" style={{width:"49%",marginRight:"5px"}}>
													<Calendar value={filterByCreateAtFrom} onChange={(e) => {setFilterByCreateAtFrom(e.target.value);setFilterByCreateAtTo(null)}} showButtonBar locale="br"  id="fromDate" style={{width:"100%"}} inputStyle={{width:"100%"}} showIcon dateFormat="dd/mm/yy"  />
													<label> de</label>
												</span>										
												<span className="p-float-label" style={{width:"49%"}}>
													<Calendar value={filterByCreateAtTo} minDate={filterByCreateAtFrom} disabled={!filterByCreateAtFrom} onChange={(e) => setFilterByCreateAtTo(e.target.value)} showButtonBar locale="br"  id="todate" style={{width:"100%"}} inputStyle={{width:"100%"}} showIcon dateFormat="dd/mm/yy"  />
													<label> até</label>
												</span>	
											</div>
											
										  </div>
										
									  </Grid>										  
								  
								  <Grid item xs={12} sm={6}>
									<div style={{display:"flex",flexDirection:"row",justifyContent:"stretch",alignItems:"center",gap:"10px"}}>
										<label style={{minWidth:"100px"}}>Serviço: <span className="requiredField" style={{color:"red"}}>*</span></label>
										<Dropdown filter value={filterByService} onChange={(e) => setFilterByService(e.target.value)} options={[{"code":1,name:"Todos"},{"code":2,name:"Formatação sem Backup"},{"code":3,name:"Limpeza e diagnóstico"},{"code":4,name:"Montagem de PC"}]} optionLabel="name" placeholder="Selecione um ou mais serviços" style={{flex:1}} className="w100"></Dropdown>
									</div>									
								  </Grid>								  
								
									  <Grid item xs={12} sm={6}>
										<div style={{display:"flex",flexDirection:"row",justifyContent:"stretch",alignItems:"center",gap:"10px"}}>
										<label style={{minWidth:"100px"}}>Status: <span className="requiredField" style={{color:"red"}}>*</span></label>
											<Dropdown  value={filterByStatus} onChange={(e) => setFilterByStatus(e.target.value)} options={[{"code":0,name:"Todos"},{"code":1,name:"Agendado"},{"code":2,name:"Em execução"},{"code":3,name:"Finalizado"},{"code":4,name:"Em disputa"}]} optionLabel="name" placeholder="Selecione o Status"  style={{flex:1}} className="w100"></Dropdown>
										</div>										
									  </Grid>
									  
									</Grid>
								  </>
								  }		
								  {/* ServiceOrder instance */}								  
		
								</Grid>	
						
					  </CardBody>
					</Card>	 
 
				  </GridItem>
				</GridContainer>	

			<AlertView
				alert={alert}
				setAlert={setAlert}
				alertTitle={alertTitle}
				alertBody={alertBody}
				alertAction={alertAction}
				scrollBlock={true}				
			/>						
				
			</Dialog>	
	</>	
	);
	
});


export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(AdminUsers);	
