import React,{useState,useRef} from "react";
import {useHistory,Link} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// @material-ui/core components
import './DataTable.css';
import { makeStyles,lighten } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";

// import { DataGrid,GridToolbar,ptBR  } from '@material-ui/data-grid';
import moment from "moment";

// Import Prime React Components
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import 'primereact/resources/themes/saga-blue/theme.css'; 
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Calendar } from 'primereact/calendar';
import { locale, addLocale, updateLocaleOption, updateLocaleOptions, localeOption, localeOptions } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Backdrop from '@material-ui/core/Backdrop';
import { Fieldset } from 'primereact/fieldset';
import { SelectButton } from 'primereact/selectbutton';
import { InputTextarea } from 'primereact/inputtextarea';
import { Chips } from 'primereact/chips';
import { Panel } from 'primereact/panel';
import { RadioButton } from 'primereact/radiobutton';

// import { BlockUI } from 'primereact/blockui'; // loading panel
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';

// Add Locale
addLocale('pt-BR', {
    firstDayOfWeek: 1,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar'
});

locale('pt-BR');

import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import Person from "@material-ui/icons/Person";
import View from '@material-ui/icons/Visibility';
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import ZoomInIcon from '@material-ui/icons/ZoomIn';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Table from "components/Table/Table.js";
// import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

// Load Actions
import * as AppActions from 'Store/Actions/App'
import * as ProviderActions from 'Store/Actions/Provider'

const useStyles = makeStyles(styles);

const AdminUsers = (props) => {

// set vars from props
const { 
	appLoading,
	setAppLoading,
} = props;		


const [value, setValue] = React.useState(0);
const [contentVisible, setContentVisible] = React.useState(false); 

// console.log("[providers]: "+JSON.stringify(providers,null,2));
	
const classes = useStyles();

// Handle Tab changes
const handleChange = (event, newValue) => {
	
// Check which TAB was picked
//
		
setValue(newValue);

};

 
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

	
		
// set Use of history for PUSH 
let history = useHistory();

// Tasks to execute when mount component
React.useEffect(() => {
	
	setTimeout(function() {
			
		setAppLoading(false);
		setContentVisible(true);		
		
	},1000);

    return () => {
     setAppLoading(true);
    };	
	
}, []);

	
if(!contentVisible) {	  
	return (
		<div style={{width:"100%",height:"80vh",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
		
			<div style={{display:"flex",flex:1,alignItems:"center",justifyContent:"center"}}>
			
				<CircularProgress color="primary" />
				<div style={{fontSize:"18px",marginLeft:"15px"}}> Carregando dados...</div>
			
			</div>
		
		</div>
	)
  }

if(contentVisible) {
  return ( 
  <>	
   <Box ml={3} mb={5}>
   
	 <Breadcrumbs aria-label="breadcrumb">
	  <Typography color="textSecondary">Área Gerencial</Typography>
	  <Typography color="textPrimary">Gestão de usuários</Typography>
	</Breadcrumbs>
	
	<Divider />
	
	<h3 style={{marginTop:"30px"}}>Cadastros de usuários administrativos</h3>
	<h5>Cadastro de usuários com acesso ao backoffice. </h5>	
		
	</Box>
  
    <GridContainer>
	
      <GridItem xs={12}>

		<Tab1Content
			setAppLoading={setAppLoading}
			setContentVisible={setContentVisible}
		/>
					
	  </GridItem>
	  
    </GridContainer>		
	</>
  );
}

return (null);

}

const mapStateToProps = store => ({
  buttonLoading:store.appReducer.buttonLoading
 });

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions }, dispatch);


// Tab1Content Object
export const Tab1Content = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {

// set vars from props
const { 
	appLoading,
	setAppLoading,
	retrieveAdminUsers,
	retrieveUserGroups
} = props;		

// Set classes
const classes = useStyles();	

// useRef
var dt = useRef(null);

// Scope data
const [usersRows, setAdminUsersRows] = useState([]);
const [groupsRows, setUserGroupsRows] = useState([]);
const [levelsRows, setUserLevelsRows] = useState([]);

// Filter fields
const [filterByName, setFilterByName] = useState("");
const [filterByEmail, setFilterByEmail] = useState("");
const [filterByContactName, setFilterByContactName] = useState("");
const [filterByCompanyDocument, setFilterByCompanyDocument] = useState("");
const [filterByDocument, setFilterByDocument] = useState("");
const [filterByCreatedAt, setFilterByCreatedAt] = useState("");
const [filterByStatus, setFilterByStatus] = useState(null); 

const [checked, setChecked] = useState(false); 

// Dialog control
const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico"); 

// Service Dialog control
const [alertUser, setAlertUser] = React.useState(false); 
const [alertUserUpdate, setAlertUserUpdate] = React.useState(false); 
const [userId, setUserId] = React.useState(null); 

// DataGrid Params
const [parentLoading, setParentLoading] = useState(false);

// DataGrid Params
const [loading, setLoading] = useState(false);
const [totalRecords, setTotalRecords] = useState(0);
const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
		filters:null,
		sortField:"createdAt",
		sortOrder:1
    });

React.useEffect(() => {

	loadLazyData();

},[lazyParams]);
 
const loadLazyData = () => {
	
	setLoading(true);
	
	// Retrieve List of AdminUsers
	retrieveAdminUsersAction();
}

const onPage = (event) => {
	let _lazyParams = { ...lazyParams, ...event };
	setLazyParams(_lazyParams);
}

const onSort = (event) => {	
	let _lazyParams = { ...lazyParams, ...event };
	setLazyParams(_lazyParams);
}

const onFilter = (event) => {
	let _lazyParams = { ...lazyParams, ...event };
	_lazyParams['first'] = 0;
	setLazyParams(_lazyParams);
}

// ToolBar Content  
const leftToolbarTemplate = () => {
	return (
		<React.Fragment>
			
		</React.Fragment>
	)
}

const rightToolbarTemplate = () => {
	return (
		<React.Fragment>
			
		</React.Fragment>
	)
}  
  
// Header Template  
const header = (
	<div className="table-header" style={{height:"40px"}}>
		<h5 style={{float:"left"}}> Usuários com acesso ao backoffice </h5>
		<span style={{float:"right"}}>
			<Button 
				onClick={() => { setAlertUser(true); }}
			 >
			  Novo Usuário
			</Button>			
		</span>			
	</div>
);	

// Column Name Body Template  
const nameBodyTemplate = (rowData) => {
	return (
		<React.Fragment>
			<span className="p-column-title">Nome Usuário</span>
			{rowData.ManagerProfile.completeName} 
			{rowData.master == 1 &&
			<>
				<i style={{color:"gold",marginLeft:"10px"}} className="pi pi-star"></i>
			</>
			}
		</React.Fragment>
	);
}  

// Column Group Body Template  
const groupBodyTemplate = (rowData) => {
	
	// Select Region 1 from price
	var array = groupsRows; // clone
	var index = array.filter(item => item.id == rowData.groupId);
	
	try { var groupName = index[0].groupName;} catch(e) { var groupName = "-"; }
	
	return (
		<React.Fragment>
			<span className="p-column-title">Grupo</span>
			{groupName}
		</React.Fragment>
	);
	
}  

// Column Level Body Template  
const levelBodyTemplate = (rowData) => {
	
	// Select Region 1 from price
	var array = levelsRows; // clone
	var index = array.filter(item => item.id == rowData.accessLevelId);
	
	var levelName = index[0].accessRoleName;	
	
	return (
		<React.Fragment>
			<span className="p-column-title">Nível de acesso</span>
			{levelName}
		</React.Fragment>
	);
	
}  

// Column Email Body Template    
const emailBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Email</span>
                {rowData.email}
            </React.Fragment>
        );
    }

// Column CreatedAt Body Template  
const createdBodyTemplate = (rowData) => {
	return (
		<React.Fragment>
			<span className="p-column-title">Criado em</span>
			{moment(rowData.createdAt).format("DD/MM/YYYY")}
		</React.Fragment>
	);
}	

// Column Activation Status Body Template  
const statusBodyTemplate = (rowData) => {
	
	if (rowData.status == 0) {
		var activationStatus = "BLOQUEADO";
		var activationStatusLabel = "Inativo";
	} // end if
	else if (rowData.status == 1) {
		var activationStatus = "ATIVADO";
		var activationStatusLabel = "Ativo";
	} // end if
	 
	return (
	<>
	<span className={`product-badge status-${activationStatus.toLowerCase()}`}>{activationStatusLabel}</span>
	</>
	)
}	

// Template for Activation Status DropDown List
const statusItemTemplate = (option) => {	
	return <span className={`product-badge status-${option.id.toLowerCase()}`}>{option.label}</span>;
}	

// Column Actions Body Template  
// NEED TO ADD ROW ID AND ONCLICK EVENT
const actionBodyTemplate = (rowData) => {
	return (
		<React.Fragment>
			<Button label="Editar" icon="pi pi-pencil" className="p-button-outlined p-button-secondary p-button-sm" style={{margin:"3px"}} onClick={() => { openUpdateDialog(rowData.id); }} />
		</React.Fragment>
	);
}	

const filterDate = (value, filter) => {
	if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
		return true;
	}

	if (value === undefined || value === null) {
		return false;
	}

	return value === formatDate(filter);
}

const formatDate = (date) => {
	let month = date.getMonth() + 1;
	let day = date.getDate();

	if (month < 10) {
		month = '0' + month;
	}

	if (day < 10) {
		day = '0' + day;
	}

	return date.getFullYear() + '-' + month + '-' + day;
}		

// Apply Filter
const applyFilterAction = () => {
	
	// Check if any of the filters are not equal to NULL
	if (!filterByName && !filterByEmail && !filterByContactName && !filterByCompanyDocument && !filterByDocument && !filterByCreatedAt && !filterByStatus) {
		setAlertTitle("Verifique as informações");
		setAlertBody("Para aplicar o filtro você tem que ao menos selecionar ou preencher algum item para filtrar.");
		setAlert(true);
		return;
	} // end if
	
	var filters = {};
	filters.realName = filterByName ? filterByName:null;
	filters.email = filterByEmail ? filterByEmail:null;
	filters.completeName = filterByContactName ? filterByContactName:null;
	filters.document = filterByCompanyDocument ? filterByCompanyDocument:null;
	filters.personalDocument = filterByDocument ? filterByDocument:null;
	filters.createdAt = filterByCreatedAt ? filterByCreatedAt:null;
	filters.status = filterByStatus ? filterByStatus.code:null;
	
	// Apply filter on Data from service 
	let _lazyParams = { ...lazyParams, filters:filters };
	setLazyParams(_lazyParams);
	
}

// Reset Filter
const resetFilterAction = () => {
	
	setFilterByName("");
	setFilterByEmail("");
	setFilterByContactName("");
	setFilterByCompanyDocument("");
	setFilterByDocument("");
	setFilterByCreatedAt("");
	setFilterByStatus("");
	setFilterByStatus(null);
	
	var filters = null;
	
	// Apply filter on Data from service 
	let _lazyParams = { ...lazyParams, filters:filters };
	setLazyParams(_lazyParams);	
	
	// Load Data from service with defautl option
	// ...
}

// Filter fields and action buttons

	// Name Filter
	const nameFilter = <InputText type="search" value={filterByName} onChange={(e) => setFilterByName(e.target.value)} className="p-column-filter" placeholder="Filtrar por nome" />;	
	
	// Email Filter
	const emailFilter = <InputText type="search" value={filterByEmail} onChange={(e) => setFilterByEmail(e.target.value)} className="p-column-filter" placeholder="Filtrar por email" />;	
	
	// Phone Filter
	const contactNameFilter = <InputText type="search" value={filterByContactName} onChange={(e) => setFilterByContactName(e.target.value)} className="p-column-filter" placeholder="Filtrar por nome" />;		
	
	// Company Document
	const companyDocumentFilter = <InputText type="search" value={filterByCompanyDocument} onChange={(e) => setFilterByCompanyDocument(e.target.value)} className="p-column-filter" placeholder="Filtrar por CNPJ" />;

	// Personal Document
	const documentFilter = <InputText type="search" value={filterByDocument} onChange={(e) => setFilterByDocument(e.target.value)} className="p-column-filter" placeholder="Filtrar por CPF" />;	
	
	// CreatedAt Filter
	const dateFilter = <Calendar locale="pt-BR" showButtonBar value={filterByCreatedAt} onChange={(e) => setFilterByCreatedAt(e.target.value)} dateFormat="dd/mm/yy" className="p-column-filter" placeholder="Filtrar por data"/>;	

	// Set Status Dropdown Content
	const statuses = [{"id":"BLOQUEADO","label":"Inativo","code":1},{"id":"ATIVADO","label":"Ativo","code":1}];

	// Activation Status Filter
	const statusFilter = <Dropdown value={filterByStatus} options={statuses} onChange={(e) => setFilterByStatus(e.target.value)} itemTemplate={statusItemTemplate} placeholder="Selecione o Status" className="p-column-filter" showClear />;

	// Action Buttons 
	const actionFilter = <><Button label="Aplicar Filtro" icon="pi pi-check" className="p-button-outlined p-button-primary" onClick={() => applyFilterAction()} style={{marginRight:"5px"}} /> {lazyParams.filters && <Button title="Limpar Filtros" icon="pi  pi-filter-slash" className=" p-button-info p-button-outlined" onClick={() => resetFilterAction()} />}</>
		  

// Dialog Footer
const renderFooter = () => {
	return (
		<div>
			<Button label="OK" icon="pi pi-check" onClick={() => setAlert(false)} />
		</div>
	);
}		
		
// Tasks to execute when mount component
React.useEffect(() => {

	// on component mount
	//console.log("[MOUNTED COMPONENT...]--->");

    return () => {
     setAppLoading(true);
    };			
}, []);

		
const retrieveAdminUsersAction = () => {

	// Retrieve Users Groups
	var propsParam = {
		setUserGroupsRows:setUserGroupsRows,
		setUserLevelsRows:setUserLevelsRows
	}		
	retrieveUserGroups(propsParam)

	setTimeout(function() {
		// Retrive Users List
		var data = {
			status:[1,2,3],
			...lazyParams
		}
		var propsParam = {
			setAdminUsersRows:setAdminUsersRows,
			setLoading:setLoading,
			setTotalRecords:setTotalRecords
		}	
		retrieveAdminUsers(data,propsParam);
	},1000);
;	

} // end 


// Open Category Update Dialog
const openUpdateDialog = (id) => {

	setUserId(id);
	setAlertUserUpdate(true);

} // end 

// set Use of history for PUSH 
let history = useHistory();
	
	return (
	<>
		<Card>
			  <CardHeader color="info" icon>
				<CardIcon color="info">
				  <Assignment />
				</CardIcon>
				<h4 className={classes.cardIconTitle}>Lista de usuários</h4>
			  </CardHeader>
	
			  <CardBody>	  

				<div className="datatable-responsive-demo" style={{marginTop:"20px"}}>
				
					<div className="card">
							
						{/*<Toolbar style={{marginBottom:"4px"}} left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>*/}
							
						<DataTable ref={dt} value={usersRows} className="p-datatable-responsive-demo" rows={lazyParams.rows} header={header} paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords}" rowsPerPageOptions={[10,20,50]} emptyMessage="Nenhum prestador ativo." lazy paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage} onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder} onFilter={console.log("filtering...")} filters={lazyParams.filters} loading={loading} >
							
							<Column field="completeName" header="Nome Completo" body={nameBodyTemplate} />
								
							<Column field="email" header="Email" body={emailBodyTemplate} />
							
							<Column header="Grupo" body={groupBodyTemplate} />		
							
							<Column header="Nível Acesso" body={levelBodyTemplate} />		

							<Column header="Status" body={statusBodyTemplate} />
							
							<Column body={actionBodyTemplate} ></Column>
							
						</DataTable>						
						
					</div>
				</div>
								
			  </CardBody>
			</Card>	
			
			<NewUser
				alertUser={alertUser}
				setAlertUser={setAlertUser}
				loadLazyData={loadLazyData}
				setAlertTitle={setAlertTitle}
				setAlertBody={setAlertBody}
				setAlert={setAlert}
				setParentLoading={setParentLoading}
				groupsRows={groupsRows}
				levelsRows={levelsRows}
			/>
			
			<UpdateUser
				alertUserUpdate={alertUserUpdate}
				setAlertUserUpdate={setAlertUserUpdate}
				loadLazyData={loadLazyData}
				setAlertTitle={setAlertTitle}
				setAlertBody={setAlertBody}
				setAlert={setAlert}
				setParentLoading={setParentLoading}
				userId={userId}
				groupsRows={groupsRows}
				levelsRows={levelsRows}				
			/>

			<Dialog header={alertTitle} visible={alert} style={{ width: '50vw' }} footer={renderFooter()} onHide={() => setAlert(false)} draggable={false} dismissableMask={true} closable={false} >
				<p>{alertBody}</p>
			</Dialog> 
			
	</>	
	);
	
});

// New User dialog component
export const NewUser = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
		
// Set classes
const classes = useStyles();		

// set vars from props
const { 
	alertUser,
	setAlertUser,
	loadLazyData,
	buttonLoading,
	setAlertTitle,
	setAlertBody,
	setAlert,
	createAdminUser,
	setParentLoading,
	groupsRows,
	levelsRows
} = props;		

// Service Details 
const [userName, setUserName] = useState(""); 
const [userEmail, setUserEmail] = useState(""); 
const [userPhone, setUserPhone] = useState(null); 
const [userPassword, setUserPassword] = useState(""); 
const [userGroupId, setUserGroupId] = useState(""); 
const [userLevelId, setUserLevelId] = useState(""); 
const [userStatus, setUserStatus] = useState(null); 

const [saving, setSaving] = useState(false); 

// Options for status
const statuses = [
    {label: 'Inativo', value: '0'},
    {label: 'Ativo', value: '1'}
];	
	
// Execute when dialog opens
React.useEffect(() => {

	// Only r]uns when  dialog opens
	if (alertUser === true) {
	
		
	} // end if
	
		
},[alertUser]);

// Open alert when save button is disabled
const openAlert = (section) => {

	if (section == 'user') {
		setAlertTitle("Verifique as informações");
		setAlertBody("Preencha os dados do usuário marcados com * para prosseguir com o cadastro.");
		setAlert(true);
	} // end if
	else {
		setAlertTitle("Verifique as informações");
		setAlertBody("Preencha os dados mínimos marcados com * para prosseguir com o cadastro.");
		setAlert(true);
	} // end if	
			
} // end set Region		

// Save instance method	
const save = () => {

	// Check if min CATEGORY DETAILS fields are filled
	if (!userName || !userPassword || !userStatus || !userGroupId || !userLevelId || !userEmail) {
		openAlert('user');
		return;
	} // end if
	
	// ALL OK, save service
	
	// Turn on loading on button
	setSaving(true);	
	
	// Turn on overlay loading
	setParentLoading(true);

	// Prepare Data
	var data = {
		userName:userName,
		userEmail:userEmail,
		userPhone:userPhone ? userPhone : null,
		userPassword:userPassword,
		userGroupId:userGroupId,
		userLevelId:userLevelId,
		userStatus:userStatus
	}
	// Prepare Props
	var propsParam = {
		setSaving:setSaving,
		setParentLoading:setParentLoading,
		loadLazyData:loadLazyData,
		setAlertTitle:setAlertTitle,
		setAlertBody:setAlertBody,
		setAlert:setAlert,
		setAlertUser:setAlertUser
	}
	// Save
	createAdminUser(data,propsParam);

} // end function save
	
	return (
	<>
	<Dialog header="Novo usuário" visible={alertUser} footer={(<div>
			<Button label="FECHAR" disabled={saving} style={{marginTop:"20px"}} icon="pi pi-check" className="p-button-outlined p-button-secondary p-button-sm" onClick={() => {setAlertUser(false)} } />
		</div>)} onHide={() => setAlertUser(false)} draggable={false} dismissableMask={false} closable={saving ? false : true} closeOnEscape={false} >
				
				<GridContainer justify="center">
				  <GridItem xs={12} sm={12}>

					<Card>
					  <CardHeader color="info" icon>
						<CardIcon color="info">
						  <Assignment />
						</CardIcon>
						<h4 className={classes.cardIconTitle}>Cadastro de novo usuário</h4>
					  </CardHeader>
					  <CardBody>	 
						
						{/* Details Block */}
						<TableContainer component={Box} mt={2}>
						  <Table  aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Dados da Usuário</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Nome completo <sup style={{color:"red"}}>*</sup></TableCell>
								  <TableCell align="left"><InputText style={{width:"100%"}} value={userName} onChange={(e) => setUserName(e.target.value)} maxLength="45" /></TableCell>
								</TableRow>

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Email<sup style={{color:"red"}}>*</sup></TableCell>
								  <TableCell align="left"><InputText type="email" style={{width:"100%"}} value={userEmail} onChange={(e) => setUserEmail(e.target.value)} maxLength="45" /> <small className="block" style={{marginTop:"5px"}}>O email é usado como usuário de login</small></TableCell>
								</TableRow>
								
								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Telefone Celular</TableCell>
								  <TableCell align="left"><InputMask mask="(99) 99999-9999" style={{width:"100%"}} value={userPhone} onChange={(e) => setUserPhone(e.target.value)} maxLength="45" /> <small className="block" style={{marginTop:"5px"}}>Apenas número incluindo o DDD.</small></TableCell>
								</TableRow>								
								
								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Senha de acesso<sup style={{color:"red"}}>*</sup></TableCell>
								  <TableCell align="left"><InputText type="password" style={{width:"100%"}} value={userPassword} onChange={(e) => setUserPassword(e.target.value)} maxLength="45" /> <small className="block" style={{marginTop:"5px"}}>Digite uma senha forte para evitar acessos indesejados.</small></TableCell>
								</TableRow>								


								<TableRow >
								  <TableCell component="th" scope="row">Grupo <sup style={{color:"red"}}>*</sup></TableCell>
								  <TableCell align="left">
									  <Dropdown value={userGroupId} optionValue="id" optionLabel="groupName" options={groupsRows} onChange={(e) => setUserGroupId(e.value)} placeholder="Grupo"/>
								  </TableCell>
								</TableRow>		
								
								<TableRow >
								  <TableCell component="th" scope="row">Nível de acesso <sup style={{color:"red"}}>*</sup></TableCell>
								  <TableCell align="left">
									  <Dropdown value={userLevelId} options={levelsRows} optionValue="id" optionLabel="accessRoleName" onChange={(e) => setUserLevelId(e.value)} placeholder="Nível de acesso"/>
								  </TableCell>
								</TableRow>						

								<TableRow >
								  <TableCell component="th" scope="row">Status <sup style={{color:"red"}}>*</sup></TableCell>
								  <TableCell align="left">
									  <Dropdown value={userStatus} options={statuses} onChange={(e) => setUserStatus(e.value)} placeholder="Status"/>
								  </TableCell>
								</TableRow>

							</TableBody>
						  </Table>
						</TableContainer>
						
					  </CardBody>
					</Card>	 
 
					<div style={{textAlign:"right"}}>
						<Button label="FINALIZAR CADASTRO" icon="pi pi-check" className="p-button-lg" loading={saving} onClick={() => { save(); } } />
					</div>

				  </GridItem>
				</GridContainer>				
				
			</Dialog>	
	</>	
	);
	
});

// Update User dialog component
export const UpdateUser = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
		
// Set classes
const classes = useStyles();	

const screenStyleLoading = {
  alignItems: "middle",
  justifyContent: "center",
  width:"100%",
  display: "flex",
  flexDirection: "column"
};	

// set vars from props
const { 
	alertUserUpdate,
	setAlertUserUpdate,
	loadLazyData,
	buttonLoading,
	setAlertTitle,
	setAlertBody,
	setAlert,
	updateAdminUser,
	retrieveAdminUser,
	userId,
	setParentLoading,
	groupsRows,
	levelsRows
} = props;		

// AdminUser Details
const [userName, setUserName] = useState(""); 
const [userEmail, setUserEmail] = useState(""); 
const [userPhone, setUserPhone] = useState(null); 
const [userPassword, setUserPassword] = useState(""); 
const [userGroupId, setUserGroupId] = useState(""); 
const [userLevelId, setUserLevelId] = useState(""); 
const [userStatus, setUserStatus] = useState(null); 

const [adminUserRow, setAdminUserRow] = useState({});
const [masterUser, setUserMaster] = useState(0);;

const [saving, setSaving] = useState(false); 
const [loadingUser, setLoadingUser] = useState(true); 

// Options for status
const statuses = [
    {label: 'Inativo', value: '0'},
    {label: 'Ativo', value: '1'}
];	
	
// Execute when dialog opens
React.useEffect(() => {

	// Only r]uns when  dialog opens
	if (alertUserUpdate === true) {

		// Retrieve Category - add delay to wait for previous returns
		var data = {
			userId:userId
		}
		var propsParam = {
			setAdminUserRow:setAdminUserRow,
			setLoadingUser:setLoadingUser
		}	
		retrieveAdminUser(data,propsParam);
		
	} // end if
	
	// Reset all fields when closes or open dialog
	// ...
	
	// Reset images when open interface
	setAdminUserRow({});
	setLoadingUser(true);
		
},[alertUserUpdate]);

// Set values for each field
React.useEffect(() => {

	// If service returned set values
	if (adminUserRow.id) {
		
		console.log("[adminUserRow]--->"+JSON.stringify(adminUserRow,null,2));
				
		setUserName(adminUserRow.ManagerProfile.completeName);
		setUserEmail(adminUserRow.email);
		setUserPhone(adminUserRow.mobilePhone);
		setUserGroupId(adminUserRow.groupId);
		setUserLevelId(adminUserRow.accessLevelId);
		setUserStatus(""+adminUserRow.status+"");
		
		setUserMaster(adminUserRow.master);
		
	} // end if

},[adminUserRow]);

// Open alert when save button is disabled
const openAlert = (section) => {

	if (section == 'user') {
		setAlertTitle("Verifique as informações");
		setAlertBody("Preencha os dados do usuário marcados com * para prosseguir com a atualização.");
		setAlert(true);
	} // end if
	else {
		setAlertTitle("Verifique as informações");
		setAlertBody("Preencha os dados mínimos marcados com * para prosseguir com a atualização.");
		setAlert(true);
	} // end if	
			
} // end set Region		

// Save instance method	
const save = () => {

	// Check if min CATEGORY DETAILS fields are filled
	if (!userName || !userStatus || !userGroupId || !userLevelId || !userEmail) {
		openAlert('user');
		return;
	} // end if
	
	// ALL OK, save service
	
	// Turn on loading on button
	setSaving(true);	
	
	// Turn on overlay loading
	setParentLoading(true);

	// Prepare Data
	var data = {
		userId:userId,
		userName:userName,
		userEmail:userEmail,
		userPhone:userPhone ? userPhone : null,
		userPassword:userPassword,
		userGroupId:userGroupId,
		userLevelId:userLevelId,
		userStatus:userStatus
	}
	// Prepare Props
	var propsParam = {
		setSaving:setSaving,
		setParentLoading:setParentLoading,
		loadLazyData:loadLazyData,
		setAlertTitle:setAlertTitle,
		setAlertBody:setAlertBody,
		setAlert:setAlert,
		setAlertUser:setAlertUser
	}
	// Save
	updateAdminUser(data,propsParam);

} // end function save
	
	return (
	<>
	<Dialog header="Editar usuário" visible={alertUserUpdate} style={{ width: '70vw' }} footer={(<div>
			<Button label="FECHAR" disabled={saving} style={{marginTop:"20px"}} icon="pi pi-check" className="p-button-outlined p-button-secondary p-button-sm" onClick={() => {setAlertUserUpdate(false)} } />
		</div>)} onHide={() => setAlertUserUpdate(false)} draggable={false} dismissableMask={false} closable={saving ? false : true} closeOnEscape={false} >
				
				{loadingUser && 
					<Box style={{...screenStyleLoading,height:"65vh"}}>
							<Box mt={0} mx={0} style={{textAlign:"center"}}>								
								<CircularProgress /> 
								<div> Carregando dados...Aguarde!</div>								
							</Box>	
					</Box>
				}

				{!loadingUser &&
				<>	
				<GridContainer justify="center">
				  <GridItem xs={12} sm={12}>

					<Card>
					  <CardHeader color="info" icon>
						<CardIcon color="info">
						  <Assignment />
						</CardIcon>
						<h4 className={classes.cardIconTitle}>Edição usuário de serviço</h4>
					  </CardHeader>
					  <CardBody>	 
						
						{/* Details Block */}
						<TableContainer component={Box} mt={2}>
						  <Table  aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Dados da Usuário</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Nome completo <sup style={{color:"red"}}>*</sup></TableCell>
								  <TableCell align="left"><InputText style={{width:"100%"}} value={userName} onChange={(e) => setUserName(e.target.value)} maxLength="45" /></TableCell>
								</TableRow>

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Email<sup style={{color:"red"}}>*</sup></TableCell>
								  <TableCell align="left"><InputText style={{width:"100%"}} value={userEmail} onChange={(e) => setUserEmail(e.target.value)} maxLength="45" /> <small className="block" style={{marginTop:"5px"}}>O email é usado como usuário de login</small></TableCell>
								</TableRow>
								
								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Telefone Celular</TableCell>
								  <TableCell align="left"><InputMask mask="(99) 99999-9999" style={{width:"100%"}} value={userPhone} onChange={(e) => setUserPhone(e.target.value)} maxLength="45" /> <small className="block" style={{marginTop:"5px"}}>Apenas número incluindo o DDD.</small></TableCell>
								</TableRow>								
								
								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Senha de acesso</TableCell>
								  <TableCell align="left"><InputText type="password" style={{width:"100%"}} value={userPassword} onChange={(e) => setUserPassword(e.target.value)} maxLength="45" /> <small className="block" style={{marginTop:"5px"}}>Caso queira alterar a senha, digite a nova senha acima.</small></TableCell>
								</TableRow>								

								<TableRow >
								  <TableCell component="th" scope="row">Grupo <sup style={{color:"red"}}>*</sup></TableCell>
								  <TableCell align="left">
									  <Dropdown disabled={masterUser} value={userGroupId} optionValue="id" optionLabel="groupName" 
								 options={groupsRows} onChange={(e) => setGroupId(e.value)} placeholder="Grupo"/>
								  </TableCell>
								</TableRow>		
								
								
								<TableRow >
								  <TableCell component="th" scope="row">Nível de acesso <sup style={{color:"red"}}>*</sup></TableCell>
								  <TableCell align="left">
									  <Dropdown disabled={masterUser}  value={userLevelId} optionValue="id" optionLabel="accessRoleName" options={levelsRows} onChange={(e) => setLevelId(e.value)} placeholder="Nível de acesso"/>
								  </TableCell>
								</TableRow>						

								<TableRow >
								  <TableCell component="th" scope="row">Status <sup style={{color:"red"}}>*</sup></TableCell>
								  <TableCell align="left">
									  <Dropdown value={userStatus} options={statuses} onChange={(e) => setUserStatus(e.value)} placeholder="Status"/>
								  </TableCell>
								</TableRow>

							</TableBody>
						  </Table>
						</TableContainer>	
						
					  </CardBody>
					</Card>	 					
					<div style={{textAlign:"right"}}>
					
						<Button label="EXCLUIR USUÁRIO" disabled={masterUser} icon="pi pi-trash" className="p-button-lg p-button-danger"  loading={saving} onClick={() => { console.log("deletar..."); } } style={{marginRight:"50px"}} />					
					
						<Button label="FINALIZAR EDIÇÃO" icon="pi pi-check" className="p-button-lg"  loading={saving} onClick={() => { save(); } } />
					</div>					

				  </GridItem>
				</GridContainer>
				</>
				}
				
			</Dialog>	
	</>	
	);
	
});


export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(AdminUsers);	
