import React,{useState,useRef} from "react";
import {useHistory,Link} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// @material-ui/core components
import './DataTable.css';
import { makeStyles,lighten } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";

// import { DataGrid,GridToolbar,ptBR  } from '@material-ui/data-grid';
import moment from "moment";

// Import Prime React Components
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import 'primereact/resources/themes/saga-blue/theme.css'; 
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Calendar } from 'primereact/calendar';
import { locale, addLocale, updateLocaleOption, updateLocaleOptions, localeOption, localeOptions } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Backdrop from '@material-ui/core/Backdrop';
import { Fieldset } from 'primereact/fieldset';
import { SelectButton } from 'primereact/selectbutton';
import { InputTextarea } from 'primereact/inputtextarea';
import { Chips } from 'primereact/chips';
import { Panel } from 'primereact/panel';
import { RadioButton } from 'primereact/radiobutton';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// import { BlockUI } from 'primereact/blockui'; // loading panel
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';

// Add Locale
addLocale('pt-BR', {
    firstDayOfWeek: 1,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar'
});

locale('pt-BR');

import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import Person from "@material-ui/icons/Person";
import View from '@material-ui/icons/Visibility';
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import ZoomInIcon from '@material-ui/icons/ZoomIn';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Table from "components/Table/Table.js";
// import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

// Load Actions
import * as AppActions from 'Store/Actions/App'
import * as ProviderActions from 'Store/Actions/Provider'

const useStyles = makeStyles(styles);

const Complaints = (props) => {

// set vars from props
const { 
	appLoading,
	setAppLoading,
} = props;		


const [value, setValue] = React.useState(0);
const [contentVisible, setContentVisible] = React.useState(false); 

// console.log("[providers]: "+JSON.stringify(providers,null,2));
	
const classes = useStyles();

// Handle Tab changes
const handleChange = (event, newValue) => {
	
// Check which TAB was picked
//
		
setValue(newValue);

};

 
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

	
		
// set Use of history for PUSH 
let history = useHistory();

// Tasks to execute when mount component
React.useEffect(() => {
	
	setTimeout(function() {
			
		setAppLoading(false);
		setContentVisible(true);		
		
	},1000);

    return () => {
     setAppLoading(true);
    };	
	
}, []);

	
if(!contentVisible) {	  
	return (
		<div style={{width:"100%",height:"80vh",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
		
			<div style={{display:"flex",flex:1,alignItems:"center",justifyContent:"center"}}>
			
				<CircularProgress color="primary" />
				<div style={{fontSize:"18px",marginLeft:"15px"}}> Carregando dados...</div>
			
			</div>
		
		</div>
	)
  }

if(contentVisible) {
  return ( 
  <>	
   <Box ml={3} mb={5}>
   
	 <Breadcrumbs aria-label="breadcrumb">
	  <Typography color="textSecondary">Central de Suporte</Typography>
	  <Typography color="textPrimary">Problemas, Denúncias e Reclamações</Typography>
	</Breadcrumbs>
	
	<Divider />
	
	<h3 style={{marginTop:"30px"}}>Relação de problemas, denúncias e reclamações.</h3>
	<h5>Envidas por Clientes e Prestadores.</h5>	
		
	</Box>
  
    <GridContainer>
	
      <GridItem xs={12}>

		<Tab1Content
			setAppLoading={setAppLoading}
			setContentVisible={setContentVisible}
		/>
					
	  </GridItem>
	  
    </GridContainer>		
	</>
  );
}

return (null);

}

const mapStateToProps = store => ({
  buttonLoading:store.appReducer.buttonLoading
 });

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions }, dispatch);


// Tab1Content Object
export const Tab1Content = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {

// set vars from props
const { 
	appLoading,
	setAppLoading,
	retrieveComplaints,
	retrieveComplaintsGroups
} = props;		

// Set classes
const classes = useStyles();	

// useRef
var dt = useRef(null);

// Scope data
const [complaintsRows, setComplaintsRows] = useState([]);
const [complaintsGroupsRows, setComplaintsGroupsRows] = useState([]);

// Filter fields
const [filterByCreatedAt, setFilterByCreatedAt] = useState("");
const [filterByStatus, setFilterByStatus] = useState(null); 
const [filterByType, setFilterByType] = useState(null); 
const [filterByOrigin, setFilterByOrigin] = useState(null); 

const [checked, setChecked] = useState(false); 

// Dialog control
const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico"); 

// Service Dialog control
const [alertComplaint, setAlertUser] = React.useState(false); 
const [alertComplaintUpdate, setAlertUserUpdate] = React.useState(false); 
const [complaintId, setComplaintId] = React.useState(null); 

// DataGrid Params
const [parentLoading, setParentLoading] = useState(false);

// DataGrid Params
const [loading, setLoading] = useState(false);
const [totalRecords, setTotalRecords] = useState(0);
const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
		filters:null,
		sortField:"createdAt",
		sortOrder:1
    });

React.useEffect(() => {

	loadLazyData();

},[lazyParams]);
 
const loadLazyData = () => {
	
	setLoading(true);
	
	// Retrieve List of Complaints
	retrieveComplaintsAction();
}

const onPage = (event) => {
	let _lazyParams = { ...lazyParams, ...event };
	setLazyParams(_lazyParams);
}

const onSort = (event) => {	
	let _lazyParams = { ...lazyParams, ...event };
	setLazyParams(_lazyParams);
}

const onFilter = (event) => {
	let _lazyParams = { ...lazyParams, ...event };
	_lazyParams['first'] = 0;
	setLazyParams(_lazyParams);
}

// ToolBar Content  
const leftToolbarTemplate = () => {
	return (
		<React.Fragment>
			
		</React.Fragment>
	)
}

const rightToolbarTemplate = () => {
	return (
		<React.Fragment>
			
		</React.Fragment>
	)
}  
  
// Header Template  
const header = (<>
	{/*<div className="table-header" style={{height:"40px"}}>
		<h5 style={{float:"left"}}> Usuários com acesso ao backoffice </h5>
		<span style={{float:"right"}}>
			BUTTON
		</span>			
	</div>*/}</>
);	

const titleBodyTemplate = (rowData) => {
	
	if (rowData.type == 1) {
		var activationStatus = "BLOQUEADO";
		var activationStatusLabel = "Problema";
	} // end if
	else if (rowData.type == 2) {
		var activationStatus = "REVIEW";
		var activationStatusLabel = "Reclamação";
	} // end if
	else if (rowData.type == 3) {
		var activationStatus = "ENCERRADO";
		var activationStatusLabel = "Denúncia";
	} // end if
	else {
		var activationStatus = "INFO";
		var activationStatusLabel = "Sem Tipo";
	}
	
	return (
		<React.Fragment>
			<span className="p-column-title">Título</span>
			<div>{rowData.title}</div>
			<div style={{marginTop:"5px",marginBottom:"5px"}}><span className={`product-badge status-${activationStatus.toLowerCase()}`}>{activationStatusLabel}</span></div>	
		</React.Fragment>
	);
}  


// Column Email Body Template    
const originBodyTemplate = (rowData) => {
	
		// set label
		var label = rowData.origin == 'customer' ? "Cliente" : "Prestador";
		
        return (
            <React.Fragment>
                <span className="p-column-title">Origem</span>
                {label}
            </React.Fragment>
        );
}


const categoryBodyTemplate = (rowData) => {
	
		// set label
		// filter array of categories...
		var categoryFlag = false;
		if (rowData.categoryId && rowData.categoryId !== 0) {
			
			try {
				var array = [...complaintsGroupsRows];
				var index = array.filter(item => item.id == rowData.categoryId);			
				var selectedCategory = index[0].label;
				
				var array2 = [...complaintsGroupsRows];
				var index2 = array2.filter(item => item.id == rowData.subCategoryId);	

				var selectedSubCategory = index2[0].label;
			} catch(e) { }
			
			categoryFlag = true;
		} // end if
		
		return (
            <React.Fragment>
                <span className="p-column-title">Categoria</span>
				
				{categoryFlag &&
				<>
					<div style={{fontSize:"12px"}}>{selectedCategory}</div>
					<div style={{fontSize:"12px",color:"gray"}}>{selectedSubCategory}</div>
				</>
				}
				
				{!categoryFlag &&
				<>
					<small>-</small>
				</>
				}				
				
            </React.Fragment>
        );
}

  
const orderBodyTemplate = (rowData) => {
	
		// set label
		var orderId = rowData.ServiceOrderId ? "Pedido #"+rowData.ServiceOrderId : "-";
		
        return (
            <React.Fragment>
                <span className="p-column-title"># Serviço</span>
                {orderId}
            </React.Fragment>
        );
}



// Column CreatedAt Body Template  
const createdBodyTemplate = (rowData) => {
	return (
		<React.Fragment>
			<span className="p-column-title">Criado em</span>
			{moment(rowData.createdAt).format("DD/MM/YYYY")}
		</React.Fragment>
	);
}	

// Column Activation Status Body Template  
const statusBodyTemplate = (rowData) => {
	
	if (rowData.status == 0) {
		var activationStatus = "INFO";
		var activationStatusLabel = "Enviado";
	} // end if
	else if (rowData.status == 1) {
		var activationStatus = "REVIEW";
		var activationStatusLabel = "Em análise";
	} // end if
	else if (rowData.status == 2) {
		var activationStatus = "ATIVADO";
		var activationStatusLabel = "Encerrado gestor";
	} // end if
	else if (rowData.status == 3) {
		var activationStatus = "ATIVADO";
		var activationStatusLabel = "Encerrado cliente";
	} // end if	
	 
	return (
	<>
	<span className={`product-badge status-${activationStatus.toLowerCase()}`}>{activationStatusLabel}</span>
	</>
	)
}	

// Template for Activation Status DropDown List
const statusItemTemplate = (option) => {	
	return <span className={`product-badge status-${option.id.toLowerCase()}`}>{option.label}</span>;
}	

// Column Actions Body Template  
// NEED TO ADD ROW ID AND ONCLICK EVENT
const actionBodyTemplate = (rowData) => {
	return (
		<React.Fragment>
			<Button label="Editar" icon="pi pi-pencil" className="p-button-outlined p-button-secondary p-button-sm" style={{margin:"3px"}} onClick={() => { openUpdateDialog(rowData.id); }} />
		</React.Fragment>
	);
}	

const filterDate = (value, filter) => {
	if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
		return true;
	}

	if (value === undefined || value === null) {
		return false;
	}

	return value === formatDate(filter);
}

const formatDate = (date) => {
	let month = date.getMonth() + 1;
	let day = date.getDate();

	if (month < 10) {
		month = '0' + month;
	}

	if (day < 10) {
		day = '0' + day;
	}

	return date.getFullYear() + '-' + month + '-' + day;
}		

// Apply Filter
const applyFilterAction = () => {
	
	// Check if any of the filters are not equal to NULL
	if (!filterByCreatedAt && !filterByStatus && !filterByType && !filterByOrigin) {
		setAlertTitle("Verifique as informações");
		setAlertBody("Para aplicar o filtro você tem que ao menos selecionar ou preencher algum item para filtrar.");
		setAlert(true);
		return;
	} // end if
	
	var filters = {};
	filters.createdAt = filterByCreatedAt ? filterByCreatedAt:null;
	filters.status = filterByStatus ? filterByStatus.code:null;
	filters.type = filterByType ? filterByType.code:null;
	filters.origin = filterByOrigin ? filterByOrigin.code:null;
	
	// Apply filter on Data from service 
	let _lazyParams = { ...lazyParams, filters:filters };
	setLazyParams(_lazyParams);
	
}

// Reset Filter
const resetFilterAction = () => {
	
	setFilterByCreatedAt("");
	
	setFilterByStatus("");
	setFilterByStatus(null);
	
	setFilterByType("");
	setFilterByType(null);
	
	setFilterByOrigin("");
	setFilterByOrigin(null);
	
	var filters = null;
	
	// Apply filter on Data from service 
	let _lazyParams = { ...lazyParams, filters:filters };
	setLazyParams(_lazyParams);	
	
	// Load Data from service with defautl option
	// ...
}

// Filter fields and action buttons

	
	// CreatedAt Filter
	const dateFilter = <Calendar locale="pt-BR" showButtonBar value={filterByCreatedAt} onChange={(e) => setFilterByCreatedAt(e.target.value)} dateFormat="dd/mm/yy" className="p-column-filter" placeholder="Filtrar por data"/>;	

	// Set Status Dropdown Content
	const types = [{"id":"BLOQUEADO","label":"PROBLEMA","code":1},{"id":"REVIEW","label":"RECLAMAÇÃO","code":2},{"id":"ENCERRADO","label":"DENÚNCIA","code":3}];

	// Activation Status Filter
	const typeFilter = <Dropdown value={filterByType} options={types} onChange={(e) => setFilterByType(e.target.value)} itemTemplate={statusItemTemplate} placeholder="Selecione o Tipo" className="p-column-filter" showClear />;
	
	
	// Set Status Dropdown Content
	const origins = [{"id":"INFO","label":"Prestador","code":"provider"},{"id":"INFO","label":"Cliente","code":"customer"}];

	// Activation Status Filter
	const originFilter = <Dropdown value={filterByOrigin} options={origins} onChange={(e) => setFilterByOrigin(e.target.value)} itemTemplate={statusItemTemplate} placeholder="Selecione a Origem" className="p-column-filter" showClear />;


	// Set Status Dropdown Content
	const statuses = [{"id":"INFO","label":"Enviado","code":0},{"id":"REVIEW","label":"Em análise","code":1},{"id":"ATIVADO","label":"Encerrado Gestor","code":2},{"id":"ATIVADO","label":"Encerrado Cliente","code":3}];

	// Activation Status Filter
	const statusFilter = <Dropdown value={filterByStatus} options={statuses} onChange={(e) => setFilterByStatus(e.target.value)} itemTemplate={statusItemTemplate} placeholder="Selecione o Status" className="p-column-filter" showClear />;

	// Action Buttons 
	const actionFilter = <><Button label="Aplicar Filtro" icon="pi pi-check" className="p-button-outlined p-button-primary" onClick={() => applyFilterAction()} style={{marginRight:"5px"}} /> {lazyParams.filters && <Button title="Limpar Filtros" icon="pi  pi-filter-slash" className=" p-button-info p-button-outlined" onClick={() => resetFilterAction()} />}</>
		  

// Dialog Footer
const renderFooter = () => {
	return (
		<div>
			<Button label="OK" icon="pi pi-check" onClick={() => setAlert(false)} />
		</div>
	);
}		
		
// Tasks to execute when mount component
React.useEffect(() => {

    return () => {
     setAppLoading(true);
    };			
}, []);

		
const retrieveComplaintsAction = () => {

	// Retrive Complaints Groups
	var data = {
		...lazyParams
	}
	var propsParam = {
		setComplaintsGroupsRows:setComplaintsGroupsRows,
		setLoading:setLoading
	}	
	retrieveComplaintsGroups(propsParam);

	// Retrive Users List
	setTimeout(function() {
		var data = {
			...lazyParams
		}
		var propsParam = {
			setComplaintsRows:setComplaintsRows,
			setLoading:setLoading,
			setTotalRecords:setTotalRecords
		}	
		retrieveComplaints(data,propsParam);
	},1000);

} // end 


// Open Category Update Dialog
const openUpdateDialog = (id) => {

	setComplaintId(id);
	setAlertUserUpdate(true);

} // end 

// set Use of history for PUSH 
let history = useHistory();
	
	return (
	<>
		<Card>
			  <CardHeader color="info" icon>
				<CardIcon color="info">
				  <Assignment />
				</CardIcon>
				<h4 className={classes.cardIconTitle}>Lista de Problemas, Denúncias e Reclamações</h4>
			  </CardHeader>
	
			  <CardBody>	  

				<div className="datatable-responsive-demo" style={{marginTop:"20px"}}>
				
					<div className="card">
							
						<DataTable ref={dt} value={complaintsRows} className="p-datatable-responsive-demo" rows={lazyParams.rows} headerr={header} paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords}" rowsPerPageOptions={[10,20,50]} emptyMessage="Nenhum item encontrado." lazy paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage} onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder} filters={lazyParams.filters} loading={loading} >

							
							<Column field="title" header="Título" body={titleBodyTemplate} filter filterElement={typeFilter} />
							
							<Column field="ServiceOrderId" header="# Serviço" body={orderBodyTemplate} />
							
							<Column field="origin" header="Origem" body={originBodyTemplate} filter filterElement={originFilter} />
							
							<Column header="Categoria" body={categoryBodyTemplate} />		
							
							<Column field="createdAt" header="Criado em" body={createdBodyTemplate} sortable filter filterElement={dateFilter} filterFunction={filterDate} />
							
							<Column header="Status" body={statusBodyTemplate} filter filterElement={statusFilter} />
							
							<Column body={actionBodyTemplate} filter filterElement={actionFilter} ></Column>
							
							
						</DataTable>						
						
					</div>
				</div>
								
			  </CardBody>
			</Card>	
			
			<UpdateComplaint
				alertComplaintUpdate={alertComplaintUpdate}
				setAlertUserUpdate={setAlertUserUpdate}
				loadLazyData={loadLazyData}
				setAlertTitle={setAlertTitle}
				setAlertBody={setAlertBody}
				setAlert={setAlert}
				setParentLoading={setParentLoading}
				complaintId={complaintId}
				complaintsGroupsRows={complaintsGroupsRows}				
			/>

			<Dialog header={alertTitle} visible={alert} style={{ width: '50vw' }} footer={renderFooter()} onHide={() => setAlert(false)} draggable={false} dismissableMask={true} closable={false} >
				<p>{alertBody}</p>
			</Dialog> 
			
	</>	
	);
	
});

// Update User dialog component
export const UpdateComplaint = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
		
// Set classes
const classes = useStyles();	

const screenStyleLoading = {
  alignItems: "middle",
  justifyContent: "center",
  width:"100%",
  display: "flex",
  flexDirection: "column"
};	

// set vars from props
const { 
	alertComplaintUpdate,
	setAlertUserUpdate,
	loadLazyData,
	buttonLoading,
	setAlertTitle,
	setAlertBody,
	setAlert,
	updateComplaint,
	retrieveComplaint,
	createComplaintComment,
	replyComplaintComment,
	retrieveInstance,
	retrieveServiceOrder,
	complaintId,
	setParentLoading,
	complaintsGroupsRows
} = props;		

// AdminUser Details
const [type, setType] = useState(null); 
const [title, setTitle] = useState(""); 
const [details, setDetails] = useState(""); 
const [categoryId, setCategoryId] = useState(null); 
const [subCategoryId, setSubCategoryId] = useState(""); 
const [createdAt, setCreatedAt] = useState(""); 
const [origin, setOrigin] = useState(""); 
const [instanceId, setInstanceId] = useState(null); 
const [instanceType, setInstanceType] = useState(""); 
const [actionToBeTaken, setActionToBeTaken] = useState(null); 
const [serviceOrderId, setServiceOrderId] = useState(null);
const [status, setStatus] = useState(null); 
const [currentStatus, setCurrentStatus] = useState(null); 
const [topic, setTopic] = useState([]);
const [interactions, setInteractions] = useState([]);
const [complaintCommentId, setComplaintCommentId] = useState(null);


const [complaintRow, setComplaintRow] = useState({});
const [instanceRow, setInstanceRow] = useState({});
const [serviceOrderRow, setServiceOrderRow] = useState({});

const [comment, setComment] = useState("");
const [reply, setReply] = useState("");
const [notify, setNotify] = useState(true);

const [saving, setSaving] = useState(false); 
const [savingComment, setSavingComment] = useState(false); 
const [savingReply, setSavingReply] = useState(false); 

const [loadingComplaint, setLoadingComplaint] = useState(true); 
const [loadingInstance, setLoadingInstance] = useState(true); 
const [loadingServiceOrder, setLoadingServiceOrder] = useState(true); 
	
// Set Status Dropdown Content
const statuses = [
	{label:"Enviado",value:0},
	{label:"Em análise",value:1},
	{label:"Encerrado Gestor",value:2},
	{label:"Encerrado Cliente",value:3}
];

const loadData = () => {
		// Retrieve Category - add delay to wait for previous returns
		var data = {
			complaintId:complaintId
		}
		var propsParam = {
			setComplaintRow:setComplaintRow,
			setLoadingComplaint:setLoadingComplaint
		}	
		retrieveComplaint(data,propsParam);
}
		
// Execute when dialog opens
React.useEffect(() => {

	// Only r]uns when  dialog opens
	if (alertComplaintUpdate === true) {

		loadData();
		
	} // end if
	
	// Reset all fields when closes or open dialog
	// ...
	
	// Reset images when open interface
	setComplaintRow({});
	setLoadingComplaint(true);
		
},[alertComplaintUpdate]);

// Set values for each field
React.useEffect(() => {

	// If service returned set values
	if (complaintRow.id) {
				
		setType(complaintRow.type);
		setTitle(complaintRow.title);
		setDetails(complaintRow.details);
		
		// set category and subcategory
		if (complaintRow.categoryId && complaintRow.categoryId !== 0) {
	
				var array = [...complaintsGroupsRows];
				var index = array.filter(item => item.id == complaintRow.categoryId);			
				var selectedCategory = index[0].label;
				setCategoryId(selectedCategory);
				
				if (complaintRow.subCategoryId && complaintRow.subCategoryId !== 0) {
					var array2 = [...complaintsGroupsRows];
					var index2 = array2.filter(item => item.id == complaintRow.subCategoryId);
					var selectedSubCategory = index2[0].label;
					setSubCategoryId(selectedSubCategory);	
				} // end if
				
		} // end if		
		
		setCreatedAt(moment(complaintRow.createdAt).format("DD/MM/YYYY"));
		setOrigin(complaintRow.origin);
		setInstanceId(complaintRow.instanceId);
		setInstanceType(complaintRow.origin);
		setActionToBeTaken(complaintRow.actionToBeTaken);
		setServiceOrderId(complaintRow.ServiceOrderId);
		setStatus(complaintRow.status);
		setCurrentStatus(complaintRow.status);
		
		// Load Instance Data
		var data = {
			instanceType:complaintRow.origin,
			instanceId:complaintRow.instanceId
		}
		var propsParam = {
			setInstanceRow:setInstanceRow,
			setLoadingInstance:setLoadingInstance
		}	
		retrieveInstance(data,propsParam);	

		// Retrieve Service order if exist
		if (complaintRow.ServiceOrderId) {			
			// Load Instance Data
			var data = {
				serviceOrderId:complaintRow.ServiceOrderId
			}
			var propsParam = {
				setServiceOrderRow:setServiceOrderRow,
				setLoadingServiceOrder:setLoadingServiceOrder
			}	
			retrieveServiceOrder(data,propsParam);	
		}
		
		// Set Topic and replies		
		setTopic(complaintRow.ServiceOrderComplaintComments);
		if (complaintRow.ServiceOrderComplaintComments.length > 0) {
			setInteractions(complaintRow.ServiceOrderComplaintComments[0].ServiceOrderComplaintCommentReplies);
			setComplaintCommentId(complaintRow.ServiceOrderComplaintComments[0].id);
		} // end if 
		else {
			setInteractions([]);
		} // end else
		
		
	} // end if

},[complaintRow]);

// Open alert when save button is disabled
const openAlert = (section) => {

	if (section == 'complaint') {
		setAlertTitle("Verifique as informações");
		setAlertBody("Preencha os dados marcados com * para prosseguir com a atualização.");
		setAlert(true);
	} // end if
	else if (section == 'status') {
		setAlertTitle("Verifique as informações");
		setAlertBody("Selecione um Status para prosseguir com a atualização.");
		setAlert(true);
	} // end if	
	else if (section == 'statusSame') {
		setAlertTitle("Verifique as informações");
		setAlertBody("Você não alterou o status. Altere para atualizar.");
		setAlert(true);
	} // end if	
	else {
		setAlertTitle("Verifique as informações");
		setAlertBody("Preencha os dados mínimos marcados com * para prosseguir com a atualização.");
		setAlert(true);
	} // end if	
			
} // end set Region		

// Save instance method	
const save = () => {

	// Check if min CATEGORY DETAILS fields are filled
	if (!status) {
		openAlert('status');
		return;
	} // end if
	
	// Check if newStatus = currentStatus
	if (status == currentStatus) {
		openAlert('statusSame');
		return;
	} // end if	
	
	// ALL OK, save service
	
	// Turn on loading on button
	setSaving(true);	
	
	// Turn on overlay loading
	setParentLoading(true);

	// Prepare Data
	var data = {
		serviceComplaintId:complaintId,
		newStatus:status
	}
	// Prepare Props
	var propsParam = {
		setSaving:setSaving,
		loadLazyData:loadLazyData,
		loadData:loadData,
		setAlertTitle:setAlertTitle,
		setAlertBody:setAlertBody,
		setAlert:setAlert
	}
	// Save
	updateComplaint(data,propsParam);

} // end function save


// Save comment
const saveComment = () => {

	// Check if min CATEGORY DETAILS fields are filled
	if (!comment) {
		openAlert('complaint');
		return;
	} // end if
	
	// ALL OK, save service
	
	// Turn on loading on button
	setSavingComment(true);	

	// Prepare Data
	var data = {
		serviceComplaintId:complaintId,
		comment:comment,
		notifyOrigin:notify		
	}
	// Prepare Props
	var propsParam = {
		setSaving:setSavingComment,
		setComment:setComment,
		setAlertTitle:setAlertTitle,
		setAlertBody:setAlertBody,
		setAlert:setAlert,
		loadData:loadData,
		loadLazyData:loadLazyData
	}
	// Save
	createComplaintComment(data,propsParam);

} // end function save


// Save topic for comment
const saveCommentReply = () => {

	// Check if min CATEGORY DETAILS fields are filled
	if (!reply) {
		openAlert('complaint');
		return;
	} // end if
	
	// ALL OK, save service
	
	// Turn on loading on button
	setSavingReply(true);	

	// Prepare Data
	var data = {
		serviceComplaintId:complaintId,
		serviceComplaintCommentId:complaintCommentId,
		reply:reply	
	}
	// Prepare Props
	var propsParam = {
		setSaving:setSavingReply,
		setReply:setReply,
		setAlertTitle:setAlertTitle,
		setAlertBody:setAlertBody,
		setAlert:setAlert,
		loadData:loadData,
		loadLazyData:loadLazyData
	}


console.log("[data]--->"+JSON.stringify(data,null,2));

	replyComplaintComment(data,propsParam);

} // end function save

const maskPhone = (phone) => {
	
	// Clean and format
	var cleaned = ('' + phone).replace(/\D/g, '')
	  var match = cleaned.match(/^(\d{2})\D*(\d{5}|\d{4})\D*(\d{4})$/)
	  if (match) {
		return '(' + match[1] + ') ' + match[2] + '-' + match[3];
	  } // end if
	  else {
		return "Telefone Inválido";
	  } // end else	  
	
}

	
	return (
	<>
	<Dialog header="Editar usuário" visible={alertComplaintUpdate} style={{ width: '70vw' }} footer={(<div>
			<Button label="FECHAR" disabled={saving} style={{marginTop:"20px"}} icon="pi pi-check" className="p-button-outlined p-button-secondary p-button-sm" onClick={() => {setAlertUserUpdate(false)} } />
		</div>)} onHide={() => setAlertUserUpdate(false)} draggable={false} dismissableMask={false} closable={saving ? false : true} closeOnEscape={false} >
				
				{loadingComplaint && 
					<Box style={{...screenStyleLoading,height:"65vh"}}>
							<Box mt={0} mx={0} style={{textAlign:"center"}}>								
								<CircularProgress /> 
								<div> Carregando dados...Aguarde!</div>								
							</Box>	
					</Box>
				}

				{!loadingComplaint &&
				<>	
				<GridContainer justify="center">
				  <GridItem xs={12} sm={12}>

					<Card>
					  <CardHeader color="info" icon>
						<CardIcon color="info">
						  <Assignment />
						</CardIcon>
						<h4 className={classes.cardIconTitle}>Edição de reclamação ou denúncia</h4>
					  </CardHeader>
					  <CardBody>	 
						
						{/* Details Block */}
						<TableContainer component={Box} mt={2}>
						  <Table  aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Informações enviadas</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell style={{width:"200px"}} component="th" scope="row">Tipo </TableCell>
								  <TableCell align="left">
								  
									  {type == 1 &&
										<span className="product-badge status-bloqueado">PROBLEMA</span>
									  }
									  
									  {type == 2 &&
										<span className="product-badge status-review">RECLAMAÇÃO</span>
									  }

									  {type == 3 &&
										<span className="product-badge status-encerrado">DENÚNCIA</span>
									  }									  
									  									  
								   </TableCell>
								</TableRow>
								
								<TableRow >
								  <TableCell style={{width:"200px"}} component="th" scope="row">Criado em</TableCell>
								  <TableCell align="left">{createdAt}</TableCell>
								</TableRow>								

								<TableRow >
								  <TableCell style={{width:"200px"}} component="th" scope="row">Título</TableCell>
								  <TableCell align="left">{title}</TableCell>
								</TableRow>
								
								<TableRow >
								  <TableCell style={{width:"200px"}} component="th" scope="row">Detalhes</TableCell>
								  <TableCell align="left">{details}</TableCell>
								</TableRow>
								
								{type == 1 &&
								<>
								<TableRow >
								  <TableCell style={{width:"200px"}} component="th" scope="row">Categorias</TableCell>
								  <TableCell align="left">
								  
									<div style={{fontSize:"12px"}}>{categoryId}</div>
									<div style={{fontSize:"11px",color:"gray"}}>{subCategoryId}</div>								  
								  
								  </TableCell>
								</TableRow>
								</>
								}
								
								<TableRow >
								  <TableCell style={{width:"200px"}} component="th" scope="row">Origem</TableCell>
								  <TableCell align="left">
								  
									  {origin == 'customer' &&
										<span className="product-badge status-info">CLIENTE</span>
									  }
									  
									  {origin == 'provider' &&
										<span className="product-badge status-info">PRESTADOR</span>
									  }								  
								  
								  </TableCell>
								</TableRow>	

								{(actionToBeTaken == 1 || actionToBeTaken == 2)  &&
								<>
								<TableRow >
								  <TableCell style={{width:"200px"}} component="th" scope="row">Ação realizada</TableCell>
								  <TableCell align="left">
								  
									  {actionToBeTaken == 1 &&
										<span className="product-badge status-bloqueado">CLIENTE ABRIU DISPUTA</span>
									  }
									  
									  {actionToBeTaken == 2 &&
										<span className="product-badge status-review">SERVIÇO EM ANÁLISE</span>
									  }								  
								  
								  </TableCell>
								</TableRow>	
								</>								
								}
								
								<TableRow >
								  <TableCell component="th" scope="row">Status <sup style={{color:"red"}}>*</sup></TableCell>
								  <TableCell align="left">
									  <Dropdown value={status} options={statuses} onChange={(e) => setStatus(e.value)} placeholder="Status"/>
									  
									  <Button style={{marginLeft:"25px"}} label="ATUALIZAR STATUS" icon="pi pi-check" className="p-button-lg"  loading={saving} onClick={() => { save(); } } />
									  
								  </TableCell>
								</TableRow>

							</TableBody>
						  </Table>
						</TableContainer>	
						

						{/* Interactions Details Block */}
						<TableContainer component={Box} mt={2}>
						  <Table  aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Interações</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

	
								<TableRow >
								  <TableCell colspan="2">
										
										{topic.length > 0 &&
										<>
											<Card style={{width:"70%",padding:"20px",backgroundColor:"#FFFFEA"}}>
												<div style={{fontSize:"14px",color:"gray",fontWeight:"bold"}}> {topic[0].comment} </div>
												<div style={{fontSize:"10px",color:"gray"}}> Enviada por {topic[0].ManagerUserName} em {moment(topic[0].createdAt).format("DD/MM/YYYY")} </div>	
											</Card>
										</>
										}
									
										{interactions.length > 0 &&
										<>

											{interactions.map((item) => {
												
												var createdAt = moment(item.createdAt).format("DD/MM/YYYY");
												var sentBy = item.origin == 'manager' ? "Enviada pela Motuum em "+createdAt : "Enviada pelo remetente em "+createdAt;

												var color = item.origin == 'manager' ? "#FFFFEA" : "#FFFFFF";
											
												return (
													<Card style={{padding:"20px",backgroundColor:color}} >
														<div style={{fontSize:"14px",color:"gray"}}> {item.reply} </div>
														<div style={{fontSize:"10px",color:"gray"}}> {sentBy} </div>	
													</Card>
												)}
											)}												

										</>
										}										
		
								  </TableCell>
								</TableRow>
								
								{complaintRow.ServiceOrderComplaintComments.length > 0 && (complaintRow.status != 2 &&complaintRow.status != 3) &&
								<>	
								<TableRow >
								  <TableCell style={{width:"250px",verticalAlign:"baseline"}} component="th" scope="row">Adicionar Resposta</TableCell>
								  <TableCell align="left"><InputTextarea  style={{width:"100%"}} rows={3} value={reply} onChange={(e) => { setReply(event.target.value) } } autoResize />
								  
								   <Button style={{float:"right"}} label="Adicionar" icon="pi pi-check" className="p-button-lg"  loading={savingReply} onClick={!savingReply ? () => { saveCommentReply();} : null} />
										
								  </TableCell>
								</TableRow>
								</>
								}
								
								{complaintRow.ServiceOrderComplaintComments.length == 0 && (complaintRow.status != 2 &&complaintRow.status != 3) &&
								<>								
								<TableRow >
								  <TableCell style={{width:"250px",verticalAlign:"baseline"}} component="th" scope="row">Adicionar primeira interação</TableCell>
								  <TableCell align="left"><InputTextarea  style={{width:"100%"}} rows={3} value={comment} onChange={(e) => { setComment(event.target.value) } } autoResize />

									  <FormControlLabel
										control={
											<Switch
												color="primary"
												checked={notify} 
												onChange={(event) => {
													setNotify(event.target.checked);
												}}	
											/>}
										label="Enviar notificação"
									  />
								  
							  
								   <Button style={{float:"right"}} label="Adicionar" icon="pi pi-check" className="p-button-lg"  loading={savingComment} onClick={!savingComment ? () => { saveComment(); } : null} />
										
								  </TableCell>
								</TableRow>	
								</>
								}


							</TableBody>
						  </Table>
						</TableContainer>	
						
						
						{/* Details Block */}
						<TableContainer component={Box} mt={2}>
						  <Table  aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Dados do {instanceType == 'customer' ? "Cliente" : "Prestador"}</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>
							
							{loadingInstance && 
							<>								
								<TableRow >
								  <TableCell colpsan="2" component="th" scope="row">
									<div style={{display:"flex",flex:1,alignItems:"center",justifyContent:"center"}}>
										<CircularProgress color="primary" />
										<div style={{fontSize:"18px",marginLeft:"15px"}}> Carregando dados...</div>
									</div>							  
								  </TableCell>
								</TableRow>	
							</>																
							}
							
							{instanceType == 'customer' && !loadingInstance &&
							<>								
								<TableRow >
								  <TableCell style={{width:"200px"}} component="th" scope="row">Nome completo</TableCell>
								  <TableCell align="left">{instanceRow.CustomerProfile ? instanceRow.CustomerProfile.completeName : "-"}</TableCell>
								</TableRow>								

								<TableRow >
								  <TableCell style={{width:"200px"}} component="th" scope="row">Telefone</TableCell>
								  <TableCell align="left">{instanceRow.mobilePhone ? maskPhone(instanceRow.mobilePhone) : "-"}</TableCell>
								</TableRow>
								
								<TableRow >
								  <TableCell style={{width:"200px"}} component="th" scope="row">Email</TableCell>
								  <TableCell align="left">{instanceRow.email ? instanceRow.email : "-"}</TableCell>
								</TableRow>
																
								<TableRow >
								  <TableCell colspan="2" component="th" scope="row"><Button label="Abrir cadastro em nova aba" icon="pi pi-check" className="p-button-outlined p-button-sm" onClick={()=>window.open("/admin/"+instanceType+"/"+instanceId)} /></TableCell>
								</TableRow>	
							</>
							}
							
							{instanceType == 'provider' && !loadingInstance &&
							<>								
								<TableRow >
								  <TableCell style={{width:"200px"}} component="th" scope="row">Empresa</TableCell>
								  <TableCell align="left">{instanceRow.ProviderProfile ? instanceRow.ProviderProfile.ProviderProfileCompany.realName : "-"} ({instanceRow.ProviderProfile ? instanceRow.ProviderProfile.ProviderProfileCompany.fantasyName : "-"})</TableCell>
								</TableRow>								

								<TableRow >
								  <TableCell style={{width:"200px"}} component="th" scope="row">Nome Prestador</TableCell>
								  <TableCell align="left">{instanceRow.ProviderProfile ? instanceRow.ProviderProfile.ProviderProfilePerson.completeName : "-"}</TableCell>
								</TableRow>
								
								<TableRow >
								  <TableCell style={{width:"200px"}} component="th" scope="row">Telefone</TableCell>
								  <TableCell align="left">{instanceRow.mobilePhone ? maskPhone(instanceRow.mobilePhone) : "-"}</TableCell>
								</TableRow>
								
								<TableRow >
								  <TableCell style={{width:"200px"}} component="th" scope="row">Email</TableCell>
								  <TableCell align="left">{instanceRow.email ? instanceRow.email : "-"}</TableCell>
								</TableRow>
								
								<TableRow >
								  <TableCell colspan="2" component="th" scope="row"><Button label="Abrir cadastro em nova aba" icon="pi pi-check" className="p-button-outlined p-button-sm" onClick={()=>window.open("/admin/"+instanceType+"/"+instanceId)} /></TableCell>
								</TableRow>	
							</>
							}
								

							</TableBody>
						  </Table>
						</TableContainer>

						{/* Service Details Block */}
						{serviceOrderId &&
						<>
						<TableContainer component={Box} mt={2}>
						  <Table  aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Dados do Serviço</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>
								
							{loadingServiceOrder && 
							<>								
								<TableRow >
								  <TableCell colpsan="2" component="th" scope="row">
									<div style={{display:"flex",flex:1,alignItems:"center",justifyContent:"center"}}>
										<CircularProgress color="primary" />
										<div style={{fontSize:"18px",marginLeft:"15px"}}> Carregando dados...</div>
									</div>							  
								  </TableCell>
								</TableRow>	
							</>																
							}								
								
							{!loadingServiceOrder &&
							<>
								<TableRow >
								  <TableCell style={{width:"200px"}} component="th" scope="row">Nome do serviço</TableCell>
								  <TableCell align="left">{serviceOrderRow.ServiceOrderService ? serviceOrderRow.ServiceOrderService.name : "-"}</TableCell>
								</TableRow>								

								<TableRow >
								  <TableCell style={{width:"200px"}} component="th" scope="row">Descrição do serviço</TableCell>
								  <TableCell align="left">{serviceOrderRow.ServiceOrderService ? serviceOrderRow.ServiceOrderService.shortDescription : "-"}</TableCell>
								</TableRow>
								
								<TableRow >
								  <TableCell style={{width:"200px"}} component="th" scope="row">Agendado para</TableCell>
								  <TableCell align="left">{serviceOrderRow.scheduledDate ? moment(serviceOrderRow.scheduledDate).format("DD/MM/YYYY") : "-"}</TableCell>
								</TableRow>	
								
								{serviceOrderRow.finishedAt &&
								<>
								<TableRow >
								  <TableCell style={{width:"200px"}} component="th" scope="row">Executado em</TableCell>
								  <TableCell align="left">{moment(serviceOrderRow.finishedAt).format("DD/MM/YYYY")}</TableCell>
								</TableRow>	
								</>								
								}
																
								<TableRow >
								  <TableCell colspan="2" component="th" scope="row"><Button label="Abrir serviço em nova aba" icon="pi pi-check" className="p-button-outlined p-button-sm" onClick={()=>window.open("/admin/service/"+serviceOrderId)} /></TableCell>
								</TableRow>
								</>
							}
								

							</TableBody>
						  </Table>
						</TableContainer>	
						</>
						}
						
						
					  </CardBody>
					</Card>	 					


				  </GridItem>
				</GridContainer>
				</>
				}
				
			</Dialog>	
	</>	
	);
	
});


export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(Complaints);	
