import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {useHistory} from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
 
// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import moment from "moment";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import Logo from "../../assets/img/logoTypeWOsloganNew.svg";

import styles from "../../assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

// Load Actions
import * as AppActions from 'Store/Actions/App'
import * as LoginActions from 'Store/Actions/Login'

const useStyles = makeStyles(styles);

const LoginPage = (props) => {
	
		
	const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
	React.useEffect(() => {
	let id = setTimeout(function () {
	  setCardAnimation("");
	}, 700);
	// Specify how to clean up after this effect:
	return function cleanup() {
	  window.clearTimeout(id);
	};
	});
  
const { 
	processLogin
} = props;  
  

const classes = useStyles();

const [loading, setLoading] = React.useState(false);
const [password, setPassword] = React.useState("");
const [email, setEmail] = React.useState("");

const [dialog, setDialog] = React.useState(false); // state for tab
const [dialogMessage, setDialogMessage] = React.useState(""); // state for tab

// set Use of history for PUSH 
let history = useHistory();

const proccessLoginAction = () => {

	// check of terms of urgency was accepted
	if (!password || !email) {			
		setDialogMessage("Preencha o email e a senha para prosseguir");
		setDialog(true);
		return;			
	} // end if	

	setLoading(true);
	
	// Set Service Schedule
	let data = {
		email:email,
		password:password
	}
	var propsParam = {
		history:history,
		setDialogMessage:setDialogMessage,
		setDialog:setDialog,
		setLoading:setLoading
	}	
	processLogin(data,propsParam);

};	

const handleKeypress = e => {	
	 //it triggers by pressing the enter key  
	if (e.key === "Enter") {
	  proccessLoginAction();
	}
};	
  
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                style={{backgroundColor:"#FFF",padding:"25px",borderBottomWidth:"1px",borderBottomColor:"#EEE",borderBottomStyle:"solid"}}
				
              >
				<img src={Logo} style={{marginBottom:"20px",width:"90%",height:"auto"}} />
                <div style={{color:"#333",padding:"10px"}}>
					Faça o login para acessar o BackOffice
					Se não tem um login, você está no lugar errado.               
                </div>
              </CardHeader>
              <CardBody>
			  
			  <div>Digite seu email e sua senha para entrar.</div>
				
				<TextField
				  label="Email"
				  style={{ margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%"}}
				  placeholder="Informe o email"
				  required
				  size="small"
				  type="email"
				  onKeyPress={handleKeypress}
				  value={email}
				  onChange={(event) => {
					  setEmail(event.target.value)
				  }}
				/>
								
				<TextField
				  label="Senha"
				  style={{ margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%"}}
				  placeholder="Informe a senha"
				  required
				  size="small"
				  type="password"
				  onKeyPress={handleKeypress}
				  value={password}
				  onChange={(event) => {
					  setPassword(event.target.value)
				  }}	
				/>					
				
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
				
					<Button size="lg" block disabled={loading} variant="contained" onClick={()=>proccessLoginAction(props)}>
						{ !loading &&
						   <div>Entrar no backoffice</div>
						}
						
						{ loading &&
						<>
						   <CircularProgress size={30} color="inherit" />
						   <span style={{marginLeft:"10px"}}>Autenticando...</span>
						</>
						}
					</Button>				
				
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
	  
			<AlertDialog 
				dialog={dialog}
				setDialog={setDialog}
				dialogMessage={dialogMessage}
			/>		  
	  
    </div>
  );
}

const AlertDialog = (props) => {
	
	return (
		 <Dialog
			open={props.dialog}
			onClose={() => props.setDialog(false)} 
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		  >
		  <DialogTitle id="alert-dialog-title">Verifique as informações</DialogTitle>
			<DialogContent>
			  <DialogContentText id="alert-dialog-description">
			  {props.dialogMessage}
			  </DialogContentText>
			</DialogContent>
			<DialogActions>
			  <Button onClick={() => props.setDialog(false)} color="primary" autoFocus>
				OK
			  </Button>
			</DialogActions>			
		  </Dialog>
	  )
	
	
} // end AlertDialog


const mapStateToProps = store => ({
  buttonLoading:store.appReducer.buttonLoading,  
  providersApproval:store.providerReducer.providersApproval,
  providerApproval:store.providerReducer.providerApproval
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions, ...LoginActions }, dispatch);


export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(LoginPage);	
