import * as types from '../actionTypes';

const initialState = { 
  buttonLoading: true,
  appLoading: true,	
  dialog:{},

  adminUsers: [],
  adminUser: {},
  
  services: [],
  service: {},

  categoriesTree: [],
  categories: [],
  category: {},
  
  regions:[],
  
  payments: [],
  payment: {},

  report: {},

  currentFilter:null
}
 
export const appReducer = (state = initialState, action) => {
		
    switch (action.type) {	
		
		case types.APP_LOADING:
			return { ...state, appLoading: action.payload };
        
		case types.SET_DIALOG:
            return { ...state, dialog: action.payload }	
		
		case types.SET_ADMINUSERS:
			return { ...state,adminUsers: action.payload };	
		
		case types.SET_ADMINUSER:
			return { ...state,adminUser: action.payload };		
		
		case types.SET_SERVICES:
			return { ...state,services: action.payload };	
		
		case types.SET_SERVICE:
			return { ...state,service: action.payload };
				
		case types.SET_CATEGORIES:
			return { ...state,categories: action.payload };	
			
		case types.SET_CATEGORIESTREE:
			return { ...state,categoriesTree: action.payload };	
			
		case types.SET_REGIONS:
			return { ...state,regions: action.payload };	
		
		case types.SET_CATEGORY:
			return { ...state,category: action.payload };
						
		case types.SET_PAYMENTS:
			return { ...state,payments: action.payload };	
		
		case types.SET_PAYMENT:
			return { ...state,payment: action.payload };
		
		case types.SET_REPORT:
			return { ...state,report: action.payload };	

		case types.SET_CURRENT_FILTER:
			return { ...state,currentFilter: action.payload };	
        
		default:
            return state
    }
}